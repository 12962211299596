/* @flow */
import * as React from 'react';
import { type Map } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import cx from 'classnames';
import { summaryEntriesSelector } from 'domain/journal';
import { type EntriesType } from 'domain/journal/helper';
import { GridTable as Grid } from '../grid';
import { getMetaRange } from '../helpers';
import { getDataCell, isMandatory, isActive } from './helpers';
import { renderColgroup } from './component';
import { alpha } from '@mui/material';

type Props = {|
  classes: {|
    [key: string]: string,
  |},
  data: Map<string, EntriesType>,
  getItem: (d: ?EntriesType) => React$Node,
  active: string,
|};

const sheet = (theme) => ({
  container: {
    marginTop: 20,
    paddingLeft: 30,
    position: 'relative',
  },
  wrapper: {
    border: '1px solid #F0F0F0',
    borderTop: `2px solid ${theme.palette.primary.main}`,
    position: 'relative',
    padding: [15, 20],
    borderRadius: 4,
    backgroundColor: alpha(theme.palette.primary.main, 0.02),
  },
  tableRemove: {
    width: 30,
  },
  column: {
    width: '20%',
  },
  row: {
    backgroundColor: 'transparent',
    '&:last-child $col:after': {
      display: 'none',
    },
  },
  col: {
    '&:after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      top: '100%',
      left: 0,
      right: 0,
      borderBottom: '1px solid #DEE2E6',
    },
    '&:nth-child(4)': {
      '&:after': {
        display: 'none',
      },
    },
    '&:nth-child(5)': {
      textAlign: 'right',
      '&:after': {
        display: 'none',
      },
    },
  },
});

class ManualSettingsLayout extends React.Component<Props> {
  get meta() {
    const meta = getMetaRange(this.props.data);
    return [['A', 'B', 'C', 'D', 'E'], meta[1]];
  }

  getCollStyle = (col: string, row: number) => {
    const { classes, active } = this.props;
    const entry = this.getData(row, col);
    return cx(classes.col, {
      [classes.mandatory]: isMandatory(entry),
      [classes.active]: isActive(col, row, active),
    });
  };

  getData = (row: number, col: string) => getDataCell(this.props.data, row, col);

  getItem = (row: number, col: string) => this.props.getItem(this.getData(row, col));

  getRowStyle = () => {
    const { classes } = this.props;
    return classes.row;
  };

  getItem = (row: number, col: string) => this.props.getItem(getDataCell(this.props.data, row, col));

  colgroup = () => {
    const { classes } = this.props;
    return renderColgroup([{ id: 'A', className: classes.column, span: 5 }]);
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <Grid
            meta={this.meta}
            getRowStyle={this.getRowStyle}
            getCollStyle={this.getCollStyle}
            getItem={this.getItem}
            colgroup={this.colgroup}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: summaryEntriesSelector(state),
});

export default compose(connect(mapStateToProps), withStyles(sheet, { withTheme: true }))(ManualSettingsLayout);
