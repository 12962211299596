// @flow
import React from 'react';

import AvatarLetter from 'components/mui/AvatarLetter';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ChipMui from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import PhoneIcon from '@mui/icons-material/Phone';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material';

type TAppBarLogo = {
  name: string, // in future maybe need separate title and avatar name
  src?: string,
  phone?: string,
  email?: string,
  isAllowPhoneLink?: boolean,
};

const Chip = styled(ChipMui)(({ theme }) => ({
  // by design, cant use spacing or pxToRem :(
  height: 18,
  borderRadius: 4,
  fontSize: 12,
  backgroundColor: alpha(theme.palette.primary.main, 0.12),

  '& .MuiChip-icon': {
    fontSize: 16,
  },
  '& .MuiChip-label': {
    direction: 'ltr /* @noflip */',
  },
}));

const Title = styled(Typography)(() => ({
  // by design, cant use spacing or pxToRem :(
  lineHeight: 1,
}));

const AppBarLogoBase: React$StatelessFunctionalComponent<TAppBarLogo> = ({
  src,
  name,
  email,
  phone,
  isAllowPhoneLink = false,
}) => (
  <Box display="flex" alignItems="center">
    <AvatarLetter src={src} name={name} />
    <Box ml={1.5}>
      <Title variant="subtitle1">{name}</Title>
      {(email || phone) && (
        <Stack direction="row" spacing={1} marginTop="6px">
          {email && (
            <Chip
              clickable
              color="primary"
              variant="outlined"
              icon={<EmailOutlinedIcon />}
              label={email}
              component="a"
              href={`mailto:${email}`}
            />
          )}
          {phone && (
            <Chip
              clickable={isAllowPhoneLink}
              color={isAllowPhoneLink ? 'primary' : 'default'}
              variant="outlined"
              icon={<PhoneIcon />}
              label={phone}
              {...(isAllowPhoneLink && {
                component: 'a',
                href: `https://api.whatsapp.com/send?phone=${phone}`,
                target: '_blank',
                rel: 'noopener noreferrer',
              })}
            />
          )}
        </Stack>
      )}
    </Box>
  </Box>
);

export default AppBarLogoBase;
