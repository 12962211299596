// @flow
import React, { useContext, cloneElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, generatePath } from 'react-router-dom';
import { hrefObjectToString } from 'domain/router/helpers';
import type { RecordOf } from 'immutable';
import { FavoritesItem } from 'domain/dashboard/type.js.flow';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { categoryAllSelector } from 'domain/categories';
import { deleteFavoritesAction } from 'domain/dashboard';

import CompanyContext from 'pages/company/CompanyContext';
import useFavorites from 'pages/company/CompanyNavigation/FavoritesMenu/useFavorites';

import RouterLink from 'components/mui/Router/RouterLink';
import { ListItemIcon } from 'components/mui/Layouts/components/Drawer';
import { FavoriteListLink } from 'pages/company/CompanyNavigation/FavoritesMenu/StyledComponents';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AvatarMui from '@mui/material/Avatar';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { useTheme } from '@mui/material';

type TFavoritesListItem = {
  entry: RecordOf<FavoritesItem>,
};

const FAVORITE_TYPES = {
  system: 'system',
  user: 'user',
  userTag: 'userTag',
};

const FAVORITE_ICONS = {
  system: <SearchOutlinedIcon />,
  user: <SavedSearchIcon />,
};

const FavoritesListItem: React$StatelessFunctionalComponent<TFavoritesListItem> = ({ entry }) => {
  const dispatch = useDispatch();
  const categoryAll = useSelector(categoryAllSelector);
  const { companyType } = useContext(CompanyContext);
  const { getUserBySearchLink, isCurrentUser } = useFavorites();
  const theme = useTheme();
  const { companyId, category1 } = useParams();

  const getFavoriteType = (favoriteEntry: RecordOf<FavoritesItem>) =>
    favoriteEntry.system ? FAVORITE_TYPES.system : FAVORITE_TYPES.user;

  const redirectParams = {
    companyId,
    category1: categoryAll ? categoryAll.id : category1,
  };
  const search = entry.system && entry.query ? `${entry.query}&label=${entry.label}` : entry.query;
  const ROUTE_ID =
    companyType === 'confidential' ? ROUTES_PATH.COMPANY_CONFIDENTIAL_WORKSPACE.id : ROUTES_PATH.COMPANY_WORKSPACE.id;
  const user = getUserBySearchLink(entry.query);
  const isIAm = user ? isCurrentUser(user.userId) : false;
  const favoriteType = user ? FAVORITE_TYPES.userTag : getFavoriteType(entry);

  const linkTo = hrefObjectToString({
    pathname: generatePath(ROUTES_PATH[ROUTE_ID].absolute, redirectParams),
    search,
  });

  const onDeleteFavorite = (e) => {
    e.preventDefault();

    dispatch(deleteFavoritesAction({ ID: entry.ID }));
  };

  return (
    <FavoriteListLink component={RouterLink} href={linkTo}>
      <ListItemIcon minWidth={24} sx={{ color: 'inherit' }}>
        {user ? (
          <AvatarMui src={user.picture} imgProps={{ loading: 'lazy' }} sx={{ width: 24, height: 24 }} />
        ) : (
          cloneElement(FAVORITE_ICONS[favoriteType], { sx: { fontSize: 20 } })
        )}
      </ListItemIcon>
      <ListItemText disableTypography sx={{ my: 0 }}>
        <Typography
          variant="body2"
          noWrap
          sx={{ fontSize: theme.typography.pxToRem(12), color: 'inherit', fontWeight: isIAm ? '500' : '400' }}
        >
          {user ? user.username : entry.label}
        </Typography>
      </ListItemText>
      {!entry.system && (
        <IconButton onClick={onDeleteFavorite} color="inherit" edge="end">
          <CloseOutlinedIcon sx={{ fontSize: 12 }} />
        </IconButton>
      )}
    </FavoriteListLink>
  );
};

export default FavoritesListItem;
