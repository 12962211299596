// @flow
import { asyncAction, syncAction } from 'lib/action';

export const setOrganization = asyncAction('organization/SET_ORGANIZATION');
export const getOrganization = asyncAction('organization/GET_ORGANIZATION');
export const getOrganizationList = asyncAction('organization/GET_ORGANIZATION_LIST');
export const getBackupSSOParamsAction = asyncAction('organization/GET_ORGANIZATION_BACKUP_SSO_PARAMS');
export const disconnectOrgBackupAction = asyncAction('organization/DISCONNECT_ORGANIZATION_BACKUP');
export const clearOrganization = syncAction('organization/CLEAR_ORGANIZATION');
export const getOrganizationSignings = asyncAction('organization/GET_ORGANIZATION_SIGNINGS');
export const getOrganizationFeatures = asyncAction('organization/GET_ORGANIZATION_FEATURES');
export const setOrganizationFeaturesAction = asyncAction('organization/SET_ORGANIZATION_FEATURES');
export const getOrgBackupConnections = asyncAction('organization/GET_ORGANIZATION_BACKUP_CONNECTION');
export const updateOrganizationSignings = asyncAction('organization/UPDATE_ORGANIZATION_SIGNINGS');
export const setOrganizationSigningsGuid = asyncAction('organization/SET_ORGANIZATION_SIGNINGS_GUID');
export const removeOrganizationSigner = asyncAction('organization/REMOVE_ORGANIZATION_SIGNER');
export const removeAllOrganizationSigner = asyncAction('organization/REMOVE_ALL_ORGANIZATION_SIGNER');
export const addEmptyOrganizationSigner = syncAction('organization/ADD_EMPTY_ORGANIZATION_SIGNER');
export const createOrganization = asyncAction('organization/CREATE_ORGANIZATION');
export const updateOrganization = asyncAction('organization/UPDATE_ORGANIZATION');
export const uploadLogoOrganization = asyncAction('organization/UPLOAD_LOGO_ORGANIZATION');
export const setOrganizationsFilter = syncAction('organization/SET_FILTER');
export const setOrganizationsSort = syncAction('organization/SET_SORT');
export const getPasswordExpirationAction = asyncAction('organization/GET_PASSWORD_EXPIRATION');
export const updatePasswordExpirationAction = asyncAction('organization/UPDATE_PASSWORD_EXPIRATION');
// sets org id to filter companies by
export const setCurrentOrganization = syncAction('organization/SET_CURRENT_ORGANIZATION');
export const unsetCurrentOrganization = syncAction('organization/UNSET_CURRENT_ORGANIZATION');
export const resetMessages = syncAction('organization/RESET_MESSAGES');
