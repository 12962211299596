import { LicenseManager } from 'ag-grid-enterprise';

import useAgGridGlobalTheme from 'hooks/agGrid/useAgGridGlobalTheme';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE);

const useAgGridDefaultConfig = (useCustomStyles = () => {}) => {
  useAgGridGlobalTheme();
  useCustomStyles();

  return {
    // this is needed for tooltip,
    // columnHoverHighlight add .ag-column-hover on cell for finding hovered cell
    columnHoverHighlight: true,
    suppressCellFocus: true,
    suppressRowClickSelection: true,
    suppressClickEdit: true,
    suppressMaxRenderedRowRestriction: true,
    suppressMenuHide: true,
    suppressScrollOnNewData: true,
    // suppressColumnMoveAnimation: true,
    enableRangeSelection: true,
    enableRangeHandle: true,
    rowSelection: 'multiple',
    loadingOverlayComponent: 'loadingOverlay',
    localeText: {
      applyFilter: 'OK',
      cancelFilter: 'Cancel',
      resetFilter: 'Clear Filter',
    },
    tooltipShowDelay: 0,
    defaultColDef: {
      sortable: true,
      resizable: true,
      enableValue: true,
      minWidth: 100,
    },
    statusBar: {
      statusPanels: [
        {
          statusPanel: 'agTotalAndFilteredRowCountComponent',
          align: 'left',
        },
        { statusPanel: 'agFilteredRowCountComponent' },
        { statusPanel: 'agSelectedRowCountComponent' },
        {
          statusPanel: 'agAggregationComponent',
          statusPanelParams: {
            // possible values are: 'count', 'sum', 'min', 'max', 'avg'
            aggFuncs: ['count'],
          },
        },
        {
          statusPanel: 'customSum',
        },
      ],
    },
    getRowId: (data) => data.documentID,
    getContextMenuItems: () => ['copy', 'copyWithHeaders', 'paste'],
    getRowStyle: (e) => {
      if (e.node.rowPinned) return { border: 'none' };
      return e.node.detail ? { overflowY: 'auto', backgroundColor: '#fff' } : undefined;
    },
    getMainMenuItems: (e: {| defaultItems: string[] |}) => {
      const {
        defaultItems = [],
        column: { colId },
      } = e;

      const currentItems = Array.isArray(defaultItems) && defaultItems.slice(0, -3);

      // suppress autosize menu option for system columns
      return colId.startsWith('__') ? currentItems.filter((i) => !['autoSizeThis'].includes(i)) : currentItems;
    },
  };
};

export default useAgGridDefaultConfig;
