// flow
import React, { memo } from 'react';

import AuthPaperBox from 'pages/authPage/Layout/PaperBox';
import LoginForm from 'pages/authPage/DefaultAuth/Form';
import AuthBuildVersion from 'pages/authPage/Layout/BuildVersion';

const DefaultAuth = () => (
  <>
    <AuthPaperBox>
      <LoginForm />
    </AuthPaperBox>
    <AuthBuildVersion />
  </>
);

export default memo(DefaultAuth);
