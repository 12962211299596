// @flow
export default {
  primaryLightest: '#6C98D8', // processed
  rgbPrimaryLightest: '108,152,216',
  primaryLight: '#3F6DB2', // processed
  rgbPrimaryLight: '63,109,178',
  primaryDark: '#456595', // processed
  rgbPrimaryDark: '69,101,149', // processed

  // TODO: mixed up the name of colors - dark and light
  //  dark its light, light its dark
  secondaryDark: '#139D29',
  rgbSecondaryDark: '19, 157, 41',
  secondaryLight: '#107520',
  rgbSecondaryLight: '16, 117, 32',

  primaryWhite: '#FFF',
  greyLightest: '#F8F9FA', // processed
  greyLighter: '#EFEFEF', // processed
  greyLight: '#DEE2E6',
  greyDark: '#D3D6D9',
  greyDarker: '#8C9BA5',
  greyDarkest: '#5A6872',
  greyBlack: '#171A1C',
  primaryDanger: '#BC5353',
  primaryGreen: '#139D29',
  lightGreen: '#107520',
  primaryYellow: '#EBB908',
  primaryBlue: '#3F6DB2',
  // colors not represented in figma theme
  primaryOrange: '#F47F2A',
  indigo: '#4F74D1', // Name that Color
  wildSand: '#F5F5F5', // https://chir.ag/projects/name-that-color/#F5F5F5,
  buttonGradientStart: 'rgba(12, 112, 234, 1)',
  buttonGradientEnd: 'rgba(0, 205, 128, 1)',
};
