// @flow
import {
  Record,
  Map,
  Set,
  OrderedSet,
  type RecordOf,
  type RecordFactory,
  type List as ListType,
  List,
  OrderedMap,
} from 'immutable';
import type { CancelToken } from 'axios';
import { signOutAction } from 'domain/env/envActions';
import { companySignInAction } from 'domain/companies/companiesActions';
import { type JournalStore, RecordCellSet } from 'domain/journal/helper';
import { type UnreadRequestDocType } from 'domain/contracts';
import * as A from './documentsActions';
import * as Company from '../companies/companiesActions';
import * as Reconciliation from '../reconciliation/actions';
import type {
  TViewArrangement,
  GridHeaderItemType,
  GridFiltersType,
  GridSortingType,
  GridSortingRecordListType,
  TDocumentHotkey,
  THotkeysState,
  TDocumentHotkeysRecord,
  THotkeyMatchCellListItem,
  THotkeyMatch,
  GridPresetType,
  TSupplierStateDocCounts,
  TSupplierPageTokens,
} from './types.js.flow';
import type { TPublishTypes } from 'pages/document/components/DocumentPublishButton/DropdownButton/types.js.flow';

// eslint-disable-next-line import/no-cycle
import { wsGridPresetsAdapter, wsGridSharePresetAdapter } from './adapters';

import moment from 'moment';
import { type ColDef } from 'ag-grid-react';
import { NOTE_PALETTE_DEFAULT_KEY } from 'components/LabeledThemeProvider/notesPalette';
import { documentResetExportFormatsLoaded } from './documentsActions';

// freese to interprete values as 'literals' instead of 'string'
export const TypeTags = /* :: Object.freeze( */ {
  _T_FIN: 'financial',
  _T_GEN: 'general',
}; /* ::) */

// freese to interprete values as 'literals' instead of 'string'
export const OptionalTags = /* :: Object.freeze( */ {
  _S_PROTECTED: 'protected',
}; /* ::) */

export const PairsTagsStatuses = [
  {
    status: 'signed',
    tags: Set.of('_S_SIG_DOKKA1_SIGNED', '_S_SIG_DOKKA2_SIGNED'),
  },
  {
    status: 'pending',
    tags: Set.of('_S_SIG_DOKKA1_PENDING', '_S_SIG_DOKKA2_PENDING'),
  },
  {
    status: 'pending',
    tags: Set.of('_S_SIG_DOKKA1_SIGNED', '_S_SIG_DOKKA2_PENDING'),
  },
  {
    status: 'pending',
    tags: Set.of('_S_SIG_DOKKA1_PENDING', '_S_SIG_DOKKA2_SIGNED'),
  },
];

export const StatusTags = /* :: Object.freeze( */ {
  _S_NEW: 'new',
  _S_READ: 'read',
  _S_ACCEPTED: 'accepted',
  _S_REJECTED: 'rejected',
  _S_SIG_ORIGINAL: 'signed',
  _S_PREVIEW_STATUS: 'isBeingProcessed',
  _S_SCHEDULED_ACCEPTANCE: 'isScheduledAcceptance',
}; /* ::) */

// eslint-disable-next-line max-len
const pairsTags = PairsTagsStatuses.map((pair) => pair.tags).reduce((res, tags) => res.concat(tags), new Set());

export const StatusTagsSet = Set.fromKeys(StatusTags).concat(pairsTags);

export type TypeTag = $Keys<typeof TypeTags>;

export type StatusTag = $Keys<typeof StatusTags>;

export type ProcessedTypeTag = $Values<typeof TypeTags>;

export type ProcessedStatusTag = $Values<typeof StatusTags>;

export type OptionalTag = $Keys<typeof OptionalTags>;

export type Tag =
  | TypeTag
  | StatusTag
  | '_S_CONFIDENTIAL'
  | '_S_APPROVAL_COMPLETE'
  | '_S_APPROVAL_PENDING'
  | '_S_SCHEDULED_APPROVAL';

export type AngleType = 0 | 90 | 180 | 270 | 360 | -90 | -180 | -270;

export type ExportFormat = {
  [key: string]: string,
};

export type StoreExportFormats = Map<ExportFormat>;

export type ViewinfoType = {|
  pages: number,
  rotations: ListType<AngleType>,
|};

export type Document = {|
  documentID: string,
  created: string,
  favorite: boolean,
  isAcceptable: boolean,
  doc: string,
  sourceID: ?string,
  versionID: ?string,
  docStatus: string,
  finInfo?: mixed,
  folderID?: mixed,
  new: number,
  notes: string,
  notes_color: string,
  source: string,
  total: number,
  type: number,
  tags: Set<Tag>,
  doctype: ProcessedTypeTag,
  status: Array<ProcessedStatusTag>,
  recentTags: Set<Tag>,
  protected: boolean,
  reason: string,
  viewinfo: RecordOf<ViewinfoType>,
  linkid: string,
  ltext: string,
  rootCategory: ?boolean,
  approvals: Map<*, *>,
  gridMeta: Map<*, *>,
  paymentData: { [key: string]: string },
  fromEmail: boolean,
  indicateWarning: boolean,
  canBeMoved: boolean,
|};

export type RawValidatedDocument = {|
  ...Document,
  details?: JournalStore,
|};

// @to-do check why type intersaction doesnt work here. ValidatedDocument = Document & {}
export type ValidatedDocument = {|
  ...Document,
  acceptValidationError: ?string,
  lastModifiedDate: string,
|};

function createViewInfo() {
  return {
    pages: 0,
    rotations: new List(),
  };
}

export const ViewinfoFactory: RecordFactory<ViewinfoType> = new Record(createViewInfo());

export const DocumentFactory: RecordFactory<Document> = new Record({
  documentID: '',
  created: '',
  favorite: false,
  isAcceptable: false,
  doc: '',
  docStatus: '',
  finInfo: null,
  sourceID: null,
  versionID: null,
  folderID: null,
  new: 0,
  notes: '',
  notesColor: NOTE_PALETTE_DEFAULT_KEY,
  source: '',
  total: 0,
  type: 2,
  tags: new Set(),
  recentTags: new Set(),
  doctype: 'general',
  status: ['new'],
  reason: '',
  protected: false,
  viewinfo: ViewinfoFactory(),
  linkid: '',
  ltext: '',
  rootCategory: null,
  isConfidential: false,
  approvals: new Map(),
  gridMeta: new Map(),
  paymentData: {},
  fromEmail: false,
  indicateWarning: false,
  stateColumn: null,
  canBeMoved: false,
});

export const ValidatedDocumentFactory: RecordFactory<ValidatedDocument> = new Record({
  documentID: '',
  created: '',
  doc: '',
  docStatus: '',
  finInfo: null,
  sourceID: null,
  versionID: null,
  folderID: null,
  new: 0,
  notes: '',
  notesColor: NOTE_PALETTE_DEFAULT_KEY,
  source: '',
  total: 0,
  type: 2,
  tags: new Set(),
  recentTags: new Set(),
  doctype: 'general',
  status: ['new'],
  reason: '',
  protected: false,
  viewinfo: ViewinfoFactory(),
  linkid: '',
  ltext: '',
  acceptValidationError: null,
  rootCategory: null,
  isConfidential: false,
  approvals: new Map(),
  gridMeta: new Map(),
  fromEmail: false,
  indicateWarning: false,
  lastModifiedDate: '',
  approvalsActiveGroupId: null,
  canBeMoved: false,
});

declare type Actions = {
  type: string,
  payload?: mixed,
};

export type DocumentsType = RecordOf<Document>;
export type ValidatedDocumentsType = RecordOf<ValidatedDocument>;
export type DocumentsList = Map<string, DocumentsType>;

type SearchTag = {|
  text: string,
|};

type ParamsRaw = {|
  category?: ?number,
  creationDate: ?Date,
|};

type ParamsFactory = {|
  category: ?number,
  creationDate: ?string,
  subject: ?string,
  uploadText: ?string,
  ccEmailList: ?(string[]),
  linkID: ?string,
|};

export const paramsFactory: RecordFactory<ParamsFactory> = new Record({
  category: null,
  creationDate: null,
  subject: null,
  uploadText: null,
  ccEmailList: null,
  linkID: null,
});
export type QueueItemStatus = 'unsupportedType' | 'ok' | 'pending' | 'failure' | 'uploading' | 'maxFileSizeError';

export type QueueItemType = {|
  id: string,
  path: string,
  status: QueueItemStatus,
  progress: number,
  cancelToken: ?CancelToken,
  file: ?File,
  params: RecordOf<ParamsFactory>,
  isConfidential: boolean,
|};

type QueueItemTypeRaw = {|
  id: string,
  path: string,
  status?: QueueItemStatus,
  progress?: number,
  cancelToken?: ?CancelToken,
  file?: ?File,
  params?: ParamsRaw,
  isConfidential?: boolean,
|};

export const paramsAdapter = (data?: ParamsRaw) => {
  const { creationDate: creation, ...rest } = data || {};

  const creationDate =
    creation && !moment(creation).isSame(moment(), 'day') ? creation.format('YYYY-MM-DD[T]HH:mm:ss') : null;
  return paramsFactory({ creationDate, ...rest });
};

export const uploadQueueItemFactory: RecordFactory<QueueItemType> = new Record({
  id: '',
  path: '',
  status: 'pending',
  progress: 0,
  cancelToken: null,
  file: null,
  params: paramsFactory(),
  isConfidential: false,
});

export const uploadQueueItem = ({ params, ...rest }: QueueItemTypeRaw) =>
  uploadQueueItemFactory({
    params: paramsAdapter(params),
    ...rest,
  });

type SearchTags = Array<SearchTag>;
type Linked = {
  count: number,
  pageToken: ?string,
  tag: ?SearchTags,
  list: DocumentsList,
  isOpen: boolean,
};

type TCachedDocument = {
  hash?: string,
  downloadProgress: number,
};

export const cachedDocumentFactory: RecordFactory<TCachedDocument> = new Record({
  hash: null,
  downloadProgress: 0,
});

type Documents = {|
  list: DocumentsList,
  gridHeadersList: List<GridHeaderItemType>,
  recentTags?: Set<Tag>,
  recentTagsCompanyId: ?string,
  companyId?: string,
  dokkaToken: string,
  document?: ValidatedDocument,
  linked: RecordOf<Linked>,
  linkingTag: string,
  editableDoc?: ValidatedDocument,
  pageToken: string,
  searchTags: SearchTags,
  uploadQueue: Map<string, RecordOf<QueueItemType>>,
  erpDuplicated: Set<string>,
  exportFormats: ?StoreExportFormats,
  exportFormatsLoaded: boolean,
  searchQuery: string,
  count: number,
  processing: number,
  company_total: number,
  count_without_link_panels: number,
  unreadRequests: ListType<RecordOf<UnreadRequestDocType>>,
  unreadRequestsLoaded: boolean,
  viewArrangement?: TViewArrangement,
  fetchedDocumentsTimeStamp: ?string,
  latestWorkerTimeStamp: ?string,
  gridLoader: boolean,
  gridFilters: ?GridFiltersType,
  gridSorting: ?GridSortingRecordListType,
  gridColumns: ?(ColDef[]),
  gridDefaultColumns: ?(ColDef[]),
  gridPresets: GridPresetType[],
  gridPresetsLoaded: boolean,
  sharePreset: ?GridPresetType,
  currentPresetId: ?string,
  changesPresetId: ?string,
  gridReset: boolean,
  gridDocumentListWithFilters: OrderedSet<string>,
  hotkeys: TDocumentHotkeysRecord,
  stateColumn?: string,
  cachedDocuments: List<TCachedDocument>,
  justPublished?: TPublishTypes,
  loaded: boolean,
|};

export type DocumentsStore = RecordOf<Documents>;

const linkedFactory = new Record({
  count: 0,
  pageToken: null,
  tag: null,
  list: new Map(),
  isOpen: false,
});

export const HotkeyFactory: RecordFactory<TDocumentHotkey> = new Record({
  keys: new List(),
  description: '',
  level: '',
});

export const HotkeyCellListFactory: RecordFactory<THotkeyMatchCellListItem> = new Record({
  set: RecordCellSet(),
  name: '',
  cell: '',
});

export const HotkeyMatchFactory: RecordFactory<THotkeyMatch> = new Record({
  key: new List(),
  cell_list: new List(),
});

const HotkeysStateFactory: RecordFactory<THotkeysState> = new Record({
  isLoading: false,
  companyId: null,
  data: new List(),
});

const SupplierPageTokensFactory: RecordFactory<TSupplierPageTokens> = new Record({
  received: null,
  in_process: null,
  processed: null,
  paid: null,
  rejected: null,
});

const SupplierStateDocCountsFactory: RecordFactory<TSupplierStateDocCounts> = new Record({
  received: 0,
  in_process: 0,
  processed: 0,
  paid: 0,
  rejected: 0,
});

const supplierStateDocCountsWithoutLinkPanelsFactory: RecordFactory<TSupplierStateDocCounts> = new Record({
  received: 0,
  in_process: 0,
  processed: 0,
  paid: 0,
  rejected: 0,
});

export const DocumentsFactory: RecordFactory<Documents> = new Record({
  list: new OrderedMap(),
  gridHeadersList: new List(),
  document: ValidatedDocumentFactory(),
  editableDoc: ValidatedDocumentFactory(),
  linked: linkedFactory(),
  linkingTag: '',
  recentTags: new Set(),
  recentTagsCompanyId: null,
  pageToken: '',
  dokkaToken: '',
  companyId: '',
  searchTags: [],
  uploadQueue: new Map(),
  erpDuplicated: new Set(),
  exportFormats: new Map(),
  exportFormatsLoaded: false,
  searchQuery: '',
  count: 0,
  processing: 0,
  company_total: 0,
  count_without_link_panels: 0,
  unreadRequests: new List(),
  unreadRequestsLoaded: false,
  viewArrangement: null,
  fetchedDocumentsTimeStamp: null,
  latestWorkerTimeStamp: null,
  gridLoader: false,
  gridFilters: null,
  gridSorting: null,
  gridColumns: null,
  gridDefaultColumns: null,
  gridPresets: [],
  gridPresetsLoaded: false,
  sharePreset: null,
  currentPresetId: null,
  changesPresetId: null,
  gridReset: false,
  gridDocumentListWithFilters: new OrderedSet(),
  hotkeys: HotkeysStateFactory(),
  stateColumn: null,
  supplierPageTokens: SupplierPageTokensFactory(),
  supplierStateDocCounts: SupplierStateDocCountsFactory(),
  supplierStateDocCountsWithoutLinkPanels: supplierStateDocCountsWithoutLinkPanelsFactory(),
  cachedDocuments: new List(),
  justPublished: null,
  loaded: false,
});

function withOldGridMetaCB(document) {
  return (d) => d && document.set('gridMeta', d.gridMeta);
}
export const gridSortingFactory: RecordFactory<GridSortingType> = {
  colId: '',
  sort: 'asc',
};

export const reducer = {
  documents(state: DocumentsStore = DocumentsFactory(), action: Actions) {
    switch (action.type) {
      case companySignInAction.success:
        return state.set('dokkaToken', action.payload.dokkaToken).set('companyId', action.payload.companyId);

      case A.documentClearValidations.type:
        return state.setIn(['document', 'acceptValidationError'], null);

      case A.resetDocumentAction.type:
        return state.set('document', ValidatedDocumentFactory());

      case A.documentSearchAction.success:
        return state
          .update('list', (list) =>
            action.payload.infiniteScroll ? list.merge(action.payload.data.list) : action.payload.data.list,
          )
          .set('pageToken', action.payload.data.pageToken)
          .set('count', action.payload.data.count)
          .set('processing', action.payload.data.processing)
          .set('company_total', action.payload.data.company_total)
          .set('count_without_link_panels', action.payload.data.count_without_link_panels)
          .set('loaded', true);

      case A.supplierDocumentSearchAction.success:
        return state
          .update('list', (list) =>
            action.payload.infiniteScroll ? list.merge(action.payload.data.list) : action.payload.data.list,
          )
          .set('processing', action.payload.data.processing)
          .update('supplierPageTokens', (s) => s.set(action.payload.data.stateColumn, action.payload.data.pageToken))
          .update('supplierStateDocCounts', (s) => s.set(action.payload.data.stateColumn, action.payload.data.count))
          .update('supplierStateDocCountsWithoutLinkPanels', (s) =>
            s.set(action.payload.data.stateColumn, action.payload.data.count_without_link_panels),
          );

      case A.supplierDocumentRefreshAction.success:
        return (
          state
            // filtering first removes all docs for particular column
            // and merged new ones to ensure ones that were deleted are removed
            .update('list', (list) =>
              list.filter((item) => item.stateColumn !== action.payload.stateColumn).merge(action.payload.data.list),
            )
            .set('processing', action.payload.data.processing)
            .update('supplierPageTokens', (s) => s.set(action.payload.data.stateColumn, action.payload.data.pageToken))
            .update('supplierStateDocCounts', (s) => s.set(action.payload.data.stateColumn, action.payload.data.count))
            .update('supplierStateDocCountsWithoutLinkPanels', (s) =>
              s.set(action.payload.data.stateColumn, action.payload.data.count_without_link_panels),
            )
        );

      case A.documentWorkerSearch.success:
        return state
          .set('list', action.payload.list)
          .set('pageToken', action.payload.pageToken)
          .set('count', action.payload.count)
          .set('processing', action.payload.processing)
          .set('company_total', action.payload.company_total)
          .set('count_without_link_panels', action.payload.count_without_link_panels);

      case A.documentWorkerCompanyLatestTimestamp.success:
        return state.set('latestWorkerTimeStamp', action.payload);

      case A.documentForceSearch.success:
        return state
          .update('list', (list) =>
            action.payload.infiniteScroll ? list.merge(action.payload.data.list) : action.payload.data.list,
          )
          .set('count', action.payload.data.count)
          .update('count_without_link_panels', (count) =>
            action.payload.infiniteScroll ? count : action.payload.data.count_without_link_panels,
          )
          .set('processing', action.payload.processing);

      case A.documentGet.success:
        // @to-do remove approvals as they are set in approvals reducer
        return state.merge(action.payload);

      case A.documentUpdate.success:
        return state
          .merge(action.payload)
          .updateIn(['list', action.payload.document.documentID], withOldGridMetaCB(action.payload.document))
          .updateIn(['linked', 'list', action.payload.document.documentID], withOldGridMetaCB(action.payload.document));

      case A.markAsPaidAction.success:
        return state
          .merge(action.payload)
          .updateIn(['list', action.payload.document.documentID], withOldGridMetaCB(action.payload.document))
          .updateIn(['linked', 'list', action.payload.document.documentID], withOldGridMetaCB(action.payload.document));

      case A.documentIgnoreWarningsAction.success:
        return state
          .updateIn(['list', action.payload], (doc) => doc && doc.setIn(['indicateWarning'], false))
          .updateIn(['linked', 'list', action.payload], (doc) => doc && doc.setIn(['indicateWarning'], false))
          .updateIn(['document'], (doc) =>
            doc.documentID === action.payload ? doc.setIn(['indicateWarning'], false) : doc,
          );

      case A.documentGetLinkedAction.success:
      case A.documentGetLinkedForPreviewAction.success:
        return state.update('linked', (l) =>
          l
            .set('count', action.payload.count)
            .set('pageToken', action.payload.pageToken)
            .set('list', action.payload.list)
            .set('tag', action.payload.tag)
            .set('isOpen', action.payload.isOpen),
        );

      case A.lockLinkedDocsAction.success:
        return state
          .update('list', (list) => list.merge(action.payload.list.filter((el) => list.has(el.documentID))))
          .update('linked', (l) => l.set('list', action.payload.list).set('tag', action.payload.tag));

      case A.documentsBulkUpdateAction.success:
        return state
          .update('list', (l) => action.payload.documents || l)
          .updateIn(['linked', 'list'], (l) => action.payload.linked || l);

      case A.documentAccept.success:
        return state.set('document', action.payload.document);

      case A.documentsJustPublishedAction.type:
        return state.set('justPublished', action.payload);

      case A.documentSignShowDocument.success:
        return state.set('document', action.payload.document);

      case A.documentStopProcessingAction.success:
        return state.set('document', action.payload.document);

      case A.addEditableDoc.type:
        return state.set('editableDoc', state.list.get(action.payload));

      case A.setEditableDoc.type:
        return state.set('editableDoc', action.payload);

      case A.clearEditableDoc.type:
        return state.set('editableDoc', ValidatedDocumentFactory());

      case A.setLinkingTagAction.type:
        return state.set('linkingTag', action.payload);

      case A.documentUpdateTagsInList.success:
        return (
          state
            // eslint-disable-next-line max-len
            .updateIn(
              ['list', action.payload.document.documentID],
              (d) => d && d.merge(action.payload.document.set('gridMeta', d.gridMeta)),
            )
            .updateIn(
              ['linked', 'list', action.payload.document.documentID],
              (d) => d && d.merge(action.payload.document.set('gridMeta', d.gridMeta)),
            )
        );

      case A.documentMultipleUpdateTags.success: {
        return state
          .update(
            'list',
            (d) => d && d.mergeWith((oldV, newV) => newV.set('gridMeta', oldV.get('gridMeta')), action.payload),
          )
          .updateIn(
            ['linked', 'list'],
            (d) =>
              d &&
              d.merge(
                // only merge documents that are already in linked list,
                // as tags might be updated for docs that are not linked
                action.payload &&
                  action.payload.filter((document) => d.keySeq().toArray().includes(document.documentID)),
              ),
          );
      }

      case A.documentUnreadInFilteredList.success:
        return state
          .deleteIn(['list', action.payload.document.documentID])
          .updateIn(
            ['linked', 'list', action.payload.document.documentID],
            (d) => d && d.merge(action.payload.document),
          );

      case A.documentLinkInListAction.success: {
        const conditionalState = state
          // eslint-disable-next-line max-len
          .updateIn(
            ['list', action.payload.adapter.document.documentID],
            (d) => d && d.merge(action.payload.adapter.document),
          )
          .setIn(['linked', 'tag'], action.payload.tag);
        if (state.linked.tag === action.payload.tag) {
          // regular addition of linked document to list with same linked tag
          return (
            conditionalState
              // eslint-disable-next-line max-len
              .setIn(['linked', 'list', action.payload.adapter.document.documentID], action.payload.adapter.document)
          );
        }
        // document added has another linked tag, so we create new list with this doc only
        // and remove docs with other linked tags
        return (
          conditionalState
            // eslint-disable-next-line max-len
            .setIn(
              ['linked', 'list'],
              new Map({ [action.payload.adapter.document.documentID]: action.payload.adapter.document }),
            )
        );
      }

      case A.documentUnlinkInList.success:
        return (
          state
            // eslint-disable-next-line max-len
            .updateIn(['list', action.payload.document.documentID], (d) => d && d.merge(action.payload.document))
            .deleteIn(['linked', 'list', action.payload.document.documentID])
            .updateIn(['linked', 'tag'], (tag) => (state.linked.list.size === 1 ? null : tag))
        );

      case A.documentsClearLinkedAction.type:
        return state.set('linked', linkedFactory());

      case A.documentsClearLinkedFromCompany.type:
        return (
          state
            .set('linked', linkedFactory())
            .set('dokkaToken', '')
            .set('companyId', '')
            // additionally clear document list
            .set('list', new Map())
            .set('loaded', false)
        );

      case A.documentUpdateLinkAction.success: {
        const items: DocumentsList = action.payload.list;
        // linked docs may have items that are not present in current document list
        // and mustn't be merged into it. Additional filtering applied

        // eslint-disable-next-line max-len
        return state
          .update('list', (list) => list.mergeDeep(items.filter((item) => state.list.has(item.documentID))))
          .updateIn(['linked', 'list'], (list) => (list.size > 0 ? list.mergeDeep(action.payload.list) : list));
      }

      case A.documentRemoveNotesInList.success:
        return state
          .updateIn(['list', action.payload.document.documentID], withOldGridMetaCB(action.payload.document))
          .updateIn(['linked', 'list', action.payload.document.documentID], withOldGridMetaCB(action.payload.document));

      case A.documentRemove.success:
      case A.moveDocumentToCompanyAction.success:
        return state
          .deleteIn(['list', action.payload.id])
          .deleteIn(['linked', 'list', action.payload.id])
          .update('company_total', (u) => u - 1)
          .update('count_without_link_panels', (u) => u - 1)
          .update('gridDocumentListWithFilters', (list) => list.filter((docId) => docId !== action.payload.id));

      case A.documentSplit.success: {
        const stateInTileType = state.deleteIn(['linked', 'list', action.payload.documentID]);
        return action.payload.isGrid ? stateInTileType.deleteIn(['list', action.payload.documentID]) : stateInTileType;
      }

      case A.mergeDocumentsAction.success:
        return action.payload.isGrid
          ? state.update('list', (l) => l.filter((item) => !action.payload.deletedDocumentIds.has(item.documentID)))
          : state;

      case A.documentUpdateNotes.success:
        return (
          state
            // returning the same value from updater func will result in no changes after updateIn
            // otherwise it will create intermediate keys and set value when no linked
            // docs present at all
            .updateIn(['list', action.payload.documentID, 'notes'], (value) =>
              typeof value === 'undefined' ? undefined : action.payload.notes,
            )
            .updateIn(['linked', 'list', action.payload.documentID, 'notes'], (value) =>
              typeof value === 'undefined' ? undefined : action.payload.notes,
            )
            .updateIn(['list', action.payload.documentID, 'notesColor'], (value) =>
              typeof value === 'undefined' ? undefined : action.payload.notes_color,
            )
            .updateIn(['linked', 'list', action.payload.documentID, 'notesColor'], (value) =>
              typeof value === 'undefined' ? undefined : action.payload.notes_color,
            )
        );

      case A.documentSignDocument.success:
        return state
          .updateIn(['list', action.payload.documentID], withOldGridMetaCB(action.payload.document))
          .updateIn(['linked', 'list', action.payload.documentID], withOldGridMetaCB(action.payload.document));

      case A.documentHideLinkedDocs.type:
        return state.set('linked', linkedFactory());

      case A.documentGetMRUTags.success:
        return state.set('recentTags', Set(action.payload)).set('recentTagsCompanyId', action.companyId);

      case A.documentInvalidateMRUTagsCacheAction.type:
        return state.set('recentTagsCompanyId', null);

      case A.documentSetAsCoverAction.success:
        return state
          .updateIn(['list', action.payload.document.documentID], (d) => d && action.payload.document)
          .updateIn(['linked', 'list', action.payload.document.documentID], (d) => d && action.payload.document);

      case A.documentsGridViewGetAction.type:
        return state.set('gridLoader', true);

      case A.documentsGridViewGetAction.success:
        return state
          .set('gridHeadersList', action.payload.gridHeadersList)
          .set('list', action.payload.gridDocumentsList)
          .set('count', action.payload.gridDocumentsList.size)
          .set('fetchedDocumentsTimeStamp', action.payload.timeStamp)
          .set('count_without_link_panels', action.payload.count_without_link_panels)
          .set('gridLoader', false)
          .set('loaded', true);

      case A.documentsGridViewGetAction.failure:
        return state.set('gridLoader', false);

      case A.queueAddAction.type:
        return state.setIn(['uploadQueue', action.payload.id], action.payload);

      case A.queueUpdateAction.type:
        return state.updateIn(['uploadQueue', action.payload.id], (q) => q && q.merge(action.payload));

      case A.documentUploadAction.success:
      case A.supplierDocumentUploadAction.success:
        return state.setIn(['uploadQueue', action.id, 'status'], 'ok');

      case A.documentUploadAction.failure:
      case A.supplierDocumentUploadAction.failure:
        return state
          .setIn(['uploadQueue', action.payload.id, 'status'], 'failure')
          .setIn(['uploadQueue', action.payload.id, 'file'], action.payload.file);

      case A.queueProgress.type:
        return state.setIn(['uploadQueue', action.payload.id, 'progress'], action.payload.value);

      case A.queueRemoveAction.type:
        return state.update('uploadQueue', (u) => u.removeAll(action.payload));

      case A.documentsRemoveDocumentsAction.success:
      case A.moveDocumentsToCompanyAction.success:
        return state
          .update('list', (u) => u.removeAll(action.payload.movedIDs))
          .updateIn(['linked', 'list'], (u) => u.removeAll(action.payload.movedIDs))
          .update('count', (u) => u - action.payload.movedIDs.length)
          .update('company_total', (u) => u - action.payload.movedIDs.length)
          .update('count_without_link_panels', (u) => u - action.payload.nonLinkedDocumentIDs.length);

      case A.documentWorkerTransactionErp.success:
        return state.update('erpDuplicated', (d) => d.add(action.payload.documentID));

      case A.clearTransactionErpDuplicated.type:
        return state.update('erpDuplicated', (d) => d.filter((docId) => docId !== action.payload));

      // case A.duplicateDocumentAction.success:
      //   return state
      //     .setIn(['linkedDocs', action.payload.documentID], action.payload);

      case A.documentGetTagsAction.success:
        return state.setIn(['document', 'tags'], action.payload);

      case A.documentGetViewArrangementAction.success:
      case A.documentUpdateViewArrangementAction.success:
        return state.set('viewArrangement', action.payload);

      case A.getUnreadRequestsAction.success:
        return state.set('unreadRequests', action.payload).set('unreadRequestsLoaded', true);

      case Reconciliation.showReconciliationRequestDetailsAction.type:
        return action.payload.status === 'read'
          ? state
          : state.update('unreadRequests', (unreadRequests) =>
              unreadRequests.reduce((res, doc) => {
                const uDoc = doc.update('lines', (lines) => lines.filter(({ id }) => id !== action.payload.id));
                return uDoc.lines.size ? res.push(uDoc) : res;
              }, new List()),
            );

      case A.documentGetExportFormats.success:
        return state.set('exportFormats', action.payload).set('exportFormatsLoaded', true);

      case A.documentResetExportFormatsLoaded.type:
        return state.set('exportFormatsLoaded', false);

      case A.documentsGridResetAction.type:
        return state.set('gridReset', action.payload);

      case A.documentsGridFilterAppliedAction.type:
        return state.set('changesPresetId', state.currentPresetId).set('gridFilters', action.payload.filters);

      case A.documentsGridSortingAppliedAction.type:
        return state.set('changesPresetId', state.currentPresetId).set('gridSorting', action.payload.sorting);

      case A.documentsGridSetListIdWithFiltersAction.type:
        return state.set('gridDocumentListWithFilters', action.payload);

      case A.documentsGridColumnsAppliedAction.type:
        return state.set('gridColumns', action.payload).set('changesPresetId', state.currentPresetId);

      case A.documentsGridDefaultColumnsAppliedAction.type:
        return state.set('gridDefaultColumns', action.payload);

      case A.documentsGridSavePresetAction.success:
      case A.documentsGridDeletePresetAction.success:
      case A.documentsGridGetPresetsAction.success:
        return state.set('gridPresets', wsGridPresetsAdapter(action.payload)).set('gridPresetsLoaded', true);

      case A.documentsGridGetSharedPresetAction.success:
        return state.set('sharePreset', wsGridSharePresetAdapter(action.payload));

      case A.documentsGridClearSharedPresetAction.type:
        return state.set('sharePreset', null);

      case A.documentsGridSetCurrentPresetIdAction.type:
        return state.set('currentPresetId', action.payload);

      case A.documentsGridClearConfigurationChangesAction.type:
        return state
          .set('gridColumns', null)
          .set('gridSorting', null)
          .set('gridFilters', null)
          .set('changesPresetId', null);

      case A.documentsGridRestoreResentPresetId.success:
        return state.set('currentPresetId', action.payload);

      case A.documentClear.type:
        return state
          .update('list', (docs) => docs.filter((doc, index) => !action.payload.includes(index)))
          .update('count_without_link_panels', (count) => (count ? count - 1 : count));

      case A.documentClearAll.type:
        return state.set('list', new Map());

      case A.getDocumentHotkeysAction.request:
        return state.setIn(['hotkeys', 'isLoading'], true);

      case A.getDocumentHotkeysAction.success:
        return state
          .setIn(['hotkeys', 'isLoading'], false)
          .setIn(['hotkeys', 'companyId'], action.companyId)
          .setIn(['hotkeys', 'data'], action.payload);

      case A.getDocumentHotkeysAction.failure:
        return state.setIn(['hotkeys', 'isLoading'], false).setIn(['hotkeys', 'data'], new List());

      case Company.companySignInAction.request:
      case signOutAction.success:
        return DocumentsFactory({ cachedDocuments: state.cachedDocuments });

      case A.saveCachedDocumentAction.type:
        return state.update('cachedDocuments', (docs) => {
          const index = docs.findIndex(({ hash }) => hash === action.payload.data.hash);
          if (index !== -1) {
            return docs.set(index, action.payload.data);
          }
          if (docs.size === 50) {
            window.URL.revokeObjectURL(docs.get(0).url);
            return docs.delete(0).push(action.payload.data);
          }
          return docs.push(action.payload.data);
        });

      // used for update document data in document list when approve/reject document
      case A.documentUpdateInListByID.type:
        return state
          .updateIn(['list', action.payload.document.documentID], withOldGridMetaCB(action.payload.document))
          .updateIn(['linked', 'list', action.payload.document.documentID], withOldGridMetaCB(action.payload.document));

      default:
        return state;
    }
  },
};
