// @flow
import React from 'react';
import type { Map, RecordOf } from 'immutable';
import { type QueueItemType } from 'domain/documents';

import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';

import UploadFileIcon from '@mui/icons-material/UploadFile';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ReplayIcon from '@mui/icons-material/Replay';

import { lightBlue, blue, grey, red } from '@mui/material/colors';
import { isNumber } from 'lodash';
import { FormattedMessage } from 'react-intl';

import elements from 'components/elements';

const getQueueColors = (status) => {
  switch (status) {
    case 'unsupportedType':
    case 'maxFileSizeError':
    case 'failure':
      return {
        wrapper: red[100],
        progress: 'error.main',
      };
    default:
      return {
        wrapper: blue[100],
        progress: 'primary.main',
      };
  }
};

type Props = {
  queue: Map<string, RecordOf<QueueItemType>>,
  remove: (l: string[]) => void,
  reload: (item: RecordOf<QueueItemType>) => void,
};

const Queue: React$StatelessFunctionalComponent<Props> = ({ queue, remove, reload }) => {
  const onRemove = (e: RecordOf<QueueItemType>) => {
    if (e.status === 'uploading') {
      e.getIn(['cancelToken', 'cancel'], () => null)();
    }
    remove([e.id]);
  };

  const handleAction = (item: RecordOf<QueueItemType>) => () => {
    switch (item.get('status')) {
      case 'uploading':
      case 'pending':
        item.getIn(['cancelToken', 'cancel'], () => null)();
        break;

      case 'ok':
      case 'unsupportedType':
        remove([item.id]);
        break;

      case 'failure':
        reload(item);
        break;

      default:
        break;
    }
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case 'uploading':
      case 'ok':
      case 'unsupportedType':
      case 'maxFileSizeError':
      case 'failure':
        return <FormattedMessage id={`document.upload.${status}`} defaultMessage={status} />;

      case 'pending':
      default:
        return '';
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'ok':
        return 'success.main';

      case 'unsupportedType':
      case 'maxFileSizeError':
      case 'failure':
        return 'error.main';

      case 'uploading':
      case 'pending':
      default:
        return 'inherit';
    }
  };

  return (
    <Stack spacing={1} maxHeight={390} overflow="auto" my={4}>
      {queue.toList().map((e) => (
        <Grid
          container
          key={e.get('id')}
          flexWrap="nowrap"
          justifyContent="space-between"
          bgcolor={grey[50]}
          borderRadius={2}
          px={2}
          pt={2}
          data-element={elements.popup.Upload.queue.item}
        >
          <Grid item>
            <IconButton
              sx={{ bgcolor: `${lightBlue[50]} !important` }}
              onClick={handleAction(e)}
              disabled={e.status !== 'failure'}
            >
              {e.status !== 'failure' ? <UploadFileIcon color="primary" /> : <ReplayIcon color="error" />}
            </IconButton>
          </Grid>
          <Grid item flexGrow={2} mx={2} overflow="hidden">
            <Typography noWrap variant="subtitle1" data-element={elements.popup.Upload.queue.document}>
              {e.path}
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center" color={grey[500]}>
              {isNumber(e.file?.size) && <Typography variant="body2">{Math.floor(e.file.size / 1024)}kb</Typography>}
              {getStatusText(e.status) && <FiberManualRecordIcon sx={{ fontSize: 6 }} />}
              <Typography variant="body2" color={getStatusColor(e.status)}>
                {getStatusText(e.status)}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="baseline">
              <Box width="100%" height={4} bgcolor={getQueueColors(e.status).wrapper}>
                <Box width={`${e.progress}%`} height={4} bgcolor={getQueueColors(e.status).progress} />
              </Box>
              <Box ml={2}>
                <Typography variant="body2">{e.progress}%</Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item>
            <IconButton onClick={() => onRemove(e)} data-element={elements.popup.Upload.queue.deleteBtn}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
    </Stack>
  );
};

export default Queue;
