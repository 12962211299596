import React, { useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Tooltip from 'components/mui/Tooltip';

import { grey } from '@mui/material/colors';
import { isEllipsisActive } from 'pages/company/grid/helpers';

type Props = {
  className: string,
  columnID: string,
  paymentData: {
    [key: string]: string,
  },
};

// eslint-disable-next-line react/prop-types
const Row = ({ label, value }) => {
  const textRef = useRef(null);

  const tooltipIsDisabled = textRef.current ? !isEllipsisActive(textRef.current) : true;

  return (
    <Tooltip t={value} disabled={tooltipIsDisabled}>
      <Box>
        <Typography variant="caption" color={grey[500]}>
          {label}:
        </Typography>
        <Typography ref={textRef} variant="body1" color={grey[700]} ml={0.5} noWrap>
          {value}
        </Typography>
      </Box>
    </Tooltip>
  );
};

const PaymentData: React$StatelessFunctionalComponent<Props> = ({ className, paymentData, columnID }) => {
  const { formatMessage } = useIntl();

  const getRowProps = useCallback(
    (id, value) => {
      const props = {
        label: formatMessage({ id: `company.supplier.workspace.item.paymentData.${id}`, defaultMessage: id }),
        value,
      };

      if (id === 'paymentDate') {
        if (columnID === 'paid') {
          return {
            ...props,
            label: formatMessage({
              id: 'company.supplier.workspace.item.paymentData.paymentDate',
              defaultMessage: 'Payment date',
            }),
          };
        }

        return {
          ...props,
          label: formatMessage({
            id: 'company.supplier.workspace.item.paymentData.scheduledPayment',
            defaultMessage: 'Scheduled payment',
          }),
        };
      }

      return props;
    },
    [columnID, formatMessage],
  );

  return (
    <Stack className={className} p={1} ml={1}>
      {Object.entries(paymentData).map(([key, value]) => {
        if (value === null) {
          return null;
        }

        return <Row key={key} {...getRowProps(key, value)} />;
      })}
    </Stack>
  );
};

export default PaymentData;
