import { all, takeEvery, put, select } from 'redux-saga/effects';
import { navigate, LOCATION_CHANGE } from 'domain/router/redux/reduxActions';
import * as Router from 'domain/router';
import { cleanQuery, queryToSearch } from 'domain/router/helpers';
import { matchRoute } from 'domain/router/utils';
import { resetPosition, getPosition } from 'lib/scrollManager';

function* updateQuery({ payload }) {
  const { pathname } = yield select(Router.locationSelector);
  const query = yield select(Router.querySelector);
  const previousSearch = queryToSearch(cleanQuery({ ...query }));
  const newSearch = queryToSearch(cleanQuery({ ...query, ...payload }));

  yield put(Router.completeResetAction());
  // no need to push if location hasnt changed. Otherwise locationChange will be dispatched,
  // but router saga will not handle it as isLocationChange returns false
  if (newSearch !== previousSearch) {
    yield put(
      navigate.push({
        pathname,
        search: queryToSearch(cleanQuery({ ...query, ...payload })),
      }),
    );
  }
}

function* resetWSScrollPosition() {
  const { top, left } = getPosition();

  if (top || left) {
    const { location, prevLocation } = yield select(Router.routing);
    const isDocumentPagePrevRoute = matchRoute.isDocument(prevLocation.pathname);
    const isCompanyPagePrevRoute = matchRoute.isCompany(prevLocation.pathname);
    const isDocumentPageRoute = matchRoute.isDocument(location.pathname);
    const isCompanyPageRoute = matchRoute.isCompany(location.pathname);

    if (!((isCompanyPagePrevRoute && isDocumentPageRoute) || (isDocumentPagePrevRoute && isCompanyPageRoute))) {
      resetPosition();
    }
  }
}

export default function* router() {
  yield all([takeEvery(Router.updateQueryAction.type, updateQuery), takeEvery(LOCATION_CHANGE, resetWSScrollPosition)]);
}
