// @flow
import React, { useCallback } from 'react';
import { compose } from 'redux';
import { Field, reduxForm, formValueSelector } from 'redux-form/immutable';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { countries } from 'lib/countries';
import { renderOption, renderDivider } from './helpers/selectOptions';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextFieldForm from 'components/mui/Form/TextField/TextFieldForm';
import SelectForm from 'components/mui/Form/Select/SelectForm';
import CompanyPic from 'pages/common/input/CompanyPic';
// TODO: rewrite File component for MUI
import { listToSelectOptions } from 'components/Form/Select';
import PhoneForm from 'components/mui/Form/Phone/PhoneForm';

import { styled } from '@mui/styles';
import logoPlaceholder from './logo.svg';

const EmailContainer = styled(Grid)(({ theme }) => ({
  flip: false,
  direction: 'ltr',
  justifyContent: theme.direction === 'rtl' ? 'flex-end' : 'flex-start',
}));

type Props = {
  handleSubmit: () => void,
  onLogoChange: (file: any, resolve: (picture: string) => void, reject: () => void) => void,
  context: 'organization' | 'company',
  emailParams: { address: string, domain: string, initialAddress: string, isDefault: boolean },
};

const mapStateToProps = (state) => ({
  idValue: formValueSelector('mainInfoForm')(state, 'id'),
  nameValue: formValueSelector('mainInfoForm')(state, 'name'),
  emailValue: formValueSelector('mainInfoForm')(state, 'email_box'),
  pictureValue: formValueSelector('mainInfoForm')(state, 'picture'),
  whatsappPhone: formValueSelector('mainInfoForm')(state, 'whatsapp_phone'),
});

const MainForm: React$StatelessFunctionalComponent<Props> = ({ handleSubmit, context, emailParams, onLogoChange }) => {
  // TODO: do we need to change the email input color if idValue === emailValue
  const { idValue, nameValue, emailValue, pictureValue, whatsappPhone } = useSelector(mapStateToProps);
  const { formatMessage } = useIntl();

  const companyEmailPlaceholder = (): string => {
    const { isDefault, initialAddress } = emailParams;
    if (isDefault) return initialAddress;
    return formatMessage({ id: 'forms.company.main.email.placeholder', defaultMessage: 'company' });
  };

  const renderOptions = useCallback(() => {
    const options = listToSelectOptions(countries);
    return [...options.splice(0, 7).map(renderOption), renderDivider(), ...options.splice(7).map(renderOption)];
  }, []);

  const addressHeaderId = `forms.${context}.main.address.header`;

  return (
    <form onSubmit={handleSubmit}>
      <Grid container flexDirection="column" spacing={2}>
        <Grid item sm={6}>
          <Field
            component={CompanyPic}
            onUpload={onLogoChange}
            label={{
              id: `forms.${context}.main.logo.label`,
              defaultMessage: context === 'organization' ? 'Organization logo' : 'Company logo',
            }}
            accept="image/png, image/jpg, image/jpeg, image/bmp"
            name="picture"
            picture={pictureValue || logoPlaceholder}
          />
        </Grid>
        <Grid item sm={6}>
          <Field
            component={TextFieldForm}
            label={formatMessage({
              id: `forms.${context}.main.name.label`,
              defaultMessage: context === 'organization' ? 'Organization name' : 'Company name',
            })}
            placeholder={formatMessage({
              id: `forms.${context}.name.placeholder`,
              defaultMessage: context === 'organization' ? 'Organization name' : 'Company name',
            })}
            required
            name="name"
            type="text"
            fullWidth
            withTrim
          />
        </Grid>
      </Grid>

      {context === 'company' ? (
        <>
          <Divider sx={{ my: 3 }} />
          <Typography variant="body1" fontWeight="500" mb={2}>
            {formatMessage({
              id: 'forms.company.main.email.title',
              defaultMessage: 'Company email',
            })}
          </Typography>
          <EmailContainer container spacing={2}>
            <Grid item sm={6}>
              <Field
                component={TextFieldForm}
                placeholder={companyEmailPlaceholder()}
                label={formatMessage({ id: 'forms.company.main.email.title', defaultMessage: 'Company email' })}
                name="email_box"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{`@${emailParams.domain}`}</InputAdornment>,
                }}
                inputProps={{
                  style: { textAlign: 'right', flip: 'false', direction: 'ltr' },
                }}
                fullWidth
                withTrim
              />
            </Grid>
          </EmailContainer>
        </>
      ) : null}
      <Divider sx={{ my: 3 }} />

      <Typography variant="body1" fontWeight="500" mb={2}>
        {formatMessage({
          id: addressHeaderId,
          defaultMessage: context === 'organization' ? 'Organization address' : 'Company address',
        })}
      </Typography>

      <Grid container spacing={2}>
        <Grid item sm={6}>
          <Field
            component={SelectForm}
            renderOptions={renderOptions}
            label={formatMessage({
              id: 'forms.common.main.country.label',
              defaultMessage: 'Country',
            })}
            required
            name="country"
            FormControlProps={{ fullWidth: true }}
            id="country"
          />
        </Grid>
        <Grid item sm={6}>
          <Field
            component={TextFieldForm}
            label={formatMessage({
              id: 'forms.common.main.address.label',
              defaultMessage: 'Address',
            })}
            placeholder={formatMessage({
              id: 'forms.common.address.placeholder',
              defaultMessage: 'Address',
            })}
            required
            name="street"
            type="text"
            fullWidth
            withTrim
          />
        </Grid>
        <Grid item sm={6}>
          <Field
            component={TextFieldForm}
            label={formatMessage({
              id: 'forms.common.main.city.label',
              defaultMessage: 'City',
            })}
            placeholder={formatMessage({
              id: 'forms.common.city.placeholder',
              defaultMessage: 'City',
            })}
            required
            name="city"
            type="text"
            fullWidth
            withTrim
          />
        </Grid>
        {Boolean(whatsappPhone) && (
          <Grid item sm={6}>
            <Field
              component={PhoneForm}
              CodeProps={{
                fullWidth: true,
                disabled: true,
              }}
              NumberProps={{
                fullWidth: true,
                disabled: true,
                label: formatMessage({
                  id: 'forms.common.whatsappPhone.placeholder',
                  defaultMessage: 'Whatsapp Phone',
                }),
              }}
              withoutBrackets
              name="whatsapp_phone"
            />
          </Grid>
        )}
      </Grid>
      <Box display="flex" alignItems="center" pt={2}>
        <Button type="submit" sx={{ minWidth: '14ch', ml: 'auto' }}>
          {formatMessage({ id: 'button.save', defaultMessage: 'Save' })}
        </Button>
      </Box>
    </form>
  );
};

export default compose(reduxForm({ form: 'mainInfoForm' }))(MainForm);
