import chromeIcon from './icons/chromeIcon.svg';
import ipadIcon from './icons/ipad.png';
import browserIcon from './icons/browser.svg';
import crossIcon from './icons/crossIcon.svg';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },

  logo: {
    width: 460,
    height: 116,
    background: `url("${theme.labelOptions.extLogo.src}") center scroll no-repeat`,
    backgroundSize: 'contain',
    marginTop: 57,
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },

  generalMessage: {
    fontSize: 34,
    marginBottom: 50,
  },

  imageWrapper: {
    position: 'relative',
    marginBottom: 50,
  },

  browserIcon: {
    width: 275,
    height: 275,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: theme.colors.primaryLight,
    WebkitMask: `url("${browserIcon}") center no-repeat`,
  },

  ipadIcon: {
    extend: 'browserIcon',
    height: 275,
    backgroundColor: theme.colors.primaryLight,
    WebkitMask: `url("${ipadIcon}") center no-repeat`,
  },

  crossIcon: {
    flip: false,
    position: 'absolute',
    top: '-5px',
    right: 5,
    width: 55,
    height: 55,
    backgroundPosition: 'center',
    background: `url("${crossIcon}") center no-repeat`,
  },

  description: {
    color: '#5A6872',
  },

  descriptionTitle: {
    display: 'inline-block',
    lineHeight: '40px',
    marginBottom: 15,

    '& span': {
      verticalAlign: 'super',
    },
  },

  chromeWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    margin: [0, 5],
  },

  chromeIcon: {
    width: 25,
    height: 25,
    background: `url("${chromeIcon}") center no-repeat`,
    display: 'inline-block',
  },

  chromeLink: {
    color: theme.colors.primaryLight,
    lineHeight: '15px',
    verticalAlign: 'super',
    textDecoration: 'none',
    borderBottom: '1px solid #AFC2E0',
    padding: [5, 0, 3],
  },

  descriptionMessage: {
    fontSize: 19,
    maxWidth: 380,
    textAlign: 'center',
    margin: [0, 'auto'],
  },
}));
