// @flow
import { Map, Record, List } from 'immutable';
import type { RecordOf, RecordFactory, List as ListOf } from 'immutable';
import { erpsUpdateDataAdapter } from './helpers';
import { erpsAdapter } from './adapters';
import * as A from './settingsActions';
import { type ConfigurationCompanyListType, ERP_NAMES } from 'domain/companies/helpers';
import type { ERPsType } from './types.js.flow';

type Actions = {
  type: string,
  payload?: mixed,
};

export type object = {
  [key: string]: string,
};

type ERP = {
  status: 'connected' | 'disconnected' | 'inactive',
  details: object,
};

export const settingsTypes = Object.freeze({
  boolean: 'boolean',
  text: 'text',
  list: 'list',
  dynamic_list: 'dynamic_list',
  file: 'file',
  float: 'float',
  number: 'number',
  int: 'int',
  ftp_connection: 'ftp_connection',
  indexes_sync_time: 'indexes_sync_time',
});

export const keys = Object.values(ERP_NAMES);

export type ERPS = {
  QBO: ERP,
  QBD: ERP,
  PRIORITY_PRO: ERP,
  PRIORITY_PRO_SQL: ERP,
  SAGE_SA: ERP,
  SAGE: ERP,
  XERO: ERP,
  FOX: ERP,
  DEFAULT_ERP: ERP,
};

type ERPSettingsBasicType = {|
  name: string,
  label: string,
  group?: string,
  type: $Keys<typeof settingsTypes>,
|};

export type ERPSettingBoolean = {|
  ...ERPSettingsBasicType,
  value: boolean,
  type: 'boolean',
|};

export type RawERPSettingBoolean = ERPSettingBoolean;

export type ERPSettingText = {|
  ...ERPSettingsBasicType,
  value: string,
  type: 'text',
|};

export type RawERPSettingText = ERPSettingText;

export type ERPSettingFloat = {|
  ...ERPSettingsBasicType,
  value: string | number,
  type: 'float',
|};

export type ERPSettingNumber = {|
  ...ERPSettingsBasicType,
  value: string | number,
  type: 'number',
|};

export type ERPSettingInt = {|
  ...ERPSettingsBasicType,
  value: string | number,
  type: 'int',
  max: number | null,
  min: number | null,
|};

export type RawERPSettingFloat = ERPSettingFloat;

export type ERPSettingFTPConnection = {|
  ...ERPSettingsBasicType,
  mandatory: boolean,
  value: string,
  type: 'ftp_connection',
|};

export type ERPSettingsTimeSelector = {|
  ...ERPSettingsBasicType,
  mandatory: boolean,
  indexes_update_date?: string,
  value: {
    hours: number,
    timezone: string,
  },
  is_sync_running: boolean,
  type: 'indexes_sync_time',
|};

export type RawERPSettingFTPConnection = ERPSettingFTPConnection;

export type ERPSettingList = {|
  ...ERPSettingsBasicType,
  options: Map<string, string>,
  value: string,
  type: 'list',
  deletable: boolean,
|};

export type RawERPSettingList = {|
  ...ERPSettingsBasicType,
  options: {
    [key: string]: string,
  },
  value: string,
  type: 'list',
|};

export type ERPSettingDynamicList = {|
  ...ERPSettingsBasicType,
  value: string,
  display_value: string,
  options_function: string,
  type: 'dynamic_list',
  deletable: boolean,
|};

export type RawERPSettings = Array<
  RawERPSettingBoolean | RawERPSettingText | RawERPSettingList | RawERPSettingFloat | RawERPSettingFTPConnection,
>;
// eslint-disable-next-line max-len
export type ERPSettings = ListOf<
  | RecordOf<ERPSettingBoolean>
  | RecordOf<ERPSettingText>
  | RecordOf<ERPSettingList>
  | RecordOf<ERPSettingDynamicList>
  | RecordOf<ERPSettingFloat>,
  RecordOf<ERPSettingFTPConnection>,
>;

export type SettingsType = {|
  category: ?string,
  params: Map<string, string>,
  erps: ERPsType,
  erpType: ?string,
  endpoint: ?string,
  erpSettings: ERPSettings,
  erpCompanies: ConfigurationCompanyListType,
  isLoadingERPSetting: boolean,
|};

const InitialState: RecordFactory<SettingsType> = new Record({
  category: null,
  params: new Map(),
  erps: new Map(),
  endpoint: null,
  erpType: null,
  erpSettings: new List(),
  erpCompanies: new List(),
  isLoadingERPSetting: false,
});

export const reducer = {
  settings(state: RecordOf<SettingsType> = InitialState(), action: Actions) {
    switch (action.type) {
      case A.getCategory.success:
        return state.set('category', action.payload.category).mergeIn(['erps'], action.payload.erps);

      case A.setErpsAction.type:
        return state.mergeIn(['erps'], erpsAdapter(action.payload)).set('erpSettings', new List());

      case A.updateConnectionsTable.success:
        return state.mergeIn(['erps'], erpsUpdateDataAdapter(action.payload, state.erps));

      case A.getSSOParams.success:
        return state.merge(action.payload);

      case A.getCurrentERPSettings.type:
        return state.set('isLoadingERPSetting', true);

      case A.getCurrentERPSettings.success:
      case A.setCurrentERPSettings.success:
        return state.set('erpSettings', action.payload).set('isLoadingERPSetting', false);

      case A.getCurrentERPSettings.failure:
        return state.set('erpSettings', new List()).set('isLoadingERPSetting', false);

      case A.getCurrentERPCompaniesAction.success:
        return state.set('erpCompanies', action.payload);

      // update ftp connection value, find setting index and update value from payload
      case A.saveFTPConnectionAction.success:
        return state.updateIn(
          ['erpSettings', state.erpSettings.findIndex((item) => item.type === 'ftp_connection')],
          (item) => item.set('value', action.payload),
        );

      case A.clearCategoryAction.success:
        return state.set('category', null).set('erps', new Map());

      default:
        return state;
    }
  },
};
