// @flow
import { useIntl } from 'react-intl';
import { ROLES } from 'domain/env/helpers';

const userOptions = [
  {
    label: {
      id: 'forms.organization.users.position.bookkeeper',
      defaultMessage: 'Bookkeeper',
    },
    value: ROLES.accountant,
    createFilter: () => true,
    editFilter: ({ role, isCompany }) => (isCompany ? role === ROLES.accountant : true),
  },
  {
    label: {
      id: 'form.organization.users.edit.regular',
      defaultMessage: 'Regular user',
    },
    value: ROLES.user,
    createFilter: () => true,
    editFilter: ({ role, isCompany }) =>
      isCompany ? role === ROLES.user || role === ROLES['confidential-user'] : true,
  },
  {
    label: {
      id: 'form.organization.users.edit.confidential',
      defaultMessage: 'Confidential user',
    },
    value: ROLES['confidential-user'],
    createFilter: () => true,
    editFilter: ({ role, isCompany }) =>
      isCompany ? role === ROLES.user || role === ROLES['confidential-user'] : true,
  },
  {
    label: {
      id: 'form.organization.users.edit.supplier',
      defaultMessage: 'Supplier',
    },
    value: 'supplier',
    createFilter: () => true,
    editFilter: ({ role }) => role === 'supplier',
  },
];

const roleOptionsObj = [...userOptions];

type TUseManageUserForm = {
  role: string,
  isEdit: boolean,
  isCompany: boolean,
};

const useManageUserForm = ({ role, isEdit, isCompany }: TUseManageUserForm) => {
  const intl = useIntl();

  const roleOptions = roleOptionsObj
    .filter(({ createFilter, editFilter }) => (isEdit ? editFilter({ role, isCompany }) : createFilter({ role })))
    .map(({ createFilter, editFilter, ...o }) => ({ ...o, label: intl.formatMessage(o.label) }));

  return { roleOptions };
};

export default useManageUserForm;
