import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  '@global': {
    '.ag-theme-material': {
      '&& .ag-root-wrapper-body': {
        paddingRight: theme.spacing(2),
      },
    },
  },
}));
