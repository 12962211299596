/* @flow */
import React from 'react';
import _ from 'lodash';
import { throttle } from 'throttle-debounce';
import { compose, type Dispatch } from 'redux';
import { connect } from 'react-redux';
import { documentUpdateViewAction } from 'domain/env';
import { Map, List } from 'immutable';
import { ArcherContainer } from 'react-archer';
import type { Map as MapType, List as ListType } from 'immutable';
import type { TViewArrangement } from 'pages/document/types.js.flow';
import { type DocumentsType } from 'domain/documents/documentsModel';
import { type Company } from 'domain/companies';
import * as ACL from 'domain/restriction';

import ArcherContainerContext from 'pages/document/Body/ArcherContainerContext';
import DocumentWorkspaceResizer from 'pages/document/components/DocumentWorkspaceResizer';
import DocumentRejectAlert from 'pages/document/components/DocumentRejectAlert';
import DocumentTopPanel from 'pages/document/DocumentTopPanel';
import DocumentLayout from './Document/DocumentLayout';
import JELayout from './JE/JELayout';
import DocumentComponent from 'pages/document/DocumentComponent';
import JETabs from 'pages/document/Body/JE/JETabs';
import XLSComponent from 'pages/document/XLSComponent';
import Box from '@mui/material/Box';
import InsightsWidget from './JE/InsightsWidget';

import cx from 'classnames';
import { withStyles } from '@mui/styles';
import sheet from './sheet';

export type BodyProps = {|
  classes: {|
    [key: string]: string,
  |},
  viewArrangement: TViewArrangement,
  documentUpdateView: Dispatch<documentUpdateViewAction>,
  view: MapType<string, number>,
  isFinancial: boolean,
  document: DocumentsType,
  scale: number,
  onScale: (mx: number) => void,
  signatureStatus: boolean,
  company: Company,
  dokkaToken: string,
  isStatement: boolean,
  showGridBetaLabel: () => void,
  isShowGridBetaLabel: boolean,
  isPdfView: boolean,
  spreadZoom: number,
  onZoom: (direction: 1 | -1 | 0) => void,
  onReset: () => void,
  getIsDisabledZoomButton: (direction: 1 | -1 | 0) => boolean,
  isSupplier: boolean,
  children: React$Node,
  rtl: boolean,
  setIsChangeApprovals: () => void,
  textractEnabledForDocument: boolean,
  isSelectTableMode: boolean,
  isGranted: (number | number[]) => boolean,
  setSelectTableHeaderRef: (ref: HTMLElement) => void,
|};

type State = {|
  contentWidth: ?number,
  docWidthBeforeResize: number,
|};

type ArrangementMapItem = {|
  isVertical?: boolean,
  map: ListType<string>,
|};

export const FULL_WIDTH_VIEW = ['docOnly', 'jeOnly', 'ttb', 'btt'];

const viewArrangementOptions: MapType<string, ArrangementMapItem> = Map({
  docOnly: {
    map: List(['doc']),
  },
  jeOnly: {
    map: List(['je']),
  },
  ttb: {
    map: List(['doc', 'je']),
  },
  btt: {
    map: List(['je', 'doc']),
  },
  ltr: {
    isVertical: true,
    map: List(['doc', 'je']),
  },
  rtl: {
    isVertical: true,
    map: List(['je', 'doc']),
  },
});

const sizeKeys: MapType<string, string> = Map({
  doc: 'documentsWidth',
  je: 'infoBarWidth',
});

const MIN_V_SIZE = 100;
const MIN_H_SIZE = 100;

class BodyComponent extends React.Component<BodyProps, State> {
  boxEl: ?HTMLElement;

  constructor(props) {
    super(props);

    this.state = {
      contentWidth: null,
      docWidthBeforeResize: 0,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateContentWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateContentWidth);
  }

  onViewResizeStart = () => {
    this.setState({ docWidthBeforeResize: this.getDocElWidth() });
  };

  onViewResize = throttle(150, (event: MouseEvent) => {
    this.saveSizes(this.getCurrentSizes(event));
  });

  onViewResizeEnd = () => {
    this.setState({ docWidthBeforeResize: 0 });
  };

  getCurrentSizes = (event: MouseEvent): [number, number] => {
    const boxRect = this.boxEl.getBoundingClientRect();
    const [boxPos, clientPos, allSizeParam, minSize] = this.isVertical()
      ? ['left', 'clientX', 'width', this.getMinSize()]
      : ['top', 'clientY', 'height', this.getMinSize()];

    const boxSize = boxRect[allSizeParam];
    const maxSize = boxSize - minSize;
    const currentSize = event[clientPos] - boxRect[boxPos];
    const firstSize = _.cond([
      [(s) => s < minSize, _.constant(minSize)],
      [(s) => s > maxSize, _.constant(maxSize)],
      [_.stubTrue, _.constant(currentSize)],
    ])(currentSize);

    const secondSize = boxSize - firstSize;
    return [firstSize, secondSize];
  };

  getSizeKeyByIndex = (index: number) => {
    const map = this.getMap();
    return sizeKeys.get(map.get(index));
  };

  getDocElWidth = () => (this.isVertical() ? this.getActualPxSize('doc') : _.get(this.boxEl, 'offsetWidth', 0));

  getMinSize = () => (this.isVertical() ? MIN_H_SIZE : MIN_V_SIZE);

  getSizeByType = (sizeType: 'doc' | 'je'): number => {
    const { view } = this.props;
    return parseInt(view.get(sizeKeys.get(sizeType)), 10) || this.getMinSize();
  };

  getSafeSizeByType = (sizeType: 'doc' | 'je'): number => Math.max(this.getSizeByType(sizeType), this.getMinSize());

  getSumSize = (map: ListType<string>): number =>
    sizeKeys.reduce((res, val, key) => (map.includes(key) ? res + this.getSafeSizeByType(key) : res), 0);

  getPercentSize = (sizeType: 'doc' | 'je'): number => {
    const sumSize = this.getSumSize(this.getMap());
    const currentSize = this.getSafeSizeByType(sizeType);
    return (currentSize / sumSize) * 100;
  };

  getActualPxSize = (sizeType: 'doc' | 'je') => {
    const { contentWidth } = this.state;

    // !IMPORTANT - getActualPxSize FN invoked only for vertical document arrangement
    // 8px - width separator, 34px - left bar width
    // (8 + 34) / 2 = 21;
    const offset = 21;
    const width = this.boxEl ? contentWidth : this.getSumSize(this.getMap());
    const percentSize = this.getPercentSize(sizeType);
    return Math.round(((width - offset) / 100) * percentSize);
  };

  getMap = () => this.getArrangementOption('map');

  getArrangementOption = (option: string) => {
    const { viewArrangement } = this.props;
    return viewArrangementOptions.getIn([viewArrangement, option]);
  };

  getOrder = (mapItem: 'doc' | 'je' | 'slider') => {
    const map = this.getMap();
    const order = () => (map.indexOf(mapItem) ? 3 : 1);
    return mapItem === 'slider' ? 2 : order();
  };

  getDisplay = (mapItem: 'doc' | 'je' | 'slider') => {
    const map = this.getMap();
    return mapItem === 'slider' ? map.size > 1 : map.includes(mapItem);
  };

  setBoxEl = (el) => {
    if (el) {
      this.boxEl = el;
      this.updateContentWidth();
    }
  };

  // eslint-disable-next-line react/sort-comp
  updateContentWidth = throttle(20, () => {
    if (this.boxEl) {
      const contentWidth = this.boxEl.getBoundingClientRect().width;
      this.setState({ contentWidth });
    }
  });

  saveSizes = (sizes: [number, number]) => {
    const { documentUpdateView } = this.props;
    const saveObj = sizes.reduce((res, val, index) => ({ ...res, [this.getSizeKeyByIndex(index)]: val }), {});
    window.localStorage.setItem('dokkaDocumentsWidth', saveObj.documentsWidth);
    window.localStorage.setItem('dokkaInfoBarWidth', saveObj.infoBarWidth);
    documentUpdateView(saveObj);
  };

  isDirectChildRender = () => {
    const { viewArrangement, isPdfView } = this.props;
    return viewArrangement === 'jeOnly' || !isPdfView;
  };

  getCurrentSidebar = () => {
    const { viewArrangement, rtl } = this.props;
    return (viewArrangement === 'rtl' && !rtl) || (viewArrangement === 'ltr' && rtl) ? 'right' : 'left';
  };

  isVertical = (): boolean => this.getArrangementOption('isVertical');

  getIsVisibleInsightsWidget = () => {
    const {
      isGranted,
      document: { doctype },
    } = this.props;
    const isNotSupplier = !isGranted(ACL.IS_SUPPLIER_USER);
    return isNotSupplier && !this.getDisplay('je') && doctype === 'financial';
  };

  archerRef = {
    arrowMarkerUniquePrefix: '',
    refreshScreen: () => {},
  };

  render() {
    const {
      classes,
      company,
      dokkaToken,
      view,
      document,
      isStatement,
      scale,
      signatureStatus,
      viewArrangement,
      isFinancial,
      onScale,
      showGridBetaLabel,
      isShowGridBetaLabel,
      isPdfView,
      spreadZoom,
      onZoom,
      onReset,
      getIsDisabledZoomButton,
      isSupplier,
      setIsChangeApprovals,
      textractEnabledForDocument,
      isSelectTableMode,
      children,
      setSelectTableHeaderRef,
    } = this.props;

    const { docWidthBeforeResize } = this.state;

    return (
      <ArcherContainer
        strokeColor="red"
        ref={(ref) => {
          this.archerRef = ref;
        }}
        style={{ height: '100%', flexGrow: 1, overflow: 'hidden' }}
        svgContainerStyle={{ zIndex: 8 }}
      >
        <ArcherContainerContext.Provider value={this.archerRef}>
          <Box display="flex" flexDirection="column" height="100%">
            {!isSupplier && <DocumentTopPanel setIsChangeApprovals={setIsChangeApprovals} />}
            <Box display="flex" width="100%" flexGrow="1" overflow="hidden" id="draggingZone">
              {this.getCurrentSidebar() !== 'left' && this.getIsVisibleInsightsWidget() && (
                <InsightsWidget
                  updateContentWidth={this.updateContentWidth}
                  onViewResizeStart={this.onViewResizeStart}
                  onViewResize={this.onViewResize}
                  onViewResizeEnd={this.onViewResizeEnd}
                />
              )}
              <div
                className={cx(classes.contentPanel, {
                  [classes.contentPanelHide]: this.getCurrentSidebar() !== 'left',
                })}
              >
                {this.isDirectChildRender() ? (
                  <div id="doc-preview" className={classes.docPreviewPortal}>
                    {children}
                  </div>
                ) : (
                  <div id="doc-preview" className={classes.docPreviewPortal} />
                )}
              </div>
              <div
                ref={this.setBoxEl}
                className={cx(classes.bodyBox, this.isVertical() ? classes.rowDirection : classes.colDirection)}
              >
                {viewArrangement === 'docOnly' && (
                  <DocumentRejectAlert status={document.status} reason={document.reason} />
                )}
                {this.getDisplay('doc') && (
                  <DocumentLayout
                    isVertical={this.isVertical()}
                    size={this.getPercentSize('doc')}
                    order={this.getOrder('doc')}
                    isShowGridBetaLabel={isShowGridBetaLabel}
                  >
                    {isPdfView ? (
                      <DocumentComponent
                        data={document}
                        companyId={company.id}
                        documentsWidth={view.get('documentsWidth')}
                        dokkaToken={dokkaToken}
                        isFinancial={isFinancial}
                        onScale={onScale}
                        scale={scale}
                        width={this.getDocElWidth()}
                        startResizeWidth={docWidthBeforeResize}
                        signatureStatus={signatureStatus}
                        isStatement={isStatement}
                        showGridBetaLabel={showGridBetaLabel}
                        viewArrangement={viewArrangement}
                        onZoom={onZoom}
                        onReset={onReset}
                        getIsDisabledZoomButton={getIsDisabledZoomButton}
                        currentSidebar={this.getCurrentSidebar()}
                        textractEnabledForDocument={textractEnabledForDocument}
                        isSupplier={isSupplier}
                        isSelectTableMode={isSelectTableMode}
                        setSelectTableHeaderRef={setSelectTableHeaderRef}
                      >
                        {children}
                      </DocumentComponent>
                    ) : (
                      <XLSComponent
                        companyId={company.id}
                        documentID={document.documentID}
                        startResizeWidth={docWidthBeforeResize}
                        dokkaToken={dokkaToken}
                        signatureStatus={signatureStatus}
                        spreadZoom={spreadZoom}
                        onZoom={onZoom}
                        onReset={onReset}
                        getIsDisabledZoomButton={getIsDisabledZoomButton}
                        isSupplier={isSupplier}
                      />
                    )}
                  </DocumentLayout>
                )}

                {this.getDisplay('slider') && (
                  <DocumentWorkspaceResizer
                    viewArrangement={viewArrangement}
                    onResize={this.onViewResize}
                    onResizeStart={this.onViewResizeStart}
                    onResizeEnd={this.onViewResizeEnd}
                    isVertical={this.isVertical()}
                    order={this.getOrder('slider')}
                  />
                )}

                {this.getDisplay('je') && (
                  <JELayout
                    isVertical={this.isVertical()}
                    size={this.getPercentSize('je')}
                    order={this.getOrder('je')}
                    updateContentWidth={this.updateContentWidth}
                  >
                    <DocumentRejectAlert status={document.status} reason={document.reason} />
                    <JETabs viewArrangement={viewArrangement} />
                  </JELayout>
                )}
              </div>
              {this.getCurrentSidebar() !== 'right' && this.getIsVisibleInsightsWidget() && (
                <InsightsWidget
                  updateContentWidth={this.updateContentWidth}
                  onViewResizeStart={this.onViewResizeStart}
                  onViewResize={this.onViewResize}
                  onViewResizeEnd={this.onViewResizeEnd}
                />
              )}
              <div
                className={cx(classes.contentPanel, {
                  [classes.contentPanelHide]: this.getCurrentSidebar() !== 'right',
                })}
              >
                {this.isDirectChildRender() ? (
                  <div id="doc-preview-rtl" className={classes.docPreviewPortalRtl}>
                    {children}
                  </div>
                ) : (
                  <div id="doc-preview-rtl" className={classes.docPreviewPortalRtl} />
                )}
              </div>
            </Box>
          </Box>
        </ArcherContainerContext.Provider>
      </ArcherContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  isGranted: ACL.isGranted(state),
});

const mapDispatchToProps = {
  documentUpdateView: documentUpdateViewAction,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(sheet))(BodyComponent);
