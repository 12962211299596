// @flow
import { useLayoutEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

// helpers
import { sessionST } from 'lib/sessionStorage';
import * as Router from 'domain/router';
import { matchRoute } from 'domain/router/utils';

const initPosition = { top: 0, left: 0, view: 'tile' };

type Props = {
  isTargetLoaded: boolean,
  view?: 'grid' | 'tile',
};
export const resetPosition = () => {
  sessionST().workspaceScrollPosition().set(JSON.stringify(initPosition));
};

export const getPosition = () => {
  try {
    const position = sessionST().workspaceScrollPosition().get();
    return position ? JSON.parse(position) : {};
  } catch (e) {
    console.log('doesn`t get workspaceScrollPosition');
    return {};
  }
};

const ScrollManager = ({ view = 'tile', isTargetLoaded }: Props) => {
  const {
    prevLocation: { pathname: prevPathname },
  } = useSelector(Router.routing);

  const savePosition = useCallback(
    (target: ?HTMLElement) => {
      if (target) {
        try {
          const { scrollLeft: left, scrollTop: top } = target;
          sessionST().workspaceScrollPosition().set(JSON.stringify({ left, top, view }));
        } catch (e) {
          console.log('doesn`t save workspaceScrollPosition');
        }
      }
    },
    [view],
  );

  const restorePosition = useCallback(
    (target: ?HTMLElement) => {
      const { view: sview, ...rest } = getPosition();
      if (target && sview === view) {
        target.scrollTo(rest);
      }
    },
    [view],
  );

  useLayoutEffect(() => {
    const gridBody = document.querySelector('.ag-body-viewport');
    const tileBody = document.documentElement;
    const target = view === 'grid' ? gridBody : tileBody;
    const isCompanyPrevPage = matchRoute.isCompany(prevPathname);

    if (isTargetLoaded) {
      if (!isCompanyPrevPage) {
        Promise.resolve().then(() => {
          restorePosition(target);
          resetPosition();
        });
      } else {
        resetPosition();
      }
    }

    return () => {
      if (isTargetLoaded) {
        savePosition(target);
      }
    };
  }, [isTargetLoaded, prevPathname, restorePosition, savePosition, view]);

  return null;
};

export default ScrollManager;
