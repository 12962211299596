// @flow
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';
import { isJELoadingSelector } from 'domain/journal/selectors';

import Button from '@mui/material/Button';
import ButtonGroup, { type ButtonGroupProps as MuiButtonGroupProps } from '@mui/material/ButtonGroup';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';

import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import UpdateIcon from '@mui/icons-material/Update';

type TOptions = {
  key: string,
  title: {
    id: string,
    defaultMessage: string,
  },
  onClick: () => void,
};

export type TSplitButton = {
  options: $ReadOnlyArray<TOptions>,
  activeActionKey?: string,
} & MuiButtonGroupProps;

const SplitButton: React$StatelessFunctionalComponent<TSplitButton> = ({
  options,
  activeActionKey,
  disabled,
  variant = 'contained',
  color = 'primary',
}) => {
  const { formatMessage } = useIntl();
  const popupState = usePopupState({ variant: 'popover', popupId: 'PublishButton' });
  const isJELoading = useSelector(isJELoadingSelector);

  const handleMenuItemClick = (cb) => {
    cb();
    popupState.close();
  };

  const currentState = useMemo(() => {
    const { title, onClick } = options.filter(({ key }) => key === activeActionKey)[0] || options[0];
    return { title: typeof title === 'string' ? title : formatMessage(title), onClick };
  }, [options, formatMessage, activeActionKey]);

  return (
    <>
      <ButtonGroup variant={variant} color={color} disabled={disabled || isJELoading}>
        <Button onClick={currentState.onClick} sx={{ minWidth: '18ch' }} startIcon={disabled && <UpdateIcon />}>
          {disabled
            ? formatMessage({ id: 'document.show.btns.publishing', defaultMessage: 'PUBLISHING' })
            : currentState.title}
        </Button>
        <Button {...bindTrigger(popupState)}>
          {popupState.isOpen ? <ArrowDropUpOutlinedIcon /> : <ArrowDropDownOutlinedIcon />}
        </Button>
      </ButtonGroup>

      <Menu
        autoFocus
        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        slotProps={{ paper: { sx: { mt: -1, minWidth: '18ch' } } }}
        {...bindMenu(popupState)}
      >
        {options.map(({ key, title, onClick }) => (
          <MenuItem
            key={key}
            selected={key === activeActionKey}
            onClick={() => handleMenuItemClick(onClick)}
            disabled={disabled}
          >
            <Typography variant="body2" align="center">
              {typeof title === 'string' ? title : formatMessage(title)}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SplitButton;
