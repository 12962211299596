/* eslint-disable camelcase */
// @flow
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import { debounce } from 'throttle-debounce';
import { type IntlShape, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { Set } from 'immutable';
import { connect } from 'react-redux';
import { rtlEnable } from 'domain/env';
import TagSuggestions from 'pages/common/Dialog/DialogTagsManage/TagSuggestions__legacy/TagSuggestions';
import sheet from './sheet';

type Query = {
  tags: Array<string>,
  stag: Array<string>,
};

type Props = {
  classes: {
    [key: string]: string,
  },
  onFilter: (d: Query) => void,
  query: {
    date?: [string, string],
    text?: Array<string>,
    tags?: Array<string>,
    stag?: Array<string>,
  },
  intl: IntlShape,
  dokkaToken: string,
  // eslint-disable-next-line react/no-unused-prop-types
  direction: 'rtl' | 'ltr',
};

type State = {
  value: string,
};

const TAG_PREFIX = '#';

class SearchInput extends Component<Props, State> {
  constructor(props) {
    super(props);
    const value = props.query.stag && props.query.stag.length > 0 ? props.query.stag.join(' ') : '';
    this.state = {
      value,
    };
  }

  UNSAFE_componentWillReceiveProps() {
    this.focusTextInput();
  }

  componentDidUpdate(prevProps: Props) {
    const {
      query: { stag },
    } = this.props;
    if (prevProps.query.stag && !stag) {
      this.clearValue();
    }
  }

  onSelectSuggestion = (suggestion: string) => {
    const truncatedSuggestion = suggestion.trim();
    if (truncatedSuggestion.length < 1 || truncatedSuggestion.replace(/ /g, '').length < 1) return;
    const { onFilter, query } = this.props;
    const tags = query.tags || [];
    const text = Set(query.text || []);
    const tagList = Set([truncatedSuggestion]);
    const updatedText = text.union(tagList).toJS();
    onFilter({
      tags,
      text: updatedText,
      stag: undefined,
    });
    this.setState({
      value: '',
    });
  };

  search: debounce<void, string> = debounce(500, (value) => {
    const { onFilter, query } = this.props;
    const querytags = query.tags || [];
    const stagsTrimmed = value.trim();
    const stagList = [stagsTrimmed];
    onFilter({
      tags: querytags,
      stag: stagList,
    });
  });

  clearValue = () => {
    this.setState({ value: '' });
  };

  handleInputChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const { value: oldValue } = this.state;
    const { value } = event.target;

    // dont trigger search if value starts with TAG_PREFIX. tags suggestions shouldnt trigger search
    if (!value.startsWith(TAG_PREFIX) && (oldValue || value.length > 0)) {
      const searchValue = value.length > 1 ? value : '';
      this.search(searchValue);
    }
    this.setState({ value });
  };

  list: ?HTMLUListElement;

  focusTextInput = () => {
    if (this.searchInput) this.searchInput.focus();
  };

  render() {
    const { classes, intl } = this.props;
    const { value } = this.state;

    return (
      <div className={classes.wrapper}>
        <TagSuggestions
          inputValue={value}
          onSelect={this.onSelectSuggestion}
          onInputChange={this.handleInputChange}
          inputPlaceholder={intl.formatMessage({
            id: 'documents.search.placeholder',
            defaultMessage: 'Search',
          })}
          requirePrefix={TAG_PREFIX}
          prefixOutputWithHashtag
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  direction: rtlEnable(state) ? 'rtl' : 'ltr',
});

export default compose(injectIntl, connect(mapStateToProps), withStyles(sheet))(SearchInput);
