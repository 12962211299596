import type { RecordFactory } from 'immutable';
import { List, OrderedSet, Record } from 'immutable';
import type { TDocumentInsightsRedux, TInsightsVendor } from 'domain/insights/types.js.flow';

export const InsightsVendorFactory: RecordFactory<TInsightsVendor> = new Record({
  value: '',
  label: '',
});

export const DocumentInsightsReduxFactory: RecordFactory<TDocumentInsightsRedux> = new Record({
  gridHeaders: new List(),
  gridRows: new OrderedSet(),
  vendor: InsightsVendorFactory(),
});
