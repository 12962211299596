// @flow
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import constant from 'lodash/constant';
import { currentCompanySelector } from 'domain/documents';
import DocumentPreview from 'pages/company/DocumentPreview';
import type { Document, DocumentsType } from 'domain/documents';
import { getDocumentUrl, getPreviewSrc, getRotationAngle } from 'pages/company/workSpace/helpers';
import { useApiToken } from 'lib/apiTokenKeeper';
import { isDocPaid } from 'domain/documents/helpers';
import useDebounce from 'hooks/useDebounce';

export type Props = {
  documentId: string,
  setPreview: (d: DocumentsType, c: string) => void,
  list: Array<Document>,
  allCount: number,
  onContextMenu?: (e: MouseEvent, d: DocumentsType) => void,
  isContextMenuOpen?: boolean,
  onClick?: () => void,
  onLoadNext?: () => void,
};

const DocumentPreviewNavigation = ({
  documentId,
  setPreview,
  list,
  allCount,
  onContextMenu,
  onClick,
  onLoadNext,
  isContextMenuOpen = false,
}: Props) => {
  const apiToken = useApiToken();
  const companyId = useSelector(currentCompanySelector);

  const prevIndex = useRef(0);
  const [lastCountWasUploaded, setLastCountWasUploaded] = useState(0);

  const currentIndex = useMemo(() => {
    const index = list.findIndex((item) => item.documentID === documentId);
    const tempIndex = index >= 0 ? index : prevIndex.current;
    const resIndex = Math.min(list.size - 1, tempIndex);
    prevIndex.current = resIndex;
    return resIndex;
  }, [documentId, list]);

  const currentPage = currentIndex + 1;
  const isLoading = lastCountWasUploaded === list.size && lastCountWasUploaded !== allCount;
  const isShowLoader = currentPage === list.size && isLoading;

  useEffect(() => {
    if (onLoadNext && allCount > list.size && currentIndex + 5 > list.size && !isLoading) {
      setLastCountWasUploaded(list.size);
      onLoadNext();
    }
  }, [currentIndex, onLoadNext, allCount, list, isLoading]);

  const document = useMemo(() => list.get(currentIndex), [currentIndex, list]);

  const isLinked = useMemo(() => document && document.linkid, [document]);

  const isShowPopUp = document;

  const previewSrc = useMemo(
    () => (document ? getPreviewSrc(companyId, document.documentID, apiToken, true) : null),
    [companyId, document, apiToken],
  );

  const docLink = useMemo(
    () => (document ? getDocumentUrl(companyId, document.documentID) : ''),
    [companyId, document],
  );

  const setDocPreview = useCallback(
    (doc: Document) => {
      if (doc) {
        setPreview(doc);
      }
    },
    [setPreview],
  );

  const onClose = useCallback(() => {
    setPreview(null);
  }, [setPreview]);

  const onPrev = useCallback(() => {
    const resIndex = currentIndex - 1;
    const action = resIndex >= 0 ? setDocPreview : constant(null);
    action(list.get(resIndex));
  }, [currentIndex, list, setDocPreview]);

  const onPrevWithDelay = useDebounce(onPrev, 200);

  const onNext = useCallback(() => {
    const falseFn = constant(null);
    const nextIndex = currentIndex > list.size - 2 ? null : currentIndex + 1;
    const action = nextIndex ? setDocPreview : falseFn;
    action(list.get(nextIndex));
  }, [currentIndex, list, setDocPreview]);

  const onNextWithDelay = useDebounce(onNext, 200);

  const onKeyNav = useCallback(
    (e: KeyboardEvent) => {
      const actions = {
        ArrowLeft: onPrevWithDelay,
        ArrowRight: onNextWithDelay,
        Escape: onClose,
      };
      const execute = actions[e.key] || constant(null);
      execute();
    },
    [onPrevWithDelay, onNextWithDelay, onClose],
  );

  useEffect(() => {
    window.addEventListener('keydown', onKeyNav);
    return () => {
      window.removeEventListener('keydown', onKeyNav);
    };
  }, [onKeyNav]);

  const onClickContextMenu = useCallback(
    (e: MouseEvent) => {
      if (typeof onContextMenu === 'function') {
        onContextMenu(e, document);
      }
    },
    [onContextMenu, document],
  );

  const onClickOpen = useCallback(
    (e: MouseEvent) => {
      if (typeof onClick === 'function') {
        onClick(e, document);
      }
    },
    [onClick, document],
  );

  return isShowPopUp ? (
    <DocumentPreview
      documentID={document.documentID}
      isPaid={isDocPaid(document)}
      src={previewSrc}
      to={docLink}
      rotationAngle={getRotationAngle(document)}
      onClick={onClickOpen}
      close={onClose}
      onPrev={onPrev}
      onNext={onNext}
      isDisablePrev={currentPage === 1}
      isDisableNext={currentPage === list.size}
      onContextMenu={typeof onContextMenu === 'function' && onClickContextMenu}
      isContextMenuOpen={isContextMenuOpen}
      allPagesCount={allCount}
      currentPage={currentPage}
      isLinked={isLinked}
      isLoading={isShowLoader}
    />
  ) : null;
};

export default DocumentPreviewNavigation;
