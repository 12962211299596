// @flow
import { asyncAction } from 'lib/action';

export const getOrganizationUsers = asyncAction('ORGANIZATION_USERS/GET');
export const createOrganizationUser = asyncAction('ORGANIZATION_USERS/CREATE');
export const deleteOrganizationUser = asyncAction('ORGANIZATION_USERS/DELETE');
export const updateOrganizationUser = asyncAction('ORGANIZATION_USERS/UPDATE');
export const updateOrganizationUserProfile = asyncAction('ORGANIZATION_USERS/UPDATE_PROFILE');
export const updateOrganizationUserPicture = asyncAction('ORGANIZATION_USERS/UPDATE_PICTURE');
export const assignOrganizationUser = asyncAction('ORGANIZATION_USERS/ASSIGN');
export const revokeOrganizationUser = asyncAction('ORGANIZATION_USERS/REVOKE');
export const revokeUserFromAllCompanies = asyncAction('ORGANIZATION_USERS/REVOKE_FROM_ALL_COMPANIES');
