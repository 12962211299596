/* @flow */
import { selector } from 'lib/selectors';
import { createTreeFromNestedSet, getRootItems, getFirstCategory, getCategoryAll, getCategoryInbox } from './helpers';

import type { DokkaStore, StoreExtractor } from '../types.js.flow';

type SelectorType<T> = (s: DokkaStore) => T;
type CategoriesStore = $PropertyType<StoreExtractor, 'categories'>;
type CategoriesNestedSet = $PropertyType<CategoriesStore, 'list'>;

const categories = (state): CategoriesStore => state.categories;

export const categoriesListSelector: SelectorType<CategoriesNestedSet> = selector(
  categories,
  (e: CategoriesStore) => e.list,
);

export const rootCategoriesListSelector: SelectorType<CategoriesNestedSet> = selector(
  categoriesListSelector,
  getRootItems,
);

// exposes tree categories structure instead of flat
export const categoriesTreeSelector: SelectorType<CategoriesNestedSet> = selector(
  categoriesListSelector,
  createTreeFromNestedSet,
);

export const firstRootCategorySelector: SelectorType<?number> = selector(categoriesListSelector, getFirstCategory);

export const categoryAllSelector: SelectorType<?number> = selector(rootCategoriesListSelector, getCategoryAll);

export const categoryInboxSelector: SelectorType<?number> = selector(rootCategoriesListSelector, getCategoryInbox);
