// @flow
import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { FormattedMessage } from 'react-intl';
import type { EnhancedUser as TEnhancedUser } from 'domain/organizationUser/types.js.flow';
import { createOrganizationUser, updateOrganizationUser } from 'domain/organizationUser/actions';

import DialogManageUser from 'pages/common/Dialog/DialogManageUser';
import OrganizationUsersList from 'pages/configurations/organization/pages/users/List/UsersList';
import OrganizationUserDetails from 'pages/configurations/organization/pages/users/List/UserDetails';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

const OrganizationUsers = () => {
  const dispatch = useDispatch();
  const [userID, setUserID] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const openDialog = useCallback(() => setIsOpen(true), []);

  const closeDialog = useCallback(() => setIsOpen(false), []);

  const onClickListItem = useCallback((id: string) => setUserID(id), []);

  const onGoBack = useCallback(() => setUserID(null), []);

  const handleManageUser = useCallback(
    (data: TEnhancedUser): Promise<void> =>
      new Promise((resolve, reject) => {
        if (userID) {
          dispatch(updateOrganizationUser({ data, resolve, reject }));
        } else {
          dispatch(createOrganizationUser({ data, resolve, reject }));
        }
      }).then(() => setIsOpen(false)),
    [userID, dispatch],
  );

  const isEdit = !!userID;

  return (
    <>
      <Box display="flex" mb={2}>
        <Typography variant="subtitle1">
          <FormattedMessage id="configurations.org_users.title" defaultMessage="Organization Users" />
        </Typography>
        {!isEdit && (
          <Button variant="outlined" type="button" onClick={openDialog} startIcon={<AddIcon />} sx={{ ml: 'auto' }}>
            <FormattedMessage id="button.add.user" defaultMessage="Add User" />
          </Button>
        )}
      </Box>

      <Paper sx={{ flex: '1 1 0', overflowY: 'auto' }}>
        {!userID && <OrganizationUsersList onClickListItem={onClickListItem} />}
        {userID && <OrganizationUserDetails userID={userID} onGoBack={onGoBack} onClickEdit={openDialog} />}

        <DialogManageUser
          userID={userID}
          open={isOpen}
          onCancel={closeDialog}
          onSubmit={handleManageUser}
          subtitle={
            !isEdit && (
              <Typography variant="subtitle1" textAlign="center" mb={2} color="text.secondary">
                <FormattedMessage
                  id="form.organization.users.create.subtitle"
                  defaultMessage="An account activation email will be sent to the new user"
                />
              </Typography>
            )
          }
        />
      </Paper>
    </>
  );
};

export default OrganizationUsers;
