// @flow
import React, { useCallback, Fragment, forwardRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteOrganizationUser, organizationUsersSelector } from 'domain/organizationUser';
import { relevantSearch } from 'lib/helpers';
import { FormattedMessage, useIntl } from 'react-intl';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useConfirm } from 'material-ui-confirm';

import Avatar from 'components/mui/Avatar';
import Button from '@mui/material/Button';
import ListMui from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import ListSubheader from '@mui/material/ListSubheader';
import SearchBase from 'components/mui/Layouts/components/AppbarSearchBase';
import { getUserDisplayName } from 'pages/configurations/helpers';

type TOrganizationUsersList = {
  onClickListItem: (id: string) => void,
};

const rolesOrder = [
  'admin',
  'accountant',
  'limited-accountant',
  'restricted-accountant',
  'user',
  'confidential-user',
  'restricted-user',
  'confidential-restricted-user',
  'supplier',
];

const mapStateToProps = (state) => ({
  users: organizationUsersSelector(state),
});

const OrganizationUsersList = forwardRef(({ onClickListItem }: TOrganizationUsersList, ref) => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const { formatMessage } = useIntl();
  const { users } = useSelector(mapStateToProps);
  const [searchText, setSearchText] = useState('');

  const sortedUsers = useMemo(() => {
    const userGroups = users
      .sort((a, b) => a.display_name.localeCompare(b.display_name))
      .reduce((res, user) => {
        const groupName = user.admin ? 'admin' : user.role;
        const group = res[groupName] || [];
        res[groupName] = [...group, user];
        return res;
      }, {});

    return rolesOrder.reduce((res, role) => {
      const userGroup = userGroups[role] || [];
      return [...res, ...userGroup];
    }, []);
  }, [users]);

  const usersList = useMemo(
    () => (searchText ? relevantSearch(searchText, sortedUsers, (user) => user.display_name) : sortedUsers),
    [sortedUsers, searchText],
  );

  const displayName = (name: string) => {
    const matches = match(name, searchText, { findAllOccurrences: true });
    const parts = parse(name, matches);

    return parts.map((part, index) => (
      <span
        key={`${index.toString()}`}
        style={{
          fontWeight: part.highlight ? 700 : 400,
        }}
      >
        {part.text}
      </span>
    ));
  };

  const onChangeSearch = useCallback((text: string) => setSearchText(text), []);

  const onDelete = (e, id: string) => {
    e.preventDefault();
    e.stopPropagation();

    confirm({
      title: formatMessage({
        id: 'confirm.user.organization.delete.title',
        defaultMessage: 'Delete User?',
      }),
      description: formatMessage({
        id: 'confirm.user.organization.delete.description',
        defaultMessage: 'User will be permanently deleted from the system, action cannot be  undone.',
      }),
      confirmationText: formatMessage({
        id: 'button.delete',
        defaultMessage: 'Delete',
      }),
      confirmationButtonProps: {
        color: 'error',
      },
    }).then(() => {
      dispatch(deleteOrganizationUser({ id }));
    });
  };

  return (
    <>
      <ListMui
        ref={ref}
        subheader={
          <ListSubheader sx={{ py: 2, lineHeight: 1 }}>
            <SearchBase value={searchText} onChange={onChangeSearch} />
          </ListSubheader>
        }
      >
        {usersList.map((user, index) => (
          <Fragment key={user.email}>
            <ListItemButton dense alignItems="center" onClick={() => onClickListItem(user.id)}>
              <ListItemAvatar>
                <Avatar size={40} src={user.pic} />
              </ListItemAvatar>
              <Box flexGrow={1} my="6px">
                <Typography variant="body2">{displayName(getUserDisplayName(user))}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {user.email}
                </Typography>
              </Box>
              <Box flexShrink={0}>
                {user.companies_id.size === 0 && (
                  <Button
                    size="small"
                    color="error"
                    onClick={(e) => onDelete(e, user.id)}
                    startIcon={<DeleteIcon />}
                    sx={{ ml: 1 }}
                  >
                    <FormattedMessage id="button.delete" defaultMessage="Delete" />
                  </Button>
                )}
                {user.companies_id.size > 0 && (
                  <Typography variant="body2" color="text.secondary">
                    <FormattedMessage
                      defaultMessage={`Assigned companies ${user.companies_id.size}`}
                      id="configurations.org_users.list.assigned_companies"
                      values={{ count: <strong>{user.companies_id.size}</strong> }}
                    />
                  </Typography>
                )}
              </Box>
            </ListItemButton>
            {users.size !== index + 1 && <Divider variant="inset" component="li" />}
          </Fragment>
        ))}
      </ListMui>
    </>
  );
});

export default OrganizationUsersList;
