// @flow
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { rtlEnable } from 'domain/env';
import { viewArrangementSelector } from 'domain/documents';

import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material';

export const PaidBadgeStyled = styled('div', {
  label: 'PaidBadge',
  shouldForwardProp: (propName) => !['isRtl', 'badgeWidth'].includes(propName),
})(({ theme, isRtl, badgeWidth }) => ({
  color: alpha(theme.palette.secondary.main, 0.5),
  fontWeight: 500,
  position: 'absolute',
  top: 0,
  [isRtl ? 'left' : 'right']: '0 /* @noflip */',
  zIndex: 1,
  width: 0,
  height: 0,
  borderTop: `${badgeWidth}px solid ${alpha(theme.palette.secondary.main, 0.12)}`,
  borderLeft: `${badgeWidth}px solid transparent`,
}));

export const PaidBadgeTextContainer = styled('div', {
  label: 'PaidBadgeText',
  shouldForwardProp: (propName) => !['isRtl', 'textContainerWidth', 'fontSize'].includes(propName),
})(({ isRtl, textContainerWidth, fontSize }) => ({
  width: textContainerWidth,
  height: textContainerWidth,
  rotate: isRtl ? '-45deg' : '45deg',
  top: -textContainerWidth * 1.6,
  fontSize,
  textTransform: 'uppercase',
  position: 'absolute',
  right: fontSize / 2,
}));

const PaidBadge = ({ docWidth }) => {
  const viewArrangement = useSelector(viewArrangementSelector);
  const isRtl = useSelector(rtlEnable);
  const badgeWidth = docWidth * 0.15;
  const fontSize = badgeWidth * 0.21;
  const textContainerWidth = badgeWidth / 2;

  return (
    <PaidBadgeStyled isRtl={viewArrangement ? viewArrangement === 'rtl' : isRtl} badgeWidth={badgeWidth}>
      <PaidBadgeTextContainer
        textContainerWidth={textContainerWidth}
        fontSize={fontSize}
        isRtl={viewArrangement ? viewArrangement === 'rtl' : isRtl}
      >
        <FormattedMessage id="documents.item.badge.paid" defaultMessage="Paid" />
      </PaidBadgeTextContainer>
    </PaidBadgeStyled>
  );
};

export default memo(PaidBadge);
