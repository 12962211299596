// @flow
import { Range, Record, type RecordOf, type RecordFactory, type List } from 'immutable';
import type { RenderSuccessData } from 'react-pdf';
import type { ItemType } from 'domain/journal/types.js.flow';
import type { CSSProps, PageParams } from 'pages/document/types.js.flow';
import _ from 'lodash';
import { getRotateByDocID, type AngleType } from 'lib/pdfHelpers';

export const PageParamsFactory: RecordFactory<PageParams> = new Record({
  idx: 0,
  height: 0,
  rotate: 0,
  embdRotate: 0,
  status: null,
});

export type PageParamsRecord = List<RecordOf<PageParams>>;

export const devicePixelRatio = (): number => {
  if (process.env.NODE_ENV === 'test') return 1;
  return (window && window.devicePixelRatio) || 1;
};

export function getOffset(width: number, scale: number, nextScale: number, prevOffsetX: number): number {
  /**
   * Method based on center alrady center
   */
  const delta1 = width - width * scale;
  const delta2 = width - width * nextScale;
  if (delta1 === 0) return delta2 / 2;
  return (prevOffsetX * delta2) / delta1;
}

export function getTransform(x: number = 0, y: number = 0): string {
  return `translate3d(${x}px, ${y}px, 0)`;
}

export const rumb = ['top', 'right', 'bottom', 'left'];
export const dm = ['width', 'height'];
export const angles: Array<AngleType> = [0, 90, 180, 270];

export function getPropName<T: CSSProps>(prop: T, rotate: AngleType): T {
  const idx = rotate / 90;
  const r = rumb.indexOf(prop);
  const d = dm.indexOf(prop);
  if (r > -1) return ((rumb[(r + idx) % rumb.length]: any): T);
  return ((dm[(d + idx) % dm.length]: any): T);
}

export function createRange(...arg: number[]): List<number> {
  return Range(...arg).toList();
}

export function getPageParams(
  pageInfo: $PropertyType<RenderSuccessData, 'pageInfo'>,
  pageParams: RecordOf<PageParams>,
  viewPortWidth: number,
  scale: number,
): RecordOf<PageParams> {
  /*
  When document has embed rotation pdf.js would still provide pageInfo with height and width
  that doesnt respect embed rotation, though doc will be rendered in a right manner.
  If embeded rotation is 90/270 we must substitude width and height manually.
  Later we will do additional substitution when backend/frontend rotation is applied additionally
   */
  const isEmbedRotated = (pageParams.embdRotate / 90) % 2 === 1;

  /*
  Let's check under what index in the array we store the height and width of the page.
  Because for some documents, the width and height are stored at a different index in the array,
  because of which we cannot determine the width and height of the page,
  as a result of which the document does not appear on the page.
  */
  const firstSideIndex = pageInfo.view[3] ? 3 : 1;
  const secondSideIndex = pageInfo.view[2] ? 2 : 0;

  const pageProportions = {
    width: isEmbedRotated ? secondSideIndex : firstSideIndex,
    height: isEmbedRotated ? firstSideIndex : secondSideIndex,
  };
  const params = {
    // eslint-disable-next-line max-len
    height: (pageInfo.view[pageProportions.width] * viewPortWidth) / pageInfo.view[pageProportions.height],
    // eslint-disable-next-line max-len
    width: (pageInfo.view[pageProportions.height] * viewPortWidth) / pageInfo.view[pageProportions.width],
  };
  return pageParams
    .set('height', params[getPropName('height', pageParams.rotate)] * scale)
    .set('embdRotate', pageInfo.rotate);
}

export function getPolygonStyleFactory(
  pageWidth: number,
  pageHeight: number,
  rotation: AngleType,
  ratio: number,
  item: RecordOf<ItemType>,
) {
  const { w, h, y, x } = item.boundingPoly;
  const getFloat = (s: string) => parseFloat(s) * ratio;
  const left = getFloat(x);
  const width = getFloat(w);
  const height = getFloat(h);
  const top = getFloat(y) - height;
  if (rotation === 90) {
    return {
      left: pageWidth - height - top,
      top: left,
      width: height,
      height: width,
    };
  }
  if (rotation === 180) {
    return {
      left: pageWidth - width - left,
      top: pageHeight - height - top,
      width,
      height,
    };
  }
  if (rotation === 270) {
    return {
      left: top,
      top: pageHeight - width - left,
      width: height,
      height: width,
    };
  }
  return {
    left,
    top,
    width,
    height,
  };
}

// eslint-disable-next-line max-len
export function getPreviewStyle(documentId: string, width: number, initialRotation: number, image: HTMLImageElement) {
  const rotation = getRotateByDocID(documentId) ? getRotateByDocID(documentId)[0] : initialRotation;
  const ratio = image.naturalHeight / image.naturalWidth;

  const angleRotation = rotation === 90 || rotation === 270;

  return {
    width: `${angleRotation ? width / ratio : width}px`,
    height: `${angleRotation ? width : width * ratio}px`,
    opacity: 1,
    transformOrigin: '50% 50%',
    transform: typeof rotation === 'number' ? `rotate(${rotation}deg)` : null,
  };
}

export function createPageParams(documentRotate, rotationState) {
  return (idx: number) => {
    const lsRotate = _.get(rotationState, idx);
    const rotate = _.isNumber(lsRotate) ? lsRotate : documentRotate.get(idx, 0);
    return PageParamsFactory({ idx, rotate });
  };
}

export function findIndex<T>(target: T): (a: ?number, v: T, i: number) => ?number {
  return (a, v, i): ?number => {
    if (v === target) return i;
    if (typeof a !== 'undefined') return a;
    return a;
  };
}

export function cancellation<T>(fn: (a: T) => void) {
  let cancel = false;
  return {
    onCancel() {
      cancel = true;
    },
    execute(...args) {
      if (!cancel) fn(...args);
    },
  };
}
