/* @flow */
import { type RecordOf } from 'immutable';
import * as A from './actions';
import { UiStateFactory, validStampMode } from 'domain/ui/helpers';
import type { TUiState } from 'domain/ui/types.js.flow';

export const reducer = {
  ui(state: RecordOf<TUiState> = UiStateFactory(), action: any) {
    switch (action.type) {
      case A.toggleDocumentHotkeysModalAction.type:
        return state.setIn(
          ['modal', 'isOpenDocumentHotkeysModal'],
          !state.getIn(['modal', 'isOpenDocumentHotkeysModal']),
        );

      case A.toggleWorkspaceHotkeysModalAction.type:
        return state.updateIn(['modal', 'isOpenWorkspaceHotkeysModal'], (currentValue) => !currentValue);

      case A.toggleAFPanelAction.type:
        return state.setIn(['approvalFlow', 'isOpen'], action.payload);

      case A.setApprovalStampModeAction.type:
        return state.setIn(
          ['approvalFlow', 'stampMode'],
          validStampMode.includes(action.payload) ? action.payload : state.approvalFlow.stampMode,
        );
      case A.setApprovalStampAction.type:
        return state.setIn(['approvalFlow', 'stamp'], action.payload);

      default:
        return state;
    }
  },
};
