import Stack from '@mui/material/Stack';
import IconButtonMui from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

import { styled, alpha } from '@mui/material/styles';

export const DocumentPageControlsWrapper = styled('div', { shouldForwardProp: (propName) => propName !== 'pageWidth' })(
  ({ theme, pageWidth }) => ({
    position: 'absolute',
    top: 0,
    left: '50%',
    height: '100%',
    opacity: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    pointerEvents: 'none',
    transition: 'opacity 250ms',
    width: pageWidth,
    marginLeft: -(pageWidth / 2),
    '&:hover': {
      opacity: 1,
    },
  }),
);

export const ControlsStack = styled(Stack, {
  shouldForwardProp: (propName) => !['shrink', 'wasMoved'].includes(propName),
})(({ theme, shrink, wasMoved }) => ({
  backgroundColor: alpha(theme.palette.common.black, 0.65),
  color: theme.palette.common.white,
  padding: shrink ? theme.spacing(0.5, 2) : theme.spacing(1, 2),
  borderRadius: 48,
  alignItems: 'center',
  position: wasMoved ? 'relative' : 'sticky',
  top: wasMoved ? 0 : '80%',
  pointerEvents: 'all',
  cursor: 'move',
}));

ControlsStack.defaultProps = {
  direction: 'row',
  spacing: 1,
};

export const ControlButton = styled(IconButtonMui)(({ theme }) => ({
  color: theme.palette.common.white,

  '&.Mui-disabled': {
    color: alpha(theme.palette.common.white, 0.4),
  },
}));

export const ControlsDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  height: 24,
}));

ControlsDivider.defaultProps = {
  orientation: 'vertical',
};

export const ControlsCounter = styled(Stack, { shouldForwardProp: (propName) => propName !== 'shrink' })(
  ({ theme, shrink }) => ({
    color: theme.palette.common.white,
    fontWeight: 500,
    paddingLeft: theme.spacing(2),
    whiteSpace: 'nowrap',
    fontSize: theme.typography.pxToRem(shrink ? 12 : 14),
  }),
);
