// @flow
import React from 'react';
import { Link } from 'react-router-dom';

import { DrawerStatic, DrawerHeader, DrawerFooter } from 'components/mui/Layouts/components/Drawer';
import { LayoutRoot, LayoutContent } from 'components/mui/Layouts/components/Layout';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import { AppBarItemDivider, AppBarStatic } from 'components/mui/Layouts/components/AppBar';
import AppbarLogoCompany from 'components/mui/Layouts/components/AppbarLogoCompany';
import SettingsLink from 'components/mui/Layouts/components/Drawer/SettingsLink';
import HelpDropdown from 'components/mui/Layouts/components/HelpDropdown';
import LocaleSelect from 'components/mui/LocaleSelect';
import AccountDropdown from 'components/mui/Layouts/components/AccountDropdown';
import BackToArrow from 'components/mui/Layouts/Profile/components/BackToArrow';
import FullscreenIconButton from 'components/mui/Layouts/components/FullscreenIconButton';
import AsideLogo from 'components/mui/Layouts/components/AsideLogo';

type TLayoutProfile = {
  children: React$Node,
};

const LayoutProfile: React$StatelessFunctionalComponent<TLayoutProfile> = ({ children }) => (
  <LayoutRoot>
    <DrawerStatic variant="permanent">
      <DrawerHeader>
        <Link to="/">
          <AsideLogo />
        </Link>
      </DrawerHeader>
      <DrawerFooter>
        <List>
          <SettingsLink />
        </List>
      </DrawerFooter>
    </DrawerStatic>

    <AppBarStatic>
      <Box flexShrink={0} display="flex" alignItems="center">
        <BackToArrow />
        <AppbarLogoCompany />
      </Box>

      <Stack direction="row" alignItems="center" spacing={1} flexShrink={0} ml="auto">
        <FullscreenIconButton />
        <AppBarItemDivider />
        <HelpDropdown />
        <LocaleSelect flagOnly />
        <AccountDropdown />
      </Stack>
    </AppBarStatic>

    <LayoutContent>
      <DrawerHeader />
      {children}
    </LayoutContent>
  </LayoutRoot>
);

export default LayoutProfile;
