// @flow
import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';

export default makeStyles((theme) => ({
  errorRow: {
    '&&&': {
      '&.ag-row .ag-cell:not(.action-btns)': {
        backgroundColor: `${alpha(theme.palette.error.main, theme.palette.action.activatedOpacity)} !important`,
      },
      '&.ag-row-hover .ag-cell:not(.action-btns)': {
        backgroundColor: `${alpha(
          theme.palette.error.main,
          theme.palette.action.activatedOpacity + theme.palette.action.hoverOpacity,
        )} !important`,
      },
      '&.ag-row-selected .ag-cell:not(.action-btns)': {
        backgroundColor: `${alpha(
          theme.palette.error.main,
          theme.palette.action.activatedOpacity + theme.palette.action.selectedOpacity,
        )} !important`,
      },
      '&.ag-row .ag-cell.ag-cell-range-selected:not(.action-btns):not(.ag-column-hover)': {
        '&:after': {
          content: '""',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: 'var(--ag-range-selection-background-color ,rgba(122,134,203,.1)) !important',
        },
      },
    },
  },
  warningRow: {
    '&&&': {
      '&.ag-row .ag-cell:not(.action-btns)': {
        backgroundColor: `${alpha(theme.palette.warning.main, theme.palette.action.activatedOpacity)} !important`,
      },
      '&.ag-row-hover .ag-cell:not(.action-btns)': {
        backgroundColor: `${alpha(
          theme.palette.warning.main,
          theme.palette.action.activatedOpacity + theme.palette.action.hoverOpacity,
        )} !important`,
      },
      '&.ag-row-selected .ag-cell:not(.action-btns)': {
        backgroundColor: `${alpha(
          theme.palette.warning.main,
          theme.palette.action.activatedOpacity + theme.palette.action.selectedOpacity,
        )} !important`,
      },
      '&.ag-row .ag-cell.ag-cell-range-selected:not(.action-btns):not(.ag-column-hover)': {
        '&:after': {
          content: '""',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: 'var(--ag-range-selection-background-color ,rgba(122,134,203,.1)) !important',
        },
      },
    },
  },

  scheduledForPublishingRow: {
    '&&&': {
      '&.ag-row .ag-cell:not(.action-btns)': {
        backgroundColor: `${alpha(theme.palette.grey[600], theme.palette.action.activatedOpacity)} !important`,
        color: theme.palette.text.disabled,
      },
      '&.ag-row-hover .ag-cell:not(.action-btns)': {
        backgroundColor: `${alpha(
          theme.palette.grey[600],
          theme.palette.action.activatedOpacity + theme.palette.action.hoverOpacity,
        )} !important`,
      },
      '&.ag-row-selected .ag-cell:not(.action-btns)': {
        backgroundColor: `${alpha(
          theme.palette.grey[600],
          theme.palette.action.activatedOpacity + theme.palette.action.selectedOpacity,
        )} !important`,
      },
      '&.ag-row .ag-cell.ag-cell-range-selected:not(.action-btns):not(.ag-column-hover)': {
        '&:after': {
          content: '""',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: 'var(--ag-range-selection-background-color ,rgba(122,134,203,.1)) !important',
        },
      },
    },
  },
}));
