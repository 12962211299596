// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import * as ACL from 'domain/restriction';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import LockButton from 'pages/company/LinkedDocumentsPanel/components/LinkedActions/LockButton';
import PrintButton from 'pages/company/LinkedDocumentsPanel/components/LinkedActions/PrintButton';
import DownloadButton from 'pages/company/LinkedDocumentsPanel/components/LinkedActions/DownloadButton';
import ExportButton from 'pages/company/LinkedDocumentsPanel/components/LinkedActions/ExportButton';
import MoveToCompanyButton from 'pages/company/LinkedDocumentsPanel/components/LinkedActions/MoveToCompanyButton';

type TLinkedNote = {
  total: string,
  linkID: string,
  onMoveToCompany: () => void,
};

const mapStateToProps = (state) => ({
  isGranted: ACL.isGranted(state),
});

const LinkedActions: React$StatelessFunctionalComponent<TLinkedNote> = ({ linkID, total, onMoveToCompany }) => {
  const { formatMessage } = useIntl();
  const { isGranted } = useSelector(mapStateToProps);
  const isAccountUser = isGranted(ACL.IS_ACCOUNT);

  return (
    <Box display="flex" alignItems="center">
      <Typography variant="subtitle1">
        {formatMessage(
          {
            id: 'documents.linkedPanel.title.list',
            defaultMessage: `${total} documents`,
          },
          {
            value: total,
          },
        )}
      </Typography>
      <Stack direction="row" spacing={1} ml="auto">
        <LockButton linkID={linkID} />
        <Divider orientation="vertical" variant="middle" flexItem sx={{ my: '8px !important' }} />
        {isAccountUser && <MoveToCompanyButton linkID={linkID} onMoveToCompany={onMoveToCompany} />}
        <PrintButton linkID={linkID} />
        <DownloadButton linkID={linkID} />
        <ExportButton linkID={linkID} />
      </Stack>
    </Box>
  );
};

export default LinkedActions;
