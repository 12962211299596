/* @flow */
import React, { useMemo } from 'react';

import {
  FakeDropdownContainer,
  FakeDropdown,
  FakeDropdownValue,
} from 'pages/company/grid/components/DropdownCellRenderer/StyledComponents';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface IProps {
  api: any;
  rowIndex: number;
  column: any;
  value: { name: string };
}

const DropdownCellRenderer = ({ value }: IProps) => {
  // TODO: rework
  const label = useMemo(() => (Array.isArray(value) ? value.map((v) => v.label).join(', ') : value.label), [value]);

  return (
    <FakeDropdownContainer>
      <FakeDropdown
        clickable
        label={
          <FakeDropdownValue>
            {label} <ArrowDropDownIcon />
          </FakeDropdownValue>
        }
      />
    </FakeDropdownContainer>
  );
};

export default DropdownCellRenderer;
