// @flow
import React from 'react';
import { useIntl } from 'react-intl';
import { compose } from 'redux';
import { reduxForm, Field, type FormProps, getFormValues } from 'redux-form/immutable';
import useManageUserForm from 'pages/common/Dialog/DialogManageUser/useManageUserForm';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SelectForm from 'components/mui/Form/Select/SelectForm';
import TextFieldForm from 'components/mui/Form/TextField/TextFieldForm';
import Grid from '@mui/material/Grid';
import PhoneForm from 'components/mui/Form/Phone/PhoneForm';
import SwitchForm from 'components/mui/Form/Switch/SwitchForm';

import { ROLES } from 'domain/env/helpers';
import { connect } from 'react-redux';

type TManageUserForm = {
  isEdit: boolean,
  isCompany: boolean,
  onCancel: () => void,
} & FormProps;

const ManageUserForm: React$StatelessFunctionalComponent<TManageUserForm> = ({
  handleSubmit,
  isEdit,
  initialValues,
  submitting,
  onCancel,
  currentValues,
  subtitle,
  isCompany,
}) => {
  const { formatMessage } = useIntl();
  const currentRole = initialValues.role;
  const { roleOptions } = useManageUserForm({ role: currentRole, isEdit, isCompany });

  const renderModificators = React.useCallback(
    (role, isAdmin, readonly, limited) => {
      switch (role) {
        case ROLES.accountant:
          return !isAdmin ? (
            <>
              <Field
                component={SwitchForm}
                name="restricted"
                props={{
                  FormControlLabelProps: {
                    disabled: false,
                    labelPlacement: 'end',
                    label: formatMessage({
                      id: 'form.organization.users.role.modificators.restricted',
                      defaultMessage: 'Limited documents visibility',
                    }),
                    sx: { mt: 1, display: 'flex' },
                  },
                }}
              />
              <Field
                component={SwitchForm}
                name="limited"
                props={{
                  FormControlLabelProps: {
                    disabled: readonly,
                    labelPlacement: 'end',
                    label: formatMessage({
                      id: 'form.organization.users.role.modificators.limited',
                      defaultMessage: 'Disable publish transaction',
                    }),
                    sx: { mt: 1, display: 'flex' },
                  },
                }}
              />
              <Field
                component={SwitchForm}
                name="readonly"
                props={{
                  FormControlLabelProps: {
                    disabled: limited,
                    labelPlacement: 'end',
                    label: formatMessage({
                      id: 'form.organization.users.role.modificators.readOnly',
                      defaultMessage: 'View-only financial access',
                    }),
                    sx: { mt: 1, display: 'flex' },
                  },
                }}
              />
            </>
          ) : null;
        case ROLES.user:
        case ROLES['confidential-user']:
          return (
            <>
              <Field
                component={SwitchForm}
                name="restricted"
                disabled={false}
                props={{
                  FormControlLabelProps: {
                    labelPlacement: 'end',
                    label: formatMessage({
                      id: 'form.organization.users.role.modificators.restricted',
                      defaultMessage: 'Limited documents visibility',
                    }),
                    sx: { mt: 1, display: 'flex' },
                  },
                }}
              />
            </>
          );

        default:
          return null;
      }
    },
    [formatMessage],
  );

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        {subtitle}
        <Field
          component={SelectForm}
          options={roleOptions}
          name="role"
          id="role"
          label={formatMessage({
            id: 'form.organization.users.create.position.placeholder',
            defaultMessage: 'Executive position',
          })}
          FormControlProps={{
            disabled: initialValues.role === 'supplier',
            fullWidth: true,
          }}
        />

        {renderModificators(currentValues?.role, currentValues?.admin, currentValues?.readonly, currentValues?.limited)}

        <Typography variant="subtitle1" my={2}>
          {formatMessage({
            id: 'form.organization.create.contactsTitle',
            defaultMessage: 'Contact Person information',
          })}
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Field
              component={TextFieldForm}
              label={formatMessage({
                id: 'form.organization.create.firstName.label',
                defaultMessage: 'First name',
              })}
              placeholder={formatMessage({
                id: 'form.organization.create.firstName.placeholder',
                defaultMessage: 'Enter your first name',
              })}
              required
              name="first_name"
              id="first_name"
              fullWidth
              withTrim
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextFieldForm}
              label={formatMessage({
                id: 'form.organization.create.lastName.label',
                defaultMessage: 'Last name',
              })}
              placeholder={formatMessage({
                id: 'form.organization.create.lastName.placeholder',
                defaultMessage: 'Enter your last name',
              })}
              required
              name="last_name"
              id="last_name"
              fullWidth
              withTrim
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              component={TextFieldForm}
              label={formatMessage({
                id: 'form.organization.create.email.label',
                defaultMessage: 'Email',
              })}
              placeholder={formatMessage({
                id: 'form.organization.create.email.placeholder',
                defaultMessage: 'email@example.com',
              })}
              required
              name="email"
              id="email"
              disabled={isEdit}
              fullWidth
              withTrim
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="phone"
              component={PhoneForm}
              CodeInputProps={{
                required: true,
              }}
              NumberProps={{
                required: true,
                placeholder: formatMessage({
                  id: 'form.organization.create.phone.placeholder',
                  defaultMessage: '+3 8093 4648407',
                }),
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onCancel}>
          {formatMessage({
            id: 'button.cancel',
            defaultMessage: 'Cancel',
          })}
        </Button>
        <LoadingButton variant="contained" type="submit" loading={submitting}>
          {formatMessage({
            id: 'button.submit',
            defaultMessage: 'Submit',
          })}
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

export default compose(
  reduxForm({
    form: 'organizationUserForm',
  }),
  connect((state) => ({
    currentValues: getFormValues('organizationUserForm')(state),
  })),
)(ManageUserForm);
