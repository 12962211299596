/* @flow */
import { handleImport } from 'lib/import';

export const DEFAULT_LABEL = 'dokka';

export const labels = {
  pegasus: [
    'pegasus.dokka.loc',
    'pegasus.dokka.biz',
    'pegasus.dokka.com',
    'pegasus-redesigned.dokka.biz',
    'pegasus-redesigned.dokka.com',
  ],
  alprime: ['app.alprimecap.com', 'alprime.dokka.biz', 'alprimecap.dokka.com'],
  bdo: ['app.bdo.com', 'bdo.dokka.biz', 'docs.bdo.co.il', 'bdo.dokka.com'],
  profitian: ['app.profitian.com', 'profitian.dokka.biz', 'app.profitian-tech.com', 'profitian.dokka.com'],
  autodok: ['app.autodok.co.il', 'autodok.dokka.biz', 'autodok.dokka.com'],
  made: ['app.made360.co.il', 'made.dokka.biz', 'release-made.dokka.biz', 'made.dokka.com'],
};

export const optionLoaders = {
  dokka: () => handleImport(import('./dokka')),
  pegasus: () => handleImport(import('./pegasus')),
  alprime: () => handleImport(import('./alprime')),
  bdo: () => handleImport(import('./bdo')),
  profitian: () => handleImport(import('./profitian')),
  autodok: () => handleImport(import('./autodok')),
  made: () => handleImport(import('./made')),
};

export const getLabel = () =>
  Object.entries(labels).reduce(
    (res, [key, hosts]) => (hosts.includes(window.location.host) ? key : res),
    DEFAULT_LABEL,
  );

const spreadJSLicensedDomains = ['app.dokka.com', 'localhost:3000'];
export const checkIsAllowSpreadJSLicense = () =>
  spreadJSLicensedDomains.includes(window.location.host) || window.location.host.includes('.dokka.biz');

// export const getLabel = (host: string) => 'bdo';

export const getOptionsLoader = (label: string) => optionLoaders[label] || optionLoaders[DEFAULT_LABEL];
