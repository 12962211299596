import { selector } from 'lib/selectors';

export const routing = (state) => state.routing;
export const locationSelector = selector(routing, (r) => r.get('location'));
export const searchSelector = selector(locationSelector, (r) => r.get('search'));
export const querySelector = selector(locationSelector, (l) => l.query);
export const stateSelector = selector(locationSelector, (r) => r.get('state'));
export const backUrlSelector = selector(stateSelector, (r) => r.get('backUrl'));
export const prevLocationSelector = selector(routing, (r) => r.get('prevLocation'));
export const completeSelector = selector(routing, (r) => r.complete);
export const prevQuerySelector = selector(prevLocationSelector, (l) => l.query);

// eslint-disable-next-line max-len
export const locationIsChange = selector(prevLocationSelector, locationSelector, (prev, current) => {
  if (prev.pathname !== current.pathname) return true;
  return prev.search !== current.search;
});

export const isClassifiedSelector = selector(querySelector, (q) => 'classified' in q && q.classified === 'red');
