import { locationChangeAction } from 'domain/router/redux/reduxActions';

const initLocationChangeListener = (store, router) => {
  // init location store
  store.dispatch(locationChangeAction(router.state.location, router.state.historyAction));

  router.subscribe((state) => {
    const { historyAction, location } = state;

    store.dispatch(locationChangeAction(location, historyAction));
  });
};

export default initLocationChangeListener;
