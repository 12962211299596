// @flow
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useTheme } from '@mui/material';

import { shouldHighlightAmountSelector } from 'domain/companies';

import type { TGridApi } from '../../types.js.flow';

type Props = {
  api: TGridApi,
};

const handleRangeSelectionChanged = (api, setSum, setVisible) => {
  const cellRanges = api.getCellRanges();
  let sum = 0;
  let count = 0;
  const onHide = () => {
    count = 0;
    setVisible(false);
  };

  if (!cellRanges || cellRanges.length === 0) {
    onHide();
    return;
  }

  if (cellRanges) {
    count = 0;
    cellRanges.forEach((range) => {
      // get starting and ending row, remember rowEnd could be before rowStart
      const startRow = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
      const endRow = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);
      for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
        range.columns.forEach((column) => {
          const rowModel = api.getModel();
          const rowNode = rowModel.getRow(rowIndex);
          // define that column type is 'amount'
          const isAmountColumnType = column.colDef.type === 'amount';
          let value = api.getValue(column, rowNode);
          if (isAmountColumnType) {
            // convert to number(HASH cell value can be string) and in some erps value can include comma instead of point
            // '2100,00' -> 2100
            if (typeof value === 'string') {
              value = Number(value.replace(',', '.'));
            }
            sum += value;
            count++;
          }
        });
      }
      setSum(sum);
      setVisible(count > 1);
    });
  }
};

const getDisplayHighlightedValue = (shouldHighlight, value) => (shouldHighlight ? `(${value})` : value);
const CustomSum: React$StatelessFunctionalComponent<Props> = ({ api }) => {
  const { formatMessage } = useIntl();
  const { palette } = useTheme();
  const [sum, setSum] = useState(0);
  const [visible, setVisible] = useState(false);

  const shouldHighlightAmount = useSelector(shouldHighlightAmountSelector);

  const shouldHighlight = shouldHighlightAmount && sum < 0;

  useEffect(() => {
    api.addEventListener('rangeSelectionChanged', () => handleRangeSelectionChanged(api, setSum, setVisible));
  }, [api]);

  const formattedSum = Number.isInteger(sum) ? sum : sum.toFixed(2);

  return (
    <div className="ag-status-name-value" style={{ display: visible ? 'flex' : 'none' }}>
      <span className="component">
        {formatMessage({ id: 'company.workspace.grid.statusBar.sum', defaultMessage: 'Sum' })}:&nbsp;
      </span>
      <span
        className="ag-status-name-value-value"
        style={{ color: shouldHighlight ? palette.error.main : 'inherit', flip: 'false', direction: 'ltr' }}
      >
        {shouldHighlightAmount ? getDisplayHighlightedValue(shouldHighlight, Math.abs(formattedSum)) : formattedSum}
        &nbsp;
      </span>
    </div>
  );
};

export default CustomSum;
