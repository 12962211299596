// @flow
import React from 'react';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

interface IProps {
  onClick: (e: MouseEvent) => void;
}

const ContextButton = ({ onClick }: IProps) => (
  <Stack justifyContent="center" alignItems="center" flex={1} height="100%">
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
      sx={{ fontSize: '22px' }}
    >
      <MoreHorizIcon fontSize="inherit" />
    </IconButton>
  </Stack>
);

export default ContextButton;
