/* @flow */

export const DEFAULT_THEME = 'dokka';

export const themeLoaders = {
  bdo: () => import('styles/bdoTheme'),
  dokka: () => import('styles/dokkaTheme'),
  profitian: () => import('styles/profitianTheme'),
  autodok: () => import('styles/autodokTheme'),
  made: () => import('styles/madeTheme'),
};

export const getThemeLoader = (theme: string) => {
  return themeLoaders[theme] || themeLoaders[DEFAULT_THEME];
}
