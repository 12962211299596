import { takeEvery } from 'redux-saga/effects';
import { locationChangeAction } from 'domain/env/envActions';

function setLocationParams({ payload: locale }) {
  const rtl = locale === 'he';

  window.localStorage.setItem('locale', JSON.stringify({ rtl, locale }));
  window.location.reload();
}

export default function* ensureChangeLanguage() {
  yield takeEvery(locationChangeAction.type, setLocationParams);
}
