import React, { forwardRef, useCallback, memo } from 'react';

import { TextField, Autocomplete } from 'pages/company/grid/components/MultipleDropdownCellRenderer/StyledComponents';
import Chip from '@mui/material/Chip';

const MultipleDropdownCellRenderer = forwardRef((props, ref) => {
  const { value } = props;

  const renderTags = useCallback(
    (chips, getTagProps) =>
      chips.map((option, index) => (
        <Chip key={option.value} label={option.label} size="small" {...getTagProps({ index })} />
      )),
    [],
  );

  return (
    <Autocomplete
      open
      value={value}
      options={[]}
      renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps }} variant="standard" />}
      renderTags={renderTags}
      limitTags={1}
      readOnly
      fullWidth
      autoComplete
      disablePortal
      disableClearable
      disableCloseOnSelect
      multiple
    />
  );
});

export default memo(MultipleDropdownCellRenderer);
