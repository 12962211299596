// @flow
import { LOCATION_CHANGE } from 'domain/router/redux/reduxActions';
import * as Auth from 'domain/env/envActions';
import * as A from './companiesActions';
import {
  signerFactory,
  signingsFactory,
  CompaniesFactory,
  signParamsFactory,
  configurationCompanyFactory,
  type CompanyType,
  type CompaniesStore,
  SEARCH_TYPES,
} from './helpers';

type Actions = {
  type: string,
  payload?: mixed,
  params?: CompanyType,
};

export const reducer = {
  companies(state: CompaniesStore = CompaniesFactory(), action: Actions) {
    switch (action.type) {
      case A.fetchCompanies.success:
        return state.mergeIn(['list'], action.payload).set('refreshCompany', '').set('loaded', true);

      case A.setSortCompanies.type:
        return state.set('sort', action.payload);

      case A.resetConfigurationAction.type:
        return state.set('configurationCompany', configurationCompanyFactory());

      case A.fetchCompany.success:
        return state.set('configurationCompany', action.payload);

      case A.deleteCompany.success:
        return state.removeIn(['list', action.payload]).set('configurationCompany', configurationCompanyFactory());

      case A.updateLogoCompany.success:
        return state
          .setIn(['list', action.payload.id, 'logo'], action.payload.picture)
          .setIn(['configurationCompany', 'picture'], action.payload.picture);

      case A.updateCompany.success:
        return state.set('configurationCompany', action.payload);

      case A.updateCompanyInList.success:
        return state.setIn(['list', action.id, 'cname'], action.payload.name);

      case A.updateCompanyEmailBox.type:
        return state
          .setIn(['list', action.id, 'email'], action.payload.email)
          .setIn(['list', action.id, 'email.confidential'], action.payload.confidential);

      case A.markCompanyPinned.success:
        return state.updateIn(['list', action.params.id], (company) => company.set('pinned', action.payload.pinned));

      case A.setSearchTypeAction.type:
        return state.set('searchType', action.payload || SEARCH_TYPES.byCompany);

      case A.setSearchFilterAction.success:
        return state.set('filter', action.payload.filter || '').set('searchText', action.payload.text || '');

      case A.createCompanyAction.success:
        return state.mergeIn(['list'], action.payload);

      case LOCATION_CHANGE:
        return state.set('filter', '').set('searchText', '').set('searchType', SEARCH_TYPES.byCompany);

      case A.updateSignParams.success:
        return state.setIn(['list', action.params.id, 'signParams'], action.payload);

      case A.updateGeneralCompanyParams.success:
        return state.setIn(['list', action.params.id, 'generalParams'], action.payload);

      case A.updateSignParams.failure:
        return state.setIn(['list', action.params.id, 'signParams'], signParamsFactory());

      case A.getCompanySignings.success:
        return state
          .setIn(['configurationCompany', 'signings'], action.payload)
          .setIn(['configurationCompany', 'signingsCompanyId'], action.companyId);

      case A.getCompanySlackConnectionAction.success:
        return state.setIn(['configurationCompany', 'applications', 'slack'], action.payload);

      case A.goBackToListAction.type:
        return state.set('refreshCompany', action.payload);

      case A.removeAllCompanySigner.success:
        return state.setIn(['configurationCompany', 'signings'], signingsFactory());

      case A.setCompanySigningsGuid.success:
        return state.setIn(['configurationCompany', 'signings', 'guid'], action.payload);

      case A.addEmptyCompanySigner.type:
        return state.updateIn(['configurationCompany', 'signings', 'tokens'], (s) => s.push(signerFactory()));

      case A.updateCompanySignings.success:
        return state.updateIn(['configurationCompany', 'signings', 'tokens'], (s) =>
          s.push(action.payload).filter((i) => i.token_name),
        );

      case A.removeCompanySigner.success:
        return state.updateIn(['configurationCompany', 'signings', 'tokens'], (s) => {
          if (action.payload.tokenName) {
            return s.filter((i) => i.token_name !== action.payload.tokenName);
          }
          return s.filter((i, idx) => idx !== action.payload.idx);
        });

      case A.getCompanyFeatureSet.success:
        return state
          .setIn(['configurationCompany', 'featureSet'], action.payload)
          .setIn(['configurationCompany', 'featureSetCompanyId'], action.companyId)
          .setIn(['list', action.companyId, 'generalParams'], action.payload);

      case A.setCompanyFeatureSet.success:
        return state
          .setIn(['list', action.companyId, 'generalParams'], action.payload)
          .setIn(['configurationCompany', 'featureSet'], action.payload)
          .setIn(['configurationCompany', 'featureSetCompanyId'], action.companyId);

      case A.updateCompanyHashAction.type:
        return state.mergeIn(['list', action.companyId], action.payload);

      case Auth.signOutAction.success:
        return CompaniesFactory();

      default:
        return state;
    }
  },
};
