// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import type { GridRef } from 'pages/company/grid/types.js.flow';

import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Tooltip from 'components/mui/Tooltip';
import IconButtonMoveTo from 'pages/company/CompanyBarPanel/components/IconButtonMoveTo';
import IconButtonExport from 'pages/company/CompanyBarPanel/components/IconButtonExport';
import WorkspaceViewToggle from 'pages/company/CompanyBarPanel/components/WorkspaceViewToggle';
import GridPresetsMenu from 'pages/company/CompanyBarPanel/components/GridPresetsMenu';
import DialogFilterByDate from 'pages/company/CompanyBarPanel/components/DialogFilterByDate';

import AddIcon from '@mui/icons-material/Add';
import ScannerIcon from '@mui/icons-material/Scanner';
import EventIcon from '@mui/icons-material/Event';
import { documentWorkSpaceTypeSelector } from 'domain/companies';
import useToggle from 'hooks/useToggle';

type TCompanyBarPanel = {
  onUpload: () => void,
  onScan: (value: boolean) => void,
  gridRef: GridRef,
};
const CompanyBarPanel: React$StatelessFunctionalComponent<TCompanyBarPanel> = ({ onUpload, onScan, gridRef }) => {
  const workspaceType = useSelector(documentWorkSpaceTypeSelector);
  const [isOpen, toggleOpen] = useToggle();

  return (
    <Stack direction="row">
      <Stack spacing={1.5} direction="row">
        <Tooltip t={{ id: 'tooltip.company.bar.add', defaultMessage: 'Add document' }}>
          <Button onClick={onUpload} variant="contained" sx={{ minWidth: 'auto', px: 1, py: 0 }}>
            <AddIcon />
          </Button>
        </Tooltip>

        <Tooltip t={{ id: 'tooltip.company.bar.scan', defaultMessage: 'Scan Documents' }}>
          <IconButton onClick={() => onScan()} color="primary">
            <ScannerIcon />
          </IconButton>
        </Tooltip>

        <IconButtonMoveTo />

        <Divider orientation="vertical" sx={{ alignSelf: 'center', height: 24 }} />

        <Tooltip t={{ id: 'tooltip.company.bar.filterByDate', defaultMessage: 'Filter by Date' }}>
          <IconButton onClick={toggleOpen} color="primary">
            <EventIcon />
          </IconButton>
        </Tooltip>

        {workspaceType === 'grid' && (
          <>
            <Divider orientation="vertical" flexItem sx={{ alignSelf: 'center', height: 24 }} />
            <GridPresetsMenu />
          </>
        )}
      </Stack>

      <Stack spacing={1.5} direction="row" ml="auto">
        <IconButtonExport gridRef={gridRef} />
        <WorkspaceViewToggle />
      </Stack>

      {isOpen && <DialogFilterByDate onClose={toggleOpen} />}
    </Stack>
  );
};

export default CompanyBarPanel;
