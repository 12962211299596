// @flow
import React, { forwardRef, useContext, createContext, useCallback } from 'react';

import Typography from '@mui/material/Typography';
import { FixedSizeList } from 'react-window';

// EXAMPLE from MUI
// https://mui.com/material-ui/react-autocomplete/#asynchronous-requests

const LISTBOX_PADDING = 8; // px

const renderRow = (props) => {
  // props from FixedSizeList
  // data - list of options
  // index - index of option
  // style - styles for option
  const {
    data: { optionStyle, itemData },
    index,
    style,
  } = props;
  // find current option by index
  // renderOption props in MuiAutocomplete - return next data
  // const [elementProps, option, state] = data[index];
  // elementProps: The props to apply on the li element. - { aria-disabled:false, role: 'option', onClick: (x) => (x), className: "MuiAutocomplete-option", id: "mui-181-option-19", ...etc}
  // option: The option to render. - {label: '30094 (30094)', value: '30094'}
  // state: The state of the component. - {selected: false, index: 19, inputValue: ''}
  const [elementProps, option] = itemData[index];

  const inlineStyle = {
    unicodeBidi: 'plaintext',
    ...style,
    ...optionStyle,
    top: style.top + LISTBOX_PADDING,
  };

  return (
    <Typography component="li" {...elementProps} noWrap style={inlineStyle}>
      {option.label}
    </Typography>
  );
};

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxVirtualized = forwardRef((props, ref) => {
  // eslint-disable-next-line react/prop-types
  const { children, onItemsRendered, optionStyle = {}, ...other } = props;
  const itemData = [];

  // eslint-disable-next-line react/prop-types
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const itemCount = itemData.length;
  const itemSize = 36;
  const itemsHeight = itemCount * itemSize;

  const handleOnItemsRendered = useCallback(
    (params) => {
      if (typeof onItemsRendered === 'function') {
        onItemsRendered(params);
      }
    },
    [onItemsRendered],
  );

  const data = {
    optionStyle,
    itemData,
  };

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          itemData={data}
          height={itemsHeight + 2 * LISTBOX_PADDING}
          itemSize={itemSize}
          itemCount={itemCount}
          width="100%"
          outerElementType={OuterElementType}
          innerElementType="ul"
          overscanCount={5}
          onItemsRendered={handleOnItemsRendered}
        >
          {renderRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

export default ListboxVirtualized;
