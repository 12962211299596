/* @flow */
import { Record, List, Map, type RecordOf, type RecordFactory } from 'immutable';
import get from 'lodash/get';
import { getDefaultLocale } from 'labels/locale';
import { storage } from 'lib/storage';
import { type OrgBotType } from 'domain/contracts';

import { DEFAULT_PANEL_WIDTH } from 'pages/company/LinkedDocumentsPanel/components/StyledComponents';

import type { Permissions, NotesPermissions, EnvType, OrgBot, TUserFeatures, TRoleModificators } from './types.js.flow';

import botPic from './images/bot.png';

const PermissionsNotesFactory: RecordFactory<NotesPermissions> = new Record({
  isCreate: false,
  isUpdate: false,
  isDelete: false,
});

export const PermissionsFactory: RecordFactory<Permissions> = new Record({
  isDokkaSupport: false,
  superUser: false,
  notes: PermissionsNotesFactory(),
});

export const OrgBotFactory: RecordFactory<OrgBot> = new Record({
  userId: '',
  username: 'BOT',
  picture: botPic,
});

export const UserFeaturesFactory: RecordFactory<TUserFeatures> = new Record({
  modal: true,
});

export function permissionsAdapter({ notes, ...rest }: any): RecordOf<Permissions> {
  const notesRecord = PermissionsNotesFactory({
    isCreate: get(notes, 'create'),
    isUpdate: get(notes, 'update'),
    isDelete: get(notes, 'delete'),
  });
  return PermissionsFactory({
    ...rest,
    notes: notesRecord,
  });
}

export function featuresAdapter(features: { [key: string]: any }): RecordOf<TUserFeatures> {
  return UserFeaturesFactory(features);
}

export function orgBotAdapter({ userID, displayName, picture, ...rest }: OrgBotType): RecordOf<OrgBot> {
  const pictureObj = picture ? { picture } : {};
  return OrgBotFactory({
    ...rest,
    userId: userID,
    username: displayName,
    ...pictureObj,
  });
}

export const roleModificatorsFactory: RecordFactory<TRoleModificators> = new Record({
  limited: false,
  restricted: false,
  readonly: false,
});

export function roleModificatorsAdapter(data: ?TRoleModificators) {
  return roleModificatorsFactory(data);
}

export const EnvFactory: RecordFactory<EnvType> = new Record({
  dokkaToken: '',
  TFADokkaToken: null,
  TFALastPhoneNumbers: null,
  TFAEmail: null,
  role: null,
  build: process.env.REACT_APP_BUILD_VERSION,
  environment: process.env.REACT_APP_ENV,
  rtl: getDefaultLocale() === 'he',
  locale: getDefaultLocale(),
  loading: 0,
  isSagaDataLoading: false,
  linkedSidebarWidth: DEFAULT_PANEL_WIDTH,
  view: new Map(),
  resetPasswordError: false,
  chatToken: '',
  chatUser: '',
  organizationId: '', // empty indicating default value, backend will send real id or null
  organizationName: '',
  firstName: '',
  lastName: '',
  picture: '',
  phone: '',
  permissions: PermissionsFactory(),
  superUser: false,
  isDokkaSupport: false,
  backSearchUrl: null,
  persistedBackUrl: null,
  userId: null,
  navigation: new Map(),
  isExpired: false,
  userGUID: null,
  title: '',
  label: null,
  newChatFlow: false,
  googleSearchText: null,
  googlePanelStatus: false,
  appStatuses: new List(),
  helpdeskLink: null,
  workSpace: new Map(),
  organizationBot: OrgBotFactory(),
  features: UserFeaturesFactory(),
  allowFreshdesk: false,
  error: '',
  supplierAliases: '',
  roleModificators: roleModificatorsFactory(),
  connectionHealth: true,
});

// eslint-disable-next-line max-len
export function envAdapter({ permissions, restricted, limited, readonly, ...rest }: any): RecordOf<EnvType> {
  const permissionsRecord = permissionsAdapter(permissions || {});
  const roleModificatorsRecord = roleModificatorsAdapter({ restricted, limited, readonly });
  const workSpaceStr = storage().workSpaceType().get();
  const workSpace = workSpaceStr ? JSON.parse(workSpaceStr) : {};
  const persistedBackUrl = storage().backUrl().get();

  return EnvFactory({
    ...rest,
    persistedBackUrl,
    permissions: permissionsRecord,
    roleModificators: roleModificatorsRecord,
    workSpace: new Map(workSpace),
  });
}
