import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  tooltipIsEmpty: {
    display: 'none',
  },
  tooltipWrapper: {
    transition: 'opacity 250ms',
    fontSize: 12,

    '&.ag-tooltip-hiding': {
      opacity: 0,
      display: 'none',
    },

    '&.ag-react-container.ag-tooltip-custom': {
      backgroundColor: theme.palette.grey[800],
      borderRadius: 4,
      color: theme.palette.common.white,
      padding: theme.spacing(0.5, 1),
      position: 'absolute',
      display: 'flex',
      pointerEvents: 'none',
      background: 'white',
      minWidth: 50,
      maxWidth: 300,
      height: 'auto',
      wordBreak: 'break-all',
      width: 'max-content',
    },
  },
}));
