import { selector } from 'lib/selectors';
import { Map, Record } from 'immutable';
import { sortOrganizations, filterOrganizations } from './helpers';
import { organizationNotificationsAndUnreadRequestsSelector } from 'domain/companies/companiesSelector';
import { organization, currentOrganizationSelector } from './organizationPureSelector';

export * from './organizationPureSelector';

export const organizationIsPaidSelector = selector(organization, (o) => o.is_paid);
export const organizationSigningsSelector = selector(organization, (o) => o.signings);
export const organizationFeaturesSelector = selector(organization, (o) => {
  const features = {};
  Object.entries(o.features.toJS()).forEach(([key, data]) => {
    features[key] = data.value;
  });
  const result = new Record(features);
  return result();
});

const securityFeatures = ['two_fa'];

export const organizationFeaturesWithoutHiddenSelector = selector(organization, (o) =>
  Object.entries(o.features.toJS()).filter(([, { state }]) => state !== 'hidden'),
);

export const organizationAdvancedFeaturesSelector = selector(organizationFeaturesWithoutHiddenSelector, (f) =>
  f.filter(([key, _]) => !securityFeatures.includes(key)),
);

export const organizationSecurityFeaturesSelector = selector(organizationFeaturesWithoutHiddenSelector, (f) =>
  f.filter(([key, _]) => securityFeatures.includes(key)),
);
export const organizationBackupConnectionSelector = selector(organization, (o) => o.backupConnection);
export const organizationWhatsappPhoneSelector = selector(organization, (o) => o.whatsapp_phone);
export const organizationPasswordExpirationDaysSelector = selector(organization, (o) => o.expiration_days);

// list
export const organizationListSelector = selector(organization, (o) => o.list);
export const organizationLoadedSelector = selector(organization, (o) => o.loaded);
export const organizationSortSelector = selector(organization, (o) => o.sort);
export const organizationNotificationListSelector = selector(organization, () => Map());
export const organizationFilterSelector = selector(organization, (o) => o.filter);

// unfiltered list
export const organizationUnfilteredListSelector = selector(
  organizationListSelector,
  organizationSortSelector,
  organizationNotificationsAndUnreadRequestsSelector,
  sortOrganizations,
);

export const organizationFilteredListSelector = selector(
  organizationUnfilteredListSelector,
  organizationFilterSelector,
  filterOrganizations,
);

export const currentOrganizationDataSelector = selector(
  currentOrganizationSelector,
  organizationListSelector,
  (id, list) => list.find((org) => org.id === id),
);

// checks if current org was set
export const isCurrentOrganizationSetSelector = selector(currentOrganizationSelector, (org) => org !== null);

export const userHasMultipleOrgsSelector = selector(organizationListSelector, (list) => list.size > 1);
