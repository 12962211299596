/* @flow */
// import deepEquel from 'deep-equal';
import { Rect, showElements } from 'lib/canvas';
import { isPolygonInArea } from 'domain/journal/helper';
import { getCurrentDimension, createEmptyDimension, type HTMLPolygonType, type StyledPolygonType } from './helpers';

class SelectionArea {
  constructor(
    el: HTMLCanvasElement,
    getStyledPolygons: () => $ReadOnlyArray<StyledPolygonType>,
    onSelectionStart: () => void,
    onSelectionEnd: (s: $ReadOnlyArray<number>, dmx: HTMLPolygonType) => void,
    onSelectionRemove: () => void,
    theme: any,
  ) {
    this.ctx = el.getContext('2d');
    this.getStyledPolygons = getStyledPolygons;
    this.onSelectionStart = onSelectionStart;
    this._onSelectionEnd = onSelectionEnd;
    this.onSelectionRemove = onSelectionRemove;
    this.theme = theme;
    this.init();
  }

  ctx: CanvasRenderingContext2D;

  onSelectionStart: () => void;

  getStyledPolygons: () => $ReadOnlyArray<StyledPolygonType>;

  onSelectionEnd: () => void;

  onSelectionRemove: () => void;

  _onSelectionEnd: (s: $ReadOnlyArray<number>, dmx: HTMLPolygonType) => void;

  _slected: number[] = [];

  area: ?Rect;

  polygons: Array<{
    rect: Rect,
    data: Object,
  }> = [];

  onSelectionEnd() {
    if (this._slected.length) {
      const currentDimension = getCurrentDimension(
        this._slected,
        this.polygons.map((e) => e.data),
      );
      showElements(this._slected.map((e) => this.polygons[e].rect));
      this._onSelectionEnd(this._slected, currentDimension);
    } else {
      this._onSelectionEnd(this._slected, createEmptyDimension());
    }
  }

  init = () => {
    this.clean();
    this.addPolygons(this.getStyledPolygons());
  };

  start = (x: number, y: number) => {
    this.area = new Rect(this.ctx, this.theme.palette.primary.main, x, y);
    this.onSelectionStart();
  };

  selectionAreaUpdate = (layerX: number, layerY: number) => {
    if (this.area) {
      this.update(layerX, layerY);
      this.clear();
      this._slected.length = 0;
      this.refresh();
    }
  };

  refresh = () => {
    const a = this.area;
    if (a && this.polygons.length) {
      a.draw();
      this.polygons.forEach((e, idx) => {
        if (isPolygonInArea(a, e.data)) {
          e.rect.draw();
          this._slected.push(idx);
        }
      });
    }
  };

  update = (x: number, y: number) => {
    if (this.area) {
      this.area.update(x, y);
    }
  };

  stop = () => {
    if (this.area) {
      this.areaClean();
      this.onSelectionEnd();
    }
  };

  clear = () => {
    this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
  };

  clean = () => {
    this.polygons.length = 0;
    this.areaClean();
  };

  areaClean = () => {
    this.area = null;
    this.clear();
    this.onSelectionRemove();
  };

  addPolygons = (pl: $ReadOnlyArray<HTMLPolygonType>) => {
    pl.forEach((p) => {
      const item = {
        rect: new Rect(this.ctx, this.theme.palette.primary.main, p.left, p.top, p.width, p.height),
        data: p,
      };
      this.polygons.push(item);
    });
  };
}

export default SelectionArea;
