// @flow
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  TFASubmitAction,
  removeTFADataAction,
  TFAResetAction,
  TFAEmailSelector,
  TFALastPhoneNumbersSelector,
} from 'domain/env';

import Button from '@mui/material/Button';
import TextFieldBase from 'components/mui/Form/TextField/TextFieldBase';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const CODE_LENGTH = 6;

const TFAForm = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [code, setCode] = useState('');
  const TFALastPhoneNumbers = useSelector(TFALastPhoneNumbersSelector);
  const TFAEmail = useSelector(TFAEmailSelector);

  const onChange = useCallback((e: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    if (CODE_LENGTH >= value.length && /^(\s*|\d+)$/.test(value)) {
      setCode(value);
    }
  }, []);

  const onClickSubmit = () => dispatch(TFASubmitAction(code));

  const onClickBack = () => dispatch(removeTFADataAction());

  const onClickReset = () => dispatch(TFAResetAction());

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === 13) {
      onClickSubmit();
    }
  };

  const encodedEmail = useMemo(() => {
    if (typeof TFAEmail !== 'string' || !TFAEmail.includes('@')) return '';

    const [name, host] = TFAEmail.split('@');

    return `${name
      .split('')
      .map((symb: string, index: number) => (index === 0 || index + 1 === name.length ? symb : '*'))
      .join('')}@${host}`;
  }, [TFAEmail]);

  return (
    <>
      <Box mb={2}>
        <Button variant="text" size="large" onClick={onClickBack} startIcon={<KeyboardBackspaceIcon />}>
          <FormattedMessage id="auth.tfa.backBtn" defaultMessage="Back" />
        </Button>
      </Box>
      <Typography variant="h5">
        <FormattedMessage id="auth.tfa.verificationCode" defaultMessage="Verification code" />
      </Typography>
      <Typography variant="subtitle2" color="text.secondary" fontWeight="400" mb={3}>
        <FormattedMessage
          values={{
            email: encodedEmail,
          }}
          id="auth.tfa.info.email"
          defaultMessage="Enter the code we've sent to email {email}"
        />
        {TFALastPhoneNumbers && (
          <>
            &#xA0;
            <FormattedMessage
              values={{
                phone: TFALastPhoneNumbers,
              }}
              id="auth.tfa.info.phone"
              defaultMessage="and phone number ending with {phone}"
            />
          </>
        )}
      </Typography>

      <TextFieldBase
        autoFocus
        value={code}
        onChange={onChange}
        onKeyDown={onKeyDown}
        label={intl.formatMessage({
          id: 'auth.tfa.inputPlaceholder',
          defaultMessage: '6-digit code',
        })}
        fullWidth
        sx={{ mb: 2 }}
      />

      <Button
        type="submit"
        variant="contained"
        disabled={code.length !== CODE_LENGTH}
        onClick={onClickSubmit}
        size="large"
        fullWidth
        sx={{ mb: 2 }}
      >
        <FormattedMessage id="button.submit" defaultMessage="Submit" />
      </Button>

      <Typography textAlign="center" variant="body2">
        <FormattedMessage id="auth.tfa.resetText" defaultMessage="Didn’t receive the code?" />
        &nbsp;
        {/* TODO: review this rule */}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link component="button" variant="body2" underline="hover" onClick={onClickReset}>
          <FormattedMessage id="auth.tfa.resetBtn" defaultMessage="Resend code" />
        </Link>
      </Typography>
    </>
  );
};

export default TFAForm;
