// @flow
import { memo, useCallback, useEffect, useMemo } from 'react';
import { Set } from 'immutable';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { documentHotkeysSelector } from 'domain/documents/documentSelector';
import { journalSelector, selectedRowSelector } from 'domain/journal';
import { hotkeyMatcher } from 'lib/hotkeysHelpers';

import type { THotkeyList, THotkeyMatch, THotkeyCombination } from 'domain/documents/types.js.flow';
import type { JornalEntryType, RecordCellSetType } from 'domain/journal/helper';
import { HotkeyCellListFactory, HotkeyMatchFactory } from 'domain/documents';
import { RecordCellSet } from 'domain/journal/helper';

type TTableHotkeyCatcher = {|
  hotkeys: THotkeyList,
  selectedRows: Set<number>,
  cellsEntry: JornalEntryType,
  handleHotkeyMatch: (
    cell: string,
    cellSet: RecordCellSetType,
    ignoreSelectedRows: boolean,
    hotkey: THotkeyMatch,
  ) => void,
|};

const LineHotkeyCatcher: React$StatelessFunctionalComponent<TTableHotkeyCatcher> = ({
  hotkeys,
  selectedRows,
  cellsEntry,
  handleHotkeyMatch,
}: TTableHotkeyCatcher) => {
  const lineHotkeys = useMemo(() => hotkeys.filter((keys) => keys.level === 'line'), [hotkeys]);

  const onHotkeyMatch = useCallback(
    (keys: THotkeyCombination) => {
      const selectedCells = selectedRows.map((row: number) => `A${row}`);
      const cellList = selectedCells.map((_cell: string) => {
        const cellEntry = cellsEntry.get(_cell);

        return HotkeyCellListFactory({
          set: RecordCellSet(cellEntry.cellSet),
          name: cellEntry.name,
          cell: _cell,
        });
      });

      // for keyboard shortcuts it doesn't matter which cell passed to update field,
      // back-end skip update Field if hotkey Field is exist
      const plugCell = cellList.first();

      handleHotkeyMatch(
        plugCell.get('cell'),
        plugCell.get('set'),
        false,
        HotkeyMatchFactory({ cell_list: cellList, key: keys }),
      );
    },
    [selectedRows, cellsEntry, handleHotkeyMatch],
  );

  const handleKeyDown = useCallback(
    (e: SyntheticKeyboardEvent<HTMLElement>) => {
      if (selectedRows.size > 0) {
        const isMatch = hotkeyMatcher(e, lineHotkeys, onHotkeyMatch);

        if (isMatch) {
          e.preventDefault();
        }
      }
    },
    [lineHotkeys, selectedRows, onHotkeyMatch],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [lineHotkeys, selectedRows, handleKeyDown]);

  return null;
};

const mapStateToProps = (state) => ({
  hotkeys: documentHotkeysSelector(state),
  selectedRows: selectedRowSelector(state),
  cellsEntry: journalSelector(state),
});

export default compose(connect(mapStateToProps))(memo(LineHotkeyCatcher));
