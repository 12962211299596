// @flow
import React, { useImperativeHandle, useState, forwardRef, useCallback, useRef } from 'react';
import moment from 'moment';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    '& .MuiInputBase-root': {
      minHeight: 32,
      borderRadius: 5,
      borderColor: 'var(--ag-border-color)',

      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderWidth: 1,
      },
    },
    '& .MuiOutlinedInput-input': {
      fontSize: 13,
      padding: 4,
    },
    '& .MuiInputAdornment-root .MuiButtonBase-root': {
      padding: 4,
      '& .MuiSvgIcon-root': {
        fontSize: 18,
      },
    },
  },
}));

interface IProps {
  filterParams: {| format: string |};
  onDateChanged: () => void;
}

const AgGridDateFilter = forwardRef((props: IProps, ref) => {
  const [date, setDate] = useState(null);
  const [open, setOpen] = useState(false);
  const dateRef = useRef();
  const classes = useStyles();

  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onDateChanged = useCallback(
    (selectedDate) => {
      const newDate = selectedDate?.toDate() || selectedDate;
      setDate(selectedDate);
      dateRef.current = newDate;
      props.onDateChanged();
    },
    [props.onDateChanged],
  );

  const handleOutsideClick = useCallback(
    (e) => {
      if (!e.target.closest('.ag-custom-component-popup')) {
        onClose();
      }
    },
    [onClose],
  );

  // @see https://www.ag-grid.com/react-data-grid/component-date/
  useImperativeHandle(ref, () => ({
    getDate() {
      return dateRef.current;
    },

    setDate(date) {
      dateRef.current = date;
      setDate(moment(date));
    },
  }));

  React.useEffect(() => {
    if (open) {
      document.body.addEventListener('mousedown', handleOutsideClick, { capture: true });
    } else {
      document.body.removeEventListener('mousedown', handleOutsideClick, { capture: true });
    }
    return () => {
      document.body.removeEventListener('mousedown', handleOutsideClick, { capture: true });
    };
  }, [handleOutsideClick, open]);

  return (
    <DesktopDatePicker
      className={classes.container}
      open={open}
      placeholder={props.filterParams.format}
      format={props.filterParams.format}
      value={date}
      onChange={onDateChanged}
      onOpen={onOpen}
      onClose={onClose}
      slotProps={{
        popper: {
          className: 'ag-custom-component-popup',
        },
        textField: { fullWidth: true },
      }}
    />
  );
});

export default AgGridDateFilter;
