// @flow
import React from 'react';
import { toast, type ToastOptions } from 'react-toastify';
import { useIntl } from 'react-intl';

import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import elements from '../elements';

export type TToastType = 'success' | 'warning' | 'error' | 'info';

type Props = {
  children: string | React$Element<*>,
  type: TToastType,
  closeToast: () => void, // TODO: its not work, we need use component
};

export const Toast = (props: Props) => {
  const { children, type, closeToast } = props;
  const { formatMessage } = useIntl();

  return (
    <Alert
      variant="filled"
      severity={type}
      data-element={`toast.${type}`}
      iconMapping={{
        success: <CheckCircleOutlineOutlinedIcon fontSize="inherit" />,
      }}
      action={
        <Button variant="text" size="small" sx={{ color: 'common.white' }} onClick={closeToast}>
          {formatMessage({ id: 'forms.common.close', defaultMessage: 'Close' })}
        </Button>
      }
    >
      {children}
    </Alert>
  );
};

const renderToast =
  (type: TToastType) =>
  (child: string | React$Element<*>, options: ToastOptions = {}) =>
    toast.success(<Toast type={type}>{child}</Toast>, options);

export default {
  success: renderToast('success'),
  info: renderToast('info'),
  warn: renderToast('warning'),
  error: renderToast('error'),
};
