// @flow
import React from 'react';
import { useParams, useNavigate, generatePath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useConfirm } from 'material-ui-confirm';
import { companyFeatureSetSelector } from 'domain/companies';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import * as ACL from 'domain/restriction';
import { checkUsers, checkErpConnected } from 'pages/configurations/company/helpers';

import BackToArrowBase from 'components/mui/Layouts/components/BackToArrowBase';
import { erpsSelector } from 'domain/settings';
import { organizationEnhancedUsersSelector } from 'domain/organizationUser';

const mapStateToProps = (state) => ({
  features: companyFeatureSetSelector(state),
  isGranted: ACL.isGranted(state),
  erps: erpsSelector(state),
  users: organizationEnhancedUsersSelector(state),
});

const BackToArrow = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { features, isGranted, users, erps } = useSelector(mapStateToProps);
  const confirm = useConfirm();
  const { fin } = features;

  const hasConfirmBeforeLeave =
    !isGranted(ACL.IS_BK_WITHOUT_ADMIN_PERMISSIONS) &&
    ((fin && checkErpConnected(erps)) || checkUsers(users, companyId));

  const path = generatePath(ROUTES_PATH.COMPANY_DEFAULT_CATEGORY.absolute, { companyId });

  const pathForConfirm = checkErpConnected(erps) ? generatePath(ROUTES_PATH.COMPANIES.absolute) : path;

  const handleClick = () => {
    confirm({
      description: formatMessage({
        id: 'configurations.leave_company.placeholder',
        defaultMessage:
          "You won't be able to reach workspace. Next time you enter company, you will be redirected to settings",
      }),
      confirmationText: formatMessage({
        id: 'configurations.leave_company.yes',
        defaultMessage: 'Yes',
      }),
      confirmationButtonProps: {
        color: 'error',
      },
      cancellationText: formatMessage({
        id: 'configurations.leave_company.cancel',
        defaultMessage: 'No',
      }),
    }).then(() => {
      navigate(pathForConfirm);
    });
  };

  return <BackToArrowBase path={path} onClick={hasConfirmBeforeLeave && handleClick} />;
};

export default BackToArrow;
