// @flow
import React, { forwardRef, useImperativeHandle } from 'react';
import { isEllipsisActive, ID_FOR_DETECT_CELL_OVERFLOW, SET_FILTER_HOVER_CLASSNAME } from 'pages/company/grid/helpers';

import useStyles from './sheet';

type Props = {|
  value: string,
  valueFormatted: string,
  colDef: {| needContainerForDetectOverflow?: boolean |},
|};

export default forwardRef(({ valueFormatted, value, colDef }: Props, ref) => {
  const classes = useStyles();
  const rowCell = document.querySelector(
    `.ag-row-hover .ag-column-hover ${colDef.needContainerForDetectOverflow ? `#${ID_FOR_DETECT_CELL_OVERFLOW}` : ''}`,
  );

  const filterCell = document.querySelector(`.${SET_FILTER_HOVER_CLASSNAME}`);
  const cell = rowCell || filterCell;

  const isVisible = Boolean(cell) && isEllipsisActive(cell);

  useImperativeHandle(ref, () => ({
    getReactContainerClasses() {
      return isVisible ? [classes.tooltipWrapper] : [classes.tooltipIsEmpty];
    },
  }));

  return isVisible ? <span>{valueFormatted || value}</span> : null;
});
