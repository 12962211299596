// @flow
import React, { useState, useCallback } from 'react';
import { Set } from 'immutable';
import { useDispatch } from 'react-redux';
import { documentLinkInListAction, type DocumentsType } from 'domain/documents';
import { generateLinkID } from 'pages/company/helpers';
import { promisify } from 'lib/helpers';

import { DnDItemTarget, DOCUMENT } from 'components/DnDItem';

import cx from 'classnames';
import { styled } from '@mui/material/styles';

const DropTarget = styled(DnDItemTarget([DOCUMENT]), { label: 'DropTarget' })(() => ({
  '&.is-drag-over': {
    filter: 'brightness(0.8)',
  },
}));

type TDropTileCard = {
  children: React$Node,
  item: DocumentsType,
  selectedDocuments: Set<string>,
  clearSelection: () => void,
  idDraggedDocument: ?string,
};

const DropTileCard: React$StatelessFunctionalComponent<TDropTileCard> = ({
  children,
  item,
  selectedDocuments,
  clearSelection,
  idDraggedDocument,
}) => {
  const dispatch = useDispatch();
  const isLocked = item.linkid.includes('_locked');

  const [isDragOver, setIsDragOver] = useState(false);

  const onDragLeave = useCallback(() => {
    if (isDragOver) {
      setIsDragOver(false);
    }
  }, [isDragOver]);

  const onDragOver = useCallback(
    (event) => {
      event.preventDefault();
      if (!isDragOver && item.documentID !== idDraggedDocument && idDraggedDocument && !isLocked) {
        setIsDragOver(true);
      }
    },
    [isDragOver, idDraggedDocument, item, isLocked],
  );

  const onDndDrop = useCallback(
    async ({ id }: { id: string }) => {
      setIsDragOver(false);
      // we cannot link the document with ourselves
      if (id !== item.documentID && !isLocked) {
        const ids = selectedDocuments.size ? selectedDocuments.toJS() : [id];
        const fn = (arg) => dispatch(documentLinkInListAction({ ...arg }));
        await promisify(fn, {
          documentID: item.linkid ? ids : [item.documentID, ...ids],
          linkID: item.linkid || generateLinkID(),
          text: item.ltext,
        });
        if (selectedDocuments.size) clearSelection();
      }
    },
    [selectedDocuments, item, clearSelection, dispatch, isLocked],
  );

  if (selectedDocuments.size && selectedDocuments.includes(item.documentID)) {
    return <>{children}</>;
  }

  return (
    <DropTarget
      onDndDrop={onDndDrop}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      className={cx({
        'is-drag-over': isDragOver,
      })}
      stopPropagateDrop
    >
      {children}
    </DropTarget>
  );
};

export default React.memo(DropTileCard);
