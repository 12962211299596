/* @flow */
import { put, call, select, cancelled } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { navigate } from 'domain/router/redux/reduxActions';
import { storage } from 'lib/storage';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { generatePath } from 'react-router-dom';
import { clearOrganization } from 'domain/organization';
import { ensureGetOrganizationList } from 'domain/organization/sagas';
import { backSearchUrlSelector, persistedBackUrlSelector } from 'domain/env';
import { backUrlSelector } from 'domain/router';
import Api, { doLogout } from 'domain/api';
import { setUser } from 'lib/errorLogger';
import * as action from './envActions';
import * as selector from './envSelector';
import { isMobile } from 'lib/systemHelpers/browserHelpers';
import { appInsights } from 'index';
import { documentsGridViewGetAction, documentSearchAction } from 'domain/documents';
import { featuresAdapter } from 'domain/env/adapters';

import { setRoles } from './helpers';
import { yieldSuccessNotification, yieldErrorNotification, yieldValidationNotification } from 'lib/toasts';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import tokenKeeper from 'lib/apiTokenKeeper';
import { STUB_TOKEN } from 'domain/constants';
import { Auth } from 'aws-amplify';
import { nullifyTokenRefreshTime } from 'amplify/helpers';
import { asyncErrorAction } from 'components/Form/validation';

export { action, selector };

export function* TFATokenExpired(): Saga<*> {
  yield put({
    type: action.setTFADataAction.type,
    payload: {
      TFADokkaToken: null,
      TFALastPhoneNumbers: null,
      TFAEmail: null,
    },
  });

  yieldErrorNotification(
    <FormattedMessage id="auth.tfa.check.toast.expired" defaultMessage="Verification code is expired." />,
  );
}

export function* ensureSignGetUserProfile(): Saga<*> {
  try {
    const { data: profileData } = yield call(Api.getUserProfile);
    setUser(profileData.userGUID, profileData.userID);

    // set externalUserId which is equal to sendbird userId which is equal to user's email

    window.OneSignal = window.OneSignal || [];
    const { OneSignal } = window;
    if (OneSignal) {
      OneSignal.push(() => {
        OneSignal.setExternalUserId(profileData.userID);
      });
    }

    // eslint-disable-next-line max-len
    const { displayName, chatToken, userID, isDokkaSupport } = profileData;

    setRoles(profileData.role);
    storage().chatUser().set(displayName);
    storage()
      .chatToken()
      .set(chatToken || '');
    storage().userId().set(userID);
    storage().userGUID().set(profileData.userGUID);

    storage().isDokkaSupport().set(isDokkaSupport);

    // identifying appInsights user using initialized appInsights instance
    appInsights.setAuthenticatedUserContext(userID);
    console.log('AppInsights user email set ', userID);

    // retrieve org list and redirect to org list page for user
    // having more then single organizarion.
    // token is not saved to store yet
    const orgCount = yield ensureGetOrganizationList();

    // this is initialized on app start from LS for SSO
    const backUrl = yield select(backSearchUrlSelector);
    const routingBackUrl = yield select(backUrlSelector);
    const persistedBackUrl = yield select(persistedBackUrlSelector);
    const isGhostUser = orgCount > 1;
    const defaultGhostURL = generatePath(ROUTES_PATH.ORGANIZATIONS.absolute);

    const redirectUrl = persistedBackUrl || backUrl || routingBackUrl;

    if (routingBackUrl === '/' && isGhostUser) {
      yield put(navigate.replace(defaultGhostURL, {}));
    } else if (redirectUrl) {
      yield put(navigate.replace(redirectUrl, {}));
      storage().backUrl().set('');
    } else {
      yield isGhostUser
        ? put(navigate.replace(defaultGhostURL, {}))
        : put(navigate.replace(generatePath(ROUTES_PATH.COMPANIES.absolute), {}));
    }
    yield put({
      type: action.signInAction.success,
      payload: { dokkaToken: storage().token().get() || STUB_TOKEN, ...profileData },
    });
  } catch (err) {
    // @to-do check this
    // as we pass signInAction to function, it will triger signInAction.failure with proxied error
    // while we dont know what type of error it is and signInAction.failure assums exact
    // type of error and will retrieve err.response.data

    // @to-do check for not axios error type instead check by response field
    if (typeof err.response === 'undefined') {
      err.response = { data: 'Internal error. Try reloading the page' };
    }
    yield doLogout(action.signInAction, err);
  }
}

// eslint-disable-next-line require-yield
export function* storeDokkaToken(dokkaToken): Saga<*> {
  storage().token().set(dokkaToken);
}

export function* cognitoAuthHandler(): Saga<*> {
  yield call(storeDokkaToken, STUB_TOKEN);
  yield call(ensureSignGetUserProfile);
}

export function* ensureSignInWithUserProfile(args): Saga<*> {
  yield put({ type: action.signInAction.request });
  const locale = yield select(selector.localeSelector);

  try {
    const { data: signInData } = yield call(Api.signIn, { ...args, data: args.data.set('language', locale) });
    if (signInData.reset_password_token) {
      yield put(
        navigate.replace(
          generatePath(ROUTES_PATH.AUTH_RESET_PASSWORD.absolute, {
            recoveryToken: signInData.reset_password_token,
            passwordExpired: true,
          }),
        ),
      );
    } else if (signInData.is_authorized) {
      yield call(storeDokkaToken, signInData.dokkaToken);
      yield call(ensureSignGetUserProfile);
    } else {
      // 2FA {
      yield put({
        type: action.setTFADataAction.type,
        payload: {
          TFADokkaToken: signInData.dokkaToken,
          TFALastPhoneNumbers: signInData.last_phone_numbers || '',
          TFAEmail: args.data.get('email'),
        },
      });
    }
  } catch (err) {
    // @to-do check this
    // as we pass signInAction to function, it will triger signInAction.failure with proxied error
    // while we dont know what type of error it is and signInAction.failure assums exact
    // type of error and will retrieve err.response.data

    // @to-do check for not axios error type instead check by response field
    if (typeof err.response === 'undefined') {
      err.response = { data: 'Internal error. Try reloading the page' };
    }
    yield doLogout(action.signInAction, err);
  }
}

export function* ensureTFACheckWithUserProfile({ payload }): Saga<*> {
  const verifiableDokkaToken = yield select(selector.TFATokenSelector);
  try {
    const { data } = yield call(Api.checkTwoFACode, { data: { verifiableDokkaToken, twoFACode: payload } });
    yield call(storeDokkaToken, data.dokkaToken);
    yield call(ensureSignGetUserProfile);
  } catch (err) {
    const isExpired = err.response.status === 401;
    if (isExpired) {
      yield call(TFATokenExpired);
    } else {
      const isIncorrect = err.response.status === 412;

      const [id, defaultMessage] = isIncorrect
        ? ['auth.tfa.check.toast.incorrect', 'Verification code is incorrect']
        : ['auth.tfa.check.toast.expired', 'Verification code is expired.'];

      yieldErrorNotification(<FormattedMessage {...{ id, defaultMessage }} />);
    }
  }
}

export function* ensureTFAResetCode(): Saga<*> {
  const verifiableDokkaToken = yield select(selector.TFATokenSelector);
  const TFAEmail = yield select(selector.TFAEmailSelector);
  const TFALastPhoneNumbers = yield select(selector.TFALastPhoneNumbersSelector);

  try {
    const { data } = yield call(Api.resendTwoFACode, { data: { verifiableDokkaToken } });

    // refresh token which contains expire time 2tfa key
    yield put({
      type: action.setTFADataAction.type,
      payload: {
        TFADokkaToken: data.verifiableDokkaToken,
        TFALastPhoneNumbers,
        TFAEmail,
      },
    });

    yieldSuccessNotification(
      <FormattedMessage id="auth.tfa.resend.toast.success" defaultMessage="We’ve sent you another code." />,
    );
  } catch (err) {
    yield call(TFATokenExpired);
  }
}

export function* ensurePasswordLessAuthAccessLink({ payload }): Saga<*> {
  const { values } = payload;
  const locale = yield select(selector.localeSelector);
  try {
    yield call(Api.passwordLessAuthAccessLink, {
      data: values,
      headers: {
        'Accept-Language': locale || 'en',
      },
    });
    yield put(navigate.push(generatePath(ROUTES_PATH.AUTH_PLA_ACCESS_SUCCESS.absolute)));
  } catch (err) {
    const { data } = err.response;

    yield put(asyncErrorAction('passwordLessAuthForm', data));
  }
}

export function* cleanStorageDataOnSignout(): Saga<*> {
  window.OneSignal = window.OneSignal || [];
  const { OneSignal } = window;

  storage().token().remove();
  storage().chatToken().remove();
  storage().chatUser().remove();
  storage().role().remove();
  storage().userId().remove();
  storage().isDokkaSupport().remove();

  // this removes last token refresh time
  nullifyTokenRefreshTime();

  OneSignal.push(() => {
    OneSignal.removeExternalUserId();
  });
  // this might be required for SSO user also
  // however initial Auth0 implementation didnt invoke this
  if (tokenKeeper.isDokkaLogin()) {
    yield put({
      type: clearOrganization.type,
    });
  }
}

export function* ensureSignOut(data = {}): Saga<*> {
  const { payload } = data;

  if (
    !(payload && payload.reason === process.env.REACT_APP_ACCESS_DENIED_CODE) &&
    !(window.location.pathname === generatePath(ROUTES_PATH.MAINTENANCE.absolute))
  ) {
    storage()
      .backUrl()
      .set(window.location.pathname + window.location.search);
  }

  yield call(cleanStorageDataOnSignout);

  if (tokenKeeper.isDokkaLogin()) {
    yield put({
      type: clearOrganization.type,
    });
    // this action must be the last call in this func as it is listened to
    // in watchSignOut() saga and cancels this task once caught, so every side-effect
    // like LS modifications/other actions must be invoked before

    yield put({
      type: action.signOutAction.success,
      ...(payload && { payload }), // this is set when we are logged out by 403 interceptor
    });
  } else {
    Auth.signOut();
  }
}

export function* ensureResetPassword({ username, resolve, reject }): Saga<*> {
  try {
    yield call(Api.resetPassword, { params: { username } });
    yield put({
      type: action.checkPasswordAction.success,
    });
    if (typeof resolve === 'function') resolve();
  } catch (err) {
    if (typeof reject === 'function') reject(err);
  }
}

export function* ensureCheckPassword({ token }): Saga<*> {
  yield put({ type: action.checkPasswordAction.request });
  try {
    yield call(Api.checkPassword, { data: { resetPasswordToken: token } });
    yield put({ type: action.checkPasswordAction.success });
  } catch (err) {
    yield put({ type: action.checkPasswordAction.failure });
    yield put(navigate.replace(generatePath(ROUTES_PATH.AUTH_FORGOT_PASSWORD.absolute)));
  }
}

export function* ensureCheckSetPassword({ token }): Saga<*> {
  yield put({ type: action.checkPasswordAction.request });
  const role = yield select(selector.roleSelector);
  try {
    yield call(Api.checkActivate, { data: { activatUserToken: token } });
    yield put({ type: action.checkPasswordAction.success });
  } catch (err) {
    if (role) {
      // log out user and redirect to log in
      yield put({ type: action.signOutAction.type });
    } else {
      // redirect to loogin as user is logged out
      yield put(navigate.replace(generatePath(ROUTES_PATH.AUTH_LOGIN.absolute)));
    }
  }
}

export function* ensureUpdatePassword({ token, password }): Saga<*> {
  const locale = yield select(selector.localeSelector);
  const persistedBackUrl = yield select(persistedBackUrlSelector);
  try {
    const { data: signInData } = yield call(Api.updatePassword, {
      data: {
        password,
        resetPasswordToken: token,
        language: locale,
      },
    });

    yield put({
      type: action.checkPasswordAction.success,
    });

    if (signInData.is_authorized) {
      if (isMobile && !persistedBackUrl) {
        yield put(
          navigate.replace(
            generatePath(ROUTES_PATH.NOT_SUPPORTED_MOBILE_BROWSER.absolute, { action: 'password_restored' }),
          ),
        );
      } else {
        yield call(storeDokkaToken, signInData.dokkaToken);
        yield call(ensureSignGetUserProfile);
      }
    } else {
      // 2FA {
      yield put({
        type: action.setTFADataAction.type,
        payload: {
          TFADokkaToken: signInData.dokkaToken,
          TFALastPhoneNumbers: signInData.last_phone_numbers || '',
          TFAEmail: signInData.email,
        },
      });

      yield put(navigate.replace(generatePath(ROUTES_PATH.AUTH_LOGIN.absolute)));
    }
  } catch (err) {
    if (err.response.status !== 400) {
      yield put({ type: action.checkPasswordAction.failure });
      yield put(navigate.replace(generatePath(ROUTES_PATH.AUTH_FORGOT_PASSWORD.absolute)));
    }
    yield put({ type: action.setErrorMessage.type, payload: err.response.data });
  }
}

export function* ensureHealthCheck(): Saga<*> {
  try {
    yield call(Api.healthCheck, {});
    yield ensureSignOut();
    // redirect to login after signOut as user is not authorized already and SSO signOut
    // invocation does nothing for unauthorized user, while we need to get back to login
    // once healthCheck is successful which means maintenance has finished.
    // signOut should be removed when we ensure user is redirected to maintenance after 401 only
    yield put(navigate.replace(generatePath(ROUTES_PATH.AUTH_LOGIN.absolute)));
  } catch (err) {
    yield put({ type: action.healthCheckAction.failure });
  }
}

export function* ensureUpdateWorkSpace({ payload }): Saga<*> {
  try {
    const workSpaceStr = yield storage().workSpaceType().get();
    const workSpace = workSpaceStr ? JSON.parse(workSpaceStr) : {};
    workSpace[payload.companyId] = payload.type;
    yield storage().workSpaceType().set(JSON.stringify(workSpace));
    if (payload.type === 'grid') {
      yield put({
        type: documentsGridViewGetAction.type,
        payload: {},
      });
    } else {
      yield put({
        type: documentSearchAction.type,
        payload: {},
      });
    }
  } catch (err) {
    console.log('error when save workspace type in localstore');
  }
}

export function* ensureUpdateUserFeatures({ values, resolve, reject }) {
  try {
    const { data } = yield call(Api.updateUserFeatures, { data: values });
    yield put({
      type: action.updateUserFeaturesAction.success,
      payload: featuresAdapter(data),
    });

    yieldSuccessNotification(
      <FormattedMessage id="toast.preferences.updated" defaultMessage="Notification settings are updated" />,
    );

    if (typeof resolve === 'function') {
      resolve(data);
    }
  } catch (err) {
    yield doLogout(action.updateUserFeaturesAction, err);
    yieldValidationNotification(err);
    if (typeof reject === 'function') {
      reject(err);
    }
  } finally {
    if (yield cancelled()) {
      yield put({
        type: action.updateUserFeaturesAction.failure,
        err: 'cancelled',
      });
    }
  }
}

export function* ensureGetSupplierAliases() {
  try {
    const {
      data: { aliases },
    } = yield call(Api.getAliases);

    yield put({
      type: action.updateSupplierAliasesAction.success,
      payload: aliases,
    });
  } catch (err) {
    yield doLogout(action.updateSupplierAliasesAction, err);
  }
}

export function* ensureUpdateSupplierAliases({ aliases, resolve, reject }) {
  try {
    yield call(Api.updateAliases, { data: { aliases } });

    yield put({
      type: action.updateSupplierAliasesAction.success,
      payload: aliases,
    });

    yieldSuccessNotification(
      <FormattedMessage
        id="profile.aliases.toast.updated"
        defaultMessage="Your settings have been updated successfully."
      />,
    );

    if (typeof resolve === 'function') {
      resolve(aliases);
    }
  } catch (err) {
    yield doLogout(action.updateSupplierAliasesAction, err);
    if (err.response && err.response.data) {
      yieldErrorNotification(err.response.data);
    }
    if (typeof reject === 'function') {
      reject(err);
    }
  } finally {
    if (yield cancelled()) {
      yield put({
        type: action.updateSupplierAliasesAction.failure,
        err: 'cancelled',
      });
    }
  }
}
