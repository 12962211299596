// @flow
import React, { useMemo } from 'react';
import { useIntl, type $npm$ReactIntl$MessageDescriptor } from 'react-intl';

import TooltipMui, { type TooltipProps } from '@mui/material/Tooltip';

export type TTooltipMui = {
  t?: $npm$ReactIntl$MessageDescriptor | string | null | boolean,
  disabled?: boolean,
} & TooltipProps;

const Tooltip: React$StatelessFunctionalComponent<TTooltipMui> = ({ children, t = '', disabled = false, ...rest }) => {
  const intl = useIntl();

  // Tooltip title. Zero-length titles string are never displayed.
  const title = useMemo(() => {
    if (disabled) return '';
    if (t === null) return '';
    if (['undefined', 'boolean'].includes(typeof t)) return '';
    if (!React.isValidElement(t)) {
      if (typeof t === 'object') return intl.formatMessage(t);
    }

    return t;
  }, [t, intl, disabled]);

  return (
    <TooltipMui title={title} arrow {...rest}>
      {children}
    </TooltipMui>
  );
};

export default Tooltip;
