// @flow
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { documentHotkeysSelector } from 'domain/documents/documentSelector';
import { journalSelector } from 'domain/journal';
import { hotkeyMatcher } from 'lib/hotkeysHelpers';
import { HotkeyMatchFactory } from 'domain/documents';

import type { THotkeyList, THotkeyMatch, THotkeyCombination } from 'domain/documents/types.js.flow';
import type { RecordCellSetType } from 'domain/journal/helper';

type TDocumentHotkeyCatcher = {|
  handleHotkeyMatch: (
    cell: string,
    cellSet: RecordCellSetType,
    ignoreSelectedRows: boolean,
    hotkey: THotkeyMatch,
  ) => void,
|};

const DocumentHotkeyCatcher: React$StatelessFunctionalComponent<TDocumentHotkeyCatcher> = ({
  handleHotkeyMatch,
}: TDocumentHotkeyCatcher) => {
  const hotkeys: THotkeyList = useSelector(documentHotkeysSelector);
  // for keyboard shortcuts it doesn't matter which cell passed to update field,
  // back-end skip update Field if hotkey Field is exist
  const plugCell: RecordCellSetType = useSelector(journalSelector).first();

  const documentHotkeys = useMemo(() => hotkeys.filter((keys) => keys.level === 'document'), [hotkeys]);

  const onHotkeyMatch = useCallback(
    (keys: THotkeyCombination) => {
      handleHotkeyMatch(plugCell.get('_cell'), null, false, HotkeyMatchFactory({ key: keys }));
    },
    [handleHotkeyMatch, plugCell],
  );

  const handleKeyDown = useCallback(
    (e: SyntheticKeyboardEvent<HTMLElement>) => {
      const isMatch = hotkeyMatcher(e, documentHotkeys, onHotkeyMatch);

      if (isMatch) {
        e.preventDefault();
      }
    },
    [documentHotkeys, onHotkeyMatch],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [documentHotkeys, handleKeyDown]);

  return null;
};

export default memo(DocumentHotkeyCatcher);
