// @flow
import * as React from 'react';
import { compose } from 'redux';
import { List, OrderedMap, Set, type RecordOf } from 'immutable';
import moment from 'moment';
import constant from 'lodash/constant';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import {
  documentsSelector,
  documentSearchAction,
  pageTokenSelector,
  type DocumentsType,
  type Document,
  totalNumberOfDocumentsSelector,
  documentGetLinkedAction,
  linkedSelector,
  documentSortedLinkedSelector,
} from 'domain/documents';
import { processingDocsCountSelector } from 'domain/dashboard';
import { querySelector } from 'domain/router';
import { localeSelector } from 'domain/env';
import type { Query, PreviewStateT } from '../type.js.flow';
import { withPropsCollector, isChangeProps, createMemoFn } from 'lib/propHelpers';
import TileCardList from 'pages/company/TileGrid/TileCardList';
import DocumentPreviewNavigation from 'pages/company/DocumentPreviewNavigation/DocumentPreviewNavigation';

export const PREVIEW_CONTEXT_NAMES = { list: 'list', linked: 'linked' };

export const getPreviewContextName = (doc: Document) =>
  doc.linkid ? PREVIEW_CONTEXT_NAMES.linked : PREVIEW_CONTEXT_NAMES.list;

type Props = {
  items: OrderedMap<string, List<DocumentsType>>,
  itemsCountWithoutLinked: number,
  query: Query,
  locale: string,
  onShowLinked: Function,
  onContextMenu: (event: SyntheticMouseEvent<HTMLDivElement>, document: DocumentsType) => void,
  isContextMenuOpen?: boolean,
  processing: number,
  openNotes: (e: MouseEvent, d: DocumentsType) => void,
  onClick: (e: MouseEvent, d: DocumentsType) => void,

  onPreview: (p: PreviewStateT) => void,
  preview: PreviewStateT,

  clearSelection: () => void,
  selectedDocuments: Set<string>,
  isLinkedPanelSelected: boolean,
  documentSearch: ({| pageToken: string, infiniteScroll?: boolean |}) => void,
  pageToken: string,
  getDocumentLinkedCount: (id: string) => void,
  linkedItems: OrderedMap<string, List<DocumentsType>>,
  getLinked: Dispatch<typeof documentGetLinkedAction>,
  linked: RecordOf<{ tag: string, pageToken: ?string }>,
};

class DocumentList extends React.Component<Props> {
  static currentMonth() {
    return moment().format('MM-YYYY');
  }

  isIntersecting: boolean;

  observer: ?IntersectionObserver;

  container: ?HTMLElement;

  constructor(props) {
    super(props);
    moment.locale(props.locale);
  }

  componentDidMount() {
    if (this.observer && this.container) {
      this.observer.observe(this.container);
    }
  }

  shouldComponentUpdate(nextProps: Props) {
    return isChangeProps(this.props, nextProps, [
      'processing',
      'selectedDocuments',
      'selectedDocuments',
      'isContextMenuOpen',
      'items',
      'itemsCountWithoutLinked',
      'linkedItems',
      'linked',
      'preview',
    ]);
  }

  componentDidUpdate(prevProps: Props) {
    const { pageToken } = this.props;
    if (typeof prevProps.pageToken === 'string' && pageToken === null && this.observer && this.container) {
      this.observer.unobserve(this.container);
    }
    if (prevProps.pageToken === null && typeof pageToken === 'string') {
      if (this.observer && this.container) {
        this.observer.observe(this.container);
      }
    }
  }

  componentWillUnmount(): * {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  onBeginDrag = (linkid: string) => {
    if (linkid) {
      const { getDocumentLinkedCount } = this.props;
      getDocumentLinkedCount(linkid);
    }
  };

  onDocumentClick = (event: MouseEvent, doc: DocumentsType) => {
    const { onClick } = this.props;
    onClick(event, doc);
  };

  onContextMenu = (event: MouseEvent, doc: DocumentsType) => {
    const {
      preview: { contextName },
      onContextMenu,
    } = this.props;
    const source = contextName ? [contextName] : [];
    const args = [event, doc, ...source];
    onContextMenu(...args);
  };

  onOpenNotes = (event: MouseEvent, doc: DocumentsType) => {
    const { openNotes } = this.props;
    openNotes(event, doc);
  };

  onLoadNextPreviewDocuments = () => {
    const {
      preview: { contextName },
    } = this.props;
    const docLoader = {
      [PREVIEW_CONTEXT_NAMES.list]: this.getNextPage,
      [PREVIEW_CONTEXT_NAMES.linked]: this.getNextLinkedPage,
    };
    docLoader[contextName]();
  };

  onPreviewStart = (doc: Document) => {
    const { onShowLinked } = this.props;
    const { linkid } = doc;
    if (linkid) {
      onShowLinked(linkid, false, doc.documentID);
    }
    this.onPreview(doc);
  };

  onPreview = (doc: Document | null) => {
    const { onPreview } = this.props;
    const [documentId, contextName] = doc ? [doc.documentID, getPreviewContextName(doc)] : [null, ''];
    onPreview({ documentId, contextName });
  };

  get items() {
    return this.getItems();
  }

  get dseq() {
    return this.items.keySeq();
  }

  get dList() {
    return this.items.toList();
  }

  get hasNext() {
    const { pageToken } = this.props;
    return typeof pageToken === 'string' && pageToken.length;
  }

  getItems = createMemoFn(
    () => {
      const { items, processing } = this.props;
      const current = this.constructor.currentMonth();
      return { items, processing, current };
    },
    ({ items, processing, current }) =>
      items.has(current) || (items.size === 0 && !processing)
        ? items
        : OrderedMap([[current, new List()]]).merge(items),
  );

  getAnalizing = (d?: string): boolean => {
    const { processing } = this.props;
    return processing > 0 ? d === this.constructor.currentMonth() : false;
  };

  getNextPage = () => {
    const { pageToken, documentSearch } = this.props;
    if (this.hasNext) {
      documentSearch({ pageToken, infiniteScroll: true }); // true indicates infinite scroll
    }
  };

  getNext = (entries: $ReadOnlyArray<IntersectionObserverEntry>) => {
    const { isIntersecting } = entries[0];
    this.isIntersecting = isIntersecting;
    if (isIntersecting) {
      this.getNextPage();
    }
  };

  getNextLinkedPage = () => {
    const {
      getLinked,
      linked: { pageToken, tag },
    } = this.props;
    getLinked({ pageToken, tag, isOpen: false });
  };

  getPreviewList = createMemoFn(
    () => ({ list: this.dList }),
    ({ list }) => list.flatten().filter((i) => !i.linkid),
  );

  getPreviewListContext = createMemoFn(() => {
    const { itemsCountWithoutLinked } = this.props;
    const list = this.getPreviewList();
    return { count: itemsCountWithoutLinked, list };
  });

  getPreviewLinkedListContext = createMemoFn(
    () => {
      const {
        linkedItems,
        linked: { count },
      } = this.props;
      return { linkedItems, count };
    },
    ({ linkedItems, count }) => ({ list: linkedItems.toList(), count }),
  );

  getPreviewContext = () => {
    const {
      preview: { contextName },
    } = this.props;

    const contextGetter = {
      [PREVIEW_CONTEXT_NAMES.list]: this.getPreviewListContext,
      [PREVIEW_CONTEXT_NAMES.linked]: this.getPreviewLinkedListContext,
    };

    const getter = contextGetter[contextName] || constant(null);
    return getter();
  };

  refProxy = (el: ?HTMLElement) => {
    this.container = el;
    if (el) {
      this.observer = new IntersectionObserver(this.getNext, {
        root: null,
        rootMargin: '100px',
        threshold: 0.1,
      });
    }
  };

  isShowPreview = () => {
    const {
      preview: { documentId, contextName },
    } = this.props;
    return documentId && PREVIEW_CONTEXT_NAMES[contextName];
  };

  isSearch() {
    const { query } = this.props;
    return Object.values(query).some((item) => (Array.isArray(item) ? item.some((i) => !!i) : !!item));
  }

  render() {
    const {
      isContextMenuOpen,
      onShowLinked,
      clearSelection,
      selectedDocuments,
      processing,
      isLinkedPanelSelected,
      preview: { documentId },
    } = this.props;
    const context = this.getPreviewContext();

    return (
      <>
        {this.isShowPreview() && (
          <DocumentPreviewNavigation
            documentId={documentId}
            setPreview={this.onPreview}
            list={context.list}
            allCount={context.count}
            onContextMenu={this.onContextMenu}
            isContextMenuOpen={isContextMenuOpen}
            onLoadNext={this.onLoadNextPreviewDocuments}
            onClick={this.onDocumentClick}
          />
        )}

        {/* TODO: MB MOVE TO CONTEXT, */}
        <TileCardList
          isLinkedPanelSelected={isLinkedPanelSelected}
          refProxy={this.refProxy}
          processing={processing}
          dList={this.dList}
          dseq={this.dseq}
          selectedDocuments={selectedDocuments}
          clearSelection={clearSelection}
          isSearch={this.isSearch()}
          hasNext={this.hasNext}
          getAnalizing={this.getAnalizing}
          onContextMenu={this.onContextMenu}
          onBeginDrag={this.onBeginDrag}
          onClickLinked={onShowLinked}
          onClickNote={this.onOpenNotes}
          onDocumentClick={this.onDocumentClick}
          onClickPreview={this.onPreviewStart}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: localeSelector(state),
  processing: processingDocsCountSelector(state),
  pageToken: pageTokenSelector(state),
  query: querySelector(state),

  items: documentsSelector(state),
  itemsCountWithoutLinked: totalNumberOfDocumentsSelector(state),
  linkedItems: documentSortedLinkedSelector(state),
  linked: linkedSelector(state),
});

const mapDispatchToProps = {
  documentSearch: documentSearchAction,
  getLinked: documentGetLinkedAction,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withPropsCollector)(DocumentList);
