// @flow
import React, { useMemo, useState } from 'react';
import { Map } from 'immutable';
import { compose } from 'redux';
import { useSelector } from 'react-redux';
import reduxForm from 'redux-form/lib/immutable/reduxForm';
import Field from 'redux-form/lib/immutable/Field';
import { FormattedMessage, useIntl } from 'react-intl';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { generatePath } from 'react-router-dom';
import { signInAction } from 'domain/env';
import { signInValidate } from 'pages/authPage/Form/validation';
import { backUrlSelector } from 'domain/router';

import Message from 'components/Form/Message';
import TextFieldForm from 'components/mui/Form/TextField/TextFieldForm';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckboxMui from '@mui/material/Checkbox';
import LinkMui from '@mui/material/Link';
import RouterLink from 'components/mui/Router/RouterLink';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import { mqAuthHeight } from 'pages/authPage/Layout/StyledComponents';

import { federatedSignIn } from 'amplify/helpers';
import { useTheme } from '@mui/material';

type TDefaultAuthForm = {
  error: string,
  handleSubmit: () => void,
};

const DefaultAuthForm: React$StatelessFunctionalComponent<TDefaultAuthForm> = ({ handleSubmit, error }) => {
  const backUrl = useSelector(backUrlSelector);
  const intl = useIntl();
  const { labelOptions } = useTheme();
  const [isAccept, setIsAccept] = useState(true);
  const mqHeightMatch = useMediaQuery(mqAuthHeight);

  const buttonSize = useMemo(() => (mqHeightMatch ? 'large' : 'small'), [mqHeightMatch]);
  const inputSize = useMemo(() => (mqHeightMatch ? 'large' : 'small'), [mqHeightMatch]);

  const toggleAccept = (event: SyntheticInputEvent<HTMLInputElement>) => {
    setIsAccept(event.target.checked);
  };

  const SSOLogin = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    federatedSignIn(backUrl);
  };

  return (
    <form onSubmit={handleSubmit} name="login">
      <Typography variant="h5">
        <FormattedMessage id="auth.login" defaultMessage="Sign In" />
      </Typography>
      <Typography variant="subtitle2" color="text.secondary" fontWeight="400" mb={3}>
        <FormattedMessage id="auth.passwordLess.login.subtitle" defaultMessage="Enter your email address to log in" />
      </Typography>
      <Box textAlign="center">
        <Message meta={{ touched: !!error, error }} />
      </Box>
      <Field
        component={TextFieldForm}
        size={inputSize}
        name="email"
        type="text"
        fullWidth
        InputLabelProps={{ shrink: true }}
        sx={{ mb: 1 }}
        label={intl.formatMessage({
          id: 'auth.email',
          defaultMessage: 'Email',
        })}
        placeholder={intl.formatMessage({
          id: 'auth.email',
          defaultMessage: 'Email',
        })}
      />

      <Field
        component={TextFieldForm}
        size={inputSize}
        name="password"
        type="password"
        fullWidth
        InputLabelProps={{ shrink: true }}
        label={intl.formatMessage({
          id: 'auth.password',
          defaultMessage: 'Password',
        })}
        placeholder={intl.formatMessage({
          id: 'auth.password',
          defaultMessage: 'Password',
        })}
      />

      <Box
        mb={2}
        display="flex"
        justifyContent="space-between"
        sx={{ flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'flex-start', md: 'center' } }}
      >
        {labelOptions.termsAndConditionLink && (
          <FormControlLabel
            control={<CheckboxMui />}
            checked={isAccept}
            name="accept"
            onChange={toggleAccept}
            label={
              <Typography variant="body2">
                <FormattedMessage id="auth.accept" defaultMessage="I accept" />
                &nbsp;
                <LinkMui
                  underline="hover"
                  href={labelOptions.termsAndConditionLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="auth.terms" defaultMessage="Terms and Conditions" />
                </LinkMui>
              </Typography>
            }
          />
        )}
        <LinkMui
          underline="hover"
          variant="body2"
          href={generatePath(ROUTES_PATH.AUTH_FORGOT_PASSWORD.absolute)}
          component={RouterLink}
          my={labelOptions.termsAndConditionLink ? 0 : 1}
          ml="auto"
        >
          <FormattedMessage id="auth.forgotPassword" defaultMessage="Forgot Password" />
        </LinkMui>
      </Box>

      <Button size={buttonSize} disabled={!isAccept} type="submit" fullWidth variant="contained">
        <FormattedMessage id="auth.login" defaultMessage="Login" />
      </Button>

      {labelOptions.isSsoLogin && (
        <>
          <Divider sx={{ my: mqHeightMatch ? 2 : 0.5, color: 'text.secondary' }}>
            <FormattedMessage id="auth.divider.or" defaultMessage="OR" />
          </Divider>
          <Button size={buttonSize} disabled={!isAccept} type="button" fullWidth variant="outlined" onClick={SSOLogin}>
            <FormattedMessage id="auth.auth0.login" defaultMessage="Sign In with SSO" />
          </Button>
        </>
      )}
    </form>
  );
};

export default compose(
  reduxForm({
    form: 'login',
    initialValues: Map({ language: 'en' }),
    validate: signInValidate,
    onSubmit: signInAction.onSubmit,
  }),
)(DefaultAuthForm);
