// @flow
import React from 'react';
import CONST from 'domain/documents/constants';
import { FormattedMessage } from 'react-intl';
import type { DocumentsType } from 'domain/documents/documentsModel';

import { styled } from '@mui/material/styles';

type TPaidBadge = {
  document: DocumentsType,
};

const Badge = styled('div', { shouldForwardProp: (propName) => propName !== 'isPaid' })(({ theme, isPaid }) => ({
  display: 'inline-flex',
  backgroundColor: isPaid ? theme.palette.secondary.main : theme.palette.error.main,
  color: theme.palette.common.white,
  marginLeft: theme.spacing(-1),
  padding: theme.spacing(0.5, 1),
  fontSize: theme.typography.pxToRem(13),
  fontWeight: 500,
  borderRadius: '0 4px 4px 0',
}));

const PaidBadge: React$StatelessFunctionalComponent<TPaidBadge> = ({ document }) => {
  const isPaid = document.tags.has(CONST.tags.paid);
  // const isUnpaid = document.tags.has(CONST.tags.unpaid);
  const isUnpaid = false; // b-end always return unpaid tag by default, so now disable unpaid label
  const isSome = isPaid || isUnpaid;

  return (
    isSome && (
      <Badge isPaid={isPaid}>
        <FormattedMessage
          id={`documents.item.badge.${isPaid ? 'paid' : 'unpaid'}`}
          defaultMessage={isPaid ? 'Paid' : 'Unpaid'}
        />
      </Badge>
    )
  );
};

export default PaidBadge;
