import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { tokenSelector as companyTokenSelector } from 'domain/documents/documentSelector';
import Api from 'domain/api';

export default () => {
  const [grid, loadGrid] = useState({});
  const dokkaToken = useSelector(companyTokenSelector);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await Api.getSuppliersDefaults({
          params: {
            dokkaToken,
          },
        });
        loadGrid(data);
      } catch (error) {
        console.log('error fetching supplier defaults data');
      }
    };
    // effect might be run before token is retrieved
    if (dokkaToken) fetchData();
  }, [dokkaToken]);
  return { grid, updateGridData: loadGrid };
};
