/* eslint-disable camelcase */
// @flow
// eslint-disable-next-line import/no-cycle
import { GRID_SERVICE_KEYS } from 'domain/documents/helpers';
import { IS_MASTER_VIEW_CELL_CLASS } from 'hooks/agGrid/agGridThemeClasses';

// helpers
import get from 'lodash/get';

// types
import type { TColumnDefs } from 'pages/company/grid/types.js.flow';
import type { GridItemType } from 'domain/documents/types.js.flow';
import type { RawApproval } from 'domain/types.js.flow';
import type { ChatUser } from 'domain/chat/types.js.flow';
import type { RecordOf } from 'immutable';

import {
  separateTags,
  createTagRecord,
  getUserTagProps,
  getGeneralTagProps,
  replaceTagToUser,
} from '../components/Tags/helpers';
import keyBy from 'lodash/keyBy';

export const SYMBOL_FOR_EMPTY_CELL = '-';

type IParams = {|
  getValue(field: string): any,
  column: {| colId: string |},
  colDef: {| valueFormatter({ value: any }): any |},
|};

const getEmptyVariable = (value: any) => {
  if (Array.isArray(value)) {
    return [];
  }
  if (typeof value === 'object' && value !== null) {
    return {};
  }
  return null;
};

export const getEmptyRow = (gridDataSource: GridItemType[]) => [
  Object.entries(gridDataSource[0]).reduce(
    (a, [key, value]) =>
      // eslint-disable-next-line no-nested-ternary
      ({ ...a, [key]: getEmptyVariable(value) }),
    {},
  ),
];

export const hasValueByProp = (value: any, prop: string) =>
  Boolean(typeof value === 'object' && value !== null && value[prop]);

export const isServiceColumn = (colId?: string) => Object.values(GRID_SERVICE_KEYS).includes(colId);

export const addColumnsVisibilityMenuColumn = (columns: TColumnDefs[]): Array<TColumnDefs> => [
  ...columns,
  {
    field: GRID_SERVICE_KEYS.COLUMNS_VISIBILITY_MENU,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
    lockPinned: true,
    cellStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px',
    },
    pinned: 'right',
    maxWidth: 50,
    minWidth: 50,
    resizable: false,
    headerComponentParams: {
      enableSorting: false,
    },
    suppressMovable: true,
  },
];
export const addContextMenuColumn = (columns: TColumnDefs[]): Array<TColumnDefs> => [
  {
    field: GRID_SERVICE_KEYS.CONTEXT_MENU_COLUMN_NAME,
    headerName: '',
    width: 50,
    maxWidth: 50,
    pinned: 'left',
    cellRenderer: 'context',
    lockPosition: true,
    lockPinned: true,
    headerClass: 'action-btns-header',
    cellClass: 'action-btns',
    type: 'actions',
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
    suppressFilterSearch: true,
    headerComponentParams: {
      service: false,
      enableMenu: false,
      enableSorting: false,
    },
  },
  ...columns,
];

export const addPreviewColumn = (columns: TColumnDefs[]): Array<TColumnDefs> => [
  ...columns,
  {
    field: GRID_SERVICE_KEYS.PREVIEW_BTN_COLUMN_NAME,
    cellRenderer: 'preview',
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
    suppressFilterSearch: true,
    lockPinned: true,
    lockPosition: true,
    cellStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px',
    },
    pinned: 'left',
    maxWidth: 80,
    minWidth: 80,
    resizable: false,
    headerComponentParams: {
      service: true,
      enableMenu: false,
      enableSorting: false,
    },
  },
];

export const addPreviewColumnForInsights = (columns: TColumnDefs[]): Array<TColumnDefs> => [
  ...columns,
  {
    field: GRID_SERVICE_KEYS.PREVIEW_BTN_COLUMN_NAME,
    cellRenderer: 'preview',
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
    suppressFilterSearch: true,
    lockPinned: true,
    lockPosition: true,
    cellStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px',
    },
    pinned: 'left',
    maxWidth: 44,
    minWidth: 44,
    resizable: false,
    headerComponentParams: {
      service: true,
      enableMenu: false,
      enableSorting: false,
    },
  },
];
export const addMasterViewColumnForInsights = (columns: TColumnDefs[]): Array<TColumnDefs> => [
  ...columns,
  {
    field: GRID_SERVICE_KEYS.COLUMNS_MASTER_VIEW,
    cellRenderer: 'agGroupCellRenderer',
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
    suppressFilterSearch: true,
    lockPinned: true,
    lockPosition: true,
    cellStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px',
    },
    cellClass: IS_MASTER_VIEW_CELL_CLASS,
    pinned: 'left',
    maxWidth: 44,
    minWidth: 44,
    resizable: false,
    headerComponentParams: {
      service: false,
      enableMenu: false,
      enableSorting: false,
    },
  },
];

const comparators = {
  contains: ['some', (value: string, search: string) => value.includes(search)],
  notContains: ['every', (value: string, search: string) => !value.includes(search)],
  equals: ['some', (value: string, search: string) => value === search],
  notEqual: ['every', (value: string, search: string) => value !== search],
  startsWith: ['some', (value: string, search: string) => value.startsWith(search)],
  endsWith: ['some', (value: string, search: string) => value.endsWith(search)],
  default: ['some', () => true],
};

type ComparatorHandlerT = 'some' | 'every';
type ComparatorT = (value: string, search: string) => boolean;

const compareArray =
  ([handler, comparator]: [ComparatorHandlerT, ComparatorT]) =>
  (values: string[], search: string) => {
    const searchLower = search.toLowerCase();
    return values[handler]((value) => comparator(value.toLowerCase(), searchLower));
  };

export const AprovalFactoryCreator =
  (usersById: Map<string, RecordOf<ChatUser>>) =>
  ({ username = '', status = '', userid }: RawApproval) => {
    const user = usersById.get(username);
    const userName = user ? user.get('username') : username;
    const src = user ? user.get('picture') : null;

    return { userName, src, status, userid };
  };

const approvalStatusWeight: { [key: ApprovalStatus]: number } = {
  pending: 4,
  approved: 3,
  draft: 2,
  rejected: 1,
};

const getCompareTagValue = (tags: string, usersById: Map<string, RecordOf<ChatUser>>): string => {
  const userTags = tags.filter((tag) => usersById.get(tag));
  const tag = userTags[0] || tags[0] || '';
  const user = usersById.get(tag);
  return user ? user.get('username') : tag;
};

const customColumns = {
  tag: (field: string) => ({
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: [
        'contains',
        'notContains',
        'startsWith',
        'endsWith',
        {
          displayKey: 'exist',
          displayName: 'Exist',
          test: (_, data) => data[field] && data[field].length,
          hideFilterInput: true,
        },
        {
          displayKey: 'notExist',
          displayName: 'Not exist',
          test: (_, data) => !data[field] || !data[field].length, // !Array is tricky, check later
          hideFilterInput: true,
        },
      ],

      textMatcher({ filterOption, data, filterText }) {
        const { usersById } = data;
        // We have all fields in 'data' and dont need to search
        // in embeded 'document' field like we did before

        // to display as tag we must provide data as Array of strings
        const values: Array<string> = data[field];
        if (Array.isArray(values)) {
          const tagsWithUsers = replaceTagToUser(values, usersById);
          const comparator = comparators[filterOption] || comparators.default;
          return compareArray(comparator)(tagsWithUsers, filterText);
        }
        // if data is not array, its data mismatch and we avoid
        // type error by this fallback (will have empty results though)
        return false;
      },
      textFormatter: (r) => r,
      valueGetter: function getter({ data }) {
        return data;
      },
      trimInput: true,
      debounceMs: 500,
    },
    valueFormatter: ({ type = 'component', node: { data } }) => {
      const { usersById } = data;
      const values: Array<string> = data[field];
      const extractExportValue = (allTagProps) => {
        const exportTags = allTagProps.map((tagProps) => tagProps.username || tagProps.tag.tag);
        return exportTags.join(', ');
      };

      const handlers = {
        component: (x) => x,
        excel: extractExportValue,
        csv: extractExportValue,
      };
      const tagRecords = values.map(createTagRecord);
      const [userTags, generalTags] = separateTags(tagRecords, usersById);
      const userTagProps = userTags.map((tag) => ({
        ...getGeneralTagProps(tag),
        ...getUserTagProps(usersById.get(tag.tag)),
      }));
      const generalTagProps = generalTags.map((tag) => getGeneralTagProps(tag));
      const allTagProps = [...userTagProps, ...generalTagProps];
      return handlers[type](allTagProps);
    },

    comparator: (tagsA, tagsB, a) => {
      const { usersById } = a.data;
      const tagA = getCompareTagValue(tagsA, usersById);
      const tagB = getCompareTagValue(tagsB, usersById);

      return tagA.localeCompare(tagB);
    },

    cellRenderer: 'tag',
    suppressColumnsToolPanel: false,
    suppressFiltersToolPanel: false,
    suppressFilterSearch: false,
    lockPinned: false,
    cellStyle: {
      display: 'flex',
      alignItems: 'center',
      padding: '0px',
      maxWidth: '100%',
    },
    maxWidth: 1200,
    minWidth: 100,
    resizable: true,
  }),
  amount: (field: string, shouldHighlightAmount: boolean) => ({
    cellRenderer: 'amount',
    filter: 'agNumberColumnFilter',
    filterParams: {
      defaultOption: 'greaterThan',
      valueGetter: function getter({ data }) {
        return shouldHighlightAmount ? Math.abs(data[field]) : data[field];
      },
    },
    sortable: true,
    comparator: (valueA, valueB) => (shouldHighlightAmount ? Math.abs(valueA) - Math.abs(valueB) : valueA - valueB),
    valueFormatter: ({ value }) => (shouldHighlightAmount ? Math.abs(value) : value),
  }),
  approval: () => ({
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: [
        'contains',
        'notContains',
        'startsWith',
        'endsWith',
        {
          displayKey: 'exist',
          displayName: 'Exist',
          test: (_, { approvals_nodes }) => approvals_nodes && approvals_nodes.length,
          hideFilterInput: true,
        },
        {
          displayKey: 'notExist',
          displayName: 'Not exist',
          test: (_, { approvals_nodes }) => !approvals_nodes || !approvals_nodes.length,
          hideFilterInput: true,
        },
      ],
      textMatcher({
        filterOption,
        data: {
          usersById,
          rowDocumentData: { approvals },
        },
        filterText,
      }) {
        const nodes = approvals.nodes || [];
        const users = nodes.map((node) => usersById.getIn([node.username, 'username']) || node.username);
        const comparator = comparators[filterOption] || comparators.default;
        return compareArray(comparator)(users, filterText);
      },
      textFormatter: (r) => r,
      valueGetter: function getter({ data }) {
        return data;
      },
      trimInput: true,
      debounceMs: 500,
    },
    valueFormatter: ({
      type = 'component',
      node: {
        data: { usersById, approvals_nodes },
      },
    }) => {
      const extractExportValue = (nodes) => {
        if (nodes) {
          const users = nodes.map((node) => {
            const user = usersById.get(node.username);
            const username = user ? user.username : node.username;
            return `${username} (${node.status})`;
          });
          return users.join(' ');
        }
        return '';
      };
      const handlers = {
        component: (x) => x,
        excel: extractExportValue,
        csv: extractExportValue,
      };
      return handlers[type](approvals_nodes);
    },
    cellRenderer: 'approval',
    suppressColumnsToolPanel: false,
    suppressFiltersToolPanel: false,
    suppressFilterSearch: false,
    lockPinned: false,
    cellStyle: {
      display: 'flex',
      alignItems: 'center',
      padding: '0px',
      maxWidth: '100%',
    },
    maxWidth: 1000,
    minWidth: 100,
    resizable: true,
    // eslint-disable-next-line consistent-return
    comparator: (valueA, valueB, nodeA, nodeB) => {
      if (!valueA) return 1;
      if (!valueB) return -1;

      const AprovalFactoryA = AprovalFactoryCreator(nodeA.data.usersById);
      const AprovalFactoryB = AprovalFactoryCreator(nodeB.data.usersById);

      const compare = (valA, valB) => {
        const a = AprovalFactoryA(valA);
        const b = AprovalFactoryB(valB);

        const nameA = a.userName ? a.userName : '';
        const nameB = b.userName ? b.userName : '';
        const statusA = a.status;
        const statusB = b.status;

        return (
          nameA.localeCompare(nameB) || (approvalStatusWeight[statusB] || 0) - (approvalStatusWeight[statusA] || 0)
        );
      };

      // eslint-disable-next-line no-restricted-syntax
      for (const [i, v] of valueA.entries()) {
        const compareResult = compare(v, valueB[i]);
        const lengthA = valueA.length;
        const lengthB = valueB.length;

        if (compareResult !== 0) return compareResult;
        if (i === lengthB - 1 && lengthA > lengthB) {
          return -1;
        }
        if (i === lengthA - 1) {
          if (lengthA < lengthB) return 1;
          return compareResult;
        }
      }
    },
  }),
  extraSelect: () => ({
    cellRenderer: 'statusCell',
    needContainerForDetectOverflow: true,
    filter: 'agSetColumnFilter',
    valueFormatter: ({ value }) => value,
    filterValueGetter: (params: IParams) => {
      if (params) {
        const { getValue, column, colDef } = params;
        const value = getValue(column.colId) || [];

        if (value.length) {
          return colDef.valueFormatter({ value });
        }
      }
      return null;
    },
  }),
};

export const addCustomColumns = (shouldHighlightAmount: boolean) => (columns: TColumnDefs[]) =>
  columns.map((column) => {
    const customColumn = typeof customColumns[column.type] === 'function' ? customColumns[column.type] : null;
    return customColumn ? { ...column, ...customColumn(column.field, shouldHighlightAmount) } : column;
  });

export const addColDefaultParams = (columns: TColumnDefs[]) =>
  columns.map((column) => ({
    width: column.type === 'actions' ? 0 : 200,
    ...(['number', 'amount'].includes(column.type) && { cellStyle: { textAlign: 'right' } }),
    ...(['amount'].includes(column.type) && { cellClass: 'amount' }),
    ...column,
  }));

export const changeWidthIfMasterViewNotAvailable = (columns: TColumnDefs[], hasMasterDetail: boolean): TColumnDefs[] =>
  columns.map((column) =>
    column.field === GRID_SERVICE_KEYS.PREVIEW_CHECKBOX_COLUMN_NAME && !hasMasterDetail
      ? { ...column, maxWidth: 55 }
      : column,
  );

export const invertPinnedValue = (columns: TColumnDefs[] = []): TColumnDefs[] => {
  const invertPinnedValues = {
    left: 'right',
    right: 'left',
  };
  return columns.map(({ pinned, ...column }) => ({
    ...column,
    pinned: invertPinnedValues[pinned] || null,
  }));
};

export const isEllipsisActive = (el: HTMLElement) => el.offsetWidth < el.scrollWidth;

export const setExelModeFilter =
  (filterType: 'mac' | 'windows') =>
  (columns: TColumnDefs[] = []) =>
    columns.map((column) => {
      const up = { excelMode: filterType, buttons: ['apply', 'cancel'], closeOnApply: true };
      const filterParams = column.filterParams ? { ...column.filterParams, ...up } : up;
      return { ...column, filterParams };
    });

export const separateFilters = (filters, isSet = false) =>
  Object.entries(filters)
    .filter(([, { filterType }]) => (isSet ? filterType === 'set' : filterType !== 'set'))
    .reduce((res, [key, filter]) => ({ ...res, [key]: filter }), {});

// native Map
export const errorStatusList = new Map([
  [1, 'warning'],
  [2, 'error'],
]);

export const getRowErrorStatus = (params: { data: { [key: string]: any } }) => {
  const value = Object.values(params.data).find((val) => hasValueByProp(val, 'error_level'));
  return errorStatusList.get(get(value, 'error_level'));
};

export const ID_FOR_DETECT_CELL_OVERFLOW = 'containerForDetectCellOverflow';
export const SET_FILTER_HOVER_CLASSNAME = 'set-filter-hover';

// eslint-disable-next-line react/destructuring-assignment
export const FilterAdapter = (filter: any) => (Array.isArray(filter) ? filter.join(';') : filter || null);

export const FiltersAdapter = (filters: any[]) => filters.map(FilterAdapter);

export const mixSavedGridColumnsStateAdapter = (columns, orderedColumnConfig) => {
  const colMap = keyBy(columns, 'field');
  const currentColumnMap = keyBy(orderedColumnConfig, 'colId');

  const removedColumns = orderedColumnConfig.filter((col) => !colMap[col.colId]).map((col) => col.colId);

  const addedColumns = columns.filter((col) => !currentColumnMap[col.field]);

  const res = orderedColumnConfig
    .filter((state) => !removedColumns.includes(state.colId))
    .map((state) => ({ ...colMap[state.colId], ...state }));

  return [...res, ...addedColumns];
};
