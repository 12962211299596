// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useParams } from 'react-router-dom';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import * as ACL from 'domain/restriction';

import BackToArrowBase from 'components/mui/Layouts/components/BackToArrowBase';

const BackToArrow = () => {
  const isGranted = useSelector(ACL.isGranted);
  const { companyId } = useParams();

  const ROUTE_ID = isGranted(ACL.IS_SUPPLIER_USER)
    ? ROUTES_PATH.COMPANY_SUPPLIER.id
    : ROUTES_PATH.COMPANY_DEFAULT_CATEGORY.id;

  const path = generatePath(ROUTES_PATH[ROUTE_ID].absolute, { companyId });

  return <BackToArrowBase path={path} />;
};

export default BackToArrow;
