// @flow
import { useRef, useEffect } from 'react';

export function usePrevious<T>(value: T) {
  const ref: {| current: null | T |} = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
