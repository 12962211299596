// @flow
import { Map, List } from 'immutable';
import { signOutAction } from 'domain/env/envActions';
import {
  type JournalStore,
  type JournalSettings,
  TextMapFactory,
  JournalFactory,
  JournalSettingsFactory,
  extraPolygonFactory,
} from './helper';
import type { TextMapStore } from './types.js.flow';
import * as A from './actions';
import * as R from 'domain/reconciliation';
import { getTransactionMessagingHistory } from 'domain/requestTransaction/actions';
import get from 'lodash/get';

export const reducer = {
  journal(state: JournalStore = JournalFactory(), action) {
    switch (action.type) {
      // need this to reset MatchedLines JE field at least
      // check this for consistency as it was commented out previously
      case A.clearJournalEntryAction.type:
        return JournalFactory();

      case A.setLoadingAction.type:
        return state.set('loading', true);

      case A.endLoadingAction.type:
        return state.set('loading', false);

      case A.getJournalEntryAction.type:
      case A.getJournalEntryAction.success:
      case A.getJournalEntryForGridAction.success:
      case A.updateJournalEntryAction.success:
      case A.updateJEFromInsightsAction.success:
        return state.merge(action.payload.setIn(['paginationList'], state.paginationList));

      case A.getJournalEntryPageAction.success:
        return state.merge(
          action.payload
            .update('entry', (e) => state.entry.merge(e))
            .update('line_items', (l) => state.line_items.concat(l))
            .update('lines', (l) => state.lines.concat(l))
            .update('lineItemsData', (l) => state.lineItemsData.merge(l))
            .set('selectedRow', state.selectedRow),
        );

      case signOutAction.success:
      case A.getJournalEntryAction.failure:
        return JournalFactory().set('errorFromErp', get(action.err, 'response.data'));

      case A.updateJournalEntryAction.type:
        return state.setIn(['entry', action.payload.item._cell, 'cellSet'], action.payload.item.cellSet);

      case A.selectRowAction.type:
        return state.set('selectedRow', action.payload);

      case R.showReconciliationRequestDetailsAction.type:
        return state.update('lineItemsData', (e) =>
          e.map((l) => (l.line_id === action.payload.id ? l.set('line_request_status', 'read') : l)),
        );

      case getTransactionMessagingHistory.success:
        return state.update('lineItemsData', (e) =>
          e.map((l) => (l.line_id === action.lineId ? l.set('line_request_status', 'read') : l)),
        );

      case A.getJEListAction.type: {
        return action.payload.isNewList
          ? state.setIn(['paginationList', 'list'], new List()).set('pageToken', null)
          : state;
      }

      case A.getJEListAction.success:
        return state.updateIn(['paginationList'], (e) => {
          const { list, pageToken, isNewList, include_id: includeId, preload } = action.payload;

          return preload
            ? e.update('cachedList', (cl) => (includeId ? cl.set(includeId, list) : cl))
            : e
                .update('list', (l) => (isNewList ? list : l.concat(list)))
                .set('pageToken', pageToken)
                .update('cachedList', (cl) => (includeId ? cl.set(includeId, list) : cl));
        });

      case A.updateCurrentPaginationListAction.type:
        return state.updateIn(['paginationList'], (pl) => {
          const { list, pageToken = null, isNewList = true } = action.payload;
          return pl.update('list', (l) => (isNewList ? list : l.concat(list))).set('pageToken', pageToken);
        });

      case A.clearJEMessagesAction.type:
        return state.set('messages', new List());

      default:
        return state;
    }
  },
  mapText(state: TextMapStore = TextMapFactory(), action) {
    switch (action.type) {
      case A.getTextMapAction.failure:
      case A.resetMapTextAction.type:
        return TextMapFactory();

      case A.getTextMapAction.success:
        return state.set('polygons', action.payload.polygons).set('pages', action.payload.pages);

      case A.setExtraPolygonAction.type:
        return state.set('extraPolygon', extraPolygonFactory(action.payload));

      case A.updateJournalEntryAction.success:
      case A.clearExtraPolygonAction.type:
        return state.set('extraPolygon', extraPolygonFactory());

      case A.setRefernceAction.type:
        return state.setIn(['reference', action.payload.id], action.payload.params);

      case A.clearReferenceAction.type:
        return state.set('reference', new Map());

      default:
        return state;
    }
  },

  journalSettings(state: JournalSettings = JournalSettingsFactory(), { type, payload }) {
    switch (type) {
      case A.getJournalSettingsOrderedColsAction.success:
      case A.updateJournalSettingsOrderedColsAction.success:
        return state.set('orderedCols', payload);

      case A.getJournalSettingsPinnedColsAction.success:
      case A.updateJournalSettingsPinnedColsAction.success:
        return state.set('pinnedCols', payload);

      case A.getJELineColumnsVisibilityAction.success:
      case A.updateJELineColumnsVisibilityAction.success:
        return state.set('disabledCols', payload);
      default:
        return state;
    }
  },
};
