import { makeStyles } from '@mui/styles';
import { GRID_SERVICE_KEYS } from 'domain/documents/helpers';

const { COLUMNS_VISIBILITY_MENU } = GRID_SERVICE_KEYS;

export default makeStyles((theme) => ({
  sortOptions: {
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent',
  },

  sortIcon: {
    padding: 0,
    marginLeft: -2,
  },

  sortAndFilter: {
    display: 'flex',
    alignItems: 'center',
  },

  active: {
    borderBottomColor: theme.colors.greyDarkest,
  },

  menuButton: {
    marginLeft: ({ displayName }) => (displayName === COLUMNS_VISIBILITY_MENU ? 0 : 10),
  },

  headerButton: {
    '&:before': { fontSize: 18 },
  },

  activeButton: {
    color: theme.palette.primary.main,
  },
}));
