// @flow
import { generatePath, useParams } from 'react-router-dom';
import ROUTES_PATH from 'domain/router/routesPathConfig';

const useProfileLink = () => {
  const { companyId } = useParams();
  return companyId ? generatePath(ROUTES_PATH.PROFILE_SETTINGS_MAIN.absolute, { companyId }) : null;
};

export default useProfileLink;
