// @flow
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { processingDocsCountSelector } from 'domain/dashboard/selectors';

import IconLoaderCircle from 'components/Icons/loader-circle';

import useStyles from './sheet';

const ProgressBar: React$StatelessFunctionalComponent = () => {
  const classes = useStyles();
  const count = useSelector(processingDocsCountSelector);

  return (
    count > 0 && (
      <div className={classes.progressBar}>
        <IconLoaderCircle size={21} className={classes.icon} />{' '}
        <FormattedMessage
          id="company.supplier.workspace.progressBar.title"
          defaultMessage={`Analyzing documents: ${count}`}
          values={{ count }}
        />
      </div>
    )
  );
};

export default memo(ProgressBar);
