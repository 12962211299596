// @flow
/* eslint-disable camelcase */
import { Record, List, Map, type RecordFactory } from 'immutable';

// types
import type {
  Approval,
  RawApproval,
  ApprovalRecordList,
  Approvals,
  RawApprovals,
  ApprovalsRecord,
  UserEnhancedApproval,
  ApprovalReason,
  ApprovalReasonRecordMap,
  FormField,
  Option,
  TApprovalStamp,
  ApprovalGroupsType,
  PrevState,
} from './types.js.flow';

// factories
export const ApprovalItemFactory: RecordFactory<Approval> = new Record({
  userGuid: '',
  email: '',
  status: 'draft',
  picture: '', // default logo should be here
  id: null,
});

export type TagRecord = RecordFactory<{|
  tag: string,
  userGuid: string,
  status?: string,
  picture?: string,
|}>;

export const TagFactory: TagRecord = new Record({
  userGuid: '',
  tag: '',
  status: '',
  picture: '',
});

export const UserEnhancedApprovalFactory: RecordFactory<UserEnhancedApproval> = new Record({
  userGuid: '',
  tag: '',
  status: '',
  picture: '',
  fullName: '',
  id: null,
});

export const ApprovalStampFactory: RecordFactory<TApprovalStamp> = new Record({
  ll: null, // low left coordinates
  ur: null, // up right coordinates
  rotation: null,
});

export const ApprovalsGroupFactory: RecordFactory<ApprovalGroupsType> = new Record({
  group_title: '',
  group_id: '',
  created_at: '',
  approvers: new List(),
  error: '',
});

export const PrevStateFactory: RecordFactory<PrevState> = new Record({ list: new List() });

export const ApprovalsFactory: RecordFactory<Approvals> = new Record({
  list: new List(),
  prevState: PrevStateFactory(),
  author: '',
  editable: false,
  dateCreated: '',
  currentSigner: '',
  signature: '',
  status: 'draft',
  rejectReason: null,
  rejectMessage: null,
  rejectReasonsList: Map(),
  approveFormFieldsList: List(),
  signPosition: ApprovalStampFactory(),
  approvalGroups: new List(),
  rearrangingInProcess: 0,
  groupId: null,
});

export function ApprovalsListAdapter(items: Array<RawApproval> = []): ApprovalRecordList {
  return List(
    items.map(({ username, status, picture, userid, id }) =>
      ApprovalItemFactory({
        userGuid: userid,
        email: username,
        status,
        picture,
        id,
      }),
    ),
  );
}

export const ApprovalsGroupAdapter = (group: ApprovalGroupsType) =>
  ApprovalsGroupFactory({ ...group, approvers: new List(group.approvers) });

export const ApprovalGroupsAdapter = (groupsData: Array<ApprovalGroupsType> = []) =>
  new List(groupsData.map(ApprovalsGroupAdapter));

export function ApprovalRejectReasonListAdapter(items: { [key: string]: ApprovalReason }): ApprovalReasonRecordMap {
  return Map(items);
}

export const approvalFieldOptionFactory: RecordFactory<Option> = new Record({
  value: '',
  label: '',
});

export const approvalFieldFactory: RecordFactory<FormField> = new Record({
  group: '',
  label: '',
  name: '',
  type: '',
  value: '',
  mandatory: false,
  readonly: false,
  options: new List(),
  display_value: '',
});

export const approvalFieldOptionAdapter = (value: string, label: string) =>
  approvalFieldOptionFactory({ value, label });

export const approvalFieldsAdapter = (items: Array<FormField> = []) =>
  List(
    items.map((item) =>
      approvalFieldFactory({
        ...item,
        options: List(Object.entries(item.options || {}).map((option) => approvalFieldOptionAdapter(...option))),
      }),
    ),
  );

export const ApprovalStampAdapter = (stamp: TApprovalStamp) => ApprovalStampFactory(stamp);

export function ApprovalsStoreAdapter(
  // $FlowFixMe ..rest is ok
  { nodes, updated, next, creator, rejectReasons, sign_position, group_id, ...rest }: RawApprovals = {},
): ApprovalsRecord {
  return ApprovalsFactory({
    ...rest,
    dateCreated: updated,
    currentSigner: next,
    author: creator,
    list: ApprovalsListAdapter(nodes || []),
    rejectReasonsList: ApprovalRejectReasonListAdapter(rejectReasons || {}),
    approveFormFieldsList: approvalFieldsAdapter([]),
    signPosition: ApprovalStampAdapter(sign_position),
    groupId: group_id,
  });
}
