// @flow
import React from 'react';

// styles
import { styled } from '@mui/material/styles';
import { SET_FILTER_HOVER_CLASSNAME } from '../../helpers';

const Wrapper = styled('div')(() => ({ maxWidth: 165, overflow: 'hidden', textOverflow: 'ellipsis' }));
const ContainerForTooltip = styled('div')(() => ({ position: 'absolute', inset: 0 }));

type Props = {|
  value: (string | number)[],
  valueFormatted: ?string | number,
  agGridReact: any,
|};

const FilterSetRender = ({ valueFormatted, value, agGridReact }: Props) => {
  const [className, setClassName] = React.useState('');

  const handleEnter = () => {
    setClassName(SET_FILTER_HOVER_CLASSNAME);
  };
  const handleLeave = () => {
    setClassName('');
  };

  return (
    <>
      <ContainerForTooltip onMouseEnter={handleEnter} onMouseLeave={handleLeave} />
      <Wrapper className={className}>{valueFormatted || value || agGridReact.props.getLocaleText('blanks')}</Wrapper>
    </>
  );
};
export default FilterSetRender;
