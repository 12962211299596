// @flow
import React from 'react';
import get from 'lodash/get';
import { SYMBOL_FOR_EMPTY_CELL } from 'pages/company/grid/helpers';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Rest from '../RestCount';

const [SIZE, SPACING] = ['small', 0.5];

const Wrapper = React.forwardRef((props, ref) => <Stack direction="row" flexWrap="nowrap" {...props} ref={ref} />);

interface IProps {
  tags: any[];
}

interface IState {
  hiddenItems: string[];
}

class AgGridTags extends React.Component<IProps, IState> {
  state = {
    hiddenItems: [],
  };

  componentDidMount() {
    setTimeout(this.initObservers, 0);
  }

  getVisibleKeys = () => this.props.tags.map((props) => props.tag.tag).filter((tag) => !this.isHiddenKey(tag));

  getTagsStat = () => {
    const { tags } = this.props;
    const visibleTags = this.getVisibleKeys();
    const count = tags.length;
    const countVisible = visibleTags.length;

    const restCount = count - countVisible;
    const lastKey = count ? tags[count - 1] : null;
    const lastVisibleKey = countVisible && countVisible !== count ? visibleTags[countVisible - 1] : null;

    return { count, countVisible, lastVisibleKey, restCount, lastKey };
  };

  setItemRef = (key: string) => (ref) => {
    this.elements[key] = ref;
  };

  setBoxEl = (el: Element) => {
    this.boxEl = el;
  };

  initObservers = () => {
    const observerOptions = { root: this.boxEl, rootMargin: '0px', threshold: 1 };
    Object.entries(this.elements).forEach(([key, el]) => {
      if (get(el, 'nodeName')) {
        const observer = new IntersectionObserver(this.handleObserver(key), observerOptions);
        observer.observe(el);
        this.observers[key] = observer;
      }
    });
  };

  isHiddenKey = (key) => this.state.hiddenItems.includes(key);

  showItem = (key: string) => {
    if (this.isHiddenKey(key)) {
      this.setState({ hiddenItems: this.state.hiddenItems.filter((item) => item !== key) });
    }
  };

  hideItem = (key: string) => {
    if (!this.isHiddenKey(key)) {
      this.setState({ hiddenItems: [...this.state.hiddenItems, key] });
    }
  };

  handleObserver =
    (key: string) =>
    ([intersectionObject]) => {
      const action = intersectionObject.intersectionRatio === 1 ? this.showItem : this.hideItem;
      action(key);
    };

  elements: { [key: string]: any } = {};

  observers: { [key: string]: IntersectionObserver } = {};

  boxEl: Element = null;

  render() {
    const { tags } = this.props;
    const { lastVisibleKey, countVisible, restCount } = this.getTagsStat();

    return tags.length > 0 ? (
      <Wrapper maxWidth="100%" spacing={SPACING} ref={this.setBoxEl} sx={{ px: 0.5 }}>
        {!countVisible && Boolean(restCount) && <Rest size={SIZE} label={restCount} />}
        {tags.map(({ tag, picture, username }) => {
          const props = {
            label: username || tag.tag,
            avatar: username ? <Avatar src={picture} alt={username} /> : undefined,
          };

          return (
            <Wrapper spacing={SPACING} key={tag.tag} ref={this.setItemRef(String(tag.tag))}>
              <Chip
                sx={{ backgroundColor: 'common.white', opacity: this.isHiddenKey(tag.tag) ? 0 : 1 }}
                {...props}
                size={SIZE}
                color="primary"
                variant="outlined"
              />
              {lastVisibleKey === tag.tag && <Rest size={SIZE} label={restCount} />}
            </Wrapper>
          );
        })}
      </Wrapper>
    ) : (
      <div>{SYMBOL_FOR_EMPTY_CELL}</div>
    );
  }
}

export default AgGridTags;
