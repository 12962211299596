/* @flow */
import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Map } from 'immutable';

// types
import type { Dispatch } from 'redux';

// components
import toast from 'components/Toast';
import ERPConnectForm, { type Fields } from '../ERPConnectForm';
import HashSelectDevice from '../HashSelectDevice';
import ERPLogo from 'components/ERPLogo';
import ERPConnectMultiStep from '../ERPConnectMultiStep';
import CircularProgressWithBackdrop from 'components/mui/CircularProgressWithBackdrop';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';

// api
import Api from 'domain/api';

// helpers
import { get } from 'lodash';
import { ERP_NAMES } from 'domain/companies/helpers';

// selectors
import { tokenSelector } from 'domain/documents/documentSelector';
import { organizationIsPaidSelector } from 'domain/organization';

// actions
import { setErpsAction } from 'domain/settings/settingsActions';

// white-labeling
import type { TLabelOptions } from 'labels/type.js.flow';

// styles
import { withStyles } from '@mui/styles';
import sheet from './sheet';
import { FormattedMessage } from 'react-intl';

type Props = {
  classes: {
    [key: string]: string,
  },
  dokkaToken: string,
  handleConnectModal: () => void,
  setErps: Dispatch<setErpsAction>,
  fields: $ReadOnlyArray<Fields>,
  erp: 'SAGE' | 'PRIORITY_PRO' | 'HASH' | 'SAP' | 'NETSUITE',
  theme: { labelOptions: TLabelOptions },
  isPaidOrg: boolean,
  api: Promise,
  apiPayloadSerializer: (formData: Map<string, string>, dokkaToken: string) => Object,
  onSuccessToastMessageProps: ?{ id: string, defaultMessage: string },
  isSingleStep: boolean,
  section: {|
    element: (props: any) => React.Element<*>,
    fields: $ReadOnlyArray<Fields>,
    onSuccessToastMessageProps: ?{ id: string, defaultMessage: string },
  |},
};

export type TOption = {
  value: string | number,
  label: string,
};

type Companies = Array<TOption>;
export type TPriorityVersionOptions = Companies;

export type State = {
  errorMessage: null | false | string,
  companies: ?Companies,
  erpToken: ?string,
  isLoading: boolean,
  selectedCompany: ?TOption,
  isSelectedOther: ?boolean,
  selectedOtherCompanies: Array<string>,
  hashDevices: ?Array<HashDevice>,
  priorityVersionOptions: TPriorityVersionOptions,
  priorityVersion: null | TOption,
  stationId: ?string,
  token: ?string,
};

// helpers
const companySerializer = (companies) => companies.map(({ ID, Name }) => ({ value: ID, label: Name }));

const getSelectedCompany = (companies: Companies, companyId: string | null): TOption | null =>
  companies.reduce((res, company) => (res || company.value !== companyId ? res : company), null);

const endpointsForSave = {
  SAGE_SA: Api.saveSageCompany,
  SAGE: Api.saveSageCompany,
  PRIORITY_PRO: Api.savePriorityCompany,
  HASH: Api.saveHashCompanyId,
};

class ERPConnectModal extends React.Component<Props, State> {
  static defaultProps = {
    erp: 'SAGE',
    apiPayloadSerializer: () => ({}),
  };

  state = {
    errorMessage: null,
    companies: null,
    erpToken: null,
    isLoading: false,
    selectedCompany: null,
    isSelectedOther: false,
    selectedOtherCompanies: [],
    priorityVersionOptions: [],
    priorityVersion: null,
    token: null,
    stationId: null,
  };

  onSign = (data) => {
    const {
      dokkaToken,
      handleConnectModal,
      setErps,
      api,
      apiPayloadSerializer,
      isSingleStep,
      onSuccessToastMessageProps,
      section,
    } = this.props;

    this.setState({ isLoading: true, errorMessage: '' });

    const payload = apiPayloadSerializer(data, dokkaToken);
    const promise = api(payload);

    if (isSingleStep) {
      promise.then((res: { data: any }) => {
        handleConnectModal();
        setErps(res.data);
        if (onSuccessToastMessageProps) {
          toast.success(<FormattedMessage {...onSuccessToastMessageProps} />);
        }
        if (section && section.onSuccessToastMessageProps) {
          const ifAllSectionFieldsPassed = section.fields.every((el) => data.get(el.name));

          if (ifAllSectionFieldsPassed) {
            toast.success(<FormattedMessage {...section.onSuccessToastMessageProps} />);
          }
        }
      });
    } else {
      promise.then(({ data: { error, companies, selected, message, erpToken, versions } }) => {
        if (error) {
          this.setState({ errorMessage: message });
        } else {
          const serializedCompanies = companySerializer(companies);
          this.setState({
            companies: serializedCompanies,
            erpToken,
            selectedCompany: getSelectedCompany(serializedCompanies, selected),
            priorityVersionOptions: versions
              ? versions.map((value) => ({
                  label: value,
                  value,
                }))
              : [],
          });
        }
      });
    }

    promise.catch((error) => {
      const errorMessage = get(error, 'response.data.message', 'Server error');
      this.setState({ errorMessage });
    });

    promise.finally(() => {
      this.setState({ isLoading: false });
    });
  };

  onChangePriorityVersion = (value) => {
    this.setState({ priorityVersion: value, errorMessage: '' });
  };

  onCompanyChange = (value) => {
    this.setState({ selectedCompany: value, errorMessage: '', selectedOtherCompanies: [] });
  };

  onTokenChange = (value) => {
    this.setState({ token: value });
  };

  onStationIdChange = (value) => {
    this.setState({ stationId: value });
  };

  onOtherCompanyChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const value = e.currentTarget.checked;
    this.setState({ isSelectedOther: value });
  };

  onCompanySave = () => {
    const {
      dokkaToken,
      setErps,
      handleConnectModal,
      theme: { labelOptions },
      erp,
      onSuccessToastMessageProps,
    } = this.props;
    const { erpToken, isSelectedOther, selectedCompany, priorityVersion, token, stationId } = this.state;
    const extraCompanies = isSelectedOther ? this.getSelectedOtherCompanies() : [];

    this.setState({ isLoading: true });

    const data = {
      dokkaToken,
      erpToken,
      companyId: `${selectedCompany.value}`,
      extraCompanies,
      ...(this.isHash() ? { token, stationId } : {}),
      ...(priorityVersion ? { version: priorityVersion.value } : {}),
    };

    endpointsForSave[erp]({ data })
      .then((response) => {
        // $FlowFixMe
        setErps(response.data);
        handleConnectModal();

        if (isSelectedOther && onSuccessToastMessageProps) {
          toast.success(<FormattedMessage values={{ label: labelOptions.name }} {...onSuccessToastMessageProps} />);
        }
      })
      .catch((error) => {
        const errorHandlers = {
          SAGE: () => get(error, 'response.data'),
        };
        const defaultHandler = () => get(error, 'response.data.message');
        const errorHandler = errorHandlers[erp] || defaultHandler;
        const errorMessage = errorHandler() || 'Server error';

        this.setState({ errorMessage, isLoading: false });
      });
  };

  getOptions = () => {
    const { companies } = this.state;
    return companies ? companies.sort((i1, i2) => i1.label.localeCompare(i2.label)) : [];
  };

  getHashDevicesOptions = () => {
    const { hashDevices } = this.state;
    return hashDevices ? hashDevices.map((d) => ({ label: d.displayGuid, value: d.deviceId })) : [];
  };

  getAuthFields = () => {
    const { fields } = this.props;
    return this.isHash()
      ? fields.map((f) => (f.name === 'device' ? { ...f, options: this.getHashDevicesOptions() } : f))
      : fields;
  };

  getOtherCompanies = () => {
    const { selectedCompany } = this.state;
    return selectedCompany
      ? this.getOptions().filter((option) => option.value !== selectedCompany.value)
      : this.getOptions();
  };

  getSelectedOtherCompanies = () => {
    const { selectedCompany, selectedOtherCompanies } = this.state;
    return selectedOtherCompanies.filter((compId: string) => selectedCompany !== compId);
  };

  getTitle = (isCompaniesFetched: boolean) => {
    const {
      theme: { labelOptions },
    } = this.props;

    const [signTitleId, signTitleMsg] = this.isHash()
      ? ['hashConnect.title', 'Select {labelName} Erp Agent']
      : ['sageConnect.signIn', 'Sign In'];

    const [id, defaultMessage] = isCompaniesFetched
      ? ['sageConnect.chooseCompany', 'Select company']
      : [signTitleId, signTitleMsg];

    return { id, defaultMessage, values: { labelName: labelOptions.name } };
  };

  setSelectedOtherCompanies = (selectedOtherCompanies: Array<string>) => {
    this.setState({ selectedOtherCompanies });
  };

  toggleAllSelection = () => {
    const selectedOtherCompanies = this.getSelectedOtherCompanies();
    const otherCompanies = this.getOtherCompanies();
    const value = selectedOtherCompanies.length ? [] : otherCompanies.map((i) => i.value);
    this.setState({ selectedOtherCompanies: value });
  };

  isPriority = () => this.props.erp === ERP_NAMES.priorityPro;

  isHash = () => this.props.erp === ERP_NAMES.hash;

  renderConnectForm = () => {
    const { handleConnectModal, section } = this.props;
    const { isLoading } = this.state;
    let initialValues;
    if (!this.isHash()) {
      const hasSslField = this.getAuthFields().some(({ name }) => name === 'ssl');
      initialValues = hasSslField ? { ssl: true } : {};
    }

    return this.isHash() ? (
      <HashSelectDevice onSelectDevice={this.onSign} handleConnectModal={handleConnectModal} />
    ) : (
      <ERPConnectForm
        isLoading={isLoading}
        handleConnectModal={handleConnectModal}
        onSubmit={this.onSign}
        fields={this.getAuthFields()}
        section={section}
        initialValues={initialValues}
      />
    );
  };

  render() {
    const { isLoading, companies, errorMessage, isSelectedOther, priorityVersion, priorityVersionOptions } = this.state;
    const {
      theme: { labelOptions },
      isPaidOrg,
      erp,
      handleConnectModal,
    } = this.props;
    const companiesFetched = Array.isArray(companies);

    return (
      <>
        <CircularProgressWithBackdrop isOpen={isLoading} BackdropProps={{ sx: { position: 'absolute' } }} />
        <DialogTitle component={Stack} alignItems="center">
          <Stack alignItems="center" justifyContent="center" spacing={2}>
            <ERPLogo erpType={erp} />
            <FormattedMessage {...this.getTitle(companiesFetched)}>
              {(text: string) => <Typography variant="h6">{text}</Typography>}
            </FormattedMessage>
          </Stack>
          {Boolean(errorMessage) && <FormHelperText error={!!errorMessage}>{errorMessage}</FormHelperText>}
        </DialogTitle>
        {companiesFetched ? (
          <ERPConnectMultiStep
            {...this.state}
            onCompanyChange={this.onCompanyChange}
            onStationIdChange={this.onStationIdChange}
            onTokenChange={this.onTokenChange}
            onOtherCompanyChange={this.onOtherCompanyChange}
            setSelectedOtherCompanies={this.setSelectedOtherCompanies}
            toggleAllSelection={this.toggleAllSelection}
            onCompanySave={this.onCompanySave}
            isPaidOrg={isPaidOrg}
            labelOptions={labelOptions}
            isSelectedOther={isSelectedOther}
            erp={erp}
            priorityVersion={priorityVersion}
            priorityVersionOptions={priorityVersionOptions}
            onChangePriorityVersion={this.onChangePriorityVersion}
            handleConnectModal={handleConnectModal}
          />
        ) : (
          this.renderConnectForm()
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dokkaToken: tokenSelector(state),
  isPaidOrg: organizationIsPaidSelector(state),
});

export default compose(
  withStyles(sheet, { withTheme: true }),
  connect(mapStateToProps, { setErps: setErpsAction }),
)(ERPConnectModal);
