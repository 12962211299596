// @flow
import React from 'react';

import { AuthAsideBase } from 'pages/authPage/Layout/StyledComponents';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { styled, useTheme } from '@mui/material/styles';

import bg from 'pages/authPage/Layout/AuthAside/Bdo/images/bg.svg';

const AuthAside = styled(AuthAsideBase)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  backgroundImage: `url("${bg}")`,
  backgroundPosition: 'bottom right',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  width: '40%',
}));

const AuthAsideBdo = () => {
  const {
    labelOptions: { loginNameImg, loginPoweredBy, loginSlogan },
  } = useTheme();

  return (
    <AuthAside>
      <Box m="auto" width="300px">
        <img src={loginNameImg.src} alt={loginNameImg.alt} width="100%" />
        {loginSlogan && (
          <Typography mt={1} color="common.white" sx={{ textTransform: 'uppercase', textAlign: 'center' }}>
            {loginSlogan}
          </Typography>
        )}
      </Box>
      {loginPoweredBy && (
        <Box mx="auto" mb={1}>
          <Link href="https://www.dokka.com" target="blank" sx={{ color: 'common.white' }}>
            Powered by DOKKA
          </Link>
        </Box>
      )}
    </AuthAside>
  );
};

export default AuthAsideBdo;
