// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { textractLineItemsTablelLastColumnMatchedLinesSelector } from 'domain/textract/selectors';
import { jeMatchedLinesSelector } from 'domain/journal';
import { Map } from 'immutable';
import { MatchingColorsMap, MatchinArrowsAnchors, TEXTRACT_MATCH_STATUSES } from 'components/TextractCanvas/utils';
import { ArrowTrigger } from './arrowTrigger';
import { rtlEnable } from 'domain/env';

type Props = {|
  width: number,
  height: number,
  scale: number,
  page: number,
  wrapper: ?HTMLElement,
|};

export const TextractArrows: React$StatelessFunctionalComponent<Props> = ({
  width,
  height,
  scale,
  page,
  wrapper,
}: Props) => {
  const bindings = useSelector(textractLineItemsTablelLastColumnMatchedLinesSelector);
  const fullLinesMapping = useSelector(jeMatchedLinesSelector);
  const rtl = useSelector(rtlEnable);

  return (
    <>
      {bindings &&
        Object.entries(bindings).map(([key, polygons]) =>
          polygons
            .filter((polygon) => polygon?.page === page)
            .map((polygon, polygonIndex) => {
              // if case there is pointer to unexisting line
              if (!polygon) return null;

              const relations = fullLinesMapping
                .filter(
                  (jeLine) => {
                    return jeLine.get(0, new Map()).get('line') === parseInt(polygon.row)},
                )
                .flatMap((match, JELineIndex) => {
                  return {
                    relation: {
                      targetId: MatchinArrowsAnchors.journalEntryElementIdFactory(JELineIndex),
                      targetAnchor: !rtl ? 'left' : 'right',
                      sourceAnchor: !rtl ? 'right' : 'left',
                      style: {
                        strokeColor: 'transparent',
                        strokeWidth: 1,
                      },
                    },
                    color: MatchingColorsMap[match.get(0).get('status', TEXTRACT_MATCH_STATUSES.DEFAULT)],
                  };
                })
                .flatten()
                .toJS();

              const points = polygon.Geometry.Polygon;
              return Object.keys(relations).length > 0 ? (
                <>
                  <ArrowTrigger
                    polygon={polygon}
                    polygonIndex={polygonIndex}
                    relations={relations}
                    width={width}
                    height={height}
                    points={points}
                    scale={scale}
                    wrapper={wrapper}
                  ></ArrowTrigger>
                </>
              ) : null;
            }),
        )}
    </>
  );
};
