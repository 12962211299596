/* @flow */
import { type RecordOf } from 'immutable';
import type { TDocumentInsightsRedux } from 'domain/insights/types.js.flow';

import * as A from './actions';
import { DocumentInsightsReduxFactory } from 'domain/insights/helpers';
import { signOutAction } from 'domain/env/envActions';

export const reducer = {
  insights(state: RecordOf<TDocumentInsightsRedux> = DocumentInsightsReduxFactory(), { type, payload }: any) {
    switch (type) {
      case A.getDocumentInsightsAction.success:
        return state.set('gridHeaders', payload.gridHeaders).set('gridRows', payload.gridRows);

      case A.getDocumentInsightsVendorAction.success:
        return state.set('vendor', payload);

      case A.resetDocumentInsightsAction.type:
      case signOutAction.success:
        return DocumentInsightsReduxFactory();

      default:
        return state;
    }
  },
};
