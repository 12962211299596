// @flow
import React from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { setSearchFilterAction } from 'domain/companies';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

import bg from './images/bg.png';

const NotFound = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const onClick = () => {
    dispatch(setSearchFilterAction(''));
  };
  return (
    <>
      <Divider sx={{ my: 1, mb: 5 }} />
      <Box textAlign="center" mx="auto">
        <img src={bg} alt="not-found" />
        <Typography variant="h5" mt={2}>
          {formatMessage({
            id: 'companies.notFound.title',
            defaultMessage: 'No companies has been found',
          })}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {formatMessage({
            id: 'companies.notFound.body',
            defaultMessage: 'We can’t find any companies matching your search. Please, try a different criteria.',
          })}
        </Typography>
        <Button sx={{ marginTop: 3 }} onClick={onClick}>
          {formatMessage({
            id: 'companies.notFound.btn',
            defaultMessage: 'Reset search',
          })}
        </Button>
      </Box>
    </>
  );
};

export default NotFound;
