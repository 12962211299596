// @flow
import React, { useCallback } from 'react';
import useCardThumbnailPath from 'pages/company/TileGrid/TileCard/hooks/useCardThumbnailPath';
import { type DocumentsType } from 'domain/documents';

import { DOCUMENT } from 'components/DnDItem';
import Box from '@mui/material/Box';

type TDragTileCard = {
  children: React$Node,
  DragSource: React$Node,
  document: DocumentsType,
  srcType: string,
  setIdDraggedDocument?: (string | null) => void,
  isMultiplyDND?: boolean,
  readOnly?: boolean,
};

const DragTileCard: React$StatelessFunctionalComponent<TDragTileCard> = ({
  children,
  DragSource,
  document,
  srcType,
  setIdDraggedDocument,
  onBeginDrag,
  isMultiplyDND = false,
  readOnly = false,
}) => {
  const src = useCardThumbnailPath({ documentID: document.documentID });

  const getLinkedParams = useCallback((linkid) => (srcType === DOCUMENT ? { linkedID: [linkid] } : { linkid }), []);

  const handleBeginDrag = useCallback(() => {
    if (setIdDraggedDocument) setIdDraggedDocument(document.documentID);
    const { linkedID } = document.linkid ? getLinkedParams(document.linkid) : {};

    if (linkedID && onBeginDrag) {
      onBeginDrag(document.linkid);
    }
  }, [document.linkid, document.documentID, getLinkedParams, onBeginDrag, setIdDraggedDocument]);

  const handleEndDrag = () => {};

  // TODO: refactoring DND params(what is it for?) - data for move to category request?
  const getDndSourceParams = useCallback(() => {
    const { documentID, linkid } = document;
    const linkedData = linkid ? getLinkedParams(linkid) : {};
    const docIds = !linkedData.linkedID ? { documentID: [documentID] } : {};
    return { ...docIds, ...linkedData };
  }, [document, getLinkedParams]);

  return (
    <Box
      component={DragSource}
      id={document.documentID}
      params={getDndSourceParams()}
      onBeginDrag={handleBeginDrag}
      onEndDrag={handleEndDrag}
      thumbnail={src} // DND thumbnail
      isMultiplyDND={isMultiplyDND}
      sx={{ position: 'relative' }} // for dnd thumbnail
      isMiniThumbnail
      isDragging
      linkID={document.linkid}
      readOnly={readOnly}
    >
      {children}
    </Box>
  );
};

export default React.memo(DragTileCard);
