// @flow
/* eslint-disable max-len */
/* eslint no-mixed-operators: ["error", {"allowSamePrecedence": true}] */
import { selector } from 'lib/selectors';
import { Set, List, Map } from 'immutable';
import { compose } from 'redux';
import { getRowByNumber, jeSorterCb, type JournalStore, type JournalSettings, type JornalEntryType } from './helper';
import type { DokkaStore } from '../types.js.flow';
import type { JournalEntryType } from 'domain/contracts';
import {
  sideEffectFilterColumnCellsFactory,
  sideEffectMakeCellsReadOnly,
  sideEffectRemoveCellStyle,
  selectFirst3Row,
  linesReordering,
  excludeLines,
} from 'domain/reconciliation/helper';

import { denormalize } from './normalizer';
import type { ReconciliationStore } from 'domain/reconciliation/types.js.flow';

type SelectorType<T> = (s: DokkaStore) => T;

// this can not be imported from reconciliation
const reconciliation = (state): ReconciliationStore => state.reconciliation;
export const reconcileRequestRowSelector: SelectorType<number | boolean> = selector(
  reconciliation,
  (j: ReconciliationStore) => j.requestRow,
);
export const reconcileRequestRowLineIdSelector: SelectorType<?string> = selector(reconcileRequestRowSelector, (row) =>
  row.get('id'),
);
export const reconcileRequestRowLineNumberSelector: SelectorType<?number> = selector(
  reconcileRequestRowSelector,
  (row) => row.get('row'),
);

export const journal = <T: JournalStore>(state: { journal: T }): T => state.journal;
export const journalSelector: SelectorType<JournalEntryType> = selector(journal, (j: JournalStore) =>
  denormalize(j).entry.sort(jeSorterCb),
);
export const jeNameSelector = selector(journal, (j) => j.jeName);
export const jeTypeSelector = selector(journal, (j) => j.jeType);
export const jeInvoiceTypeSelector = selector(journal, (j) => j.jeInvoiceType);
export const isJeLayoutRTLSelector = selector(journal, (j) => j.jeLayout === 'rtl');
export const isStatementSelector = selector(jeTypeSelector, (t) => ['bank_statement', 'reconcile'].includes(t));
const textMap = (state) => state.mapText;
export const polygonsSelector = selector(textMap, (t) => t.polygons);
export const extraPolygonSelector = selector(textMap, (t) => t.extraPolygon);
export const pagesSelector = selector(textMap, (t) => t.pages);
export const referenceSelector = selector(textMap, (t) => t.reference);
export const activeReferenceSelector = selector(referenceSelector, (r) => r.find((v) => v.activity));
export const headerLinesNumberSelector: SelectorType<number> = selector(
  journal,
  (j: JournalStore) => j.headerLinesNumber,
);
export const lineItemsSelector: SelectorType<Set<number>> = selector(journal, (j: JournalStore) => j.line_items);
// selects current line_number with offset provided by headers
export const LineItemWithOffsetSelector = (row: number): SelectorType<number> =>
  selector(headerLinesNumberSelector, (headerHeight) => row - headerHeight);

export const settingsSelector: SelectorType<Set<number>> = selector(journal, (j: JournalStore) => j.settings);
// @to-do for empty lines it should return 6. Max (Settings) + 2 is first line number
// as line_items may be not fullyy loaded after je update
export const firstLineItemSelector: SelectorType<number> = selector(
  headerLinesNumberSelector,
  (headerHeight: number) => headerHeight + 1,
);
export const headerLineSelector: SelectorType<number> = selector(
  firstLineItemSelector,
  (lineNumber: number) => lineNumber - 1,
);
export const settingsEntriesSelector: SelectorType<JournalEntryType> = selector(
  journalSelector,
  settingsSelector,
  getRowByNumber,
);
export const linesSelector: SelectorType<Set<number>> = selector(journal, (j: JournalStore) => j.lines);
export const initialHiddenColumnsSelector = selector(journal, (j) => j.initial_hidden_columns);

export const gridEntriesSelector = selector(journalSelector, linesSelector, getRowByNumber);

const summarySelector = selector(journal, (j) => j.summary);
export const summaryEntriesSelector = selector(journalSelector, summarySelector, getRowByNumber);
export const journalWarningsSelector = selector(journal, (j) => j.messages);
export const journalPublicationWarningsSelector = selector(journal, (j) => j.publicationWarnings);

export const errorFromErpSelector = selector(journal, (j) => j.errorFromErp);
export const lineCountSelector = selector(journal, (j) => j.lineCount);
export const selectedRowSelector = selector(journal, (j) => j.selectedRow);
export const reconcileColumnSelector = selector(journal, (j) => j.reconcileColumn);
export const pinnedColumnSelector = selector(journal, (j) => j.pinnedColumn);
export const notHiddenColumnsSelector = selector(journal, (j) => j.notHiddenColumns);
export const amountColumnSelector = selector(journal, (j) => j.amountColumn);
export const dateColumnSelector = selector(journal, (j) => j.dateColumn);
export const descriptionColumnSelector = selector(journal, (j) => j.descriptionColumn);
export const rowMessagesSelector = selector(journal, (j) => j.rowMessages);
export const lineItemsDataSelector = selector(journal, (j) => j.lineItemsData);
export const jeGridLineColumnsDictionarySelector = selector(journal, (j) => j.jeGridLineColumnsDictionary);

const paginationSelector = selector(journal, (j) => j.paginationList);
export const paginationListSelector = selector(paginationSelector, (p) => p.list);
export const paginationListPageTokenSelector = selector(paginationSelector, (p) => p.pageToken);
const cachedPaginationListsSelector = selector(paginationSelector, (p) => p.cachedList);
export const getCachedPaginationListSelector = selector(cachedPaginationListsSelector, (cl) => (id) => cl.get(id));

// data for transaction request
const lineGroupSelector = selector(journalSelector, (j) => j.groupBy((g) => g._row));

export const clearSelectedRowsWithoutSelector = selector(
  selectedRowSelector,
  lineGroupSelector,
  reconcileColumnSelector,
  (rows, gr, col) => excludeLines(gr, rows, col, ['Ignored', 'Reconciled']),
);

export const selectedLinesEntriesSelector: SelectorType<List<JournalEntryType>> = selector(
  lineGroupSelector,
  clearSelectedRowsWithoutSelector,
  headerLineSelector,
  (gr, rows, header) => linesReordering(gr, selectFirst3Row(rows).unshift(header)),
);
export const singleLineEntriesSelector: SelectorType<List<JournalEntryType>> = selector(
  lineGroupSelector,
  reconcileRequestRowLineNumberSelector, // single row user clicked request icon for
  headerLineSelector,
  (list, lineNumber, header) => (lineNumber ? linesReordering(list, List([header, lineNumber])) : Map()),
);
export const selectedLineIdsSelector: SelectorType<List<string>> = selector(
  clearSelectedRowsWithoutSelector,
  reconcileRequestRowLineNumberSelector,
  lineItemsDataSelector,
  (rows: Set<number>, singleRow: ?number, linesData: Map<number, {| line_id: string, line_status: string |}>) =>
    linesData
      // singleRow indicates we selected single line, so ignore selection if present
      // and filter single line data only
      .filter((V, K) => (singleRow ? singleRow === parseInt(K, 10) : rows.has(parseInt(K, 10))))
      .reduce((A, V) => A.push(V.line_id), new List()),
);
export const headerLineEntriesSelector: SelectorType<JournalEntryType> = selector(
  journalSelector,
  headerLineSelector,
  (entry, line) => getRowByNumber(entry, Set([line])),
);
export const selectedLinesWithHeaderAsListSelector: SelectorType<JornalEntryType> = selector(
  selectedLinesEntriesSelector,
  singleLineEntriesSelector,
  headerLineEntriesSelector,
  (linesEntriesList: JornalEntryType, singleLineEntriesList: JornalEntryType) =>
    // eslint-disable-line arrow-body-style
    // if singleLineEntriesList is not empty, we have request line icon clicked, so use single line data
    singleLineEntriesList.size > 0 ? singleLineEntriesList : linesEntriesList,
);

export const selectedLinesFlatSelector: SelectorType<JournalEntryType> = selector(
  selectedLinesWithHeaderAsListSelector,
  reconcileColumnSelector,
  (list: JornalEntryType, reconcileColumn: string) =>
    compose(
      sideEffectRemoveCellStyle, // removes header styles also
      sideEffectMakeCellsReadOnly,
      sideEffectFilterColumnCellsFactory(reconcileColumn),
      // sideEffectFlatternJeList,
    )(list),
);

const journalPaginationListsSelector = selector(journalSelector, (j) => j.filter((e) => e.type === 'paginated_list'));

export const cachedPaginationListsIncludeIdsSelector = selector(cachedPaginationListsSelector, (cl) =>
  cl.reduce((a, v) => [...a, v.include_id], []),
);

export const journalNotCachedPaginationListsSelector = selector(
  journalPaginationListsSelector,
  cachedPaginationListsIncludeIdsSelector,
  (entries, cachedIds) => entries.filter((e) => e.include_id && !cachedIds.includes(e.include_id)),
);

export const journalSettings = <T: JournalSettings>(state: { journal: T }): T => state.journalSettings;
export const journalSettingsPinnedColsSelector = selector(journalSettings, (j) => j.pinnedCols);
export const journalSettingsDisabledColsSelector = selector(journalSettings, (j) => j.disabledCols);
export const journalSettingsOrderedColsSelector = selector(journalSettings, (j) => j.orderedCols);

export const jeMatchedLinesSelector = selector(journal, (j) => j.matchedLines);

export const jeMatchedLineNumbersSelector = selector(jeMatchedLinesSelector, (lines) => {
  const res = lines
    .reduce(
      (acc, matchedLines, lineItemIndex) =>
        acc.set(
          lineItemIndex,
          matchedLines.map((line) => line.get('line', null)),
        ),
      new Map(),
    )
    .filter((list) => list && list.size > 0);
  return res;
});

export const isJELoadingSelector = selector(journal, (j) => j.loading);

export const textractForVendorEnabledSelector = selector(journal, (j) => j.textractForVendorEnabled);
