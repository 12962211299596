import arrow from './images/arrow.svg';
import home from './images/home.svg';
import whatsappIcon from './images/whatsappIcon.svg';
import correspondence from './images/correspondence.svg';

export default (theme) => ({
  left: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20,
  },
  withHome: {
    height: 64,
    paddingLeft: 0,
  },
  logo: {
    maxHeight: 40,
    maxWidth: 40,
    minWidth: 40,
    borderRadius: 2,
    width: 'auto',
  },
  home: {
    width: 64,
    height: 64,
    marginRight: 20,
    background: theme.colors.primaryLight,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  homeIcon: {
    display: 'inline-block',
    background: `url("${home}") scroll no-repeat center / contain`,
    width: 20,
    height: 20,
  },
  back: {
    display: 'inline-block',
    background: `url("${arrow}") scroll no-repeat center / contain`,
    width: 24,
    padding: [14, 0],
    boxSizing: 'content-box',
    height: 12,
    marginRight: 28,
    '.rtl &': {
      transform: 'scaleX(-1)',
    },
  },
  info: {
    color: '#fff',
    marginLeft: 10,
    fontWeight: 'normal',
    lineHeight: 1.1,
    marginTop: 4,
    '& dd': {
      lineHeight: '15px',
    },
  },
  name: {
    fontSize: 16,
    fontWeight: 'bold',
    display: 'flex',
    marginBottom: 4,
  },
  nameWrap: {
    maxWidth: 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    '@media (min-width: 1000px)': {
      maxWidth: 400,
    },
  },
  descriptionWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: -2,
  },

  separate: {
    width: 3,
    height: 3,
    margin: [0, 8],
    borderRadius: '50%',
    backgroundColor: 'white',
  },

  phoneLink: {
    textDecoration: 'none',
  },

  phoneIcon: {
    width: 16,
    height: 16,
    marginRight: 4,
    background: `url("${whatsappIcon}") 50% 50% no-repeat`,
    backgroundSize: 'contain',
  },

  phoneText: {
    flip: false,
    direction: 'ltr',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    maxWidth: 200,
    color: '#fff',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    '@media (min-width: 1000px)': {
      maxWidth: 400,
    },
  },

  infoBox: {
    display: 'flex',
    alignItems: 'center',
  },

  emailIcon: {
    width: 14,
    height: 12,
    marginRight: 6,
    background: `url("${correspondence}") 50% 50% no-repeat`,
    backgroundSize: 'contain',
  },

  email: {
    fontSize: 12,
    color: '#fff',
    maxWidth: 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    '@media (min-width: 1000px)': {
      maxWidth: 400,
    },
    unicodeBidi: 'plaintext',
  },

  tooltip: {
    width: 200,
    fontSize: 14,
  },
});
