import React, { useEffect, useCallback, useMemo, useState } from 'react';
import hash from 'object-hash';
import fetchProgress from 'fetch-progress';
import * as Sentry from '@sentry/browser';

import { navigationSelector } from 'domain/env';
import { saveCachedDocumentAction } from 'domain/documents/documentsActions';
import {
  cachedDocumentSelector,
  currentCompanySelector,
  documentsByIdSelector,
  documentLastModifiedDateSelector,
} from 'domain/documents/documentSelector';
import { cachedDocumentFactory } from 'domain/documents/documentsModel';
import { useSelector, useDispatch } from 'react-redux';
import Api from 'domain/api';

import tokenKeeper from 'lib/apiTokenKeeper';

import { getIsExcel } from '../helpers';

const createAuthHeader = (token: string) => ({ Authorization: `Bearer ${token || ''}` });
export const createHeaders = (token: string) => ({
  headers: new window.Headers({
    ...createAuthHeader(token),
  }),
});

type TProps = {
  currentDocumentId: string,
  signatureStatus: string,
  extraParams?: object,
  isSupplier: boolean,
};

let cache = null;

export const getStore = async () => {
  if (!cache) {
    cache = await caches.open('documents');
  }

  // Safari does not support estimate function
  if ('storage' in navigator && 'estimate' in navigator.storage) {
    const estimate = await navigator.storage.estimate();
    const used = ((estimate.usage / estimate.quota) * 100).toFixed(2);
    if (used > 50) {
      cache.keys().then((keys) => {
        const i = Math.round(keys.length / 2);
        keys.forEach((request, index) => {
          if (index < i) {
            cache.delete(request);
          }
        });
      });
    }
  }
  return cache;
};

export const useGetDocData = (id, isMobile = false) => {
  const list = useSelector(documentsByIdSelector);
  const isApprovalStart = list.getIn([id, 'approvals', 'nodes'])
    ? list.getIn([id, 'approvals', 'nodes'])[0].status === 'approved'
    : false;
  const tags = list.getIn([id, 'tags']) || [];
  const isSign = tags.includes('_S_SIG_DOKKA1_SIGNED') || tags.includes('_S_SIG_DOKKA2_SIGNED') || isApprovalStart;
  return !isSign && (isMobile ? true : !getIsExcel(id));
};

const usePreloadDocuments = ({ currentDocumentId, signatureStatus, extraParams, isSupplier }: TProps) => {
  const dispatch = useDispatch();
  const [isLoadingCurrentDoc, setIsLoadingCurrentDoc] = useState(false);

  const navigation = useSelector(navigationSelector);
  const companyId = useSelector(currentCompanySelector);
  const lastModifiedDate = useSelector(documentLastModifiedDateSelector);

  const nextDocId = currentDocumentId !== navigation.get('nextDocId') ? navigation.get('nextDocId') : '';
  const prevDocId = currentDocumentId !== navigation.get('prevDocId') ? navigation.get('prevDocId') : '';

  const isUnsignedNext = useGetDocData(nextDocId);
  const isUnsignedPrev = useGetDocData(prevDocId);

  const getParams = useCallback(
    ({ documentID, isUnsigned, checkId = false }) => {
      const isNextExcel = checkId && documentID && getIsExcel(documentID);
      const extraOptions = isNextExcel && !isSupplier ? { original: 1, recordEvent: false } : {};

      const urlParams = {
        companyId,
        documentID,
        ...extraOptions,
        ...(checkId && extraParams ? {} : extraParams),
      };

      return isUnsigned || isSupplier || isNextExcel ? { ...urlParams, unsigned: 1 } : urlParams;
    },
    [companyId, extraParams, isSupplier],
  );

  const prevDocUrlParams = useMemo(
    () => getParams({ documentID: prevDocId, isUnsigned: isUnsignedPrev, checkId: true }),
    [getParams, prevDocId, isUnsignedPrev],
  );
  const nextDocUrlParams = useMemo(
    () => getParams({ documentID: nextDocId, isUnsigned: isUnsignedNext, checkId: true }),
    [getParams, nextDocId, isUnsignedNext],
  );

  const currentDocUrlParams = useMemo(
    () =>
      getParams({
        documentID: currentDocumentId,
        isUnsigned: signatureStatus && ['disabled', 'hide'].includes(signatureStatus),
      }),
    [getParams, currentDocumentId, signatureStatus],
  );

  const currentDocHash = hash(currentDocUrlParams);
  const nextDocHash = hash(nextDocUrlParams);
  const prevDocHash = hash(prevDocUrlParams);

  const isDocumentCached = useCallback(async (url) => {
    const cache = await getStore();
    return cache.match(url);
  }, []);

  const currentDocumentProgress = useSelector(cachedDocumentSelector(currentDocHash)).downloadProgress;

  const [currentCachedDocument, setCurrentCachedDocument] = React.useState({ blob: null, hash: '' });
  const isCurrentDocumentCached = !!currentCachedDocument.blob && currentCachedDocument.hash === currentDocHash;

  const downloadDoc = useCallback(
    async ({ documentUrl, docHash, token, lastModifiedDate }) => {
      const cache = await getStore();

      const response = await cache.match(documentUrl);
      if (response) {
        const isOlderCache =
          lastModifiedDate && Date.parse(lastModifiedDate) > Date.parse(response.headers.get('date'));
        const isInvalidCache = isOlderCache || response.status !== 200;
        if (isInvalidCache) {
          cache.delete(documentUrl);
        } else {
          dispatch(
            saveCachedDocumentAction({
              data: cachedDocumentFactory({ hash: docHash, downloadProgress: 100 }),
            }),
          );
          return response;
        }
      }

      const res = await window.fetch(documentUrl, createHeaders(token)).then(
        fetchProgress({
          onProgress({ total, transferred }) {
            if (transferred <= total) {
              dispatch(
                saveCachedDocumentAction({
                  data: cachedDocumentFactory({
                    hash: docHash,
                    downloadProgress: Math.round((transferred / total) * 100),
                  }),
                }),
              );
            } else {
              dispatch(
                saveCachedDocumentAction({
                  data: cachedDocumentFactory({ hash: docHash, downloadProgress: 100 }),
                }),
              );
            }
          },
        }),
      );
      const clonedRes = res.clone();
      const modifiedRes = new Response(clonedRes.body, clonedRes);
      modifiedRes.headers.set('docHash', docHash);
      // if appear error with put may be cache store full -> should remove elements
      try {
        await cache.put(documentUrl, modifiedRes);
      } catch (e) {
        Sentry.captureMessage(e);
      }

      return res;
    },
    [dispatch],
  );

  useEffect(() => {
    const init = async () => {
      const token = await tokenKeeper.getToken();
      if (isCurrentDocumentCached && !isLoadingCurrentDoc) {
        const [nextDocumentUrl, prevDocumentUrl] = [nextDocUrlParams, prevDocUrlParams].map(Api.getDocumentUrl);

        if (nextDocId && token && !(await isDocumentCached(nextDocumentUrl))) {
          downloadDoc({ documentUrl: nextDocumentUrl, token, docHash: nextDocHash });
        }
        if (prevDocId && token && !(await isDocumentCached(prevDocumentUrl))) {
          downloadDoc({ documentUrl: prevDocumentUrl, token, docHash: prevDocHash });
        }
      } else if (token && !isLoadingCurrentDoc) {
        setIsLoadingCurrentDoc(true);
        const documentUrl = Api.getDocumentUrl(currentDocUrlParams);
        downloadDoc({ documentUrl, docHash: currentDocHash, token, lastModifiedDate })
          .then((res) => res.blob())
          .then((file) => {
            setCurrentCachedDocument({ blob: file, hash: currentDocHash });
            setIsLoadingCurrentDoc(false);
          });
      }
    };
    init();
  }, [
    nextDocId,
    prevDocId,
    downloadDoc,
    isCurrentDocumentCached,
    currentDocUrlParams,
    nextDocUrlParams,
    prevDocUrlParams,
    nextDocHash,
    prevDocHash,
    currentDocHash,
    isDocumentCached,
    lastModifiedDate,
    isLoadingCurrentDoc,
  ]);
  return { blob: currentCachedDocument.blob, downloadProgress: currentDocumentProgress };
};

export default usePreloadDocuments;
