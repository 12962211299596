/* @flow */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { List } from 'immutable';
import { textractLineItemsTablelLastColumnMatchedLinesSelector } from 'domain/textract/selectors';
import { jeMatchedLinesSelector } from 'domain/journal/selectors';
import { rtlEnable } from 'domain/env';
import { ArcherElement } from 'react-archer';
import { type TextractBlock } from 'domain/textract/types.js.flow';
import { type TJeMatchedLines } from 'domain/journal/helper';
import { MatchingColorsMap, MatchinArrowsAnchors, ArrowTargetFactory } from 'components/TextractCanvas/utils';
import useStyles from './sheet';

type Props = {|
  headerLineNumber: number,
  row: number,
  hovered: number,
|};

type TextractMatchedLinesCells = {|
  [key: number]: TextractBlock,
|};

export const MatchingArrow = ({ headerLineNumber, row, hovered }: Props) => {
  const classes = useStyles();
  const jeMatchedLines: TJeMatchedLines = useSelector(jeMatchedLinesSelector);
  const textractMatchedLinesCells: TextractMatchedLinesCells = useSelector(
    textractLineItemsTablelLastColumnMatchedLinesSelector,
  );
  const rtl: boolean = useSelector(rtlEnable);
  const line = row - headerLineNumber;
  const match = jeMatchedLines.get(String(line), new List());
  const targets = textractMatchedLinesCells[line] || [];

  // relations force arrow paint and must be set on hover only
  // <ArcherElement /> must be rendered anyway as lines can be rendered from pdf to line items
  // so that ArcherElement serves as target in this case
  const relations = targets
    .map((target, index) => {
      const matchType = match.get(index).get('status');
      // relation can not be empty as it doesnt force rerender
      // fake relations spam too much console noise
      // transparent coloring is approach from EY which is relatively meaningful
      const strokeColor = hovered === row ? MatchingColorsMap[matchType].border : 'transparent';

      return target
        ? ArrowTargetFactory(MatchinArrowsAnchors.documentElementIdFactory(target.id), strokeColor, rtl).toJS()
        : null;
    })
    .filter((x) => x);

  useEffect(() => {
    if (hovered === row) {
      const anchor = MatchinArrowsAnchors.textractVisibleAnchorElementIdFactory(targets[0]?.id);
      const el = document.getElementById(anchor);
      return el?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [hovered, row, targets]);

  // early return is not available due to UseEffect rule that prevents conditional useEffect invoсation
  if (!Object.keys(textractMatchedLinesCells).includes(String(line))) return null;

  return (
    <ArcherElement id={MatchinArrowsAnchors.journalEntryElementIdFactory(line)} relations={relations}>
      <div className={classes.itemArrow} />
    </ArcherElement>
  );
};

export default MatchingArrow;
