// @flow
import { stopSubmit } from 'redux-form';

export const emailReg = /^.+@.+\..{2,24}$/;

type RulesType = {
  [key: string]: (value: string | number, ...rest?: Array<mixed>) => string | void,
};

type ErrorsType = {
  [key: string]: string,
};

export const REQUIRED = 'required';
export const INVALID_EMAIL = 'invalid_email_address';
export const MUST_BE_THE_SAME = 'must_be_the_same';

export const rules: RulesType = {
  required(v) {
    return v ? undefined : REQUIRED;
  },
  email(v) {
    return /.+/i.test(v) ? undefined : INVALID_EMAIL;
  },
  emailExtended(v) {
    return emailReg.test(v) ? undefined : INVALID_EMAIL;
  },
  same(v, ...rest) {
    return rest.reduce((A, V) => {
      if (typeof A === 'undefined') {
        if (V !== v) return MUST_BE_THE_SAME;
        return undefined;
      }
      return A;
    }, undefined);
  },
  strMinLength(v, len, message) {
    return (v || '').toString().length < len ? message : undefined;
  },
  strMaxLength(v, len, message) {
    return (v || '').toString().length > len ? message : undefined;
  },
};

export function addError(fieldName: string, err: ?string) {
  return (errors: ErrorsType): ErrorsType => {
    if (errors[fieldName]) return errors;
    if (typeof err !== 'undefined') {
      return { ...errors, [fieldName]: err };
    }
    return errors;
  };
}

export function makeErrorAction(errors: string | Array<string> | ErrorsType): ErrorsType {
  if (typeof errors === 'string') return { _error: errors };
  if (Array.isArray(errors)) {
    if (typeof errors[0] === 'string') {
      return { _error: errors[0] };
    }
    throw new Error('Incorrect format error message');
  }
  const res = (r) => (Array.isArray(r) ? r[0] : r);
  return Object.keys(errors).reduce((A, field) => ({ ...A, [field]: res(errors[field]) }), {});
}

export function asyncErrorAction(formName: string, errors: string | Array<string> | ErrorsType) {
  return stopSubmit(formName, makeErrorAction(errors));
}
