// @flow
import * as React from 'react';
import { Set, List, type RecordOf } from 'immutable';
import { compose, type Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import identity from 'lodash/identity';
import { debounce } from 'throttle-debounce';
import { linkedSidebarWidthSelector, documentChangeLinkedWidthAction, rtlEnable, localeSelector } from 'domain/env';
import { chatParticipantsByIdSelector, documentsUnreadCounterSelector } from 'domain/chat';
import type { ChatUser } from 'domain/chat/types.js.flow';

import {
  documentLinkInListAction,
  documentUpdateLinkAction,
  exportDataAction,
  lockLinkedDocsAction,
  isLinkedDocsLockedSelector,
  documentGetLinkedAction,
  linkedSelector,
  type DocumentsType,
  documentSortedLinkedSelector,
  getIndicateWarningByDocSelector,
  documentsDownloadDocumentsAction,
  documentGetLinkedIDsAction,
} from 'domain/documents';
import type { StoreExportFormats, ExportFormat } from 'domain/documents/documentsModel';
import { exportFormatsSelector } from 'domain/companies/companiesSelector';
import { getExportFormatOptions } from 'pages/company/helpers';
import * as ACL from 'domain/restriction';
import { promisify } from 'lib/helpers';
import DocumentWorkspaceResizer from 'pages/document/components/DocumentWorkspaceResizer';
import elements from 'components/elements';
import DragDocumentItem from 'pages/company/dragItem__legacy';
import { DnDItemTarget, DOCUMENT, LINKED, DnDItemSource } from 'components/DnDItem';
import LinkedText from './text';
import sheet from './sheet';
import Tooltip from 'components/Tooltip';
import Spinner from 'components/Spinner';
import type { Map } from 'immutable';
import DocumentPreviewNavigation from 'pages/company/DocumentPreviewNavigation/DocumentPreviewNavigation';
import { createMemoFn } from 'lib/propHelpers';
import type { Document } from 'domain/documents';
import type { PreviewStateT } from 'pages/company/type.js.flow';
import ActionsPopup from 'pages/document/components/actions__legacy';
import toast from 'components/Toast';
import Api from 'domain/api';
import { actionsList } from './helpers';
// import print, { printMultiple } from 'lib/print';

const DragSource = DnDItemSource(LINKED);

const PREVIEW_CONTEXT_NAME = 'linkedSideBar';

type Props = {
  getPreviewSrc: (id: string, token: string) => string,
  dokkaToken: string,
  getDocumentUrl: (id: string) => string,
  // eslint-disable-next-line react/no-unused-prop-types
  linkedSidebarWidth?: number, // Using in style sheet
  classes: {
    [key: string]: string,
  },
  selectedDocuments: Set<string>,
  onContextMenu: (event: SyntheticMouseEvent<HTMLDivElement>, document: DocumentsType) => void,
  isContextMenuOpen: boolean,
  documentUpdateLink: ({ linkID: string, text: string }) => void,
  onClose: () => void,
  changeLSWidth: Dispatch<documentChangeLinkedWidthAction>,
  openNotes: (e: SyntheticMouseEvent<HTMLElement>, d: DocumentsType) => void,
  locale: 'en' | 'hb',
  isGranted: (r: number | number[]) => boolean,
  onClick: (e: SyntheticMouseEvent<HTMLElement>, d: DocumentsType, l: boolean) => void,
  documentsUnreadCounter: Set<string>,
  clearSelection: () => void,
  documentLinkInList: (d: any) => void,
  onDrop?: () => void,
  disabled: boolean,
  // eslint-disable-next-line react/no-unused-prop-types
  previewOpened: boolean,
  exportData: Dispatch<typeof exportDataAction>,
  lockLinkedDocs: Dispatch<typeof lockLinkedDocsAction>,
  isForcePanelLocked: boolean,
  isPanelLocked: boolean,
  getLinked: Dispatch<typeof documentGetLinkedAction>,
  linked: RecordOf<{ tag: string, pageToken: ?string }>,
  sortedLinkedDocs: List<DocumentsType>,
  exportFormats: StoreExportFormats,
  usersById: Map<string, RecordOf<ChatUser>>,
  getIndicateWarningByDoc: (doc: Document) => boolean,
  onPreview: (p: PreviewStateT) => void,
  preview: PreviewStateT,
  restriction: number,
  isRtl: boolean,
  downloadLinkedDocs: Dispatch<typeof documentsDownloadDocumentsAction>,
  documentGetLinkedIDs: Dispatch<documentGetLinkedIDsAction>,
  printPreparationCompleted: Dispatch<documentGetLinkedIDsAction.success>,
  companyId: string,
};

type State = {
  draggedOver: boolean,
  lockIsLoading: boolean,
  moreModal: boolean,
};

const DropTarget = DnDItemTarget([DOCUMENT, LINKED]);

// const getTime = (t: string): number => new Date(t).getTime();

// export function sorter<T: { +created: string }>(a: T, b: T) {
//   return getTime(b.created) - getTime(a.created);
// }

class LinkedSidebar extends React.Component<Props, State> {
  observer: ?IntersectionObserver;

  isIntersecting: boolean;

  container: ?HTMLElement;

  resizing: boolean;

  constructor(props) {
    super(props);
    this.resizing = false;

    this.state = {
      draggedOver: false,
      lockIsLoading: false,
      moreModal: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { linked } = this.props;
    if (linked.pageToken !== prevProps.linked.pageToken) {
      this.getData();
    }
  }

  componentWillUnmount(): * {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  onKeyDown = (event) => {
    if (event.keyCode === 27) this.onClose();
  };

  onDragOut = (event: SyntheticDragEvent<HTMLElement>, doc) => {
    if (event) event.dataTransfer.setData('docFromLinked', JSON.stringify(doc));
  };

  onDragOver = (event) => {
    const { draggedOver } = this.state;

    event.preventDefault();
    if (!this.isPanelLocked() && !draggedOver) {
      this.setState({
        draggedOver: true,
      });
    }
  };

  onDndDrop = ({ linkid, id }: { linkid: string, id: number }) => {
    const { selectedDocuments, getLinked, clearSelection, linked, documentLinkInList, onDrop = identity } = this.props;
    const { tag } = linked;

    if (!linked.list.has(id) && !this.isPanelLocked() && !linkid) {
      const promise = new Promise((resolve, reject) => {
        documentLinkInList({
          documentID: selectedDocuments.size ? selectedDocuments.toJS() : id,
          linkID: this.linkedId,
          text: this.linkedText,
          resolve,
          reject,
        });
      });

      promise.then(() => {
        if (tag) {
          clearSelection();
        }
      });
      onDrop();
    }
    this.onDragLeave();
  };

  onDragLeave = () => {
    const { draggedOver } = this.state;
    if (draggedOver) {
      this.setState({
        draggedOver: false,
      });
    }
  };

  onLockLinkPanel = () => {
    const { lockLinkedDocs, isGranted, isForcePanelLocked } = this.props;
    if (isGranted(ACL.IS_ACCOUNT) && !isForcePanelLocked) {
      this.setState({ lockIsLoading: true });
      new Promise((resolve, reject) => {
        lockLinkedDocs({ linkID: this.linkedId, locked: !this.isPanelLocked(), resolve, reject });
      })
        .then(() => {
          this.setState({ lockIsLoading: false });
        })
        .catch(() => {
          this.setState({ lockIsLoading: false });
        });
    }
  };

  onClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  onPreview = (doc: Document | null) => {
    const { onPreview } = this.props;
    const [documentId, contextName] = doc ? [doc.documentID, PREVIEW_CONTEXT_NAME] : [null, ''];
    onPreview({ documentId, contextName });
  };

  onDocumentItemClick = (event, item) => {
    const { onClick } = this.props;
    onClick(event, item, true);
  };

  onContextMenu = (...args) => {
    const { onContextMenu } = this.props;
    onContextMenu(...args, 'linked');
  };

  onOpenNotes = (...args) => {
    const { openNotes } = this.props;
    openNotes(...args, 'linked');
  };

  get linkedId(): string {
    return this.documentsList.getIn([0, 'linkid'], '');
  }

  get documentsList(): List<DocumentsType> {
    const { sortedLinkedDocs } = this.props;
    return sortedLinkedDocs;
  }

  get linkedText(): string {
    const firstDocument = this.documentsList.first();
    if (firstDocument) return firstDocument.ltext;
    return '';
  }

  get hasNext(): boolean {
    const { linked } = this.props;
    return typeof linked.pageToken === 'string';
  }

  getExportSubmenu = () =>
    this.getExportOptions().map(({ label, value }) => ({
      id: label,
      title: label,
      disable: false,
      action: () => {
        this.exportLinkedDocs(value);
      },
    }));

  get actionsList(): Array<*> {
    const { restriction } = this.props;
    const { actions } = this;

    return actionsList({ restriction }).map((e) => ({
      ...e,
      ...actions[e.id],
    }));
  }

  get actions() {
    return {
      'documents.linkedSidebar.export': {
        onClick: (x) => x,
        submenu: this.getExportSubmenu(),
      },
      'document.show.actions.downloadLinked': {
        onClick: this.onLinkedDownload,
      },
      'document.show.actions.printLinked': {
        onClick: this.onPrintLinked,
      },
    };
  }

  onLinkedDownload = async () => {
    const { downloadLinkedDocs } = this.props;

    downloadLinkedDocs({ linkid: this.linkedId });

    toast.success(
      <FormattedMessage id="selectedPanel.download.toast" defaultMessage="The download will start in a few seconds" />,
      { autoClose: 7000 },
    );
  };

  onPrintLinked = async () => {
    const { documentGetLinkedIDs, dokkaToken, companyId, printPreparationCompleted } = this.props;
    const ids = await promisify(documentGetLinkedIDs, { linkid: this.linkedId });
    const validUrls = ids.map((documentID) => Api.getDocumentUrl({ dokkaToken, documentID, companyId }));

    if (ids.length > 1) {
      // await printMultiple(validUrls, ids[0]);
    } else {
      const url = Api.getDocumentUrl({ dokkaToken, documentID: ids[0], companyId });

      // await print(url, ids[0], false);
    }
    printPreparationCompleted();
  };

  getExportOptions = () => {
    const { exportFormats } = this.props;
    return getExportFormatOptions(exportFormats);
  };

  getNext = (entries: $ReadOnlyArray<IntersectionObserverEntry>) => {
    const { isIntersecting } = entries[0];
    this.isIntersecting = isIntersecting;
    this.getData();
  };

  getData = () => {
    if (this.isIntersecting) {
      this.loadData();
    }
  };

  getPreviewContext = createMemoFn(
    () => {
      const {
        sortedLinkedDocs,
        linked: { count },
      } = this.props;
      return { sortedLinkedDocs, count };
    },
    ({ sortedLinkedDocs, count }) => ({ list: sortedLinkedDocs.toList(), count }),
  );

  documentUpdateLink = (...args) => {
    const { documentUpdateLink } = this.props;
    documentUpdateLink(...args);
  };

  // eslint-disable-next-line react/sort-comp
  handleChange = debounce(500, this.documentUpdateLink);

  loadData = async () => {
    const { linked, getLinked } = this.props;
    if (typeof linked.pageToken === 'string') {
      await promisify(getLinked, { tag: linked.tag, pageToken: linked.pageToken });
    }
  };

  isPanelLocked = () => {
    const { isForcePanelLocked, isPanelLocked, isGranted } = this.props;
    return isForcePanelLocked || isPanelLocked || isGranted(ACL.IS_SUPPLIER_USER);
  };

  isOpenPreview = () => {
    const {
      preview: { documentId, contextName },
    } = this.props;
    return documentId && contextName === PREVIEW_CONTEXT_NAME;
  };

  exportLinkedDocs = (format: ExportFormat) => {
    const { exportData } = this.props;
    exportData({ format, linkedId: this.linkedId });
  };

  onResize = (event) => {
    const { locale, changeLSWidth } = this.props;
    const width = locale === 'he' ? event.clientX : window.innerWidth - event.clientX;
    const finalWidth = Math.min(width, window.innerWidth / 2);
    window.localStorage.setItem('dokkaLinkedSidebarWidth', finalWidth);
    changeLSWidth(finalWidth);
  };

  refProxy = (el: ?HTMLElement) => {
    this.container = el;
    if (el) {
      this.observer = new IntersectionObserver(this.getNext, {
        root: null,
        rootMargin: '100px',
        threshold: 0.1,
      });
      this.observer.observe(this.container);
    }
  };

  onMoreModalClose = () => {
    this.setState({ moreModal: false });
  };

  onMoreModalShow = () => {
    this.setState({ moreModal: true });
  };

  render() {
    const {
      classes,
      locale,
      isGranted,
      disabled,
      isForcePanelLocked,
      usersById,
      getIndicateWarningByDoc,
      isContextMenuOpen,
      preview,
      clearSelection,
      getDocumentUrl,
      getPreviewSrc,
      dokkaToken,
      documentsUnreadCounter,
      selectedDocuments,
      linked,
      isRtl,
    } = this.props;

    if (linked.list.size < 1) return null;

    const { lockIsLoading, draggedOver, moreModal } = this.state;
    const { onResize } = this;

    const isAccountUser = isGranted(ACL.IS_ACCOUNT);
    const isSupplier = isGranted(ACL.IS_SUPPLIER_USER);
    const isPanelLocked = this.isPanelLocked();

    const context = this.getPreviewContext();

    return (
      <>
        {this.isOpenPreview() && (
          <DocumentPreviewNavigation
            documentId={preview.documentId}
            setPreview={this.onPreview}
            list={context.list}
            allCount={context.count}
            onContextMenu={this.onContextMenu}
            isContextMenuOpen={isContextMenuOpen}
            onLoadNext={this.loadData}
            onClick={this.onDocumentItemClick}
          />
        )}

        <div
          className={classes.wrapper}
          role="presentation"
          onClick={clearSelection}
          data-element={elements.content.linkedPanel.container}
        >
          <div className={classes.titleHeader}>
            <Tooltip
              id="documents.linkedSidebar.tooltip"
              defaultMessage="Please, contact your accountant for unlocking"
              disable={!isPanelLocked || isAccountUser}
              className={classes.lockTooltip}
            >
              <button
                type="button"
                className={classes.lockIcon}
                onClick={this.onLockLinkPanel}
                data-element={elements.content.linkedPanel.lock}
                disabled={isForcePanelLocked || !isAccountUser || lockIsLoading}
              />
            </Tooltip>
            <div className={classes.title}>
              <FormattedMessage id="documents.linkedSidebar.title" defaultMessage="Link Documents" />
            </div>
            <button
              type="button"
              className={classes.close}
              onClick={this.onClose}
              data-element={elements.content.linkedPanel.close}
            />
          </div>
          <div className={classes.content}>
            <LinkedText
              key={this.linkedId}
              text={this.linkedText}
              classes={classes}
              onChange={this.handleChange}
              linkedId={this.linkedId}
              isPanelLocked={isPanelLocked}
            />
            <DropTarget
              onDndDrop={this.onDndDrop}
              onDragOver={this.onDragOver}
              onDragLeave={this.onDragLeave}
              classes
              className={classes.itemsDropzone}
              stopPropagateDrop
            >
              {isPanelLocked && <div className={classes.lockedZone} />}
              <div
                className={cx(classes.items, {
                  [classes.dragOver]: draggedOver,
                  [classes.empty]: !this.documentsList.size,
                })}
              >
                {this.documentsList.size > 0 ? (
                  <ul
                    role="presentation"
                    className={classes.list}
                    onClick={clearSelection}
                    data-element={elements.content.linkedPanel.documents}
                  >
                    {this.documentsList.map((item, index) => (
                      <li
                        key={item.documentID}
                        className={cx(classes.item, {
                          [classes.disabled]: disabled,
                          [classes.favorite]: index === 0,
                        })}
                        data-element={elements.content.linkedPanel.documentItem}
                      >
                        <DragDocumentItem
                          DragSource={DragSource}
                          srcType={LINKED}
                          onDragStart={this.onDragOut}
                          readOnlyDrag={isPanelLocked}
                          to={getDocumentUrl(item.documentID)}
                          onClick={this.onDocumentItemClick}
                          onPreview={this.onPreview}
                          onContextMenu={this.onContextMenu}
                          previewSrc={getPreviewSrc(item.documentID, dokkaToken)}
                          openNotes={this.onOpenNotes}
                          readOnly={isGranted(ACL.IS_USER)}
                          hasUnread={documentsUnreadCounter.has(item.documentID)}
                          selected={selectedDocuments.has(item.documentID)}
                          item={item}
                          className={classes}
                          locale={locale}
                          selectedDocuments={selectedDocuments}
                          isLinkedSidebar
                          linkedAsSinglePage
                          usersById={usersById}
                          getIndicateWarningByDoc={getIndicateWarningByDoc}
                          isAllowNotes={!isSupplier}
                        />
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className={classes.dndPlaceholder}>
                    <FormattedMessage
                      id="documents.linkedSidebar.placeholder"
                      defaultMessage="Drag documents here to link them together"
                    />
                  </div>
                )}
                <div ref={this.refProxy} className={cx(classes.load, { [classes.hasNext]: this.hasNext })}>
                  <Spinner loading={this.hasNext} className={classes.spinner} />
                </div>
              </div>
              {this.documentsList.size > 0 ? (
                <div className={classes.footer}>
                  {moreModal && (
                    <ActionsPopup
                      actions={this.actionsList}
                      classes={classes}
                      close={this.onMoreModalClose}
                      isRtl={isRtl}
                      isLinked
                    />
                  )}
                  {!isSupplier && (
                    <>
                      <button
                        onClick={this.onMoreModalShow}
                        className={classes.moreButton}
                        data-element={elements.content.linkedPanel.more}
                        type="button"
                      >
                        <FormattedMessage id="document.show.btns.more" defaultMessage="More" />
                        ...
                      </button>
                      <button
                        type="button"
                        className={classes.doneButton}
                        onClick={this.onClose}
                        data-element={elements.content.linkedPanel.done}
                      >
                        <FormattedMessage id="documents.linkedSidebar.done" defaultMessage="Done" />
                      </button>
                    </>
                  )}
                </div>
              ) : null}
            </DropTarget>
          </div>
          <DocumentWorkspaceResizer onResize={onResize} isVertical className={classes.slidePanel} />
        </div>
      </>
    );
  }
}

LinkedSidebar.displayName = 'LinkedSidebar';
LinkedSidebar.defaultProps = {
  docs: new List(),
};

const mapStateToProps = (state) => ({
  locale: localeSelector(state),
  linkedSidebarWidth: linkedSidebarWidthSelector(state),
  isGranted: ACL.isGranted(state),
  documentsUnreadCounter: documentsUnreadCounterSelector(state),
  isPanelLocked: isLinkedDocsLockedSelector(state),
  linked: linkedSelector(state),
  sortedLinkedDocs: documentSortedLinkedSelector(state),
  exportFormats: exportFormatsSelector(state),
  usersById: chatParticipantsByIdSelector(state),
  getIndicateWarningByDoc: getIndicateWarningByDocSelector(state),
  restriction: ACL.myRestriction(state),
  isRtl: rtlEnable(state),
});

const mapDispatchToProps = {
  documentLinkInList: documentLinkInListAction,
  documentUpdateLink: documentUpdateLinkAction,
  exportData: exportDataAction,
  lockLinkedDocs: lockLinkedDocsAction,
  getLinked: documentGetLinkedAction,
  changeLSWidth: documentChangeLinkedWidthAction,
  downloadLinkedDocs: documentsDownloadDocumentsAction,
  documentGetLinkedIDs: documentGetLinkedIDsAction,
  printPreparationCompleted: () => ({ type: documentGetLinkedIDsAction.success }),
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(sheet))(LinkedSidebar);
