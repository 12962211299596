// @flow
import React, { useCallback, Fragment, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List } from 'immutable';

import { userSelector } from 'domain/env';
import {
  assignOrganizationUser,
  revokeOrganizationUser,
  organizationUsersSelector,
  deleteOrganizationUser,
} from 'domain/organizationUser';
import { currentCompanySelector } from 'domain/documents/documentSelector';
import { useIntl } from 'react-intl';
import type { EnhancedUser } from 'domain/organizationUser/types.js.flow';
import { relevantSearch } from 'lib/helpers';

import AssigmentButton from 'pages/configurations/components/AssigmentButton';
import Button from '@mui/material/Button';
import Avatar from 'components/mui/Avatar';
import ListMui from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListSubheader from '@mui/material/ListSubheader';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import SearchBase from 'components/mui/Layouts/components/AppbarSearchBase';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getUserDisplayName } from 'pages/configurations/helpers';

const mapStateToProps = (state) => ({
  currentUser: userSelector(state),
  users: organizationUsersSelector(state),
  companyId: currentCompanySelector(state),
});

const CLIENT_ROLES = ['user', 'confidential-user', 'restricted-user', 'confidential-restricted-user', 'supplier'];

type TCompanyUsersList = {
  onEdit: (id: string) => void,
};

const CompanyUsersList: React$StatelessFunctionalComponent<TCompanyUsersList> = ({ onEdit }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [searchText, setSearchText] = useState('');

  const { users, currentUser, companyId } = useSelector(mapStateToProps);

  const isDisabledFn = useCallback(
    (user: EnhancedUser) => user.email === currentUser.userId || user.admin,
    [currentUser],
  );

  const usersList = useMemo(
    () => (searchText ? new List(relevantSearch(searchText, users, (user) => user.display_name)) : users),
    [users, searchText],
  );

  const onChangeSearch = useCallback((text: string) => setSearchText(text), []);

  const onDelete = (id: string) => {
    dispatch(deleteOrganizationUser({ id }));
  };

  const handleUserAssignment = useCallback(
    (user: EnhancedUser) => {
      const { companies_id: companiesId, id } = user;
      const assigned = companiesId.has(companyId);
      if (assigned) dispatch(revokeOrganizationUser({ data: { id } }));
      else dispatch(assignOrganizationUser({ data: { id } }));
    },
    [dispatch, companyId],
  );

  return (
    <Paper sx={{ flex: '1 1 0', overflowY: 'auto' }}>
      <ListMui
        subheader={
          <ListSubheader sx={{ py: 2, lineHeight: 1 }}>
            <SearchBase value={searchText} onChange={onChangeSearch} />
          </ListSubheader>
        }
      >
        {usersList.map((user, index) => {
          const isClient = CLIENT_ROLES.includes(user.role);
          const displayName = getUserDisplayName(user);

          return (
            <Fragment key={user.email}>
              <ListItem dense alignItems="center">
                <ListItemAvatar>
                  <Avatar size={40} src={user.pic} />
                </ListItemAvatar>
                <Box flexGrow={1} my="6px">
                  <Typography variant="body2">{displayName}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {user.email}
                  </Typography>
                </Box>
                <Box flexShrink={0}>
                  {isClient ? (
                    <>
                      {user.role !== 'supplier' && (
                        <Button
                          size="small"
                          color="info"
                          onClick={() => onEdit(user.id)}
                          startIcon={<EditIcon />}
                          variant="text"
                        >
                          {formatMessage({ id: 'button.edit', defaultMessage: 'Edit' })}
                        </Button>
                      )}

                      <Button
                        size="small"
                        color="error"
                        onClick={() => onDelete(user.id)}
                        startIcon={<DeleteIcon />}
                        sx={{ ml: 1 }}
                        variant="text"
                        disabled={user.companies_id.size > 1}
                      >
                        {formatMessage({ id: 'button.delete', defaultMessage: 'Delete' })}
                      </Button>
                    </>
                  ) : (
                    <AssigmentButton
                      disabled={isDisabledFn(user)}
                      assigned={user.companies_id.has(companyId)}
                      onClick={() => handleUserAssignment(user)}
                    />
                  )}
                </Box>
              </ListItem>
              {usersList.size !== index + 1 && <Divider variant="inset" component="li" />}
            </Fragment>
          );
        })}
      </ListMui>
    </Paper>
  );
};

export default CompanyUsersList;
