import ui from 'lib/ui';
import mediaQuery from 'lib/mediaQuery';
import accepted from './accepted.svg';
import rejected from './rejected.svg';
import financial from './financial.png';
import unread from './unread.svg';
import clip from './clip.svg';
import close from './close.svg';
import view from './view.svg';
import overlay from './cornerShadow.svg';
import processing from './processing.svg';
import approvalComplete from './approval_complete.svg';
import approvalPending from './approval_pending.svg';
import approvalRejected from './approval_rejected.svg';

export default (theme) => ({
  centeredIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  document: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 2,
    overflow: 'hidden',
    background: '#fff',
    transition: 'opacity 0.3s ease',
    position: 'relative',
    boxSizing: 'content-box',
    '&$dragging': {
      opacity: 0.2,
    },
    '&$multipage': {
      position: 'relative',
      '&:after': {
        flip: false, // rtl plugin directive
        content: "''",
        position: 'absolute',
        top: -1,
        right: -2,
        pointerEvents: 'none',
        bottom: 0,
        left: 0,
        background: `url("${overlay}") 100% 0 no-repeat`,
      },
    },
  },
  multipage: {},
  selected: {},
  linkedActionsShow: {},
  hasWarning: {},
  withBorder: {
    content: '""',
    display: 'inline-block',
    position: 'absolute',
    top: -4,
    left: -4,
    width: '100%',
    height: '100%',
    borderRadius: 2,
    pointerEvents: 'none',
  },
  wrapper: {
    position: 'relative',
    height: '100%',
    width: 160,
    boxShadow: '0px 4px 4px 0px #00000040',
    border: `1px solid ${theme.colors.greyDark}`,
    '&$linkedActionsShow': {
      '& $linkedShowBtn': {
        width: '95%',
        pointerEvents: 'all',
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.55)',
      },
      '& $linkedBtnBody': {
        display: 'flex',
      },
      '& $withLinkedText': {
        flip: false,
        maxHeight: 200,
        height: '65%',
        top: 0,
        left: '2.5%',
        width: '95%',
        '& $linkedBtnBody': {
          width: '100%',
          height: '100%',
          fontSize: 16,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
    },
    '&$hasWarning:after': {
      extend: 'withBorder',
      border: `4px solid ${theme.colors.primaryDanger}`,
      boxShadow: '0px 4px 20px rgba(188, 83, 83, 0.4)',
    },
    '&$selected:after': {
      extend: 'withBorder',
      border: `4px solid ${theme.colors.primaryDark}`,
      boxShadow: '0px 4px 20px rgba(79, 116, 209, 0.4)',
    },
    '&:hover $preview': {
      opacity: 1,
      pointerEvents: 'all',
    },
  },
  shadows: {
    display: 'block',
    position: 'absolute',
    width: '100%',
    background: '#FFFFFF',
    height: '100%',
    transition: 'opacity 0.2s ease, top 0.4s ease 0.2s, left 0.4s ease 0.2s',
    border: '1px solid #E0E0E0',
    boxSizing: 'content-box',
    borderRadius: 2,
    opacity: 0.9,
    left: 3,
    top: -3,
  },
  overlay: {
    display: 'block',
    position: 'absolute',
    zIndex: 2,
    width: '100%',
    height: '100%',
    borderRadius: 2,
    top: 0,
    left: 0,
    background: `url("${overlay}") 100% 0 no-repeat`,
  },
  secondShadow: {},
  linkedShowBtn: {
    flip: false,
    ...ui(theme).btnType3,
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '22px',
    textTransform: 'none',
    border: 'none',
    borderRadius: [0, 0, 4, 4],
    padding: [8, 16],
    width: '40px',
    position: 'absolute',
    pointerEvents: 'none',
    opacity: 1,
    transition: 'opacity 0.3s ease, box-shadow 0.4s ease',
    top: 0,
    height: 40,
    zIndex: 5,
    left: '2.5%',
    background: `${theme.colors.primaryLight} url("${clip}") 8px 8px no-repeat`,
    '&:active': {
      backgroundImage: `url("${clip}")`,
      backgroundColor: theme.colors.primaryDark,
    },
  },
  linkedText: {
    lineHeight: '22px',
    maxHeight: 66,
    fontSize: 16,
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    whiteSpace: 'pre-wrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [`@media (max-height: ${mediaQuery.maxHeight})`]: {
      fontSize: 14,
      lineHeight: '20px',
      maxHeight: 60,
    },
  },
  linkedOneLineText: {
    whiteSpace: 'nowrap',
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'inline-block',
  },
  linkedTextWrapper: {
    textAlign: 'center',
    maxWidth: '100%',
    fontSize: 14,
    flex: '1 100%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 8,
  },
  withLinkedText: {},
  linkedBtnText: {},
  linkedBtnBody: {
    display: 'none',
    justifyContent: 'center',
    alignItems: 'center',
  },
  clip: {
    background: `url("${clip}") 0 0 no-repeat`,
    position: 'absolute',
    cursor: 'pointer',
    height: 42,
    width: 24,
    right: 15,
    zIndex: 2,
    top: -10,
  },
  dragging: {},
  icon: {
    position: 'absolute',
    margin: [0, 5],
    flex: '0.33',
    backgroundSize: 'contain',
    top: '50%',
    transform: 'translate(0, -50%)',
  },
  type: {
    right: 8,
  },
  financial: {
    width: 25,
    height: 24,
    backgroundImage: `url("${financial}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  hasUnread: {
    marginLeft: 'auto',
    width: 25,
    order: 3,
    height: 24,
    backgroundImage: `url("${unread}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center right',
  },
  status_icon: {
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 26,
    height: 26,
    order: 2,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  approval_pending: {
    backgroundImage: `url("${approvalPending}")`,
  },
  approval_complete: {
    backgroundImage: `url("${approvalComplete}")`,
  },
  approval_rejected: {
    backgroundImage: `url("${approvalRejected}")`,
  },
  general: {},
  new: {},
  accepted: {
    width: 26,
    order: 1,
    height: 26,
    backgroundImage: `url("${accepted}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  rejected: {
    width: 25,
    order: 1,
    height: 24,
    backgroundImage: `url("${rejected}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  read: {},
  thumb: {
    justifyContent: 'center',
    transformOrigin: ['left', 'top'],
    objectFit: 'contain',
  },
  bottom: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 40,
    zIndex: 1,
    backgroundColor: '#fff',
    borderTop: `1px solid ${theme.colors.greyDark}`,
  },
  notes: {
    position: 'absolute',
    background: 'linear-gradient(180deg, #FFF0C4 0%, #F5E4AE 100%)',
    color: '#4F4F4F',
    width: 91,
    height: 91,
    right: 1,
    bottom: 39,
    border: 0,
    zIndex: 1,
    outline: 'none',
    textAlign: 'left',
    cursor: 'pointer',
    whiteSpace: 'pre-wrap',
    '& span': {
      position: 'absolute',
      fontSize: 12,
      lineHeight: '13px',
      padding: [9, 6],
      overflow: 'hidden',
      wordBreak: 'break-word',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      // vendor specific properties, chrome/safari only
      display: '-webkit-box',
      '-webkit-line-clamp': 6,
      '-webkit-box-orient': 'vertical',
      'margin-bottom': 5,
    },
  },
  withSID: {
    height: 77,
    bottom: 52,
  },
  protectedThumb: {
    width: '100%',
  },
  en: {},
  he: {},
  preview: {
    pointerEvents: 'none',
    opacity: 0,
    width: 36,
    height: 36,
    border: 'none',
    borderRadius: '50%',
    backgroundSize: 'cover',
    zIndex: 2,
    top: '50%',
    left: '50%',
    marginLeft: -18,
    marginTop: -18,
    background: '#FFF',
    position: 'absolute',
    transition: 'box-shadow .3s ease .1s, transform .3s ease, opacity .3s ease',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15)',
    cursor: 'pointer',
    outline: 'none',
    '&:hover': {
      transform: 'scale(1.1)',
      boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.35)',
    },
    [`@media (max-height: ${mediaQuery.maxHeight})`]: {
      width: 24,
      height: 24,
      marginLeft: -12,
      marginTop: -12,
    },
  },

  previewIcon: {
    width: '100%',
    height: '100%',
    background: theme.colors.primaryLight,
    maskSize: 'cover',
    borderRadius: '50%',
    WebkitMask: `url("${view}") 50% 50% no-repeat`,
  },

  closeButton: {
    height: 12,
    width: 12,
    top: 0,
    right: -36,
    background: `url(${close}) 0 0 no-repeat`,
  },
  linkBtn: {
    position: 'absolute',
    top: '100%',
    marginTop: 30,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  openBtn: {
    ...ui(theme).btnType3,
    width: 150,
  },
  duplicate: {
    background: theme.colors.primaryDanger,
    padding: [1, 0],
    textAlign: 'center',
    color: theme.colors.primaryWhite,
    textTransform: 'uppercase',
    height: 13,
    fontSize: 11,
    fontWeight: 600,
    zIndex: 1,
    width: '100%',
    position: 'absolute',
    bottom: 40,
  },

  paid: {
    background: '#BC5353',
    position: 'absolute',
    zIndex: 1,
    transform: 'rotate(45deg)',
    color: '#FFF',
    fontWeight: 600,
    textAlign: 'center',
    width: 80,
    height: 40,
    fontSize: 10,
    left: -26,
    bottom: 34,
    paddingTop: 8,
  },

  paidWithDuplicate: {
    bottom: 47,
  },

  processing: {
    position: 'absolute',
    width: '100%',
    height: '39px',
    bottom: -2,
    zIndex: 9,
    boxSizing: 'content-box',
    border: '1px solid #F47F2A',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    background: '#FFF',
    fontSize: '13px',
    color: '#000',
    '& > span': {
      padding: [0, 10, 0, 40],
    },
  },
  processingIcon: {
    position: 'absolute',
    height: 17,
    width: 15,
    top: 12,
    left: 9,
    background: `url(${processing}) 0 0 no-repeat`,
    transform: 'rotate(-32.26deg)',
  },
  documentTooltip: {
    width: 200,
    fontSize: '12px',
    textAlign: 'center',
    lineHeight: '12px',
    padding: [5, 5],
    borderRadius: 2,
    [`@media (max-height: ${mediaQuery.maxHeight})`]: {
      width: 160,
    },
  },

  userPicksBox: {
    padding: 2,
    position: 'absolute',
    bottom: 40,
    right: 0,
    zIndex: 1,
  },

  userPicksBoxWithDuplicate: {
    bottom: 53,
  },

  approvalStatusTooltip: {
    top: -40,
  },
});
