import Stack from '@mui/material/Stack';

import { styled } from '@mui/material/styles';

export const Navigation = styled(Stack, { label: 'DocumentNavigation' })(() => ({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
}));

export const NavigationPagination = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.action.active,
  color: theme.palette.common.white,
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 500,
  padding: theme.spacing(1, 2),
  borderRadius: 24,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  margin: theme.spacing(0, 1),
}));
