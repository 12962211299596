// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getSsoEventMessageKey } from 'amplify/helpers';
import { querySelector } from 'domain/router';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { generatePath } from 'react-router-dom';

import LayoutServicePage from 'components/mui/Layouts/LayoutServicePage';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import RouterLink from 'components/mui/Router/RouterLink';
import bgImage from 'pages/accessLimited/images/icon.svg';

const AccessLimited = () => {
  const { msg } = useSelector(querySelector);

  return (
    <LayoutServicePage>
      <Stack flex={1} component="main" justifyContent="center" alignItems="center">
        <img src={bgImage} alt="background image" />
        <Typography variant="h5" mt={4}>
          <FormattedMessage
            id={`pages.access_limited.${getSsoEventMessageKey(msg)}`}
            defaultMessage="Something went wrong"
          />
        </Typography>
        <Typography variant="body1" color="text.secondary" mt={1} mb={2}>
          <FormattedMessage
            id="pages.access_limited.contact_admin"
            defaultMessage="Please contact your organization administrator"
          />
        </Typography>
        <Button href={generatePath(ROUTES_PATH.AUTH_LOGIN.absolute)} component={RouterLink}>
          <FormattedMessage id="pages.access_limited.back_to_login" defaultMessage="Back to login" />
        </Button>
      </Stack>
    </LayoutServicePage>
  );
};

export default AccessLimited;
