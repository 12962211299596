// @flow

// Map providers and domains
export const PROVIDERS_CONFIG = {
  // --- prod section ---
  k2view: ['k2view.dokka.com'],
  riskified: ['riskified.dokka.com'],
  adama: ['adama.dokka.com'],
  ActiveFence: ['activefence.dokka.com'],
  bluevine: ['bluevine.dokka.com'],
  earnix: ['earnix.dokka.com'],
  voneus: ['voneus.dokka.com'],
  DELEK: ['delek.dokka.com'],

  // --- dev section ---
  'Dokka-Okta-DEV': ['k2view-dev.dokka.biz'],
  AzureADTest: ['delek.dokka.biz'],
};

// Domains that use only SSO for login
export const SSO_ONLY_DOMAINS = [
  // --- staging ---
  // 'sso.dokka.biz',
  // --- production ---
  // 'k2view.dokka.com',
  // --- local ---
  // 'localhost:3000',
];
