// @flow
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TFATokenSelector } from 'domain/env';
import { isDomainSSOOnly, isRedirectFromSSO } from 'amplify/helpers';
import { passwordLessAuthDomains } from 'domain/env/helpers';

import AuthLayout from './Layout';
import TFAuth from 'pages/authPage/TFAuth';
import DefaultAuth from 'pages/authPage/DefaultAuth';
import PasswordLessAuth from 'pages/authPage/PasswordLessAuth/screens/accessLink';
import LegacyBrowser from 'pages/authPage/Layout/LegacyBrowser';

const AuthPage = () => {
  const TFAToken = useSelector(TFATokenSelector);

  const authType = useMemo(() => {
    if (passwordLessAuthDomains.includes(window.location.host)) return 'passwordLess';
    if (TFAToken) return 'twoFactor';
    return 'default';
  }, [TFAToken]);

  return isDomainSSOOnly() || isRedirectFromSSO() ? (
    'redirecting...'
  ) : (
    <AuthLayout>
      {authType === 'default' && <DefaultAuth />}
      {authType === 'twoFactor' && <TFAuth />}
      {authType === 'passwordLess' && <PasswordLessAuth />}
      <LegacyBrowser />
    </AuthLayout>
  );
};

export default AuthPage;
