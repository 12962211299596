// @flow
import { List, Set } from 'immutable';
import { type UserList } from './types.js.flow';
import * as A from './actions';

export const reducer = {
  organizationUser(state: UserList = new List(), action: mixed) {
    switch (action.type) {
      case A.getOrganizationUsers.success:
        return action.payload;

      case A.updateOrganizationUser.success:
        // if user is not in a list, findIndex will return -1, thus updating last list item
        return state.set(
          state.findIndex((_) => _.id === action.payload.id),
          action.payload,
        );

      case A.deleteOrganizationUser.success:
        return state.filterNot((_) => _.id === action.payload.id);

      case A.createOrganizationUser.success: {
        const n = state.push(action.payload);
        return n;
      }

      case A.assignOrganizationUser.request:
      case A.assignOrganizationUser.success:
        return state.update(
          state.findIndex((_) => _.id === action.payload.id),
          (user) => user.update('companies_id', (company) => company.add(action.payload.companyId)),
        );

      case A.assignOrganizationUser.failure:
        // if our request fails, we call doLogout which dispatch this action again, but without the payload
        if (!action.payload) {
          return state;
        }
        return state.update(
          state.findIndex((_) => _.id === action.payload.id),
          (user) => user.update('companies_id', (company) => company.delete(action.payload.companyId)),
        );

      case A.revokeOrganizationUser.request:
      case A.revokeOrganizationUser.success:
        return state.update(
          state.findIndex((_) => _.id === action.payload.id),
          (user) => user.update('companies_id', (company) => company.delete(action.payload.companyId)),
        );
      case A.revokeUserFromAllCompanies.success:
        return state.update(
          state.findIndex((_) => _.id === action.payload.id),
          (user) => user.set('companies_id', new Set()),
        );

      case A.revokeOrganizationUser.failure:
        // if our request fails, we call doLogout which dispatch this action again, but without the payload
        if (!action.payload) {
          return state;
        }
        return state.update(
          state.findIndex((_) => _.id === action.payload.id),
          (user) => user.update('companies_id', (company) => company.add(action.payload.companyId)),
        );

      default:
        return state;
    }
  },
};
