// @flow
import React, { useCallback, useRef, useState, useEffect } from 'react';
import Draggable from 'react-draggable';

import Tooltip from 'components/mui/Tooltip';
import { SelectContainer } from './StyledComponents';

import { useTheme } from '@mui/material';

type Props = {
  setSelectTableHeaderRef: (ref: HTMLElement) => void,
  documentContainerRef: ?HTMLDivElement,
};
const SelectTableHeader: React$StatelessFunctionalComponent<Props> = ({
  setSelectTableHeaderRef,
  documentContainerRef,
}) => {
  const selectRef = useRef(null);
  const { direction } = useTheme();
  const isRtl = direction === 'rtl';

  const { width: containerWidth = 0, height: containerHeight = 0 } =
    documentContainerRef.current?.getBoundingClientRect();
  const initialWidth = containerWidth * 0.7 || 150;
  const initHorizontalPositionCoefficient = isRtl ? -0.15 : 0.15;

  const [wasMoved, setWasMoved] = useState(false);
  const [isShowTooltip, setIsShowTooltip] = useState(true);
  const [position, setPosition] = useState({
    x: containerWidth * initHorizontalPositionCoefficient,
    y: containerHeight / 2,
  });

  const handleStart = useCallback(
    (e, data) => {
      if (!wasMoved) {
        setPosition({ y: selectRef?.current.offsetTop + data.y, x: data.x });
        setWasMoved(true);
      } else {
        setPosition({ x: data.x, y: data.y });
      }
    },
    [wasMoved],
  );

  const handleMouseDown = useCallback(() => {
    setIsShowTooltip(false);
  }, []);

  useEffect(() => {
    setSelectTableHeaderRef(selectRef.current);
  }, [selectRef.current]);

  return (
    <Draggable bounds="parent" handle="strong" onStop={handleStart} position={position} onMouseDown={handleMouseDown}>
      <SelectContainer className="box no-cursor" ref={selectRef} sx={{ width: initialWidth }}>
        <Tooltip
          t={{ id: 'tooltip.document.selectHeader', defaultMessage: 'Select the header of the table' }}
          open={isShowTooltip}
        >
          <strong className="cursor" style={{ display: 'inline-block', width: 'calc(100% - 10px)' }} />
        </Tooltip>
        <strong
          className="cursor"
          style={{ display: 'inline-block', width: 10, minHeight: 'calc(100% - 10px)', height: 'calc(100% - 10px)' }}
        />
      </SelectContainer>
    </Draggable>
  );
};

export default SelectTableHeader;
