/* @flow */
import type { RecordOf } from 'immutable';
import { selector } from 'lib/selectors';
import type { TUiState, TUiApprovalFlowRecord, TUiModalRecord, TUiAFStamp } from 'domain/ui/types.js.flow';
import type { SelectorType } from 'domain/types.js.flow';

const ui = (state): RecordOf<TUiState> => state.ui;

const modalSelector: SelectorType<TUiModalRecord> = selector(ui, (x) => x.modal);
const approvalFlowSelector: SelectorType<TUiApprovalFlowRecord> = selector(ui, (x) => x.approvalFlow);

export const isOpenModalDocumentHotkeysSelector: SelectorType<boolean> = selector(
  modalSelector,
  (modal: TUiModalRecord) => modal.isOpenDocumentHotkeysModal,
);

export const isOpenModalWorkspaceHotkeysSelector: SelectorType<boolean> = selector(
  modalSelector,
  (modal: TUiModalRecord) => modal.isOpenWorkspaceHotkeysModal,
);

export const isOpenAFPanelSelector: SelectorType<boolean> = selector(
  approvalFlowSelector,
  (af: TUiApprovalFlowRecord) => af.isOpen,
);

export const getUiAFlStampModeSelector: SelectorType<boolean> = selector(
  approvalFlowSelector,
  (af: TUiApprovalFlowRecord) => af.stampMode,
);

export const getUiAFlStampSelector: SelectorType<RecordOf<TUiAFStamp>> = selector(
  approvalFlowSelector,
  (af: TUiApprovalFlowRecord) => af.stamp,
);
