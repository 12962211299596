// @flow
import { useImperativeHandle } from 'react';
import { isServiceColumn } from './helpers';
import type { GridRef, TColumnApi, TGridApi } from './types.js.flow';

export const useGridRef = (
  ref: GridRef,
  columnApi: {| current: null | TGridApi |},
  gridApi: {| current: null | TColumnApi |},
  forceUpdate: () => void,
) => {
  const allColumns = columnApi.current && columnApi.current.getAllDisplayedColumns();
  const columnKeys = allColumns && allColumns.filter(({ colId }) => !isServiceColumn(colId)).map(({ colId }) => colId);

  const params = {
    columnKeys,
    autoConvertFormulas: true,
    processCellCallback: (x) => {
      const { field, type } = x.column.getColDef();
      const value = type === 'amount' ? x.value : x.column.colDef.valueFormatter(x);
      return field === 'hyperlink' ? `=HYPERLINK("${value}")` : value;
    },
    sheetName: 'report',
    skipPinnedTop: true,
    // fileName: 'report',
  };

  const exportAsExel = (onlySelected = false) => {
    try {
      gridApi.current.exportDataAsExcel({
        onlySelected,
        ...params,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const exportAsCsv = (onlySelected = false) => {
    try {
      gridApi.current.exportDataAsCsv({
        onlySelected,
        ...params,
      });
    } catch (e) {
      console.error(e);
    }
  };

  useImperativeHandle(ref, () => ({
    forceUpdate,
    exportAllRowsDataAsExcel: () => exportAsExel(),
    exportSelectedRowsDataAsExcel: () => exportAsExel(true),
    exportAllRowsDataAsCsv: () => exportAsCsv(),

    getRowsWithFilter: () => {
      const rowIdsList = [];
      if (gridApi.current) {
        gridApi.current.forEachNodeAfterFilterAndSort((node) => rowIdsList.push(node.data.documentID));
      }
      return rowIdsList;
    },
  }));
};
