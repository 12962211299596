import React, { useState, forwardRef, useImperativeHandle, useCallback, memo } from 'react';

import {
  MultipleAutocompleteVirtualized,
  TextField,
} from 'pages/company/grid/components/MultipleDropdownCellEditor/StyledComponents';
import Chip from '@mui/material/Chip';

const MultipleDropdownCellEditor = forwardRef((props, ref) => {
  const {
    values,
    column: { actualWidth },
  } = props;
  const defaultValue = props.value.length ? props.value : [];
  const [currentValue, setCurrentValue] = useState(defaultValue);

  // Component Editor Lifecycle methods
  useImperativeHandle(ref, () => ({
    // the final value to send to the grid, on completion of editing
    getValue() {
      return currentValue || [];
    },
  }));

  const handleChange = useCallback((e, selected) => {
    setCurrentValue(selected);
  }, []);

  const renderTags = useCallback(
    (chips, getTagProps) =>
      chips.map((option, index) => (
        <Chip key={option.value} label={option.label} size="small" {...getTagProps({ index })} />
      )),
    [],
  );

  return (
    <MultipleAutocompleteVirtualized
      open
      value={currentValue}
      options={values}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField {...params} inputProps={{ ...params.inputProps }} variant="standard" autoFocus />
      )}
      renderTags={renderTags}
      sx={{ width: actualWidth }}
      fullWidth
      autoComplete
      disablePortal
      disableCloseOnSelect
      multiple
      filterSelectedOptions
    />
  );
});

export default memo(MultipleDropdownCellEditor);
