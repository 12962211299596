// @flow
import React, { useCallback } from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';
import { useIntl } from 'react-intl';
import { renderOption, renderDivider } from './helpers/selectOptions';

import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextFieldForm from 'components/mui/Form/TextField/TextFieldForm';
import SelectForm from 'components/mui/Form/Select/SelectForm';

import elements from 'components/elements';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  itemField: {
    flip: false,
    direction: 'ltr',
  },
});
type Props = {
  handleSubmit: () => void,
  context: 'organization' | 'company',
  currencies: any,
};

const sorter = (a, b) => a.label.localeCompare(b.label);

const getOptionItem = (a, v, c) => (c ? a.concat([{ value: v.code, label: v.name }]) : a);

export const AMOUNT_REPRESENTATION_VALUES = {
  GENERALIZED: 'generalized',
  HIGHLIGHTED: 'highlighted_credit_transactions',
};

const amountRepresentationList = [
  { value: AMOUNT_REPRESENTATION_VALUES.GENERALIZED, label: 'Generic convention' },
  { value: AMOUNT_REPRESENTATION_VALUES.HIGHLIGHTED, label: 'Debit/Credit convention' },
];

const translatePrefix = 'forms.common.financial.amountsRepresentation.list.';
const getAmountRepresentationList = (formatMessage) =>
  amountRepresentationList.map(({ value, label }) => ({
    value,
    label: formatMessage({ id: `${translatePrefix}${value}`, defaultMessage: label }),
  }));

const FinancialForm: React$StatelessFunctionalComponent<Props> = ({ handleSubmit, context, currencies }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const renderOptions = useCallback(
    () => [
      ...currencies
        .reduce((a, v) => getOptionItem(a, v, v.favourite), [])
        .sort(sorter)
        .map(renderOption),
      renderDivider(),
      ...currencies
        .reduce((a, v) => getOptionItem(a, v, !v.favourite), [])
        .sort(sorter)
        .map(renderOption),
    ],
    [currencies],
  );

  return (
    <form onSubmit={handleSubmit} id="settings_form">
      {context === 'company' && (
        <>
          <Typography variant="body1" fontWeight="500" mb={2}>
            {formatMessage({
              id: 'forms.company.financial.currencyTitle',
              defaultMessage: 'Default Currency',
            })}
          </Typography>
          <Grid container spacing={2}>
            <Grid item sm={6} className={classes.itemField}>
              <Field
                component={SelectForm}
                renderOptions={renderOptions}
                label={formatMessage({
                  id: 'forms.common.currency.label',
                  defaultMessage: 'Currency',
                })}
                required
                name="currency"
                FormControlProps={{ fullWidth: true }}
                id="currency"
                data-element={elements.configuration.general.financial.currency}
              />
            </Grid>
          </Grid>
          <Divider sx={{ my: 3 }} />
          <Typography variant="body1" fontWeight="500" mb={2}>
            {formatMessage({
              id: 'forms.company.financial.financialTitle',
              defaultMessage: 'Financial information',
            })}
          </Typography>
        </>
      )}
      <Grid container spacing={2}>
        <Grid item sm={6} className={classes.itemField}>
          <Field
            component={TextFieldForm}
            label={formatMessage({
              id: 'forms.common.registration.label',
              defaultMessage: 'Company Registration Number',
            })}
            name="registration_number"
            type="text"
            fullWidth
            withTrim
            data-element={elements.configuration.general.financial.registrationNumber}
          />
        </Grid>
        <Grid item sm={6} className={classes.itemField}>
          <Field
            component={TextFieldForm}
            label={formatMessage({
              id: 'forms.common.vat.label',
              defaultMessage: 'VAT number',
            })}
            name="vat_number"
            type="text"
            fullWidth
            withTrim
            data-element={elements.configuration.general.financial.vat}
          />
        </Grid>
      </Grid>
      <Divider sx={{ my: 3 }} />
      <Typography variant="body1" fontWeight="500" mb={2}>
        {formatMessage({
          id: 'forms.common.financial.amountsRepresentation.label',
          defaultMessage: 'Grid view credits convention',
        })}
      </Typography>
      <Grid container spacing={2}>
        <Grid item sm={6} className={classes.itemField}>
          <Field
            component={SelectForm}
            options={getAmountRepresentationList(formatMessage)}
            label={formatMessage({
              id: 'forms.common.financial.amountsRepresentation.label',
              defaultMessage: 'Grid view credits convention',
            })}
            required
            name="amounts_representation"
            FormControlProps={{ fullWidth: true }}
            id="amounts_representation"
            data-element={elements.configuration.general.financial.amountsRepresentation}
          />
        </Grid>
      </Grid>
      <Box display="flex" alignItems="center" pt={2}>
        <Button
          type="submit"
          sx={{ minWidth: 120, ml: 'auto' }}
          data-element={elements.configuration.general.financial.saveButton}
        >
          {formatMessage({ id: 'button.save', defaultMessage: 'Save' })}
        </Button>
      </Box>
    </form>
  );
};

export default compose(reduxForm({ form: 'financialForm' }))(FinancialForm);
