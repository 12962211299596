// @flow
import React, { useRef, useEffect } from 'react';

import {
  MatchingColorsMap,
  TEXTRACT_MATCH_STATUSES,
  type TTextractMatchStatuses,
  type TMatchingColorsMap,
} from 'components/TextractCanvas/utils';
import { Map } from 'immutable';
import { type TTextractPolygonPartial } from './container';

type Props = {
  matchedLines: Map<number, string>,
  width: number,
  height: number,
  page: number,
  boundCells: any[],
  polygons: TTextractPolygonPartial[],
};

export const TextractCanvas = (props: Props) => {
  const { width, height, boundCells, matchedLines, polygons, page } = props;
  const canvasRef = useRef();
  const canvasId = `textract-${page}`;

  const cellStyle = (
    rowMatchStatus: ?TTextractMatchStatuses,
    polygon: TTextractPolygonPartial,
    colorsMap: TMatchingColorsMap,
  ) => {
    switch (true) {
      case parseInt(polygon.row, 10) === 1:
        return colorsMap[TEXTRACT_MATCH_STATUSES.HEADER];
      case rowMatchStatus === 'match':
        return colorsMap[TEXTRACT_MATCH_STATUSES.MATCH];
      default:
        return colorsMap[TEXTRACT_MATCH_STATUSES.DEFAULT];
    }
  };

  useEffect(() => {
    const positionCanvas = () => {
      const canvas = canvasRef.current;
      // Update the canvas position to match that of the image.
      // @to-do make positioning styles sharable between cancas, bindingbuttons, arrows component
      canvas.style.position = 'absolute';
      canvas.style.left = '0px';
      canvas.style.top = '0px';
      canvas.width = width;
      // height prop is updated in its own, while width is derivative value from height
      // and is affected by scale
      canvas.height = height;

      // Polygon points are defined as fractions of the total height and width of
      // the canvas.
      const context = canvas.getContext('2d');

      if (polygons) {
        polygons.forEach((poly) => {
          // @to-do add default to getter
          const rowMatchStatus = matchedLines.get(parseInt(poly.row, 10));
          // refactor based on rowMatchStatus. By default all rows are red, match status rows are green
          const { border, fill } = cellStyle(rowMatchStatus, poly, MatchingColorsMap);

          context.strokeStyle = border;
          context.fillStyle = fill;
          context.lineWidth = 1;
          const points = poly.Geometry.Polygon;
          context.beginPath();
          context.moveTo(canvas.width * points[0].X, canvas.height * points[0].Y);
          points.slice(1).forEach((point) => context.lineTo(canvas.width * point.X, canvas.height * point.Y));
          context.closePath();
          context.stroke();

          context.fillRect(
            canvas.width * points[0].X,
            canvas.height * points[0].Y,
            canvas.width * (points[1].X - points[0].X),
            canvas.height * (points[2].Y - points[0].Y),
          );
        });
      }
    };

    window.addEventListener('resize', positionCanvas);
    positionCanvas();
    return () => window.removeEventListener('resize', positionCanvas);
  }, [polygons, height, boundCells, width, matchedLines]);

  return <canvas id={canvasId} ref={canvasRef} />;
};
