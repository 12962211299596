// @flow
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import type { StoreExportFormats } from 'domain/documents/documentsModel';

import Tooltip from 'components/mui/Tooltip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import { exportFormatsSelector } from 'domain/companies';
import { getExportFormatOptions } from 'pages/company/helpers';
import { exportDataAction } from 'domain/documents';

type TExportButton = {
  linkID: string,
};

const ExportButton: React$StatelessFunctionalComponent<TExportButton> = ({ linkID }) => {
  const dispatch = useDispatch();
  const popupState = usePopupState({ variant: 'popover', popupId: 'LinkedExportMenu' });
  const exportFormats = useSelector(exportFormatsSelector);

  const options = getExportFormatOptions(exportFormats);

  const onClick = (format: StoreExportFormats) => {
    popupState.close();
    dispatch(exportDataAction({ format, linkedId: linkID }));
  };

  return (
    <>
      <Tooltip
        t={{
          id: 'tooltip.export',
          defaultMessage: 'Export',
        }}
      >
        <IconButton color="primary" {...bindTrigger(popupState)}>
          <SimCardDownloadOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Menu
        {...bindMenu(popupState)}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {options.map((o) => (
          <MenuItem key={o.value} onClick={() => onClick(o.value)}>
            {o.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ExportButton;
