// @flow
import React, { useEffect, useState, useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { getJournalEntryForGridAction } from 'domain/journal/actions';

import Table from 'components/Tables';
import Spinner from 'components/mui/CircularProgressWithBackdrop';
import Box from '@mui/material/Box';

interface IProps {
  documentID: string;
}

const RowDetailsJE = ({ documentID }: IProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    new Promise((resolve, reject) => {
      setIsLoading(true);
      dispatch(getJournalEntryForGridAction({ documentID, resolve, reject, readonly: true }));
    }).finally(() => {
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleWheelCapture = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <Box position="relative" width="100%" height="100%" sx={{ p: 1 }} onWheelCapture={handleWheelCapture}>
      <Spinner isOpen={isLoading} BackdropProps={{ sx: { position: 'absolute' } }} />
      <Table documentID={documentID} hasVisibleColumnsManageButton={false} />
    </Box>
  );
};

export default RowDetailsJE;
