// @flow
import React, { useState } from 'react';
import { Set } from 'immutable';
import { type DocumentsType, type Document } from 'domain/documents';
import moment from 'moment';
import elements from 'components/elements';

import DropTileCard from 'pages/company/TileGrid/TileCard/DropTileCard';
import DragTileCard from 'pages/company/TileGrid/TileCard/DragTileCard';
import { DnDItemSource, DOCUMENT, LINKED_DOCUMENT } from 'components/DnDItem';
import { TileCardGridList, GridTileCard } from 'pages/company/TileGrid/TileCardList/StyledComponents';
import TileCard from 'pages/company/TileGrid/TileCard/TileCard';
import TileCardFake from 'pages/company/TileGrid/TileCard/TileCardFake';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

const DragSourceDocument = DnDItemSource(DOCUMENT);
const DragSourceLinked = DnDItemSource(LINKED_DOCUMENT);

type Props = {
  clearSelection: () => void,
  isLinkedPanelSelected: boolean,
  dList: any,
  dseq: any,
  getAnalizing: (d?: string) => boolean,
  processing: number,

  selectedDocuments: Set<string>,
  onDocumentClick: (e: MouseEvent, d: DocumentsType) => void,
  onClickPreview: (d: Document) => void,
  onContextMenu: (event: SyntheticMouseEvent<HTMLDivElement>, document: DocumentsType) => void,
  onClickNote: (event: MouseEvent, doc: DocumentsType) => void,
  onBeginDrag: (linkid: string) => void,
  onClickLinked: Function,
  isSearch: boolean,
  refProxy: (el: HTMLElement | null) => void,
  hasNext: number,
};

const TileCardList: React$StatelessFunctionalComponent<Props> = ({
  clearSelection,
  isLinkedPanelSelected,
  dList,
  dseq,
  getAnalizing,
  processing,
  selectedDocuments,
  onDocumentClick,
  onClickPreview,
  onContextMenu,
  onClickNote,
  onBeginDrag,
  onClickLinked,
  isSearch,
  refProxy,
  hasNext,
}) => {
  const [idDraggedDocument, setIdDraggedDocument] = useState(null);

  return (
    <>
      {dList.map((v, i) =>
        v.size > 0 ? (
          <section key={dseq.get(i) || 'analyze-section'} onClick={clearSelection} role="presentation">
            <Divider>{moment(dseq.get(i), 'MM-YYYY').format('MMMM YYYY')}</Divider>

            <TileCardGridList>
              {getAnalizing(dseq.get(i)) && (
                <GridTileCard data-element={elements.content.documents.item}>
                  <TileCardFake value={processing} />
                </GridTileCard>
              )}

              {v.map((d) => (
                <GridTileCard data-element={elements.content.documents.item} key={d.documentID}>
                  <DropTileCard
                    item={d}
                    selectedDocuments={selectedDocuments}
                    clearSelection={clearSelection}
                    idDraggedDocument={idDraggedDocument}
                  >
                    <DragTileCard
                      DragSource={d.linkid ? DragSourceLinked : DragSourceDocument}
                      srcType={DOCUMENT}
                      document={d}
                      onBeginDrag={onBeginDrag}
                      onEndDrag={clearSelection}
                      setIdDraggedDocument={setIdDraggedDocument}
                      isMultiplyDND={
                        !isLinkedPanelSelected && selectedDocuments.has(d.documentID) && selectedDocuments.size > 1
                      }
                    >
                      <TileCard
                        document={d}
                        selected={!isLinkedPanelSelected && selectedDocuments.has(d.documentID)}
                        onContextMenu={onContextMenu}
                        onClickLinked={onClickLinked}
                        onClickPreview={onClickPreview}
                        onClickLink={onDocumentClick}
                        onClickNote={onClickNote}
                      />
                    </DragTileCard>
                  </DropTileCard>
                </GridTileCard>
              ))}
            </TileCardGridList>
          </section>
        ) : (
          getAnalizing(dseq.get(i)) && (
            <section key="analyze-section">
              <Divider>{moment(dseq.get(i), 'MM-YYYY').format('MMMM YYYY')}</Divider>
              <TileCardGridList>
                <GridTileCard data-element={elements.content.documents.item}>
                  <TileCardFake value={processing} />
                </GridTileCard>
              </TileCardGridList>
            </section>
          )
        ),
      )}
      {processing > 0 && dList.size < 1 && isSearch && (
        <TileCardGridList>
          <TileCardFake value={processing} />
        </TileCardGridList>
      )}
      <Box ref={refProxy} sx={{ height: hasNext ? 150 : 20, position: 'relative' }} />
    </>
  );
};

export default TileCardList;
