import { useSearchParams } from 'react-router-dom';

// Hook
function useQuery() {
  // https://reactrouter.com/en/main/hooks/use-search-params
  // The setSearchParams function works like navigate, but only for the search portion of the URL. Also note that the second arg to setSearchParams is the same type as the second arg to navigate.
  const [searchParams, setSearchParams] = useSearchParams();
  return Object.fromEntries(searchParams);
}

export default useQuery;
