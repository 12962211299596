// @flow
import React from 'react';

import EmptyContainer from './components/EmptyContainer';

import noResults from './images/noResults.png';

type Props = {
  link: string,
};

const NoResult: React$StatelessFunctionalComponent<Props> = ({ link }) => (
  <EmptyContainer
    link={link}
    imageSrc={noResults}
    title={{ id: 'document.search.no_result', defaultMessage: 'No results have been found' }}
    description1={{
      id: 'document.search.no_document_decription_1',
      defaultMessage: "We can't find any document matching your search.",
    }}
    description2={{
      id: 'document.search.no_document_decription_2',
      defaultMessage: 'Please, try a different search.',
    }}
    buttonText={{
      id: 'document.search.no_document.reset_search',
      defaultMessage: 'Reset search',
    }}
  />
);

export default NoResult;
