// @flow
import { match } from 'path-to-regexp';
import ROUTES_PATH from 'domain/router/routesPathConfig';

// FILTER ROUTES TREE BY RESTRICTION
export const routesByRestriction = (ACLFilterFN, routes) =>
  routes.reduce((acc, route) => {
    const isAvailable = ACLFilterFN(route);

    if (isAvailable) {
      if (route.children) {
        const children = routesByRestriction(ACLFilterFN, route.children);

        acc.push({ ...route, children });
      } else {
        acc.push(route);
      }
    }

    return acc;
  }, []);

/**
 *
 * @param path - path from router like - /company/confidential/:COMPANY_ID/:CATEGORY1/:CATEGORY2?/:CATEGORY3?
 * @param pathname - location pathname like - /company/confidential/6869691756/2/6
 * @returns {boolean}
 */
export const isMatchRoute = (path, pathname = window.location.pathname) => {
  if (typeof path !== 'string') return false; // Root route has no path
  // const p = path.replace('/*', '/(.*)');
  // path-to-regexp lib cant parse '/*' - so we skip route with '/*' - its root route for children routes
  // be careful with this behaviour
  // https://github.com/serverless-nextjs/serverless-next.js/issues/2433
  // https://github.com/pillarjs/path-to-regexp/pull/270
  if (path.endsWith('/*')) return false;
  const fn = match(path, { decode: decodeURIComponent });

  return Boolean(fn(pathname));
};

export const matchRoute = {
  isDocument: (pathname) => {
    const path = ROUTES_PATH.DOCUMENT.absolute;

    return isMatchRoute(path, pathname);
  },
  isCompany: (pathname) => {
    const isConfidential = pathname.toLowerCase().includes('confidential');

    const path = isConfidential
      ? ROUTES_PATH.COMPANY_CONFIDENTIAL_WORKSPACE.absolute
      : ROUTES_PATH.COMPANY_WORKSPACE.absolute;

    return isMatchRoute(path, pathname);
  },
  isLogin: (pathname) => {
    const path = ROUTES_PATH.AUTH_LOGIN.absolute;

    return isMatchRoute(path, pathname);
  },
};
