// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { companiesByOrganizationSelector, loadedSelector } from 'domain/companies';
import { loadingSelector, sagaDataLoadingSelector } from 'domain/env';

import Box from '@mui/material/Box';
import GridCard from 'pages/companies/components/Grid/Card/GridCard';
import NotFound from 'pages/companies/components/NotFound';

const mapStateToProps = (state) => ({
  isSagaDataLoading: sagaDataLoadingSelector(state),
  list: companiesByOrganizationSelector(state),
  isLoading: loadingSelector(state),
  loaded: loadedSelector(state),
});
const CompaniesGrid = () => {
  const { list, isLoading, isSagaDataLoading, loaded } = useSelector(mapStateToProps);

  return list.size === 0 && !isLoading && !isSagaDataLoading && loaded ? (
    <NotFound />
  ) : (
    <Box display="grid" gridTemplateColumns="repeat(auto-fill, minmax(180px, 1fr))" gap={2} my={2}>
      {list.map((entry) => (
        <GridCard key={entry.get('checksum')} entry={entry} />
      ))}
    </Box>
  );
};

export default CompaniesGrid;
