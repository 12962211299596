// @flow
import React, { useState, forwardRef, useImperativeHandle, useCallback, memo } from 'react';
import { NumericFormat } from 'react-number-format';

import Box from '@mui/material/Box';
import { TextField } from 'pages/company/grid/components/InputNumberCellEditor/StyledComponents';

const InputNumberCellEditor = forwardRef((props, ref) => {
  const [currentValue, setCurrentValue] = useState(props.value ?? '');

  useImperativeHandle(ref, () => ({
    // the final value to send to the grid, on completion of editing
    getValue() {
      if (currentValue === '') return null;
      return Number(currentValue);
    },
  }));

  const handleChange = useCallback(({ value: inputValue }) => {
    setCurrentValue(inputValue);
  }, []);

  return (
    <Box display="flex" alignItems="center" height="100%" mx={0.5}>
      <NumericFormat
        getInputRef={ref}
        customInput={TextField}
        decimalScale={0}
        value={currentValue}
        onValueChange={handleChange}
        variant="standard"
        dir="ltr"
        fullWidth
        autoFocus
      />
    </Box>
  );
});

export default memo(InputNumberCellEditor);
