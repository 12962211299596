// @flow
import { createContext } from 'react';
import { TGridApi } from 'pages/company/grid/types.js.flow';

export type TGridApiRef = {| current: TGridApi |} | null;

export type TCompanyContext = {
  gridApi: TGridApiRef,
  companyType: 'standard' | 'confidential',
};

const CompanyContext: TCompanyContext = createContext({
  gridApi: null,
  companyType: undefined,
});

export default CompanyContext;
