import cross from 'pages/configurations/assets/cross.svg';

import qboButton from './icons/qboButton.png';
import xeroButton from './icons/xeroButton.png';
import zohoButton from './icons/zohoButton.png';
import sageGlobalButton from './icons/sageGlobalButton.svg';
import checkIcon from './icons/checkIcon.svg';

import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    connectBtn: {
      height: 34,
      transition: 'opacity 0.3s ease',
      display: 'inline-block',
      border: 'none',
      cursor: 'pointer',
      background: 'none',
      '&:hover': {
        opacity: 0.75,
      },
      '&[disabled]': {
        cursor: 'not-allowed',
        filter: 'grayscale(100%)',
      },
    },
    qboButton: {
      backgroundImage: `url("${qboButton}")`,
      backgroundSize: 'cover',
      width: 195,
    },
    check: {
      backgroundImage: `url("${checkIcon}")`,
      backgroundSize: 'cover',
      width: 18,
      height: 14,
      marginRight: 10,
    },
    cross: {
      backgroundImage: `url("${cross}")`,
      backgroundSize: 'cover',
      width: 10,
      height: 10,
      marginRight: 10,
    },

    xeroButton: {
      backgroundImage: `url("${xeroButton}")`,
      backgroundSize: 'cover',
      width: 135,
      backgroundColor: 'transparent',
    },
    zohoButton: {
      backgroundImage: `url("${zohoButton}")`,
      backgroundSize: 'cover',
      width: 185,
      backgroundColor: 'transparent',
    },
    sageGlobalButton: {
      backgroundImage: `url("${sageGlobalButton}")`,
      backgroundSize: 'cover',
      width: 135,
      height: 43,
      backgroundColor: 'transparent',
    },
    qbdButton: {
      // backgroundColor: theme.colors.secondaryDark,
      padding: [0, 45],
      // color: theme.colors.primaryWhite,
      fontSize: 13,
      fontWeight: 600,
      borderRadius: 4,
    },
  }),
);
