// @flow
import { Record, List, type RecordOf, type RecordFactory, Map } from 'immutable';
import { sortByNumbersAndName, sortProp } from 'domain/companies';
import { type NotificationsType } from 'domain/companies/helpers';
import {
  type OrganizationType,
  type SigningsType,
  type OrganizationListItemType,
  type OrganizationListType,
  type OrganizationFeaturesType,
  type OrgBackupConnection,
} from './types.js.flow';
import type { OrganizationsFeaturesDataType, OrgBackupConnectionDataType } from 'domain/contracts';
import { backupConnectionTypes, backupConnectionStatus } from 'domain/contracts';

export const organizationListItemFactory = (data: OrganizationListItemType): RecordOf<OrganizationListItemType> => {
  const record: RecordFactory<OrganizationListItemType> = new Record({
    picture: '',
    name: '',
    id: '',
    notifications: 0,
  });
  return record(data);
};

export const organizationListAdapter = (list: $ReadOnlyArray<OrganizationListItemType> = []): OrganizationListType =>
  List(list).map((item) => organizationListItemFactory(item));

const contactTypeFactory = new Record({
  phone: '',
  first_name: '',
  last_name: '',
  email: '',
});

export const signerFactory = new Record({
  password: '',
  token_name: null,
  name: '',
});

export const signingsFactory = (data = {}): SigningsType => {
  const signings = new Record({
    guid: '',
    tokens: new List(),
  });
  const record = signings(data);
  if (data.tokens) {
    return record.set('tokens', List(data.tokens.map((item) => signerFactory(item))));
  }
  return record;
};

export function organizationAdapter(data): RecordOf<OrganizationType> {
  const org = new Record({
    picture: '',
    name: '',
    city: '',
    country: '',
    contact: contactTypeFactory(),
    street: '',
    vat_number: '',
    is_paid: false,
    id: null,
    registration_number: '',
    signings: signingsFactory(),
    list: List(),
    sort: 'name',
    filter: '',
    currentOrganization: null,
    features: Map(),
    backupConnection: List(),
    whatsapp_phone: null,
    expiration_days: null,
    amounts_representation: '',
    loaded: false,
  });
  const record = org(data);
  if (typeof data !== 'undefined') {
    return record.set('contact', contactTypeFactory(data.contact));
  }
  return record;
}

// eslint-disable-next-line max-len
export function orgBackupConnectionAdapter(data: OrgBackupConnectionDataType): RecordOf<OrgBackupConnection> {
  return new Record({
    type: backupConnectionTypes[0],
    connection: backupConnectionStatus[0],
    name: null,
    username: null,
    error: null,
  })(data);
}

// eslint-disable-next-line max-len
export function orgBackupConnectionsAdapter(
  data: Array<OrgBackupConnectionDataType>,
): List<RecordOf<OrgBackupConnection>> {
  return new List(data.map(orgBackupConnectionAdapter));
}

// eslint-disable-next-line max-len
export function organizationFeaturesAdapter(data: OrganizationsFeaturesDataType): Map<OrganizationFeaturesType> {
  return new Map(data);
}

// eslint-disable-next-line max-len
export function sortOrganizations(organizations: OrganizationListType, sort: string, notifications: NotificationsType) {
  const list = organizations
    .map((item) => item.set('notifications', notifications.get(item.id, 0)))
    .toList()
    .sortBy(sortProp('name'));
  const filters = {
    messages: (a, b) => sortByNumbersAndName(b.notifications, a.notifications, b.name, a.name),
  };
  return filters[sort] ? list.sort(filters[sort]) : list;
}

// eslint-disable-next-line max-len
export function filterOrganizations(organizations: OrganizationListType, term: string): OrganizationListType {
  return organizations.filter((f) => ~f.get('name', '').toLocaleLowerCase().indexOf(term.toLocaleLowerCase()));
}
