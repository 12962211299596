// @flow
/* eslint-disable react/no-array-index-key */
import * as React from 'react';

import ERPLogo from 'components/ERPLogo';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import CheckboxSetBase from 'components/mui/Form/CheckboxSet/CheckboxSetBase';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { update, flow } from 'lodash/fp';
import { styled } from '@mui/material/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import type { ERPSettings as ERPSettinsList } from 'domain/settings/settingsModel';
import { currentCompanySelector } from 'domain/documents/documentSelector';

import { currentERPCompaniesSelector } from 'domain/settings/settingsSelector';
import { getCurrentERPCompaniesAction } from 'domain/settings/settingsActions';
import { type ConfigurationCompanyListType } from 'domain/companies/helpers';

import { connect } from 'react-redux';
import { compose, type Dispatch } from 'redux';
import { FormattedMessage } from 'react-intl';

import type { OrderedMap } from 'immutable';

const sectionStyles = { maxHeight: 300, width: '100%' };

const ListItemProps = { dense: true, disablePadding: true };
const ListProps = {
  dense: true,
  disablePadding: true,
};

const CheckboxSet = (params) => (
  <CheckboxSetBase
    {...flow(
      update(['ListProps'], (l) => ({ ...ListProps, ...l })),
      update(['ListItemProps'], (l) => ({ ...ListItemProps, ...l })),
    )(params)}
  />
);

const SectionTitle = ({ children, ...rest }) => (
  <Typography mb={1} {...rest} variant="subtitle2">
    {children}
  </Typography>
);

const Divider = styled(ArrowForwardIosIcon)(({ theme }) => ({
  transform: `rotate(${theme.direction === 'rtl' ? 180 : 0}deg)`,
}));
const Count = styled(Typography)(() => ({
  marginLeft: 'auto',
}));

type Props = {|
  erp: string,
  erpSettings: OrderedMap<string, ERPSettinsList>,
  erpCompanies: ConfigurationCompanyListType,
  onPropagate: (settings: Array<string>, companies: Array<string>) => void,
  currentCompanyId: string,
  getCurrentERPCompanies: Dispatch<getCurrentERPCompaniesAction>,
|};

type State = {|
  isOpen: boolean,
  selectedSettings: ?Array<string>,
  selectedCompanies: ?Array<string>,
|};

class Propagate extends React.Component<Props, State> {
  state = {
    isOpen: false,
    selectedSettings: null,
    selectedCompanies: null,
  };

  onChangeCompanies = (e, value) => {
    const selectedCompanies = this.getSelectedCompanies();
    const newSelected = selectedCompanies.includes(value)
      ? selectedCompanies.filter((i) => i !== value)
      : [...selectedCompanies, value];
    this.setState({ selectedCompanies: newSelected });
  };

  getCompanies = () => {
    const { erpCompanies, currentCompanyId } = this.props;
    return erpCompanies.filter((company) => company.id !== currentCompanyId).toJS();
  };

  getCompaniesOptions = () =>
    this.getCompanies().map((company) => ({
      label: company.name,
      value: company.id,
    }));

  getSelectedSettings = () => {
    const { selectedSettings } = this.state;
    return selectedSettings || this.extractFieldNamesWithValueFromSettings();
  };

  getSelectedCompanies = () => {
    const { selectedCompanies } = this.state;
    return selectedCompanies || this.extractCompanyIds();
  };

  extractCompanyIds = () => this.getCompanies().map((company) => company.id);

  toggleModal = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  openModal = () => {
    this.toggleModal();
    this.props.getCurrentERPCompanies();
  };

  extractFieldNamesWithValueFromSettings = () =>
    Object.values(this.props.erpSettings.toJS()).reduce(
      (res, group) => [...res, ...group.map((setting) => setting.name)],
      [],
    );

  fieldChange = (e, fieldName: string) => {
    const currentSelectedSettings = this.getSelectedSettings();
    const selectedSettings = currentSelectedSettings.includes(fieldName)
      ? currentSelectedSettings.filter((setting) => setting !== fieldName)
      : [...currentSelectedSettings, fieldName];
    this.setState({ selectedSettings });
  };

  propagate = (e: SyntheticInputEvent) => {
    const { onPropagate } = this.props;
    e.stopPropagation();
    e.preventDefault();
    onPropagate(this.getSelectedSettings(), this.getSelectedCompanies());
    this.toggleModal();
    this.clearSelected();
  };

  clearSelected = () => {
    this.setState({ selectedSettings: null, selectedCompanies: null });
  };

  render() {
    const { erp, erpSettings } = this.props;
    const { isOpen } = this.state;
    const selectedSettings = this.getSelectedSettings();

    const companiesOptions = this.getCompaniesOptions();
    const selectedCompanies = this.getSelectedCompanies();

    return (
      <>
        <Button
          variant="text"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            this.openModal();
          }}
        >
          <FormattedMessage id="forms.configPropagate.btn" defaultMessage="Propagate" />
        </Button>
        {isOpen && (
          <Dialog open={isOpen} fullWidth onClose={this.toggleModal}>
            <DialogTitle sx={{ textAlign: 'center' }}>
              <ERPLogo erpType={erp} />
              <Typography variant="h6">
                <FormattedMessage id="forms.configPropagate.title" defaultMessage="Bulk configuration" />
              </Typography>
            </DialogTitle>
            <DialogContent sx={(theme) => ({ pt: `${theme.spacing(1)}!important` })}>
              <Stack direction="row">
                <Stack flex={1} alignItems="center">
                  <SectionTitle>
                    <FormattedMessage id="forms.configPropagate.settings" defaultMessage="Settings" />
                  </SectionTitle>
                  <List
                    variant="outlined"
                    component={Paper}
                    subheader={<li />}
                    sx={{ ...sectionStyles, overflow: 'auto' }}
                  >
                    {Object.entries(erpSettings.toJS()).map(([title, group]) => {
                      return (
                        <li key={title}>
                          <ul style={{ padding: 0, margin: 0 }}>
                            <ListSubheader>{title}</ListSubheader>
                            <CheckboxSet
                              items={group.map((i) => ({ ...i, value: i.name }))}
                              checked={selectedSettings}
                              onChange={this.fieldChange}
                              ListProps={{
                                component: 'div',
                              }}
                            />
                          </ul>
                        </li>
                      );
                    })}
                  </List>
                </Stack>
                <Divider color="primary" sx={{ mt: 6, mx: 1 }} />
                <Stack flex={1} alignItems="center">
                  <SectionTitle>
                    <FormattedMessage id="forms.configPropagate.companies" defaultMessage="Companies" />
                  </SectionTitle>
                  {companiesOptions.length ? (
                    <>
                      <CheckboxSet
                        items={companiesOptions}
                        checked={selectedCompanies}
                        onChange={this.onChangeCompanies}
                        ListProps={{
                          component: Paper,
                          variant: 'outlined',
                          sx: { ...sectionStyles, overflow: 'auto' },
                        }}
                      />
                      <Count
                        variant="body2"
                        dir="auto"
                      >{`${selectedCompanies.length} of ${companiesOptions.length}`}</Count>
                    </>
                  ) : (
                    <Stack flex={1} justifyContent="center" alignItems="center">
                      <Typography variant="body2">
                        <FormattedMessage
                          id="forms.configPropagate.noCompanies"
                          defaultMessage="No companies connected"
                        />
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.propagate} disabled={!selectedSettings.length || !selectedCompanies.length}>
                <FormattedMessage id="forms.configPropagate.btn" defaultMessage="Propagate" />
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentCompanyId: currentCompanySelector(state),
  erpCompanies: currentERPCompaniesSelector(state),
});

const mapDispatchToProps = {
  getCurrentERPCompanies: getCurrentERPCompaniesAction,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Propagate);
