/* @flow */
import * as React from 'react';

// redux
import { compose } from 'redux';
import reduxForm from 'redux-form/lib/immutable/reduxForm';

// components
import Field from 'redux-form/lib/immutable/Field';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from 'components/mui/Form/TextField/TextFieldForm';
import CheckboxForm from 'components/mui/Form/Checkbox/CheckboxForm';
import FormControlLabel from '@mui/material/FormControlLabel';

import Grid from '@mui/material/Grid';

// translations
import { FormattedMessage } from 'react-intl';

const Checkbox = ({ fullWidth, ...props }) => {
  // input props should be passed flat rather then nested under input key
  const { input: inputProps, ...checkboxProps } = props;
  return (
    <FormControlLabel
      sx={{ display: 'flex', width: 'fit-content' }}
      control={<CheckboxForm {...checkboxProps} {...inputProps} />}
      label={props.label}
    />
  );
};

export type Fields = {|
  name: string,
  type: string,
  hidden: boolean,
  label: {|
    id: string,
    defaultMessage: string,
  |},
|};

type Props = {
  isLoading: boolean,
  handleSubmit: () => void,
  handleConnectModal: () => void,
  fields: $ReadOnlyArray<Fields>,
  section: {|
    element: (props: any) => React.Element<*>,
    fields: $ReadOnlyArray<Fields>,
    onSuccessToastMessageProps: ?{ id: string, defaultMessage: string },
  |},
};
class ERPConnectForm extends React.Component<Props> {
  static defaultProps = {};

  render() {
    const { handleSubmit, fields, isLoading, section = null, handleConnectModal } = this.props;

    return (
      <>
        <DialogContent sx={(theme) => ({ textAlign: 'center', direction: 'ltr', pt: `${theme.spacing(1)}!important` })}>
          <Grid container spacing={3} component="form" id="erp-connect-form" onSubmit={handleSubmit}>
            {fields.map(
              (field) =>
                !field.hidden && (
                  <Grid key={field.name} item xs={field.xs || 6} alignSelf="start">
                    <Field
                      fullWidth
                      name={field.name}
                      component={field.type === 'checkbox' ? Checkbox : TextField}
                      type={field.type}
                      label={<FormattedMessage id={field.label.id} defaultMessage={field.label.defaultMessage} />}
                      autoComplete="new-password"
                    />
                  </Grid>
                ),
            )}
            {section && section.fields ? section.element(section.fields) : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConnectModal} variant="text">
            <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
          </Button>
          <Button type="submit" form="erp-connect-form" disabled={isLoading}>
            <FormattedMessage id="sageConnect.signIn" defaultMessage="Sign In" />
          </Button>
        </DialogActions>
      </>
    );
  }
}

export default compose(reduxForm({ form: 'erpConnect', forceUnregisterOnUnmount: true }))(ERPConnectForm);
