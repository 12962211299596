// @flow
import React, { useCallback, useState, useMemo, memo } from 'react';
import { useSelector } from 'react-redux';
import { chatAllUsersByIdSelector } from 'domain/chat/chatSelector';
import { useIntl } from 'react-intl';
import { useConfirm } from 'material-ui-confirm';
import { Set } from 'immutable';

import TagBox from 'pages/common/Dialog/DialogTagsManage/components/TagBox';

import Dialog from 'components/mui/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TagSuggestions from 'components/mui/Layouts/components/TagSuggestions';
import TagsSearch from './components/TagsSearch';

import { type Tag } from 'domain/documents';
import type { ChatUserRecords } from 'domain/chat/types.js.flow';

type TDialogTagsManage = {
  open: boolean,
  initialTags: Array<Tag>,
  onCancel: () => void,
  onSubmit: ({ tagsToAdd: Set<string>, tagsToRemove: Set<string> }) => void,
};

export const ROLES_FOR_CONFIRM = ['user', 'confidential-user', 'accountant'];

const DialogTagsManage: React$StatelessFunctionalComponent<TDialogTagsManage> = ({
  initialTags,
  onCancel,
  onSubmit,
  open,
}) => {
  const { formatMessage } = useIntl();
  const confirm = useConfirm();
  const userList: ChatUserRecords = useSelector(chatAllUsersByIdSelector);
  const [currentTags, setCurrentTags] = useState(Set(initialTags.filter((tag) => tag[0] !== '_')));
  const [currentValue, setCurrentValue] = useState('');

  const isUser = useCallback((tag: string) => +!!userList.get(tag), [userList]);

  const getUserForConfirm = useCallback(
    (tag: string) => {
      const user = userList.get(tag);

      return user && ROLES_FOR_CONFIRM.includes(user.role) && user.restricted ? user : null;
    },
    [userList],
  );

  const systemTags = useMemo(() => initialTags.filter((tag) => tag[0] === '_'), [initialTags]);
  // need send all tags to b-end, with system tags too
  const currentAllTags = currentTags.concat(systemTags);

  const isDisabledSave = useMemo(() => {
    const documentTags = initialTags.filter((tag) => tag[0] !== '_');

    return Set(currentTags).equals(Set(documentTags));
  }, [currentTags, initialTags]);

  const sortedTags = useMemo(
    () => currentTags.sort((tagA, tagB) => isUser(tagB) - isUser(tagA)),
    [isUser, currentTags],
  );

  const tagsToAdd = useMemo(
    () => currentAllTags.filter((tag) => !initialTags.includes(tag)),
    [initialTags, currentAllTags],
  );

  const tagsToRemove = useMemo(
    () => initialTags.filter((tag) => !currentAllTags.includes(tag)),
    [initialTags, currentAllTags],
  );

  const usersToAllowAccess = useMemo(
    () =>
      tagsToAdd.reduce((acc: Set<string>, tag: string) => {
        const user = getUserForConfirm(tag);

        return user ? acc.add(user.username) : acc;
      }, new Set()),
    [tagsToAdd, getUserForConfirm],
  );

  const usersToDenyAccess = useMemo(
    () =>
      tagsToRemove.reduce((acc: Set<string>, tag: string) => {
        const user = getUserForConfirm(tag);

        return user ? acc.add(user.username) : acc;
      }, new Set()),
    [tagsToRemove, getUserForConfirm],
  );

  const isAutoConfirm = usersToAllowAccess.size === 0 && usersToDenyAccess.size === 0;

  const isForbiddenAdd = useCallback(
    (tag?: string) => {
      const currentTag = tag || currentValue;

      return (
        currentTags.includes(currentTag) || !currentTag || (typeof currentTag === 'string' && currentTag[0] === '_')
      );
    },
    [currentTags, currentValue],
  );

  const onChangeInput = useCallback((value: string) => {
    setCurrentValue(value);
  }, []);

  const handleAddition = useCallback(
    (tag?: string) => {
      if (!isForbiddenAdd(tag)) {
        setCurrentValue('');
        setCurrentTags([...currentTags, tag]);
      }
    },
    [currentTags, isForbiddenAdd],
  );

  const handleDelete = useCallback(
    (deleteTag: string) => {
      const tags = currentTags.filter((tag) => deleteTag !== tag);

      setCurrentTags(tags);
    },
    [currentTags],
  );

  const handleSubmit = useCallback(() => {
    if (isAutoConfirm) {
      onSubmit({ tagsToAdd, tagsToRemove });
    } else {
      confirm({
        title: formatMessage({
          id: 'confirm.userGainingDocumentAccess.title',
          defaultMessage: 'Access to document',
        }),
        description: (
          <>
            {usersToAllowAccess.size > 0 && (
              <Box mb={2}>
                {formatMessage(
                  {
                    id: 'confirm.userAllowDocumentAccess.placeholder',
                    defaultMessage: `This action will enable ${usersToAllowAccess.join(', ')} to view the document`,
                  },
                  {
                    usernames: <strong>{usersToAllowAccess.join(', ')}</strong>,
                  },
                )}
              </Box>
            )}
            {usersToDenyAccess.size > 0 && (
              <Box>
                {formatMessage(
                  {
                    id: 'confirm.userDenyDocumentAccess.placeholder',
                    defaultMessage: `This action will disable ${usersToDenyAccess.join(', ')} to view the document`,
                  },
                  {
                    usernames: <strong>{usersToDenyAccess.join(', ')}</strong>,
                  },
                )}
              </Box>
            )}
          </>
        ),
        confirmationButtonProps: {
          color: usersToDenyAccess.size > 0 ? 'error' : 'primary',
        },
      }).then(() => {
        onSubmit({ tagsToAdd, tagsToRemove });
      });
    }
  }, [onSubmit, tagsToAdd, tagsToRemove, usersToAllowAccess, confirm, formatMessage, isAutoConfirm, usersToDenyAccess]);

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      title={formatMessage({ id: 'modal.document.tags.title', defaultMessage: 'Document Tags' })}
      maxWidth="sm"
      withActions={false}
      withContent={false}
      PaperProps={{ sx: { overflow: 'inherit' } }}
    >
      <DialogContent>
        <TagSuggestions
          InputComponent={TagsSearch}
          inputValue={currentValue}
          onSelect={handleAddition}
          onInputChange={onChangeInput}
          prefixOutputWithHashtag={false}
          inputComponentProps={{ disabled: isForbiddenAdd(), onClick: handleAddition }}
        />
        <Stack gap={0.5} flexWrap="wrap" direction="row" mt={1}>
          {sortedTags.map((tag) => (
            <TagBox key={tag} tag={tag} onDelete={handleDelete} />
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="text">
          {formatMessage({ id: 'button.cancel', defaultMessage: 'Cancel' })}
        </Button>
        <Button onClick={handleSubmit} disabled={isDisabledSave}>
          {formatMessage({ id: 'button.save', defaultMessage: 'Save' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default memo(DialogTagsManage);
