// @flow
import React from 'react';
import { SYMBOL_FOR_EMPTY_CELL, AprovalFactoryCreator } from 'pages/company/grid/helpers';
import type { RawApproval } from 'domain/approvals/types.js.flow';

import Approver from 'pages/components/Approver';
import Avatar from '@mui/material/Avatar';
import Rest from '../RestCount';
import Stack from '@mui/material/Stack';

const [SIZE, SPACING] = ['small', 0.25];

const Wrapper = React.forwardRef((props, ref) => <Stack direction="row" flexWrap="nowrap" {...props} ref={ref} />);

interface IProps {
  nodes: RawApproval[];
}

interface IState {
  hiddenItems: string[];
}

class AgGridApprovals extends React.Component<IProps, IState> {
  state = {
    hiddenItems: [],
  };

  componentDidMount() {
    setTimeout(this.initObservers, 0);
  }

  getVisibleKeys = () => (this.sortedApprovalList || []).filter((node) => !this.isHiddenKey(node.userid));

  getApprovalsStat = () => {
    const approvals = this.sortedApprovalList || [];
    const visibleNodes = this.getVisibleKeys();
    const count = approvals.length;
    const countVisible = visibleNodes.length;
    const restCount = count - countVisible;
    const lastKey = count ? approvals[count - 1].userid : null;
    const lastVisibleKey = countVisible && countVisible !== count ? visibleNodes[countVisible - 1].userid : null;

    return { count, countVisible, lastVisibleKey, restCount, lastKey };
  };

  get sortedApprovalList() {
    const { nodes, usersById } = this.props;
    const AprovalFactory = AprovalFactoryCreator(usersById);
    return nodes ? nodes.map(AprovalFactory) : nodes;
  }

  setItemRef = (key) => (ref) => {
    this.elements[key] = ref;
  };

  setBoxEl = (el: Element) => {
    this.boxEl = el;
  };

  initObservers = () => {
    const observerOptions = { root: this.boxEl, rootMargin: '0px', threshold: 1 };
    Object.entries(this.elements).forEach(([key, el]) => {
      const observer = new IntersectionObserver(this.handleObserver(key), observerOptions);
      observer.observe(el);
      this.observers[key] = observer;
    });
  };

  isHiddenKey = (key) => this.state.hiddenItems.includes(key);

  showItem = (key: string) => {
    if (this.isHiddenKey(key)) {
      this.setState({ hiddenItems: this.state.hiddenItems.filter((item) => item !== key) });
    }
  };

  hideItem = (key: string) => {
    if (!this.isHiddenKey(key)) {
      this.setState({ hiddenItems: [...this.state.hiddenItems, key] });
    }
  };

  handleObserver =
    (key: string) =>
    ([intersectionObject]) => {
      const action = intersectionObject.intersectionRatio === 1 ? this.showItem : this.hideItem;
      action(key);
    };

  elements: { [key: string]: any } = {};

  observers: { [key: string]: IntersectionObserver } = {};

  boxEl: Element = null;

  render() {
    const { getApprovalsStat, sortedApprovalList } = this;
    const { lastVisibleKey, countVisible, restCount, lastKey } = getApprovalsStat();

    return sortedApprovalList ? (
      <Wrapper spacing={SPACING} maxWidth="100%" ref={this.setBoxEl} sx={{ px: 0.5 }}>
        {!countVisible && <Rest size={SIZE} label={restCount} />}
        {sortedApprovalList.map(({ userName, src, userid, status }) => (
          <Wrapper key={userid} ref={this.setItemRef(userid)} spacing={SPACING}>
            <Approver
              size={SIZE}
              spacing={SPACING}
              sx={{ opacity: this.isHiddenKey(userid) ? 0 : 1 }}
              avatar={<Avatar src={src} alt={userName} />}
              status={status}
              label={userName}
              hasArrow={lastKey !== userid}
            />
            {lastVisibleKey === userid && <Rest size={SIZE} label={restCount} />}
          </Wrapper>
        ))}
      </Wrapper>
    ) : (
      <div>{SYMBOL_FOR_EMPTY_CELL}</div> // don't return null because ag-grid recognize it as error!!!
    );
  }
}

export default AgGridApprovals;
