/* @flow */
import type { RecordOf } from 'immutable';
import type { TDocumentInsightsRedux, TInsightsGridHeaders, TInsightsGridRows } from 'domain/insights/types.js.flow';
import type { SelectorType } from 'domain/types.js.flow';
import { compose } from 'redux';
import { selector } from 'lib/selectors';
import { chatAllUsersByIdSelector } from 'domain/chat/chatSelector';
import { addFilters, addMasterViewColumnForInsights, GridHeadersFactory } from 'domain/documents/helpers';

const insights = (state): RecordOf<TDocumentInsightsRedux> => state.insights;

export const getInsightsGridHeadersSelector: SelectorType<TInsightsGridHeaders> = selector(
  insights,
  (x) => x.gridHeaders,
);
export const getInsightsAgGridHeadersSelector = selector(getInsightsGridHeadersSelector, (headers) =>
  compose(GridHeadersFactory, addFilters)(headers.toJS()),
);

export const getInsightsGridRowsSelector: SelectorType<TInsightsGridRows> = selector(insights, (x) => x.gridRows);

export const getInsightsAgGridRowsSelector = selector(
  getInsightsGridRowsSelector,
  chatAllUsersByIdSelector,
  (rows, usersById) =>
    rows.reduce(
      (a, row) => [
        ...a,
        {
          usersById,
          rowDocumentData: row,
          ...row.gridMeta.toJS(),
        },
      ],
      [],
    ),
);

export const getInsightsVendorSelector: SelectorType<TInsightsGridHeaders> = selector(insights, (x) => x.vendor);
