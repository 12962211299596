// @flow
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createOrganizationUser, updateOrganizationUser } from 'domain/organizationUser/actions';
import { FormattedMessage } from 'react-intl';
import type { EnhancedUser as EnhancedUserType } from 'domain/organizationUser/types.js.flow';

import CompanyUsersList from 'pages/configurations/company/pages/users/List/UsersList';
import DialogManageUser from 'pages/common/Dialog/DialogManageUser';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

const CompanyUsers = () => {
  const dispatch = useDispatch();
  const [userID, setUserID] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const openDialog = useCallback(() => setIsOpen(true), []);

  const closeDialog = useCallback(() => {
    setIsOpen(false);
    setUserID(null);
  }, []);

  const handleEditUser = useCallback((id: string) => {
    setIsOpen(true);
    setUserID(id);
  }, []);

  const handleManageUser = useCallback(
    (data: EnhancedUserType): Promise<void> =>
      new Promise((resolve, reject) => {
        if (userID) {
          dispatch(updateOrganizationUser({ data, resolve, reject }));
        } else {
          dispatch(createOrganizationUser({ data, resolve, reject }));
        }
      }).then(() => {
        setIsOpen(false);
        setUserID(null);
      }),
    [userID, dispatch],
  );

  return (
    <>
      <Box display="flex" mb={2}>
        <Typography variant="subtitle1">
          <FormattedMessage id="configurations.org_users.title" defaultMessage="Organization Users" />
        </Typography>
        <Button type="button" onClick={openDialog} variant="outlined" startIcon={<AddIcon />} sx={{ ml: 'auto' }}>
          <FormattedMessage id="button.add.user" defaultMessage="Add User" />
        </Button>
      </Box>

      <CompanyUsersList onEdit={handleEditUser} />

      <DialogManageUser open={isOpen} userID={userID} onSubmit={handleManageUser} onCancel={closeDialog} isCompany />
    </>
  );
};

export default CompanyUsers;
