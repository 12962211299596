/* @flow */
import { asyncAction, syncAction } from 'lib/action';

// sync actions
export const documentClear = syncAction('DOCUMENTS/CLEAR');
export const documentClearAll = syncAction('DOCUMENTS/CLEAR_ALL');
export const addEditableDoc = syncAction('DOCUMENTS/ADD_EDITABLE_DOC');
export const setEditableDoc = syncAction('DOCUMENTS/SET_EDITABLE_DOC');
export const clearEditableDoc = syncAction('DOCUMENTS/CLEAR_EDITABLE_DOC');
export const clearTransactionErpDuplicated = syncAction('DOCUMENTS/CLEAR_TRANSACTION_ERP_DUPLICATE');
export const resetDocumentAction = syncAction('DOCUMENTS/CLEAR_DOCUMENT');
export const documentUnreadInList = syncAction('DOCUMENTS/UNREAD_IN_LIST');
export const documentReadInList = syncAction('DOCUMENTS/READ_IN_LIST');
export const documentReadAction = syncAction('DOCUMENTS/READ');
export const documentClearValidations = syncAction('DOCUMENTS/CLEAR_VALIDATIONS');
export const documentSetAsFinancialAction = syncAction('DOCUMENTS/SET_FINANCIAL');
export const documentSetAsGeneralAction = syncAction('DOCUMENTS/SET_GENERAL');
export const documentUnread = syncAction('DOCUMENTS/UNREAD');
export const documentGetTagsSuggestions = syncAction('DOCUMENTS/GET_TAGS_SUGGESTIONS');
export const documentsUnlink = syncAction('DOCUMENTS/UNLINK');
export const documentsClearLinkedAction = syncAction('DOCUMENTS/CLEAR_LINKED');
export const setLinkingTagAction = syncAction('DOCUMENTS/SET_LINKING_TAG_ACTION');
export const documentsClearLinkedFromCompany = syncAction('DOCUMENTS/CLEAR_LINKED_FROM_COMPANY');
export const queueAddAction = syncAction('QUEUE/ADD');
export const queueUpdateAction = syncAction('QUEUE/UPDATE');
export const queueProgress = syncAction('QUEUE/PROGRESS');
export const queueRemoveAction = syncAction('QUEUE/REMOVE');
export const documentsChangeTypesAction = syncAction('DOCUMENTS/CHANGE_TYPES');
export const documentsAddSignaturesAction = syncAction('DOCUMENTS/ADD_SIGNATURES');
export const documentsBulkAcceptAction = syncAction('DOCUMENTS/BULK_ACCEPT');
export const exportDataAction = syncAction('DOCUMENT/EXPORT_DATA');
export const saveCachedDocumentAction = syncAction('DOCUMENT/SAVE_CACHED_DOCUMENTS');
export const documentUpdateInListByID = syncAction('DOCUMENT/UPDATE_IN_LIST_BY_ID');

// async actions
export const documentsDownloadDocumentsAction = asyncAction('DOCUMENTS/DOWNLOAD_DOCUMENTS');
export const documentsRemoveDocumentsAction = asyncAction('DOCUMENTS/REMOVE_DOCUMENTS');
export const setUnreadRequestAsReadAction = asyncAction('DOCUMENTS/REMOVE_DOCUMENTS');
export const documentFetchList = asyncAction('DOCUMENTS/FETCH_LIST');
export const documentSearchAction = asyncAction('DOCUMENT/SEARCH');
export const supplierDocumentSearchAction = asyncAction('DOCUMENT/SUPPLIER_SEARCH');
export const supplierDocumentRefreshAction = asyncAction('DOCUMENT/SUPPLIER_REFRESH');
export const documentWorkerSearch = asyncAction('DOCUMENT/WORKER_SEARCH');
export const documentWorkerCompanyLatestTimestamp = asyncAction('DOCUMENT/WORKER_GRID_SEARCH');
export const documentForceSearch = asyncAction('DOCUMENT/FORCE_SEARCH');
export const documentGet = asyncAction('DOCUMENTS/GET');
export const documentGetExportFormats = asyncAction('DOCUMENTS/GET_EXPORT_FORMATS');
export const documentResetExportFormatsLoaded = asyncAction('DOCUMENTS/RESET_EXPORT_FORMATS_LOADED');
export const documentGetLinkedCountAction = asyncAction('DOCUMENT/GET_LINKED_COUNT');
export const getUnreadRequestsAction = asyncAction('DOCUMENTS/GET_UNREAD_REQUESTS');
export const documentGetLinkedAction = asyncAction('DOCUMENTS/GET_LINKED');
export const documentGetLinkedForPreviewAction = asyncAction('DOCUMENTS/GET_LINKED_FOR_PREVIEW');
export const documentGetLinkedIDsAction = asyncAction('DOCUMENTS/GET_LINKED_IDS');
export const lockLinkedDocsAction = asyncAction('DOCUMENTS/LOCK_LINKED_DOCS');
export const documentUpdate = asyncAction('DOCUMENTS/UPDATE');
export const documentRemove = asyncAction('DOCUMENTS/REMOVE');
export const documentReject = asyncAction('DOCUMENTS/REJECT');
export const documentSplit = asyncAction('DOCUMENTS/SPLIT');
export const documentUpdateTags = asyncAction('DOCUMENTS/UPDATE_TAGS');
export const documentMultipleUpdateTags = asyncAction('DOCUMENTS/MULTIPLE_UPDATE_TAGS');
export const documentUpdateTagsInList = asyncAction('DOCUMENTS/UPDATE_TAGS_IN_LIST');
export const documentLinkInListAction = asyncAction('DOCUMENTS/LINK_IN_LIST');
export const documentHideLinkedDocs = asyncAction('DOCUMENTS/HIDE_LINKED_DOCS');
export const documentUnlinkInList = asyncAction('DOCUMENTS/UNLINK_IN_LIST');
export const documentUpdateLinkAction = asyncAction('DOCUMENTS/UPDATE_LINK');
export const documentUnreadInFilteredList = asyncAction('DOCUMENTS/UNREAD_IN_FILTERED_LIST');
export const documentUpdateNotes = asyncAction('DOCUMENTS/UPDATE_NOTES');
export const documentRemoveNotes = asyncAction('DOCUMENTS/REMOVE_NOTES');
export const documentRemoveNotesInList = asyncAction('DOCUMENTS/REMOVE_NOTES_IN_LIST');
export const nextDocumentAction = asyncAction('DOCUMENTS/NEXT');
export const documentAccept = asyncAction('DOCUMENTS/ACCEPT');
export const documentScheduleAcceptAction = asyncAction('DOCUMENTS/SCHEDULE_ACCEPT');
export const documentGetMRUTags = asyncAction('DOCUMENTS/GET_MRU_TAGS');
export const documentSignDocument = asyncAction('DOCUMENTS/SIGN_DOCUMENT');
export const documentSignShowDocument = asyncAction('DOCUMENTS/SIGN_SHOW_DOCUMENT');
export const documentSetAsCoverAction = asyncAction('DOCUMENTS/SET_AS_COVER');
export const getDocumentHotkeysAction = asyncAction('DOCUMENT/GET_HOTKEYS');

export const documentUploadAction = asyncAction('DOCUMENT/UPLOAD');
export const supplierDocumentUploadAction = asyncAction('DOCUMENT/SUPPLIER_UPLOAD');
export const scanningRequest = asyncAction('DOCUMENT/SCAN');

export const duplicateDocumentAction = asyncAction('DOCUMENT/DUPLICATE');
export const markAsPaidAction = asyncAction('DOCUMENT/MARK_AS_PAID');
export const documentStopProcessingAction = asyncAction('DOCUMENT/STOP_PROCESSING');
export const headerTablesCorrectionAction = asyncAction('DOCUMENT/HEADER_TABLES_CORRECTION');
export const documentWorkerTransactionErp = asyncAction('DOCUMENT/WORKER_TRANSACTION_ERP');
export const documentGetTagsAction = asyncAction('DOCUMENT/GET_TAGS');
export const mergeDocumentsAction = asyncAction('DOCUMENTS/MERGE');
export const mergeDocumentsForPrintingAction = asyncAction('DOCUMENTS/MERGE_FOR_PRINTING');
export const moveDocumentToCategoryAction = asyncAction('DOCUMENTS/MOVE_DOCUMENT_TO_CATEGORY_ACTION');
export const moveDocumentToCompanyAction = asyncAction('DOCUMENTS/MOVE_DOCUMENT_TO_COMPANY_ACTION');
export const moveDocumentsToCompanyAction = asyncAction('DOCUMENTS/MOVE_DOCUMENTS_TO_COMPANY_ACTION');
export const moveAllCategoryDocumentsToOtherCategoryAction = asyncAction(
  'DOCUMENTS/MOVE_ALL_CATEGORY_DOCUMENTS_TO_OTHER_CATEGORY',
);

export const getDocumentEmailPayloadAction = asyncAction('DOCUMENTS/GET_DOCUMENT_EMAIL_PAYLOAD');
export const documentIgnoreWarningsAction = asyncAction('DOCUMENT/IGNORE_DOCUMENT_WARNINGS');
export const documentsJustPublishedAction = syncAction('DOCUMENTS/JUST_PUBLISHED');

// grid view
export const documentsGridViewGetAction = asyncAction('DOCUMENTS/GET_GRID_VIEW_DATA');
export const documentsGridFilterAppliedAction = syncAction('DOCUMENTS/GRID_FILTER_APPLIED');
export const documentsGridSortingAppliedAction = syncAction('DOCUMENTS/GRID_SORTING_APPLIED');
export const documentsGridSetListIdWithFiltersAction = syncAction('DOCUMENTS/GRID_SET_LIST_ID_WITH_SORTING');
export const documentsGridDefaultColumnsAppliedAction = syncAction('DOCUMENTS/GRID_DEFAULT_COLUMNS_APPLIED');
export const documentsGridColumnsAppliedAction = syncAction('DOCUMENTS/GRID_COLUMNS_APPLIED');
export const documentsGridClearSharedPresetAction = syncAction('DOCUMENTS/GRID_CLEAR_SHARE_PRESET');
export const documentsGridSetCurrentPresetIdAction = syncAction('DOCUMENTS/GRID_SET_CURRENT_PRESET_ID');
export const documentsGridClearConfigurationChangesAction = syncAction('DOCUMENTS/GRID_CLEAR_CONFIGURATION_CHANGES');
export const documentsGridResetAction = syncAction('DOCUMENTS/GRID_RESET');
export const documentInvalidateMRUTagsCacheAction = syncAction('DOCUMENTS/INVALIDATE_MRU_TAGS_CACHE');

export const documentsGridGetSharedPresetAction = asyncAction('DOCUMENTS/GRID_GET_SHARED_PRESET');
export const documentsGridGetPresetsAction = asyncAction('DOCUMENTS/GRID_GET_PRESETS');
export const documentsGridSavePresetAction = asyncAction('DOCUMENTS/GRID_SAVE_PRESET');
export const documentsGridDeletePresetAction = asyncAction('DOCUMENTS/GRID_DELETE_PRESET');
export const documentsGridRestoreResentPresetId = asyncAction('DOCUMENTS/GRID_RESTORE_RESENT_PRESET_ID');

// bulk
export const documentsBulkUpdateAction = asyncAction('DOCUMENTS/BULK_UPDATE');
export const documentsBulkApproveAction = asyncAction('DOCUMENTS/BULK_APPROVE');

// indexed DB
export const documentGetViewArrangementAction = asyncAction('DOCUMENT/GET_VIEW_ARRANGEMENT');
export const documentUpdateViewArrangementAction = asyncAction('DOCUMENT/UPDATE_VIEW_ARRANGEMENT');
