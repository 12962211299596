/* @flow */
import * as React from 'react';
import { connect } from 'react-redux';
import type { Match } from 'react-router';
import { type List } from 'immutable';
import { documentsSelector, type Document, documentsGridLoaderSelector, loadedSelector } from 'domain/documents';
import { loadingSelector } from 'domain/env';
import { querySelector, locationSelector } from 'domain/router';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { generatePath } from 'react-router-dom';
import { categoryAllSelector, type CategoryRecord } from 'domain/categories';
import * as ACL from 'domain/restriction';
import { processingDocsCountSelector, dashboardTotalSelector, respondedSelector } from 'domain/dashboard';
import type { TWorkspaceType } from 'domain/env/types.js.flow';

import NoResult from 'pages/common/empty/noResult';
import NoDocuments from 'pages/common/empty/noDocuments';
import Divider from '@mui/material/Divider';

type Props = {|
  match: {|
    ...$Exact<Match>,
    params: {|
      companyId: string,
    |},
  |},
  query: {
    tags: $ReadOnlyArray<string>,
    stag: $ReadOnlyArray<string>,
    text: string,
    date: string,
  },
  loading: boolean,
  gridLoading: boolean,
  location: any,
  documents: List<Document | void>,
  type: 'standard' | 'confidential',
  processing: number,
  toggleUpload: () => void,
  companyTotalDocs: number,
  isResponded: boolean,
  docsLoaded: boolean,
  allCategory: CategoryRecord,
  isGranted: (r: number | number[]) => boolean,
  workSpaceType: TWorkspaceType,
|};

type State = {|
  view: 'noDocs' | 'notPresent' | 'notFound' | null,
|};

const STUB_VIEWS = {
  noDocs: 'noDocs',
  notPresent: 'notPresent',
  notFound: 'notFound',
};

class Stub extends React.Component<Props, State> {
  state = {
    view: null,
  };

  componentDidMount(): * {
    this.initView();
  }

  componentDidUpdate(prevProps: Props, prevState: State): * {
    if (this.state === prevState) {
      this.initView();
    }
  }

  getView = () => {
    const { companyTotalDocs, isGranted, processing, workSpaceType, docsLoaded } = this.props;
    const noDocView = isGranted(ACL.IS_ACCOUNT) ? STUB_VIEWS.noDocs : STUB_VIEWS.notPresent;
    const withoutDocs = companyTotalDocs ? noDocView : STUB_VIEWS.notPresent;
    const noDocs = this.search ? STUB_VIEWS.notFound : withoutDocs;
    const isTile = workSpaceType === 'tile';

    return this.isLoading() || !docsLoaded || (processing && isTile && !this.search) ? null : noDocs;
  };

  get search() {
    const { query } = this.props;
    return Object.values(query).some((item) => (Array.isArray(item) ? item.some((i) => !!i) : !!item));
  }

  get allCategoryLink() {
    const {
      allCategory,
      type,
      match: {
        params: { companyId },
      },
    } = this.props;
    const allCategoryId = allCategory ? allCategory.id : '';
    const path =
      type === 'confidential'
        ? ROUTES_PATH.COMPANY_CONFIDENTIAL_WORKSPACE.absolute
        : ROUTES_PATH.COMPANY_WORKSPACE.absolute;
    return allCategoryId ? generatePath(path, { category1: allCategoryId, companyId }) : null;
  }

  get resetSearch() {
    const {
      match: { params },
      type,
    } = this.props;
    const path =
      type === 'confidential'
        ? ROUTES_PATH.COMPANY_CONFIDENTIAL_WORKSPACE.absolute
        : ROUTES_PATH.COMPANY_WORKSPACE.absolute;

    return generatePath(path, { ...params });
  }

  initView = () => {
    const view = this.getView();
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }

    if (this.state.view !== view) {
      if (view) {
        this.timeout = setTimeout(() => {
          this.setState({ view });
        }, 500);
      } else {
        this.setState({ view });
      }
    }
  };

  isLoading = () => {
    const { loading, gridLoading } = this.props;
    return loading || gridLoading;
  };

  timeout: ?TimeoutID;

  render() {
    const { match, toggleUpload, type } = this.props;
    const { view } = this.state;
    const isConfidential = type === 'confidential';

    return view ? (
      <>
        <Divider />
        {view === STUB_VIEWS.notFound && <NoResult link={this.resetSearch} companyId={match.params.companyId} />}
        {view === STUB_VIEWS.noDocs && (
          <NoDocuments
            random={!isConfidential}
            confidential={isConfidential}
            onClick={toggleUpload}
            link={this.allCategoryLink}
          />
        )}
        {view === STUB_VIEWS.notPresent && <NoDocuments confidential={isConfidential} onClick={toggleUpload} />}
      </>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  isGranted: ACL.isGranted(state),
  isResponded: respondedSelector(state),
  companyTotalDocs: dashboardTotalSelector(state),
  documents: documentsSelector(state),
  loading: loadingSelector(state),
  gridLoading: documentsGridLoaderSelector(state),
  query: querySelector(state),
  location: locationSelector(state),
  processing: processingDocsCountSelector(state),
  allCategory: categoryAllSelector(state),
  docsLoaded: loadedSelector(state),
});

export default connect(mapStateToProps)(Stub);
