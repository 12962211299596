/* @flow */
import { List, Record, Set, Map, fromJS, type RecordOf, type RecordFactory, OrderedMap } from 'immutable';
import get from 'lodash/get';
import { update } from 'lodash/fp';
import type {
  DocumentMetadataType,
  UnreadRequestLineType,
  UnreadRequestDocType,
  WsGridPresetT,
  WsGridPresetsT,
} from 'domain/contracts';
import type {
  GridRawItemType,
  GridItemType,
  GridRawHeaderItemType,
  GridHeaderItemType,
  TGridItemValueType,
  GridSortingType,
  THotkeyMatch,
  TDocumentHotkey,
  THotkeyRaw,
  THotkeyList,
  WsGridPreset,
} from 'domain/documents/types.js.flow';
import { gridSortingFactory, HotkeyFactory } from './documentsModel';
import moment from 'moment';
import {
  documentCompositionFunctionsAdapter,
  requiresColumnMigration,
  GRID_SERVICE_KEYS,
  changeColumnsPositionForGridRedesign,
} from 'domain/documents/helpers';
import { splitNumberByCommas, convertToFloatNumber } from 'lib/helpers';
import { compose } from 'redux';
import { SYMBOL_FOR_EMPTY_CELL } from 'pages/company/grid/helpers';

import { WS_GRID_PRESET_TYPES, WS_GRID_RECENT_DEFAULT_NAME } from './constants';
import { NOTE_PALETTE_DEFAULT_KEY } from 'components/LabeledThemeProvider/notesPalette';

function createViewInfo() {
  return {
    pages: 0,
    rotations: new List(),
  };
}

export const ViewinfoFactory: RecordFactory<ViewinfoType> = new Record(createViewInfo());

export function viewInfoAdapter({ pages, rotations }: $PropertyType<DocumentMetadataType, 'viewinfo'>) {
  const positiveRotations = (rotations || []).map((angle) => Math.abs(angle % 360));
  return ViewinfoFactory({
    pages,
    rotations: List(positiveRotations),
  });
}

export const DocumentFactory: RecordFactory<Document> = new Record({
  documentID: '',
  created: '',
  doc: '',
  docStatus: '',
  finInfo: null,
  sourceID: null,
  versionID: null,
  folderID: null,
  new: 0,
  notes: '',
  notesColor: NOTE_PALETTE_DEFAULT_KEY,
  source: '',
  total: 0,
  type: 2,
  tags: new Set(),
  recentTags: new Set(),
  doctype: 'general',
  status: ['new'], // TODO: Remove synthetic attribute
  reason: '',
  protected: false,
  viewinfo: ViewinfoFactory(),
  linkid: '',
  ltext: '',
  fromEmail: false,
  canBeMoved: false,
});

export function documentAdapter({ viewinfo, tags, ...rest }: DocumentMetadataType) {
  const vi = viewInfoAdapter(viewinfo);
  const t = Set(tags);
  return DocumentFactory({
    ...rest,
    tags: t,
    viewinfo: vi,
  });
}

export const UnredRequestDocFactory: RecordFactory<UnreadRequestDocType> = new Record({
  documentId: '',
  companyId: '',
  lines: new List(),
});

export const UnredRequestLineFactory: RecordFactory<UnreadRequestLineType> = new Record({
  id: '',
  row: 0,
  lastTimestamp: '',
});

export const unredRequestLinesAdapter = (lines): List<RecordOf<UnreadRequestLineType>> =>
  new List(lines.map(({ row, ...rest }) => UnredRequestLineFactory({ row: parseInt(row, 10), ...rest })));

export const unredRequestDocsAdapter = (docs: Array<UnreadRequestDocType>): List<RecordOf<UnreadRequestDocType>> =>
  new List(
    docs.map(({ lines, ...rest }) =>
      UnredRequestDocFactory({
        lines: unredRequestLinesAdapter(lines),
        ...rest,
      }),
    ),
  );

const GridHeaderItemFactory: RecordFactory<GridHeaderItemType> = new Record({
  field: '',
  headerName: '',
  type: 'string',
  format: 'DD/MM/YYYY',
  hide: false,
  pinned: false,
  minWidth: 50,
  operator: undefined,
  valueFormatter: (x: { value: TGridItemValueType }) => x.value || SYMBOL_FOR_EMPTY_CELL,
  cellClassRules: {
    'cell-negative-number': (x: { value: TGridItemValueType }) => typeof x.value === 'number' && x.value < 0,
  },
  tooltipComponent: undefined,
  tooltipField: undefined,
});

const createDateFormatter =
  ({ format }) =>
  ({ value }) =>
    value ? moment(value).format(format) : SYMBOL_FOR_EMPTY_CELL;

const createNumberFormat =
  () =>
  ({ value }) =>
    typeof value === 'number' ? compose(splitNumberByCommas, convertToFloatNumber)(value) : SYMBOL_FOR_EMPTY_CELL;

const createIntegerFormat =
  () =>
  ({ value }) =>
    typeof value === 'number' ? parseInt(value, 10) : SYMBOL_FOR_EMPTY_CELL;

const createExtraSelectFormat =
  () =>
  ({ value }: { value: string[] }) =>
    Array.isArray(value) && !!value.length ? value.join(';') : SYMBOL_FOR_EMPTY_CELL;

const valueFormatters = {
  date: createDateFormatter,
  number: createNumberFormat,
  extraSelect: createExtraSelectFormat,
  integer: createIntegerFormat,
};

const tooltipComponentByType = {
  string: 'customTooltip',
  select: 'customTooltip',
  date: 'customTooltip',
  extraSelect: 'customTooltip',
};

const getValueFormatter = (item) => {
  const valueFormatterFactory = get(valueFormatters, item.type, () => undefined);
  return valueFormatterFactory(item);
};

const getTooltipProps = (item: GridRawHeaderItemType) => {
  const tooltipComponent = tooltipComponentByType[item.type];

  return tooltipComponent
    ? {
        tooltipComponent,
        tooltipField: item.field,
      }
    : {};
};

const fieldsByTypes = {
  extraSelect: '.status',
};

const getField = (item: GridRawHeaderItemType) => {
  const endOfField = fieldsByTypes[item.type];
  return `${item.field}${endOfField || ''}`;
};

const GridHeaderItemAdapter = (item: GridRawHeaderItemType): RecordOf<GridHeaderItemType> =>
  GridHeaderItemFactory({
    ...item,
    field: getField(item),
    minWidth: +item.minWidth || undefined,
    valueFormatter: getValueFormatter(item),
    ...getTooltipProps(item),
  });

const createGridMetaItem = (item: GridRawItemType): GridItemType => {
  const { document, ...rest } = item;

  return new Map({
    documentID: document.document_id,
    [GRID_SERVICE_KEYS.CONTEXT_MENU_COLUMN_NAME]: '',
    [GRID_SERVICE_KEYS.PREVIEW_CHECKBOX_COLUMN_NAME]: '',
    [GRID_SERVICE_KEYS.COLUMNS_VISIBILITY_MENU]: '',
    ...rest,
  });
};

export const gridDocumentsListAdapter = (rawList: GridRawItemType[]) =>
  rawList.reduce((a, v) => {
    const { document_id, notes, ...rest } = v.document;

    return a.set(
      document_id,
      documentCompositionFunctionsAdapter({
        ...rest,
        notes: notes || '',
        documentID: document_id,
        gridMeta: createGridMetaItem(v),
      }),
    );
  }, new OrderedMap());

export const gridHeadersListAdapter = (rawList: GridRawHeaderItemType[]) =>
  List(rawList.map((item) => GridHeaderItemAdapter(item)));

export const gridSortingListAdapter = (rawList: Array<GridSortingType>) =>
  rawList ? rawList.map((item) => gridSortingFactory(item)) : [];

// eslint-disable-next-line max-len
const documentHotkeyAdapter = (item: THotkeyRaw): RecordOf<TDocumentHotkey> =>
  HotkeyFactory({
    keys: fromJS(item.keys),
    description: item.description,
    level: item.level,
  });

export const documentHotkeysAdapter = (rawList: THotkeyList) =>
  List(rawList.map((item) => documentHotkeyAdapter(item)));

export function hotkeyMatchAdapter(hotkeyMatch: THotkeyMatch) {
  return hotkeyMatch ? { hotkey: hotkeyMatch } : {};
}

export const wsGridPresetAdapter = ({ all, type = null, ...rest }: WsGridPresetT) => {
  const res = {
    ...rest,
    isAll: !!all,
    type:
      type || (rest.name === WS_GRID_RECENT_DEFAULT_NAME ? WS_GRID_PRESET_TYPES.recent : WS_GRID_PRESET_TYPES.custom),
  };

  return requiresColumnMigration(res.config?.gridColumns)
    ? update(['config', 'gridColumns'], (columns) => changeColumnsPositionForGridRedesign(columns))(res)
    : res;
};

export const wsGridPresetsAdapter = (presets: WsGridPresetsT): WsGridPreset[] =>
  presets.map((p) => wsGridPresetAdapter(p));

export const wsGridSharePresetAdapter = (preset: WsGridPresetT): WsGridPreset => {
  const migratedPreset = requiresColumnMigration(preset.config?.gridColumns)
    ? update(['config', 'gridColumns'], (columns) => changeColumnsPositionForGridRedesign(columns))(preset)
    : preset;

  return {
    ...migratedPreset,
    isAll: false,
    name: 'Shared',
    type: WS_GRID_PRESET_TYPES.shared,
  };
};
