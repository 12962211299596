// @flow
import React from 'react';
import { getRotateByDocID } from 'lib/pdfHelpers';
import useCardThumbnailPath from 'pages/company/TileGrid/TileCard/hooks/useCardThumbnailPath';
import CONST from 'domain/documents/constants';
import type { DocumentsType } from 'domain/documents/documentsModel';

import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import { THUMBNAIL_H } from 'pages/company/TileGrid/TileCard/components/StyledComponents';

import { styled, alpha } from '@mui/material/styles';

type TCardImage = {
  document: DocumentsType,
  readOnly?: boolean,
  onContextMenu: (e: SyntheticMouseEvent<*>) => void,
};

const TileCardImage = styled(Box, { label: 'TileCardImage' })(() => ({
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 4,
}));

const ImageOverlay = styled('div', { label: 'ImageOverlayProcessing' })(({ theme }) => ({
  backdropFilter: 'blur(1px)',
  backgroundColor: alpha(theme.palette.primary.main, 0.12),
  backgroundImage: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, rgba(0, 28, 152, 0.096) 80.45%)',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
}));

const CardImage: React$StatelessFunctionalComponent<TCardImage> = ({ document, readOnly, onContextMenu }) => {
  const { documentID, viewinfo, tags } = document;
  const isProcessing = tags.has(CONST.tags.isBeingProcessed);

  const src = useCardThumbnailPath({ documentID });
  const rotation = getRotateByDocID(documentID) ? getRotateByDocID(documentID)[0] : viewinfo.rotations[0];
  const isReverseSize = rotation === 90 || rotation === 270;
  const sx = {
    objectFit: 'contain',
    // not ideal, but couldn't find better :(
    margin: 'auto',
    width: isReverseSize ? THUMBNAIL_H : '100%',
    ...(typeof rotation === 'number' && { transform: `rotate(${rotation}deg)` }),
  };

  return (
    // wrapper needed for rotation
    <TileCardImage height={THUMBNAIL_H} draggable={!readOnly} onContextMenu={onContextMenu}>
      <CardMedia component="img" image={src} alt="Company logo" loading="lazy" draggable={!readOnly} sx={sx} />
      {isProcessing && <ImageOverlay />}
    </TileCardImage>
  );
};

export default CardImage;
