// @flow
/* eslint object-shorthand: 0 */
/* eslint func-names: 0 */
import { Record, Map, OrderedMap, List, type RecordFactory, type RecordOf } from 'immutable';
import moment from 'moment';
import type {
  DashItemType,
  DashboardRawType,
  DashboardType,
  FavoritesStore,
  FavoritesItem,
  FavoritesRaw,
  TodoItem,
  TodoItemsStore,
  TodoItemsGroup,
} from './type.js.flow';

export const groupByTodayTomorrowUpcoming = (date: string): string =>
  moment(date).calendar(null, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: '[Upcoming]',
    lastDay: '[Today]',
    lastWeek: '[Today]',
    sameElse: function (now) {
      return this.isBefore(now) ? '[Today]' : '[Upcoming]';
    },
  });

const DashItemFactory: RecordFactory<DashItemType> = new Record({
  fin: 0,
  general: 0,
});

export const DashboardFactory: RecordFactory<DashboardType> = new Record({
  ready: DashItemFactory(),
  completed: DashItemFactory(),
  total: DashItemFactory(),
  average: DashItemFactory(),
  processing: 0,
  responded: false,
});

export function DashboardAdapter(data: DashboardRawType): RecordOf<DashboardType> {
  return DashboardFactory({
    ready: DashItemFactory(data.ready),
    completed: DashItemFactory(data.completed),
    total: DashItemFactory(data.total),
    average: DashItemFactory(data.average),
    processing: data.processing,
  });
}

const FavoritesItemFactory: RecordFactory<FavoritesItem> = new Record({
  filter: '',
  label: '',
  new: 0,
  total: 0,
  system: false,
  ID: null,
  query: null,
});

export function FavoritesAdapter(data: FavoritesRaw): FavoritesStore {
  return data.items.reduce((a, v) => a.set(v.ID || v.label, FavoritesItemFactory(v)), new Map());
}

export const TodoItemFactory: RecordFactory<TodoItem> = new Record({
  id: '',
  date: new Date().toISOString(), // think twice before using dynamic data in Record
  action: '',
  status: 'NEW',
});

export function TodoItemsAdapter(data: Array<TodoItem>): TodoItemsStore {
  return data.reduce((a, v) => a.set(v.id, TodoItemFactory(v)), new Map());
}

export function GroupTodoItems(d: List<TodoItem>): TodoItemsGroup {
  const sorter = (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime();
  const mandatoryGroups = new OrderedMap().withMutations((map) =>
    map.set('Today', new List()).set('Tomorrow', new List()).set('Upcoming', new List()),
  );

  const presentGroups = d
    .sort(sorter)
    .groupBy((e) => groupByTodayTomorrowUpcoming(e.date))
    .reverse();
  return mandatoryGroups.merge(OrderedMap(presentGroups));
}

export function complitedTodoFilter(todo: TodoItem): boolean {
  return todo.status === 'DONE';
}
