// @flow
import { Map, Set, List } from 'immutable';
import { CONNECTED } from 'sendbird-utils/lib/constants';
import { selector } from 'lib/selectors';
import { type ChatUser } from './types.js.flow';
import { organizationBotSelector } from 'domain/env/envSelector';

const env = (state) => state.env;
const chat = (state) => state.chat;
const documents = (state) => state.documents;
export const ghostIdentities = ['ghost@dokka.biz', 'ghost@dokka.me', 'ghost@dokka.com'];

export const chatSelector = selector(env, (e) => e.get('chat'));
export const chatParticipantsSelector = selector(chat, (e) => e.get('users'));
export const chatParticipantsByIdSelector = selector(chatParticipantsSelector, (e) =>
  e.reduce((a, v) => a.set(v.userId, v), new Map()),
);

// Filtering threads with ghost user at the very low level to prevent any further selectors from
// operating ghost channels data/counters etc after ghost was removed from chat participants.
// This should prevent unread counters from appearing for chats with ghost that are not visible due to removal
// if user is ghost, filtering is not applied to allow ghost see channels history
export const chatThreadCommonSelector = selector(chat, env, (c, e) =>
  ghostIdentities.includes(e.userId)
    ? c.threads
    : c.threads.filterNot((thread) => thread.members.some((member) => ghostIdentities.includes(member.userId))),
);

export const chatThreadSelector = selector(
  chatThreadCommonSelector,
  env,
  chatParticipantsSelector,
  (threads, e, users) => {
    // hotfix: DA-8189, DA-8298 - for list of companies, list of users is empty
    // return not filtered threads
    if (users.size === 0) return threads;

    const userEmails = Set(users.map((user) => user.userId));

    // if chat members are not equals - skip this thread
    return threads.filter((thread) => {
      const members = Set(thread.members.map((user) => user.userId));

      return userEmails.intersect(members).equals(members);
    });
  },
);

export const chatUsersSelector = selector(chatParticipantsSelector, env, (u, e) =>
  u.filter((f) => f.userId !== e.userId),
);
export const chatUsersWithoutSuppliers = selector(chatUsersSelector, (users) =>
  users.filter(({ role }) => role !== 'supplier'),
);
export const chatClientsSelector = selector(chatUsersSelector, (list: List<ChatUser>) =>
  list.filter((user) => ['user', 'confidential-user'].includes(user.role)),
);
export const chatAccountantSelector = selector(chatUsersSelector, (list: List<ChatUser>) =>
  list.filter(({ role }) => role === 'accountant' || role === 'limited-accountant' || role === 'restricted-accountant'),
);
export const channelsSelector = (state) => state.chat.channels;
export const activeChannelSelector = selector(chat, (c) => c.activeChannel);
export const activeChannelForceSelector = selector(chat, (c) => c.activeChannelForce);
export const messageQuerySelector = selector(chat, (c) => c.messageQuery);
export const chatReadyToRenderOnDocumentSelector = selector(chat, (c) => c.readyToRenderOnDocument);

export const chatUnreadThreadSelector = selector(chatThreadSelector, (t) =>
  t.toList().filter((v) => v.unreadMessageCount > 0),
);

export const documentsUnreadCounterSelector = selector(chatUnreadThreadSelector, documents, (t, d) =>
  t.reduce((a, v) => (v.companyId === d.companyId && v.has('documentId') ? a.add(v.documentId) : a), new Set()),
);

export const documentUnreadMessagesCountSelector = selector(chatUnreadThreadSelector, documents, (threads, docs) =>
  threads.reduce(
    (acc, thread) =>
      thread.companyId === docs.companyId && thread.get('documentId') === docs.document.documentID
        ? acc + thread.unreadMessageCount
        : acc,
    0,
  ),
);

export const chatByCompanySelector = selector(chatUnreadThreadSelector, documents, (t, d) =>
  t.filter((f) => f.companyId === d.companyId),
);

export const chatConnectionStatusSelector = selector(chat, (c) => c.connectionStatus);
export const isChatConnectedSelector = selector(chatConnectionStatusSelector, (c) => c === CONNECTED);

export const isOpenSelector = selector(chat, (c) => c.isOpen);
export const isOneSignalInitSelector = selector(chat, (c) => c.isOneSignalInit);

export const messagesLoadedSelector = selector(chat, (c) => c.loaded);

export const supportUsersSelector = selector(chatParticipantsSelector, (users) => users.filter((u) => u.isSupport));

export const chatAllUsersSelector = selector(
  chatParticipantsSelector,
  organizationBotSelector,
  (users, organizationBot) => users.unshift(organizationBot),
);

export const chatAllUsersByIdSelector = selector(chatAllUsersSelector, (e) =>
  e.reduce((a, v) => a.set(v.userId, v), new Map()),
);

export const allowedChatWorkerSelector = selector(chat, isOpenSelector, (c, i) => c.allowedWorker && !i);
