// @flow
import React, { useMemo } from 'react';
import useToggle from 'hooks/useToggle';

// TODO: check types, its temporary
import TextField, { type TextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FloatInput from './FloatInput';
import PhoneInput from './PhoneInput';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export type TTextFieldBaseMui = {
  type: string,
} & TextFieldProps;

const TextFieldBase: React$StatelessFunctionalComponent<TTextFieldBaseMui> = React.forwardRef(
  // eslint-disable-next-line react/prop-types
  ({ type, InputProps = {}, ...rest }, ref) => {
    const [isRevealedPassword, toggleRevealedPassword] = useToggle();

    const passwordEye = useMemo(
      () => ({
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" onClick={toggleRevealedPassword}>
              {isRevealedPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }),
      [isRevealedPassword, toggleRevealedPassword],
    );

    return (
      <TextField
        ref={ref}
        type={isRevealedPassword ? 'text' : type}
        {...rest}
        InputProps={{
          ...(type === 'password' && passwordEye),
          ...InputProps,
          ...(type === 'float' && { inputComponent: FloatInput }),
          ...(type === 'tel' && { inputComponent: PhoneInput }),
        }}
      />
    );
  },
);

export default TextFieldBase;
