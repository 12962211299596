// @flow
import { yellow, orange, deepOrange, green, red } from '@mui/material/colors';
// in future can be moved to mui theme config or WL config

export type TNotePaletteItem = {|
  color: string,
  border: string,
|};
export type TNotePalette = { [string]: TNotePaletteItem };

export const NOTE_PALETTE_DEFAULT_KEY = 'color1';

export const NOTE_PALETTE: TNotePalette = {
  color1: {
    color: yellow[100],
    border: yellow[400],
  },
  color2: {
    color: green[100],
    border: green[400],
  },
  color3: {
    color: red[100],
    border: red[400],
  },
  color4: {
    color: deepOrange[100],
    border: deepOrange[400],
  },
  color5: {
    color: orange[100],
    border: orange[400],
  },
};

export type TNotePaletteKeys = $Keys<typeof NOTE_PALETTE>;
