import React, { useState, forwardRef, useImperativeHandle, useCallback, memo } from 'react';
import useDidUpdateEffect from 'hooks/useDidUpdateEffect';

import AutocompleteVirtualized from 'components/mui/Form/Autocomplete/AutocompleteVirtualized';
import { TextField } from 'pages/company/grid/components/DropdownCellEditor/StyledComponents';

const MultipleDropdownCellEditor = forwardRef((props, ref) => {
  const {
    stopEditing,
    values,
    column: { actualWidth },
  } = props;
  const defaultValue = Object.keys(props.value).length ? props.value : undefined;
  const [currentValue, setCurrentValue] = useState(defaultValue);

  // Component Editor Lifecycle methods
  useImperativeHandle(ref, () => ({
    // the final value to send to the grid, on completion of editing
    getValue() {
      return currentValue || {};
    },
  }));

  const handleChange = useCallback((e, option) => {
    setCurrentValue(option);
  }, []);

  // stop edit after select option
  useDidUpdateEffect(() => {
    stopEditing();
  }, [currentValue, stopEditing]);

  return (
    <AutocompleteVirtualized
      open
      value={currentValue}
      options={values}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField {...params} inputProps={{ ...params.inputProps }} variant="standard" autoFocus />
      )}
      ListboxProps={{ optionStyle: { unicodeBidi: 'normal' } }}
      sx={{ width: actualWidth }}
      fullWidth
      autoComplete
      disablePortal
    />
  );
});

export default memo(MultipleDropdownCellEditor);
