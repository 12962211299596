// @flow
import * as React from 'react';
import { Map, List, Set, type RecordOf, is } from 'immutable';
import cx from 'classnames';
import identity from 'lodash/identity';
import { type RecordCellSetType, type RecordsCellType, RecordCellSet } from 'domain/journal/helper';
import type { ReferenceRecord, ReconcileCellValueType } from 'domain/journal/types.js.flow';
import mediaQuery from 'lib/mediaQuery';
import type { OptionsItemType, ItemType, MakeReference, CellDataGridType } from './types.js.flow';
import Autocomplete from './Autocomplete';
import PaginationAutocomplete from './Autocomplete/PaginationAutocomplete';
import { findText } from './Autocomplete/helpers';
import HintInput from './Autocomplete/hintInput';
import Literal from './Literal';
import { textMapValueNormalize, keyCodeCharacter, keyCodeArrow, filterList, toggleDate } from './helpers';
import { executeFunc } from 'lib/helpers';
import { hotkeyMatcher } from 'lib/hotkeysHelpers';
import elements from 'components/elements';
import { cellFsm, statesDict, EVENTS, STATE, GROUP, type FSM$State, type FSM$Event, type FSM$Group } from './fsm';
import MainCell from './mainCell';
import BooleanCell from './Boolean';
import ReconciliationCell from './ReconciliationCell';
import DateToggle from './DateToggle';
import Tooltip from 'components/mui/Tooltip';

import constants from './constatnt';
import type { THotkeyList, THotkeyCombination } from 'domain/documents/types.js.flow';
import { HotkeyCellListFactory, HotkeyMatchFactory } from 'domain/documents';

type Props = {
  gridData: Map<string, RecordsCellType>,
  data: RecordsCellType,
  classes: {
    [key: string]: string,
  },
  onChange: (cell: string, cellSet: RecordCellSetType, ignoreSelectedRows: boolean) => void,
  onShowDetails?: ({| row: number, lineState: ReconcileCellValueType |}) => void,
  active: ?string,
  polygons: Map<string, RecordOf<ItemType>>, // eslint-disable-line react/no-unused-prop-types
  createRefernce: (fn: MakeReference) => void,
  activeReference: ?ReferenceRecord,
  setActiveReference: (p: { id: string, params: ReferenceRecord }) => void,
  rtl: boolean,
  isPriorityErp: boolean,
  documentView: Map<'documentsView' | 'infoBarWidth', number>,
  isSupport: ?boolean,
  optionList: List<OptionsItemType>,
  insertGrid: (p: $PropertyType<CellDataGridType, 'payload'>, c: { id: string, name: string }) => void,
  setActiveCell: (cellId: string | null) => void,
  isBulkEdit?: boolean,
  amountColumn: string,
  reference: Map<string, ReferenceRecord>,
  hotkeyList: THotkeyList,
};

type State = {
  value: RecordCellSetType,
  currentState: FSM$State,
  isOpen: boolean,
};

function getDefaultState(data: RecordsCellType) {
  if (data.type === 'checkbox') return STATE.booleanPassive;
  if (data.readonly) return STATE.roPassive;
  // eslint-disable-next-line no-use-before-define
  if (data.name === Cell.dateToggleCellName) return STATE.datePassive;
  if (data.type === 'index_list') return STATE.selectPassive;
  if (data.type === 'paginated_list') return STATE.selectPassive;
  if (data.type === 'reconciliation') return STATE.reconciliationPassive;
  if (data.type === 'string_auto_generated') return STATE.textareaPassive;
  /* if (['string', 'string_auto_generated'].includes(data.type)) {
    return STATE.passive;
  } */
  return STATE.passive;
}

class Cell extends React.PureComponent<Props, State> {
  static getGroup(currentState: FSM$State, group: FSM$Group): boolean {
    return STATE[currentState.name].group === group;
  }

  static disableHintForCell(name: string): boolean {
    return name === Cell.dateToggleCellName;
  }

  static dateToggleCellName = 'priv_date';

  constructor(props: Props) {
    super(props);
    this.state = {
      value: this.stateValue,
      isOpen: false,
      currentState: getDefaultState(props.data),
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.data.name !== this.props.data.name) {
      const currentState = getDefaultState(nextProps.data);

      this.fsm = cellFsm(statesDict, this.defState(nextProps.data));
      this.setState({ currentState });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { data, active } = this.props;
    if (active === data._cell && prevProps.active !== data._cell) {
      this.setFocus();
    }
  }

  get listItem(): ?string {
    const {
      cellSet: { value, display },
    } = this.props.data;
    return display || value || null;
  }

  get stateValue(): RecordCellSetType {
    const { cellSet } = this.props.data;
    return cellSet;
  }

  get value(): ?string {
    const {
      data: { type },
    } = this.props;
    if (['index_list', 'paginated_list'].includes(type)) return this.listItem;
    return this.stateValue.value;
  }

  get hint(): ?string {
    const {
      data: { hint },
    } = this.props;
    return hint || this.value;
  }

  get celleStyle() {
    return this.props.data.style.toJS();
  }

  get cellClasses(): string {
    const { classes } = this.props;
    return cx({
      [classes.hasError]: !this.isValid(),
      [classes.disabled]: this.isDisabled(),
    });
  }

  defState = (data): FSM$State => getDefaultState(data || this.props.data);

  getInputMaxLength = () => {
    const {
      data: { maxLength = constants.INPUT_DEFAULT_SIZE },
    } = this.props;

    return maxLength;
  };

  setFocus = () => {
    if (this.field && typeof this.field.focus === 'function') {
      this.field.focus();
    }
  };

  setFCMState = (nextState: FSM$State, cb?: () => void) => {
    this.setState(
      {
        currentState: nextState,
      },
      () => {
        executeFunc(cb);
      },
    );
  };

  eventCollector = (event: FSM$Event, params?: mixed) => {
    const state = this.fsm();
    const nextState = this.fsm(event);

    if (state.name !== nextState.name) {
      if (params && params.persist && typeof params.persist === 'function') {
        params.persist();
      }
      nextState.action.call(this, params);
    }
  };

  handleHotkey = (hotkeyValue: THotkeyCombination) => {
    const { onChange, data } = this.props;

    const hotkeyCellListItem = HotkeyCellListFactory({
      name: data.name,
      cell: data._cell,
      set: data.cellSet,
    });

    const hotkeyMatchData = HotkeyMatchFactory({
      key: hotkeyValue,
      cell_list: List([hotkeyCellListItem]),
    });

    onChange(data._cell, data.cellSet, false, hotkeyMatchData);
  };

  handleDateToggler = () => {
    this.eventCollector(EVENTS.onDateToggleClick, this.props.data._cell);
  };

  handleChange = (e: SyntheticInputEvent<HTMLInputElement>, ...arg: Array<any>) => {
    this.setState(
      {
        value: this.state.value.merge(e.target),
      },
      () => {
        if (arg.length) {
          executeFunc(arg[arg.length - 1]);
        }
      },
    );
  };

  handleBlur = () => {
    this.eventCollector(EVENTS.onBlur);
  };

  handleFocus = () => {
    this.eventCollector(EVENTS.onFocus, this.props.data._cell);
  };

  handleClick = () => {
    this.eventCollector(EVENTS.onClick, this.props.data._cell);
  };

  handleShieldClick = (state: boolean) => {
    this.eventCollector(EVENTS.onShieldClick, this.props.data._cell);
    setTimeout(() => {
      this.setState({ isOpen: state });
    }, 100);
  };

  handleKey = (e: SyntheticKeyboardEvent<HTMLElement>) => {
    const { rtl, isPriorityErp, hotkeyList } = this.props;
    const LRKeyCodeArrow: Set<number> = Set([37, 39]);
    const { keyCode } = e;

    if (LRKeyCodeArrow.has(keyCode)) {
      this.eventCollector(EVENTS.LRArrowKey, keyCode);
    }
    // no handling for now
    // if (UDKeyCodeArrow.has(keyCode)) {
    //   this.eventCollector(EVENTS.UDArrowKey, keyCode);
    // }

    if (keyCodeArrow.has(keyCode)) {
      this.eventCollector(EVENTS.arrowKey, keyCode);
    }

    if (keyCode === 9) {
      this.eventCollector(EVENTS.arrowKey, rtl || isPriorityErp ? 37 : 39);
    }

    if (keyCodeCharacter.has(keyCode)) {
      if (!e.metaKey && !e.ctrlKey && !e.altKey) {
        this.eventCollector(EVENTS.charKey, keyCode);
      }
    }
    if (keyCode === 13) {
      // ENTER
      this.eventCollector(EVENTS.onEnter, e);
    }
    if (keyCode === 27) {
      // Esc
      this.eventCollector(EVENTS.onEsc);
    }
    if (keyCode === 8) {
      // BackSpase
      this.eventCollector(EVENTS.onBackSpase);
    }

    // hotkeys from b-end
    hotkeyMatcher(e.nativeEvent, hotkeyList, this.handleHotkey);
  };

  handleDoubleClick = () => {
    this.eventCollector(EVENTS.onDoubleClick);
    setTimeout(() => this.setState({ isOpen: true }), 100);
  };

  handleCreate = (cellSet: RecordCellSetType, ...arg: Array<any>) => {
    // force call onChange for update JE, because update function have check on empty value
    this.props.onChange(this.props.data._cell, cellSet);

    this.setState(
      {
        value: cellSet,
      },
      () => {
        if (arg.length) executeFunc(arg[arg.length - 1]);
      },
    );
  };

  fsm = cellFsm(statesDict, this.defState());

  field: ?HTMLElement;

  insert = (nextState: FSM$State, cb?: () => void) => {
    const state = {
      currentState: nextState, // STATE.edit,
      value: RecordCellSet(),
    };

    this.setState(state, () => {
      executeFunc(cb);
    });
  };

  update = (nextState: FSM$State, cb?: () => void) => {
    const {
      props: { data, isBulkEdit },
      state: { value },
    } = this;
    if (!is(data.cellSet, value) || isBulkEdit) {
      if ((typeof value.value === 'string' && value.value.length === 0) || !value.value) {
        this.props.onChange(data._cell, RecordCellSet());
      } else {
        this.props.onChange(data._cell, value);
      }
    }
    this.setState(
      {
        currentState: nextState,
      },
      () => {
        executeFunc(cb);
      },
    );
  };

  toggleDate = (nextState: FSM$State, cb?: () => void) => {
    const {
      props: { data },
    } = this;
    const value = RecordCellSet({ value: toggleDate(data.cellSet.value) });
    this.props.onChange(data._cell, value);
    this.setState(
      {
        currentState: nextState,
      },
      () => {
        executeFunc(cb);
      },
    );
  };

  transition = (nextState: FSM$State, cb?: () => void) => {
    if (this.state.currentState.group !== nextState.group) {
      this.setState(
        {
          currentState: nextState,
          value: this.stateValue,
        },
        () => {
          executeFunc(cb);
        },
      );
    } else {
      executeFunc(cb);
    }
  };

  isMandatory = (): boolean => {
    const { data: cell } = this.props;
    return Boolean(cell.mandatory) && this.isEmpty();
  };

  isEmpty = (): boolean => !(this.value && this.value.length > 0);

  isValid = (): boolean => {
    const { data: cell } = this.props;
    return Boolean(cell.valid);
  };

  isDisabled = (): boolean => {
    // only cells with no inputs (cell.return = false) are marked readonly to disallow copying text
    const { data: cell } = this.props;
    return cell.readonly && !cell.return;
  };

  activeComponent: any;

  buttonRef = (el: ?HTMLElement) => {
    this.field = el;
    if (this.props.data._cell === this.props.active) {
      this.setFocus();
    }
  };

  render() {
    const {
      classes,
      data: { type, _cell, name, readonly, hint },
      setActiveCell,
      optionList,
      reference,
      hotkeyList,
    } = this.props;
    const { currentState, isOpen } = this.state;
    if (this.constructor.getGroup(currentState, GROUP.input)) {
      return (
        <div className={cx(classes.fieldWrapper, this.cellClasses)}>
          <HintInput
            id={_cell}
            name={{ _cell, name }}
            style={this.celleStyle}
            rtl={this.props.rtl}
            valueNormalize={textMapValueNormalize}
            optionList={optionList}
            getRefs={(el) => {
              this.field = el;
            }}
            onComplete={() => this.eventCollector(EVENTS.onSelect)}
            filterList={filterList}
            maxLength={this.getInputMaxLength()}
            onDoubleClick={this.handleDoubleClick}
            type={type}
            input={{
              value: this.state.value,
              onChange: this.handleChange,
              onKeyDown: this.handleKey,
              onBlur: this.handleBlur,
            }}
          />
        </div>
      );
    }

    if (this.constructor.getGroup(currentState, GROUP.select)) {
      // eslint-disable-next-line max-len
      const finDocWidth = () => window.innerWidth - (window.innerHeight < mediaQuery.maxHeightNumber ? 400 : 600);
      const { props, state, handleChange, handleBlur, handleShieldClick, handleKey, handleCreate } = this;
      const { data, rtl, documentView } = props;
      const { includes, pageToken, _options, type, isCreatable, include_id } = data;
      const { isOpen, value } = state;

      const sharedProps = {
        id: _cell,
        name: { _cell, name },
        style: this.celleStyle,
        filterList: findText,
        valueNormalize: textMapValueNormalize,
        rtl,
        maxWidth: documentView.get('infoBarWidth') || finDocWidth(),
        getRefs: (el, comp) => {
          this.field = el;
          this.activeComponent = comp;
        },
        onComplete: () => this.eventCollector(EVENTS.onSelect),
        isOpen,
        params: { id: includes, cellName: name },
        pageLimit: 50,
        pageItemsCount: 10,
        pageToken,
        input: {
          value,
          onChange: handleChange,
          onBlur: handleBlur,
          onToggleOpen: handleShieldClick,
          onKeyDown: handleKey,
          isCreatable,
          onCreate: handleCreate,
        },
      };

      const listRenders = {
        index_list: () => (
          <div className={cx(classes.fieldWrapper, this.cellClasses)}>
            <Autocomplete {...sharedProps} optionList={_options} />
          </div>
        ),
        paginated_list: () => (
          <div className={cx(classes.fieldWrapper, this.cellClasses)}>
            <PaginationAutocomplete {...sharedProps} include_id={include_id} />
          </div>
        ),
      };

      const listRender = listRenders[type] || identity;
      return listRender();
    }
    if (this.constructor.getGroup(currentState, GROUP.cell)) {
      const { link } = this.props.data;
      return (
        <div className={classes.fieldWrapper}>
          <Tooltip t={!isOpen && (hint || this.value)} disableFocusListener>
            <div
              className={cx(classes[type === 'float' ? type : 'string'], {
                [classes.disabled]: this.isDisabled(),
                [classes.readonly]: readonly,
              })}
              style={this.celleStyle}
              data-element={elements.je.readonly.container}
              data-element-id={`${_cell}-${type}`}
            >
              {link ? (
                <a href={link} target="_blank" rel="noopener noreferrer">
                  {this.value}
                </a>
              ) : (
                this.value
              )}
            </div>
          </Tooltip>
        </div>
      );
    }
    if (this.constructor.getGroup(currentState, GROUP.literal)) {
      return (
        <div className={classes.fieldWrapper}>
          <Literal
            maxLength={this.getInputMaxLength()}
            key={this.state.value.value}
            data={this.props.data}
            onBlur={this.handleBlur}
            getRefs={(el) => {
              this.field = el;
            }}
            onChange={this.props.onChange}
            eventCollector={this.eventCollector}
            value={this.state.value.value}
          />
        </div>
      );
    }
    if (this.constructor.getGroup(currentState, GROUP.boolean)) {
      return (
        <div className={classes.fieldWrapper}>
          <BooleanCell
            data={this.props.data}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            onChange={this.props.onChange}
            onClick={this.handleClick}
            getRefs={(el) => {
              this.field = el;
            }}
            onKeyDown={this.handleKey}
          />
        </div>
      );
    }
    if (this.constructor.getGroup(currentState, GROUP.reconciliation)) {
      const { amountColumn, data, gridData } = this.props;
      const amount = gridData.getIn([`${amountColumn}${data._row}`, 'cellSet', 'value']);

      return (
        <ReconciliationCell
          data={data}
          onChange={this.props.onChange}
          getRefs={(el) => {
            this.field = el;
          }}
          onFocus={this.handleFocus}
          onKeyDown={this.handleKey}
          onBlur={this.handleBlur}
          onShowDetails={this.props.onShowDetails}
          amount={amount}
        />
      );
    }

    return (
      <MainCell
        classes={classes}
        data={this.props.data}
        maxLength={this.getInputMaxLength()}
        className={this.cellClasses}
        onChange={this.props.onChange}
        handleKey={this.handleKey}
        handleDoubleClick={this.handleDoubleClick}
        handleFocus={this.handleFocus}
        handleClick={this.handleClick}
        handleBlur={this.handleBlur}
        value={this.value}
        buttonRef={this.buttonRef}
        activeReference={this.props.activeReference}
        createRefernce={this.props.createRefernce}
        setActiveReference={this.props.setActiveReference}
        isSupport={this.props.isSupport}
        eventCollector={this.eventCollector}
        insertGrid={this.props.insertGrid}
        setActiveCell={setActiveCell}
        hintDisabled={this.constructor.disableHintForCell(name)}
        reference={reference}
        hotkeyList={hotkeyList}
      >
        {this.constructor.getGroup(currentState, GROUP.selectPassive) ? (
          <button
            type="button"
            className={classes.btnShield}
            onMouseUp={() => this.handleShieldClick(true)}
            data-element={elements.je.autocomplete.shield}
          />
        ) : null}
        {this.constructor.getGroup(currentState, GROUP.datePassive) ? (
          <DateToggle data={this.props.data} className={classes.dateToggler} onClick={() => this.handleDateToggler()} />
        ) : null}
      </MainCell>
    );
  }
}

export default Cell;
