// @flow
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { querySelector } from 'domain/router/RouterSelector';
import { tokenSelector } from 'domain/env/envSelector';
import { updateQueryAction } from 'domain/router/RouterActions';

import elements from 'components/elements';
import BarIcon from 'components/BarIcon';
import Tooltip from 'components/Tooltip';
import TagSearchLib from 'components/SearchInput';
import SelectedTags from 'components/SelectedTags';

import useStyles from './sheet';

type Props = {
  openUploadPopup: () => void,
  onCalendar: () => void,
  locale: 'en' | 'he',
  dateFormat: ?string,
  selectedTagsRef?: ?React.ElementRef<HTMLDivElement>,
};

const mapStateToProps = (state) => ({
  query: querySelector(state),
  dokkaToken: tokenSelector(state),
});

const Bar: React$StatelessFunctionalComponent<Props> = ({
  openUploadPopup,
  onCalendar,
  locale,
  dateFormat,
  selectedTagsRef,
}) => {
  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();

  const { query, dokkaToken } = useSelector(mapStateToProps);

  const onFilter = useCallback((payload) => dispatch(updateQueryAction(payload)), [dispatch]);

  return (
    <>
      <div className={classes.bar}>
        <div className={classes.actionsContainer}>
          <button
            type="button"
            className={classes.uploadBtn}
            onClick={openUploadPopup}
            data-element={elements.filterBar.addDocumentButton}
          >
            <FormattedMessage id="company.supplier.bar.btns.sendDocuments" defaultMessage="Send Documents" />
          </button>
          <div className={classes.actionItem}>
            <Tooltip id="tooltip.company.bar.filterByDate" defaultMessage="Filter by Date">
              <BarIcon
                icon="calendar"
                className={classes.btn}
                action={onCalendar}
                data-element={elements.filterBar.calendarButton}
                grey
              />
            </Tooltip>
          </div>
        </div>
        <TagSearchLib onFilter={onFilter} query={query} dokkaToken={dokkaToken} />
      </div>
      <SelectedTags
        onFilter={onFilter}
        query={query}
        confidential={false}
        params={params}
        locale={locale}
        dateFormat={dateFormat}
        selectedTagsRef={selectedTagsRef}
      />
    </>
  );
};

export default Bar;
