import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LinkOffOutlinedIcon from '@mui/icons-material/LinkOffOutlined';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ScoreOutlinedIcon from '@mui/icons-material/ScoreOutlined';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import InputIcon from '@mui/icons-material/Input';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';

const MenuItemIcons = {
  setAsCover: OpenInNewOutlinedIcon,
  open: OpenInBrowserIcon,
  preview: RemoveRedEyeIcon,
  linkDocuments: LinkOutlinedIcon,
  editStickyNote: NoteAltOutlinedIcon,
  addStickyNote: NoteAddOutlinedIcon,
  tagsAs: LibraryAddOutlinedIcon,
  markAsPaid: DoneAllOutlinedIcon,
  markAsUnpaid: DoneAllOutlinedIcon,
  markAsGeneral: BookmarkBorderOutlinedIcon,
  markAsFinancial: ScoreOutlinedIcon,
  ignoreDocumentWarnings: ErrorOutlineOutlinedIcon,
  downloadDocument: CloudDownloadOutlinedIcon,
  downloadLinked: CloudDownloadOutlinedIcon,
  editDocument: EditOutlinedIcon,
  deleteDocument: DeleteOutlineOutlinedIcon,
  duplicateDocument: FileCopyOutlinedIcon,
  printDocument: PrintOutlinedIcon,
  printLinked: PrintOutlinedIcon,
  signDocument: BorderColorOutlinedIcon,
  moveTo: ArrowRightAltOutlinedIcon,
  moveToCompany: InputIcon,
  moveLinkedToCompany: InputIcon,
  unlink: LinkOffOutlinedIcon,
  editLinkDocuments: ContentPasteIcon,
  linkedBundle: AttachFileOutlinedIcon,
};

export default MenuItemIcons;
