// @flow
import React, { Fragment, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from 'domain/env';
import { FormattedMessage, useIntl } from 'react-intl';
import { companiesByOrganizationSelector } from 'domain/companies/companiesSelector';
import {
  assignOrganizationUser,
  revokeOrganizationUser,
  revokeUserFromAllCompanies,
  deleteOrganizationUser,
} from 'domain/organizationUser/actions';
import { getOrganizationUserByIdSelector } from 'domain/organizationUser/selectors';
import { useConfirm } from 'material-ui-confirm';

import AssigmentButton from 'pages/configurations/components/AssigmentButton';
import Avatar from 'components/mui/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListSubheader from '@mui/material/ListSubheader';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

type TOrganizationUserDetails = {
  userID: string,
  onGoBack: () => void,
  onClickEdit: () => void,
};

const mapStateToProps = (state) => ({
  currentUser: userSelector(state),
  companies: companiesByOrganizationSelector(state),
});

const OrganizationUserDetails: React$StatelessFunctionalComponent<TOrganizationUserDetails> = ({
  userID,
  onGoBack,
  onClickEdit,
}) => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const { formatMessage } = useIntl();
  const { companies, currentUser } = useSelector(mapStateToProps);
  const user = useSelector(getOrganizationUserByIdSelector(userID));

  const handleUserAssignment = useCallback(
    (companyId: string) => {
      const assigned = user.companies_id.has(companyId);

      if (assigned) dispatch(revokeOrganizationUser({ data: { id: user.id, companyId } }));
      else dispatch(assignOrganizationUser({ data: { id: user.id, companyId } }));
    },
    [dispatch, user],
  );

  const handleRevokeAll = () => {
    dispatch(revokeUserFromAllCompanies(user.id));
  };

  const handleDelete = () => {
    confirm({
      title: formatMessage({
        id: 'confirm.user.organization.delete.title',
        defaultMessage: 'Delete User?',
      }),
      description: formatMessage({
        id: 'confirm.user.organization.delete.description',
        defaultMessage: 'User will be permanently deleted from the system, action cannot be  undone.',
      }),
      confirmationText: formatMessage({
        id: 'button.delete',
        defaultMessage: 'Delete',
      }),
    }).then(() => {
      dispatch(deleteOrganizationUser({ id: user.id }));
      onGoBack();
    });
  };

  const isRevokeDisabled = companies.toList().size === 0 || currentUser.userId === user.email || user.admin;

  const isAssigned = (companyID: string) => user.companies_id.has(companyID);

  return (
    <>
      <List
        subheader={
          <ListSubheader sx={{ px: 2 }}>
            <Button size="small" variant="outlined" onClick={onGoBack} startIcon={<ArrowBackIcon />}>
              <FormattedMessage id="button.back" defaultMessage="Back" />
            </Button>
          </ListSubheader>
        }
        sx={{ position: 'sticky', top: 0, backgroundColor: 'common.white', zIndex: 1 }}
      >
        <ListItem divider alignItems="center">
          <ListItemAvatar>
            <Avatar size={40} src={user.pic} />
          </ListItemAvatar>
          <Box flexGrow={1} my="6px">
            <Typography variant="body2">{user.display_name}</Typography>
            <Typography variant="body2" color="text.secondary">
              {user.email}
            </Typography>
          </Box>
          <Box flexGrow={1} my="6px">
            <Typography variant="body2">
              <FormattedMessage id="configurations.org_users.details.phone" defaultMessage="Phone" />
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {user.phone}
            </Typography>
          </Box>
          <Box flexShrink={0}>
            <Button size="small" color="info" onClick={onClickEdit} startIcon={<EditIcon />}>
              <FormattedMessage id="button.edit" defaultMessage="Edit" />
            </Button>
            <Button
              size="small"
              color="error"
              onClick={() => handleDelete(user.id)}
              startIcon={<DeleteIcon />}
              disabled={Boolean(user.companies_id.size)}
              sx={{ ml: 1 }}
            >
              <FormattedMessage id="button.delete" defaultMessage="Delete" />
            </Button>
          </Box>
        </ListItem>
      </List>

      <Box display="flex" alignItems="center" px={2}>
        <Typography variant="h6" flexGrow={1}>
          <FormattedMessage id="configurations.org_users.details.companiesTitle" defaultMessage="Companies:" />
          <Typography variant="h6" component="span" pl={1}>
            {companies.size}
          </Typography>
        </Typography>
        <Button color="error" disabled={isRevokeDisabled} onClick={handleRevokeAll} startIcon={<CloseIcon />}>
          <FormattedMessage id="configurations.org_users.details.revokeAllAccess" defaultMessage="Revoke All Access" />
        </Button>
      </Box>

      <List>
        {companies.map((company, index) => (
          <Fragment key={company.id}>
            <ListItem dense alignItems="center">
              <ListItemAvatar>
                <Avatar size={40} src={company.logo} />
              </ListItemAvatar>
              <Box flexGrow={1} my="6px">
                <Typography variant="body2">{company.cname}</Typography>
              </Box>
              <Box flexShrink={0}>
                <AssigmentButton
                  disabled={isRevokeDisabled}
                  assigned={isAssigned(company.id)}
                  onClick={() => handleUserAssignment(company.id)}
                />
              </Box>
            </ListItem>
            {companies.size !== index + 1 && <Divider variant="inset" component="li" />}
          </Fragment>
        ))}
      </List>
    </>
  );
};

export default OrganizationUserDetails;
