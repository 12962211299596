// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { querySelector, updateQueryAction } from 'domain/router';
import { getCurrentCompanyData } from 'domain/companies';
import moment from 'moment';

import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import Dialog from 'components/mui/Dialog';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

type TDialogFilterByDate = {
  onClose: () => void,
};

type DateTypeConfig = {
  type: string,
  title: {
    id: string,
    defaultMessage: string,
  },
};

const DEFAULT_FORMAT = 'MM/DD/YYYY';

const DATE_TYPE_FILTERS: Array<DateTypeConfig> = [
  {
    type: 'date',
    title: {
      id: 'dayPicker.date',
      defaultMessage: 'By Date Received',
    },
  },
  {
    type: 'invoice_date',
    title: {
      id: 'dayPicker.invoice_date',
      defaultMessage: 'By Invoice Date',
    },
  },
  {
    type: 'payment_date',
    title: {
      id: 'dayPicker.payment_date',
      defaultMessage: 'By Payment Date',
    },
  },
];

const DEFAULT_RANGES = DATE_TYPE_FILTERS.reduce((acc, dateType) => ({ ...acc, [dateType.type]: [null, null] }), {});

const mapStateToProps = (state) => ({
  company: getCurrentCompanyData(state),
  query: querySelector(state),
});

const DialogFilterByDate: React$StatelessFunctionalComponent<TDialogFilterByDate> = ({ onClose }) => {
  const dispatch = useDispatch();
  const { query, company } = useSelector(mapStateToProps);
  const { formatMessage } = useIntl();
  const [currentDateType, setCurrentDateType] = useState('date');
  const [rangeError, setRangeError] = useState([null, null]);
  const [ranges, setRanges] = useState(DEFAULT_RANGES);
  const dateFormat = company.get('dateFormat') || DEFAULT_FORMAT;
  const isDisabledFuture = currentDateType !== 'payment_date';
  const isEnabledSubmit = Object.entries(ranges).some(([, [from, to]]) => from !== null && to !== null);

  const handleChangeDateType = (e, type: string) => {
    setCurrentDateType(type);
  };

  const handleChangeDateRange = useCallback(
    (range) => {
      setRanges((prevRanges) => ({ ...prevRanges, [currentDateType]: range }));
    },
    [currentDateType],
  );

  const handleOk = () => {
    const out = (d) => d.format(DEFAULT_FORMAT);
    const data = Object.entries(ranges).reduce(
      (acc, [dateType, range]) => (range[0] && range[1] ? { ...acc, [dateType]: range.map(out) } : acc),
      {},
    );

    dispatch(updateQueryAction(data));
    onClose();
  };

  const handleError = (error, value) => {
    setRangeError(error);
  };

  useEffect(() => {
    const qRanges = Object.keys(DEFAULT_RANGES).reduce((acc, dateType) => {
      const qRange = query[dateType];
      const range =
        Array.isArray(qRange) && qRange.length === 2 ? [moment(qRange[0]), moment(qRange[1])] : [null, null];

      return { ...acc, [dateType]: range };
    }, {});

    setRanges(qRanges);
  }, [query]);

  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth={false}
      maxWidth={false}
      title={formatMessage({ id: 'dayPicker.date_filter', defaultMessage: 'Date Filter' })}
      okText={formatMessage({ id: 'dayPicker.button.search', defaultMessage: 'Search' })}
      onOk={handleOk}
      okBtnProps={{ disabled: !isEnabledSubmit }}
    >
      <Tabs variant="fullWidth" value={currentDateType} onChange={handleChangeDateType}>
        {DATE_TYPE_FILTERS.map(({ type, title }) => (
          <Tab key={type} label={formatMessage(title)} value={type} />
        ))}
      </Tabs>

      <Box mt={2} mb={1}>
        <SingleInputDateRangeField
          label={`${formatMessage({ id: 'dayPicker.from', defaultMessage: 'From' })} - ${formatMessage({
            id: 'dayPicker.to',
            defaultMessage: 'To',
          })}`}
          value={ranges[currentDateType]}
          onChange={handleChangeDateRange}
          onError={handleError}
          disableFuture={isDisabledFuture}
          format={dateFormat}
          fullWidth
          helperText={
            (Boolean(rangeError[0]) || Boolean(rangeError[1])) &&
            formatMessage({ id: 'message.invalidPeriod', defaultMessage: 'Invalid period' })
          }
        />
      </Box>

      <DateRangeCalendar
        value={ranges[currentDateType]}
        disableFuture={isDisabledFuture}
        onChange={handleChangeDateRange}
      />
    </Dialog>
  );
};

export default DialogFilterByDate;
