// @flow
import React from 'react';

import { AuthAsideBase } from 'pages/authPage/Layout/StyledComponents';
import Box from '@mui/material/Box';

import { styled, useTheme } from '@mui/material/styles';

import bg from 'pages/authPage/Layout/AuthAside/Made/images/bg.svg';

const AuthAside = styled(AuthAsideBase)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  backgroundImage: `url("${bg}")`,
  backgroundPosition: 'bottom right',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  width: '40%',
}));

const AuthAsideMade = () => {
  const {
    labelOptions: { loginNameImg },
  } = useTheme();

  return (
    <AuthAside>
      <Box m="auto" width="245px">
        <img src={loginNameImg.src} alt={loginNameImg.alt} width="100%" />
      </Box>
    </AuthAside>
  );
};

export default AuthAsideMade;
