// @flow
import React, { useState, useMemo, useCallback, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { companyFeatureSetSelector } from 'domain/companies/companiesSelector';
import { updateVendorDefaultsAction } from 'domain/settings/settingsActions';
import { useDispatch, useSelector } from 'react-redux';
import { type TGridApi, type TColumnApi } from 'pages/company/grid/types.js.flow';
import { FormattedMessage } from 'react-intl';
import useInitialLoad from './hooks/initialLoad';
import { columnDefsAdapter } from 'pages/configurations/company/pages/supplier_defaults/helpers/columnDefinitions';

import DropdownCellEditor from 'pages/company/grid/components/DropdownCellEditor';
import MultipleDropdownCellEditor from 'pages/company/grid/components/MultipleDropdownCellEditor';
import MultipleDropdownCellRenderer from 'pages/company/grid/components/MultipleDropdownCellRenderer';
import DropdownCellRenderer from 'pages/company/grid/components/DropdownCellRenderer';
import InputCellRenderer from 'pages/company/grid/components/InputCellRenderer';
import InputNumberCellEditor from 'pages/company/grid/components/InputNumberCellEditor';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FeatureDisabledMessage from 'pages/configurations/components/FeatureDisabledMessage';
import CustomSum from 'pages/company/grid/components/CustomSum';

import useAgGridDefaultConfig from 'hooks/agGrid/useAgGridDefaultConfig';
import useAgGridManualConfig from 'hooks/agGrid/useAgGridManualConfig';

import useAgGridVendorStyles from 'pages/configurations/company/pages/supplier_defaults/hooks/useAgGridVendorStyles';

const SupplierDefaults = () => {
  const dispatch = useDispatch();
  const [gridApi, setGridApi] = useState(null);
  const columnApiRef = useRef();
  const features = useSelector(companyFeatureSetSelector);
  const agGridDefaultProps = useAgGridDefaultConfig(useAgGridVendorStyles);
  const { enableRtl, getLocaleText } = useAgGridManualConfig();

  const {
    grid: { headers, rows },
    updateGridData,
  } = useInitialLoad();

  const columnDefs = useMemo(
    () => ({
      columnDefs: columnDefsAdapter(headers),
    }),
    [headers],
  );

  const defaultColDef = useMemo(
    () => ({
      filter: true,
      menuTabs: ['filterMenuTab'],
      suppressMovable: true,
      resizable: true,
      width: 120,
      minWidth: 120,
      flex: 1,
    }),
    [],
  );

  // callbacks
  const onGridReady = useCallback((p) => {
    const { api, columnApi }: {| api: TGridApi, columnApi: TColumnApi |} = p;
    setGridApi(api);
    columnApiRef.current = columnApi;
  }, []);

  const save = useCallback(() => {
    if (gridApi) {
      const rowsData = [];

      gridApi.forEachNode((node) => rowsData.push(node.data));

      new Promise((resolve, reject) => {
        dispatch(updateVendorDefaultsAction({ rows: rowsData, resolve, reject }));
      }).then((data) => {
        updateGridData(data);
      });
    }
  }, [gridApi, dispatch, updateGridData]);

  const frameworkComponents = {
    dropdownCellEditor: DropdownCellEditor,
    dropdownCellRenderer: DropdownCellRenderer,
    multipleDropdownCellEditor: MultipleDropdownCellEditor,
    multipleDropdownCellRenderer: MultipleDropdownCellRenderer,
    inputCellRenderer: ({ value }) => <InputCellRenderer value={value} />,
    inputNumberCellEditor: InputNumberCellEditor,
    customSum: CustomSum,
  };

  return features.get('supplier_form', false) && features.get('fin', false) ? (
    <Box className="ag-theme-material" flexGrow={1} display="flex" flexDirection="column">
      <AgGridReact
        {...agGridDefaultProps}
        suppressClickEdit={false}
        enableRangeSelection={false}
        rowHeight={58}
        {...columnDefs}
        // props from useAgGridManualConfig hook
        getLocaleText={getLocaleText}
        enableRtl={enableRtl}
        //
        rowData={rows}
        defaultColDef={defaultColDef}
        onGridReady={onGridReady}
        components={frameworkComponents}
        animateRows
        getRowId={({ data }) => data.id}
        stopEditingWhenCellsLoseFocus
        suppressContextMenu
        suppressScrollWhenPopupsAreOpen
      />
      <Box display="flex" justifyContent="end" pt={2}>
        <Button onClick={save} sx={{ minWidth: '20ch' }}>
          <FormattedMessage id="button.save" defaultMessage="Save" />
        </Button>
      </Box>
    </Box>
  ) : (
    <FeatureDisabledMessage />
  );
};

export default SupplierDefaults;
