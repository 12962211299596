// @flow
import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { List } from 'immutable';
import { FormattedMessage } from 'react-intl';
import { favoritesListSelector } from 'domain/dashboard/selectors';
import useFavorites from 'pages/company/CompanyNavigation/FavoritesMenu/useFavorites';

import { LayoutCompanyContext } from 'components/mui/Layouts/Company/LayoutCompanyContext';
import FavoritesListItem from 'pages/company/CompanyNavigation/FavoritesMenu/ListItem';
import ListMui from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { ListItemIcon } from 'components/mui/Layouts/components/Drawer';
import { FavoriteListWrapper } from 'pages/company/CompanyNavigation/FavoritesMenu/StyledComponents';

import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';

const FavoritesMenu = () => {
  const favoritesList = useSelector(favoritesListSelector);
  const { toggle } = useContext(LayoutCompanyContext);

  const { isCurrentUser, getUserBySearchLink } = useFavorites();

  const entries = useMemo(() => {
    const addUser = (usersFav, fav, isCurrent) => (isCurrent ? usersFav.unshift(fav) : usersFav.push(fav));

    const [usersList, other] = favoritesList.reduce(
      ([userRes, otherFav], fav) => {
        const user = getUserBySearchLink(fav.query);
        return user ? [addUser(userRes, fav, isCurrentUser(user.userId)), otherFav] : [userRes, otherFav.push(fav)];
      },
      [new List(), new List()],
    );

    return usersList.merge(other);
  }, [favoritesList, isCurrentUser, getUserBySearchLink]);

  return (
    toggle && (
      <ListMui component="nav">
        <ListItem component="div">
          <ListItemIcon sx={{ color: 'inherit' }}>
            <StarBorderOutlinedIcon />
          </ListItemIcon>
          <ListItemText disableTypography sx={{ fontWeight: 500 }}>
            <FormattedMessage id="company.favorites" defaultMessage="Favorites" />
          </ListItemText>
        </ListItem>
        <FavoriteListWrapper>
          {entries.map((entry) => (
            <FavoritesListItem key={entry.label} entry={entry} />
          ))}
        </FavoriteListWrapper>
      </ListMui>
    )
  );
};

export default FavoritesMenu;
