// @flow
import React from 'react';

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import { Link } from 'react-router-dom';

interface IProps {
  valueFormatted: string | number;
  onClick: (e: MouseEvent) => void;
  onDocumentOpen: (id: string, l?: ?boolean) => void;
  visible: boolean;
  to: string;
}

const WithDocumentViewButton = ({ onClick, onDocumentOpen, to }: IProps) => (
  <Stack flex={1} direction="row" justifyContent="center" alignItems="center">
    <IconButton onClick={onClick} sx={{ fontSize: '22px' }}>
      <RemoveRedEyeOutlinedIcon fontSize="inherit" />
    </IconButton>
    <IconButton to={to} component={Link} onClick={onDocumentOpen} sx={{ fontSize: '22px' }}>
      <InsertDriveFileOutlinedIcon fontSize="inherit" />
    </IconButton>
  </Stack>
);

export default WithDocumentViewButton;
