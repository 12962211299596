// @flow
import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { debounce } from 'throttle-debounce';
import { linkedSidebarWidthSelector, rtlEnable } from 'domain/env';
import { documentLinkedOpenStatusSelector } from 'domain/documents';
import { createMemoFn } from 'lib/propHelpers';
import * as ACL from 'domain/restriction';
import withState from 'components/withState';

import { DnDItemSource, DOCUMENT } from 'components/DnDItem__legacy';
import {
  Modal,
  ModalCloseButton,
  PreviewCounter,
  PreviewBadges,
  PreviewContent,
  PreviewNavigation,
  PreviewNavigationButton,
} from 'pages/company/DocumentPreview/components/StyledComponents';
import PreviewActionGroup from 'pages/company/DocumentPreview/components/PreviewActionGroup';
import LinkedBadge from 'pages/company/DocumentPreview/components/LinkedBadge';
import PaidBadge from 'pages/company/DocumentPreview/components/PaidBadge';
import CircularProgressWithBackdrop from 'components/mui/CircularProgressWithBackdrop';
import RouterLink from 'components/mui/Router/RouterLink';
import Box from '@mui/material/Box';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import cx from 'classnames';

export type Props = {
  close: () => void,
  src: string,
  rotationAngle?: number,
  documentID: string, // ?
  isPaid: boolean,
  to: string,
  linkedPanelWidth: number,
  isOpenLinkedPanel: boolean,
  onClick: () => void,
  disabled: boolean,
  onPrev?: () => void,
  onNext?: () => void,
  isDisablePrev?: boolean,
  isDisableNext?: boolean,
  onContextMenu?: (e: MouseEvent) => void,
  isContextMenuOpen?: boolean,
  allPagesCount?: number,
  currentPage?: number,
  rotationAngleState: number,
  setRotationAngleState: (a: number) => void,
  xGap?: number,
  yGap?: number,
  isLinked?: boolean,
  isLoading?: boolean,
  isGranted: (r: number | number[]) => boolean,
  isRtl: boolean,
};

const DragSource = DnDItemSource(DOCUMENT);

type SizeT = {|
  width: number,
  height: number,
|};

type State = {
  isSuppressSpinner: boolean,

  /*
 All the state below has an impact on the view of the doc,
 we can't use these properties directly from props because they must apply only after the link is uploaded,
 the previous doc must be shown if even the one is already changed
 */
  lastLoadedSrc: ?string,
  rawSize: SizeT,
  isPaid: boolean,
};

const scaleSize = (rawSize: SizeT, ratio: number) => {
  const { width, height } = rawSize;
  return { width: width * ratio, height: height * ratio };
};

class DocumentPreview extends React.Component<Props, State> {
  imageRulerEl: ?HTMLElement = null;

  btnBoxEl: ?HTMLElement = null;

  loadTimer: ?TimeoutID = null;

  constructor() {
    super();
    this.state = {
      lastLoadedSrc: null,
      rawSize: { width: 1, height: 1 },
      isPaid: false,
      isSuppressSpinner: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.onWindowResize);
  }

  componentDidUpdate(prevProps: Props) {
    const { isPaid, src } = this.props;
    if (prevProps.isPaid !== isPaid && !this.isLoading()) {
      this.onUpdatePaidStatus();
    }

    if (src !== prevProps.src) {
      this.onStartLoad();
    }
  }

  componentWillUnmount() {
    const { close } = this.props;
    this.clearTimer();
    window.removeEventListener('resize', this.onWindowResize);
    close();
  }

  onWindowResize = () => this.onInit();

  onUpdatePaidStatus = () => {
    const { isPaid } = this.props;
    this.setState({ isPaid });
  };

  onStartLoad = () => {
    const { isSuppressSpinner } = this.state;
    if (!isSuppressSpinner) {
      this.setState({ isSuppressSpinner: true });
      this.loadTimer = setTimeout(() => {
        this.clearTimer();
        this.setState({ isSuppressSpinner: false });
      }, 700);
    }
  };

  onInit = debounce(30, () => {
    const { src, rotationAngle, isPaid, setRotationAngleState } = this.props;
    this.setState({
      lastLoadedSrc: src,
      rawSize: this.getImgRawSize(),
      isPaid,
      isSuppressSpinner: false,
    });
    this.clearTimer();
    setRotationAngleState(rotationAngle);
  });

  onClickContextBtn = (e: MouseEvent) => {
    e.stopPropagation();
    const { onContextMenu, isContextMenuOpen, isRtl } = this.props;

    if (onContextMenu && !isContextMenuOpen) {
      // TODO: rework :(
      const { x, y, width, height } = this.btnBoxEl.getBoundingClientRect();
      const rtlShift = isRtl ? width : 0;
      e.clientX = x + rtlShift;
      e.clientY = y + height + 5;
      onContextMenu(e);
    }
  };

  onClickPrev = (e: MouseEvent) => {
    const { onPrev } = this.props;
    e.stopPropagation();
    onPrev();
  };

  onClickNext = (e: MouseEvent) => {
    const { onNext } = this.props;
    e.stopPropagation();
    onNext();
  };

  getPaidStatus = () => {
    const { isPaid: isPaidState } = this.state;
    const { isPaid } = this.props;
    return this.isLoading() ? isPaidState : isPaid;
  };

  getXSpace = () => {
    const { linkedPanelWidth, isOpenLinkedPanel, xGap = 0 } = this.props;
    const offset = isOpenLinkedPanel ? linkedPanelWidth : 0;

    return window.innerWidth - offset - xGap;
  };

  getAvailableSpace = createMemoFn(() => {
    const { yGap = 0 } = this.props;
    const { innerHeight } = window;
    return {
      height: innerHeight - 100 - yGap,
      width: this.getXSpace() - 300,
    };
  });

  getImgRawSize = createMemoFn(
    () => {
      const { lastLoadedSrc } = this.state;
      return {
        lastLoadedSrc,
        imageRulerEl: this.imageRulerEl,
        isLoading: this.isLoading(), // use as trigger because this.imageRulerEl never change
      };
    },
    ({ imageRulerEl }) => {
      const { width, height } = imageRulerEl ? imageRulerEl.getBoundingClientRect() : { width: 1, height: 1 };
      return { width, height };
    },
  );

  getPresentationImgSize = createMemoFn(
    () => {
      const { rotationAngleState } = this.props;
      const { rawSize } = this.state;
      return {
        rotationAngleState,
        rawSize,
      };
    },
    ({ rotationAngleState, rawSize }) => {
      const { width: w, height: h } = rawSize;
      // if doc rotated 90, 270, etc. Set width equal height
      const isShuffle = rotationAngleState ? !!((rotationAngleState / 90) % 2) : false;
      const [width, height] = isShuffle ? [h, w] : [w, h];
      return { width, height };
    },
  );

  getRatio = () => {
    const rawSize = this.getPresentationImgSize();
    const space = this.getAvailableSpace();
    const widthRatio = space.width / rawSize.width;
    const heightRatio = space.height / rawSize.height;
    return Math.min(widthRatio, heightRatio);
  };

  getContainerStyle = createMemoFn(() => ({ width: this.getXSpace() }));

  getBoxStyle = createMemoFn(
    () => ({ rawSize: this.getPresentationImgSize(), ratio: this.getRatio() }),
    ({ rawSize, ratio }) => scaleSize(rawSize, ratio),
  );

  getImageStyle = createMemoFn(
    () => {
      const { rawSize } = this.state;
      return { rawSize, ratio: this.getRatio() };
    },
    ({ rawSize, ratio }) => scaleSize(rawSize, ratio),
  );

  clearTimer = () => {
    if (this.loadTimer) {
      clearTimeout(this.loadTimer);
      this.loadTimer = null;
    }
  };

  isContextMenuAvailable = () => {
    const { onContextMenu } = this.props;
    return onContextMenu;
  };

  isLoading = () => {
    const { src, isLoading } = this.props;
    const { lastLoadedSrc } = this.state;
    return src !== lastLoadedSrc || isLoading;
  };

  isDisable = () => {
    const { isContextMenuOpen, disabled } = this.props;
    return disabled || isContextMenuOpen;
  };

  initializeImageProxy = (el: ?HTMLElement) => {
    this.imageRulerEl = el;
  };

  refBtnBox = (el: ?HTMLElement) => {
    this.btnBoxEl = el;
  };

  renderImagePresentation = createMemoFn(
    () => {
      const { lastLoadedSrc } = this.state;

      return {
        lastLoadedSrc,
        style: this.getImageStyle(),
      };
    },
    ({ lastLoadedSrc, style }) =>
      lastLoadedSrc ? (
        <img src={lastLoadedSrc} alt="document preview" draggable={false} ref={this.refProxy} style={style} />
      ) : (
        <div />
      ),
  );

  renderImage = createMemoFn(
    () => {
      const { isOpenLinkedPanel, documentID, to, onClick } = this.props;
      const image = this.renderImagePresentation();
      return { image, isOpenLinkedPanel, documentID, to, onClick, disabled: this.isDisable() };
    },
    ({ image, isOpenLinkedPanel, disabled, documentID, to, onClick }) => {
      if (disabled) {
        return <Box display="flex">{image}</Box>;
      }

      if (isOpenLinkedPanel) {
        return (
          <Box display="flex" component={DragSource} isDragging id={documentID}>
            {image}
          </Box>
        );
      }

      return (
        <Box display="flex" component={RouterLink} href={to} onClick={onClick}>
          {image}
        </Box>
      );
    },
  );

  render() {
    const {
      onClick,
      to,
      disabled,
      onPrev,
      onNext,
      isDisablePrev,
      isDisableNext,
      src,
      close,
      isContextMenuOpen,
      currentPage,
      allPagesCount,
      isLinked = false,
      isGranted,
      isOpenLinkedPanel,
      linkedPanelWidth,
      xGap,
      rotationAngleState,
      isRtl,
    } = this.props;

    const { isSuppressSpinner } = this.state;

    const isPaid = this.getPaidStatus();
    const isSupplier = isGranted(ACL.IS_SUPPLIER_USER);
    const isBusy = !isSuppressSpinner && this.isLoading();

    return (
      <Modal open onClose={close} offset={isOpenLinkedPanel ? linkedPanelWidth : xGap || 0}>
        <>
          {/* for load image and calculate size etc, onLoad={this.onInit} */}
          <Box zIndex={-1} position="absolute" height={0} width={0} overflow="hidden">
            <img
              src={src}
              alt="document preview"
              onLoad={this.onInit}
              ref={this.initializeImageProxy} // TODO: rework :(
            />
          </Box>

          {isBusy && <CircularProgressWithBackdrop />}

          {onPrev && (
            <PreviewNavigation>
              <PreviewNavigationButton onClick={this.onClickPrev} disabled={isDisablePrev}>
                {isRtl ? <NavigateNextOutlinedIcon /> : <NavigateBeforeOutlinedIcon />}
              </PreviewNavigationButton>
            </PreviewNavigation>
          )}

          <PreviewContent
            className={cx({ 'PreviewContent-isPaid': isPaid, 'PreviewContent-isLinked': isLinked })}
            style={{ ...this.getBoxStyle() }}
          >
            <ModalCloseButton onClick={close}>
              <CloseOutlinedIcon />
            </ModalCloseButton>

            <Box boxShadow={10} sx={{ transform: `rotate(${rotationAngleState}deg)` }}>
              {this.renderImage()}
            </Box>

            {allPagesCount && <PreviewCounter>{`${currentPage} / ${allPagesCount}`}</PreviewCounter>}

            {!disabled && (
              <PreviewActionGroup
                href={to}
                onClickLink={onClick}
                onClickContextMenu={this.onClickContextBtn}
                withContext={this.isContextMenuAvailable() && !isSupplier}
                isContextMenuOpen={isContextMenuOpen}
                contextMenuRef={this.refBtnBox}
              />
            )}

            {(isLinked || isPaid) && (
              <PreviewBadges>
                {isPaid && <PaidBadge />}
                {isLinked && <LinkedBadge />}
              </PreviewBadges>
            )}
          </PreviewContent>

          {onNext && (
            <PreviewNavigation>
              <PreviewNavigationButton onClick={this.onClickNext} disabled={isDisableNext}>
                {isRtl ? <NavigateBeforeOutlinedIcon /> : <NavigateNextOutlinedIcon />}
              </PreviewNavigationButton>
            </PreviewNavigation>
          )}
        </>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  linkedPanelWidth: linkedSidebarWidthSelector(state),
  isOpenLinkedPanel: documentLinkedOpenStatusSelector(state),
  isGranted: ACL.isGranted(state),
  isRtl: rtlEnable(state),
});

export default compose(
  withState('rotationAngleState', 'setRotationAngleState', 0),
  connect(mapStateToProps),
)(DocumentPreview);
