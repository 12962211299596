// @flow
import React, { useCallback, useMemo, useState, memo } from 'react';
import { Set } from 'immutable';
import { useParams, generatePath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as ACL from 'domain/restriction';
import { getIndicateWarningByDocSelector } from 'domain/documents';
import { documentsUnreadCounterSelector, chatAllUsersByIdSelector } from 'domain/chat';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import CONST from 'domain/documents/constants';
import { FormattedMessage } from 'react-intl';
import type { DocumentsType } from 'domain/documents/documentsModel';

import RouterLink from 'components/mui/Router/RouterLink';
import {
  Tile,
  TileActions,
  TileActionArea,
  MultipageCorner,
  FavoriteIcon,
} from 'pages/company/TileGrid/TileCard/components/StyledComponents';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from 'components/mui/Tooltip';
import CardImage from 'pages/company/TileGrid/TileCard/components/CardImage';
import UserTagAvatars from 'pages/company/TileGrid/TileCard/components/UserTagAvatars';
import ApprovalStatus from 'pages/company/TileGrid/TileCard/components/ApprovalStatus';
import PublishStatus from 'pages/company/TileGrid/TileCard/components/PublishStatus';
import RejectedStatus from 'pages/company/TileGrid/TileCard/components/RejectedStatus';
import PaidBadge from 'pages/company/TileGrid/TileCard/components/PaidBadge';
import CopyBadge from 'pages/company/TileGrid/TileCard/components/CopyBadge';
import LinkedBadge from 'pages/company/TileGrid/TileCard/components/LinkedBadge';
import PreviewEye from 'pages/company/TileGrid/TileCard/components/PreviewEye';
import CardNote from 'pages/company/TileGrid/TileCard/components/CardNote';

import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

type TTileCard = {
  document: DocumentsType,
  onContextMenu: (event: SyntheticMouseEvent<*>, document: DocumentsType) => void,
  onClickPreview: (d: DocumentsType) => void,
  onClickLinked?: (link: string, linkText: string) => void,
  onClickLink: (e: MouseEvent, d: DocumentsType) => void,
  onClickNote: (e: MouseEvent, d: DocumentsType) => void,
  selected?: boolean,
  readOnly?: boolean,
  isFavorite?: boolean,
};

function excludeSystemTags(tags: Set<string>): Set<string> {
  return tags.filter((tag) => !tag.startsWith('_'));
}

const mapStateToProps = (state) => ({
  isWarningDocument: getIndicateWarningByDocSelector(state),
  isGranted: ACL.isGranted(state),
  unreadMessages: documentsUnreadCounterSelector(state),
  usersByID: chatAllUsersByIdSelector(state),
});

const TileCard: React$StatelessFunctionalComponent<TTileCard> = ({
  document,
  onContextMenu,
  onClickLinked,
  onClickPreview,
  onClickLink,
  onClickNote,
  selected,
  readOnly,
  isFavorite = false,
}) => {
  const { isWarningDocument, isGranted, unreadMessages, usersByID } = useSelector(mapStateToProps);
  const [isHovered, setIsHovered] = useState(false);
  const { companyId } = useParams();
  const { viewinfo, linkid, documentID } = document;
  const isMultipage = viewinfo.pages > 1;
  const isSupplier = isGranted(ACL.IS_SUPPLIER_USER);
  const isVisibleNote = document.notes && !isSupplier;
  const isProcessing = document.tags.has(CONST.tags.isBeingProcessed);
  const isLinked = Boolean(linkid) && typeof onClickLinked === 'function';
  const isWarning = isWarningDocument(document) && !isLinked;
  const hasUnread = unreadMessages.has(documentID);

  const onMouseOver = () => setIsHovered(true);
  const onMouseLeave = () => setIsHovered(false);

  const userTagIDs = useMemo(
    () => document.tags.filter((tag) => tag.includes('@') > 0 && usersByID.get(tag)),
    [usersByID, document.tags],
  );

  const userTags = useMemo(
    () =>
      userTagIDs.map((tag) => ({
        userId: tag,
        username: usersByID.getIn([tag, 'username']),
        src: usersByID.getIn([tag, 'picture']),
      })),
    [usersByID, userTagIDs],
  );

  const to = useMemo(
    () => generatePath(ROUTES_PATH.DOCUMENT.absolute, { companyId, documentId: documentID }),
    [documentID, companyId],
  );

  const tooltipText = useMemo(() => excludeSystemTags(document.tags).join(', '), [document.tags]);

  const handleContextMenuClick = (e: SyntheticMouseEvent<*>) => {
    e.preventDefault();
    e.stopPropagation(); // 3 dots menu click
    onContextMenu(e, document);
  };

  const handleLinkedBadgeClick = useCallback(
    (e: SyntheticMouseEvent<*>) => {
      e.preventDefault();
      e.stopPropagation();
      onClickLinked(document.linkid);
    },
    [onClickLinked, document.linkid],
  );

  const handlePreviewEyeClick = useCallback(
    (e: SyntheticMouseEvent<*>) => {
      e.preventDefault();
      e.stopPropagation();
      onClickPreview(document);
    },
    [onClickPreview, document],
  );

  const handleLinkClick = useCallback(
    (e: SyntheticMouseEvent<*>) => {
      onClickLink(e, document);
    },
    [onClickLink, document],
  );

  const handleNoteClick = useCallback(
    (e: SyntheticMouseEvent<*>) => {
      e.preventDefault();
      e.stopPropagation();
      onClickNote(e, document);
    },
    [onClickNote, document],
  );

  return (
    <Tooltip t={tooltipText} disableInteractive>
      <Tile
        raised={isHovered || selected}
        selected={selected}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        onContextMenu={handleContextMenuClick}
        onClick={handleLinkClick} // handle click for select by ctrl
      >
        <TileActionArea component={RouterLink} href={to} onContextMenu={handleContextMenuClick} isWarning={isWarning}>
          <CardImage document={document} readOnly={readOnly} onContextMenu={handleContextMenuClick} />
          {isLinked && <LinkedBadge text={document.ltext} onClick={handleLinkedBadgeClick} />}
          {isFavorite && <FavoriteIcon readOnly value={1} max={1} />}
          {isVisibleNote && (
            <CardNote note={document.notes} colorKey={document.notesColor} onClickNote={handleNoteClick} />
          )}
          {isMultipage && <MultipageCorner />}
          {userTags.size > 0 && <UserTagAvatars tags={userTags} />}
          {document.sourceID && <CopyBadge versionID={document.versionID} />}
          {isHovered && <PreviewEye onClickEye={handlePreviewEyeClick} />}
        </TileActionArea>

        {isProcessing ? (
          <TileActions>
            <CircularProgress size={20} />
            <Typography variant="body2" color="primary" ml={1}>
              <FormattedMessage id="documents.item.status.processing" defaultMessage="Processing..." />
            </Typography>
          </TileActions>
        ) : (
          <TileActions>
            <PaidBadge document={document} />
            <Stack spacing={1} direction="row">
              <PublishStatus status={document.status} />
              <RejectedStatus status={document.status} />
              <ApprovalStatus document={document} />
              {hasUnread && <EmailTwoToneIcon sx={{ fontSize: 20, color: 'warning.main' }} />}
            </Stack>
            <IconButton size="small" edge="end" sx={{ ml: 'auto' }} onClick={handleContextMenuClick}>
              <MoreHorizIcon />
            </IconButton>
          </TileActions>
        )}
      </Tile>
    </Tooltip>
  );
};

export default memo(TileCard);
