/* flow */
import { Amplify, Auth, Hub } from 'aws-amplify';
import awsconfig from 'amplify/awsconfig';
import awsauth from 'amplify/awsauth';
import { cognitoAuthInitAction } from 'domain/env';
import { persistTokenRefreshTime } from './helpers';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { generatePath } from 'react-router-dom';
import { notify } from 'lib/errorLogger/logger';
import { partial } from 'lodash/fp';

const logger = partial(notify.captureEvent, ['Cognito Auth Event']);

Amplify.configure(awsconfig);
Auth.configure({ oauth: awsauth });

const eventHandler = (dispatch) => async (response) => {
  const {
    payload: { event, data },
  } = response;

  switch (event) {
    case 'signIn':
      logger({ data: { ...data, event } }, 'Cognito successful login');
      dispatch({ type: cognitoAuthInitAction.type });
      // must set refresh time in case user leaves app inactive before first token refresh
      // this timepoint must be reference for inactivity period calculation or we have
      // no period to start from
      persistTokenRefreshTime(new Date().getTime());
      break;
    case 'signOut':
      break;
    case 'tokenRefresh':
      persistTokenRefreshTime(new Date().getTime());
      break;
    case 'signIn_failure':
    case 'cognitoHostedUI_failure':
    case 'customState_failure':
      logger({ data: { message: data.message, event } }, 'Cognito login failure');
      setTimeout(() => {
        window.location.href = `${generatePath(ROUTES_PATH.ACCESS_LIMITED.absolute)}?msg=${data.message}`;
      }, 0);
      break;
    default:
      break;
  }
};

export default (dispatch) => Hub.listen('auth', eventHandler(dispatch));
