// @flow
import type { UserEnhancedApprovalRecord } from 'domain/approvals/types.js.flow';
import type { ChatUserRecords } from 'domain/chat/types.js.flow';
import { List } from 'immutable';
import { arrayMove } from '@dnd-kit/sortable';

export { isChangeProps } from './propHelpers';
type ParamsType = {
  [key: string]: string | number,
};

function getType(any: mixed): string {
  return Object.prototype.toString.call(any).slice(8, -1);
}

export const customArrayFlat = (arr: Array<T>) => arr.reduce((acc, val) => acc.concat(val), []);

// eslint-disable-next-line max-len
function makeString<T: ParamsType>(str: Array<string>, ...keys: Array<$Keys<T>>): (a?: T) => string {
  if (keys.length === 0) return () => str.reduce((A, V) => A + V, '');
  const def = str[str.length - 1];
  return (args) => {
    if (typeof args === 'undefined') {
      return keys.reduceRight((a, v, i) => `${str[i]}:${v}${a}`, def);
    }
    const result = keys.reduceRight(
      (a, v, i) => {
        if (args && getType(args) === 'Object' && v in args && args[v]) {
          // remove ? from url indicating optional params
          // and return url with filled variabbled passed as params
          if (str[i] === '?/') {
            return `/${args[v]}${a}`;
          }
          return `${str[i]}${args[v]}${a}`;
        } else if (str[i + 1] === '?' || str[i + 1] === '?/') {
          return `${a}`;
        }
        // generate url pattern with :param placeholders
        return `${str[i]}:${v}${a}`;
      },
      def === '?' ? '' : def,
    );
    return result;
  };
}

function executeFunc(func?: ?() => void, ...args: Array<mixed>) {
  if (typeof func === 'function') {
    func(...args);
  }
}

type ListLoop<T> = {|
  prev: T,
  next: T,
|};

export function listLoop<T>(index: number, ar: Array<T>): ListLoop<T> {
  const count = ar.length;
  return {
    prev: ar[((index || count) - 1) % count],
    next: ar[(index + 1) % count],
  };
}

export function filterTags(tags: List<UserEnhancedApprovalRecord>): List<UserEnhancedApprovalRecord> {
  return tags.filter((tag) => !tag.tag.startsWith('_'));
}

export const getSystemUser = (tag: string, systemUsers: ChatUserRecords) =>
  tag.indexOf('@') >= 0 ? systemUsers.find((u) => u.userId === tag) : null;

export const sortTags = (tags: Array<{ tag: string }>, systemUsers: ChatUserRecords): Array<any> => {
  const res = tags.reduce(
    ([users, other], tag) => {
      const isUser = getSystemUser(tag.tag, systemUsers);
      return isUser ? [[...users, tag], other] : [users, [...other, tag]];
    },
    [[], []],
  );
  return customArrayFlat(res);
};

export function promisify<T, R>(fn: (d: T) => R, arg: T): Promise<R> {
  return new Promise((resolve, reject) => {
    fn({ ...arg, resolve, reject });
  });
}

// eslint-disable-next-line max-len
export function promisifyCallback<T: {}>(fn: (d: T, cb: () => void) => void, arg: T): Promise<void> {
  return new Promise((resolve) => {
    fn(arg, () => {
      resolve();
    });
  });
}

export function formatTagLabel(value: string) {
  if (typeof value === 'string' && value[0] === '#') {
    return value.slice(1);
  }

  return value;
}

export function findIndex<T>(target: T): (a: ?number, v: T, i: number) => ?number {
  return (a, v, i): ?number => {
    if (v === target) return i;
    if (typeof a !== 'undefined') return a;
    return a;
  };
}

export const testPerformance = (name: string, fn: any, iteration: number = 1) => {
  const start = Date.now();
  for (let i = 0; i < iteration; i++) {
    fn();
  }
  const end = Date.now();
  // eslint-disable-next-line  no-console
  console.log(`${name}:`, (end - start) / 1000);
};

export const generateNumRange = (from: number, to: number): Array<number> =>
  to >= from ? [...Array.from(Array(to - from).keys()).map((n) => n + from), to] : [];

export function debounceCancelable(delay: number, fn: any) {
  if (typeof fn !== 'function') {
    console.error(`debounceCancelable: callback is "${typeof fn}"`);
  }

  let timer = null;

  const cancel = () => {
    if (timer) {
      clearTimeout(timer);
    }
  };

  const debounceWrapper = (...args) => {
    cancel();
    timer = setTimeout(() => {
      timer = null;
      fn(...args);
    }, delay);
  };

  debounceWrapper.cancel = cancel;
  return debounceWrapper;
}

export function throttleCancelable(delay: number, fn: any) {
  let timer = null;

  const cancel = () => {
    if (timer) {
      clearTimeout(timer);
    }
  };

  const throttleWrapper = (...args) => {
    if (!timer) {
      fn(...args);
      timer = setTimeout(() => {
        timer = null;
      }, delay);
    }
  };

  throttleWrapper.cancel = cancel;
  return throttleWrapper;
}

export const extractEmail = (str: string): string | null => {
  const emails = str.match(/([a-zA-Z0-9.+_-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
  return emails && emails.length ? emails[0] : null;
};

export const splitNumberByCommas = (value?: number | string) =>
  typeof value === 'number' || typeof value === 'string' ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : value;

// eslint-disable-next-line no-restricted-globals
const convertToNumber = (value?: string | number) =>
  typeof value !== 'object' && !isNaN(value) && value !== '' ? +value : value;

export const convertToFloatNumber = (value?: string | number) => {
  const result = convertToNumber(value);

  return typeof result === 'number' ? result.toFixed(2) : value;
};

export { makeString, getType, executeFunc };

export const isPromise = (p: any) => typeof p === 'object' && typeof p.then === 'function';

export const relevantSearch = <T>(term: string, data: T[], getSearchText: (i: T) => string): T[] => {
  const lTerm = term.toLowerCase();
  const result = data.reduce((res, item) => {
    const value = getSearchText(item);
    const index = value.toLowerCase().indexOf(lTerm);
    const indexItems = index >= 0 ? res[index] || [] : null;
    if (indexItems) {
      res[index] = [...indexItems, item];
    }
    return res;
  }, []);
  return customArrayFlat(result);
};

export const rearrangeList = ({
  oldIndex,
  newIndex,
  list,
}: {|
  oldIndex: number,
  newIndex: number,
  list: List<any> | Array<any>,
|}) => (List.isList ? new List(arrayMove(list.toArray(), oldIndex, newIndex)) : arrayMove(list, oldIndex, newIndex));

const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
export const simulateMouseClick = (element: HTMLElement) => {
  mouseClickEvents.forEach((mouseEventType) =>
    element.dispatchEvent(
      new MouseEvent(mouseEventType, {
        view: window,
        bubbles: true,
        cancelable: true,
      }),
    ),
  );
};
