// @flow
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { type TUserFeatures } from 'domain/env/types.js.flow';

import GroupItem from 'pages/profiles/company/pages/components/GroupItem';
import Switch from 'components/mui/Form/Switch/SwitchBase';
import { FormControlLabelSwitch } from 'components/mui/Form/FormControl/FormControlLabelSwitch';
import Typography from '@mui/material/Typography';

type TProfilePreferencesFormProps = {|
  initialValues: TUserFeatures,
  handleChange: (boolean, string) => Promise<any>,
|};

const ProfilePreferencesForm: React$StatelessFunctionalComponent<TProfilePreferencesFormProps> = ({
  handleChange,
  initialValues,
}: TProfilePreferencesFormProps) => {
  const { formatMessage } = useIntl();

  const onChange = useCallback(
    (e: SyntheticInputEvent<HTMLInputElement>) => {
      const value = e.target.checked;

      handleChange(value, 'modal');
    },
    [handleChange],
  );

  return (
    <GroupItem
      title={{
        id: 'profile.preferences.confirmation.title',
        defaultMessage: 'Confirmation dialogue',
      }}
    >
      <FormControlLabelSwitch
        label={
          <Typography variant="body2">
            {formatMessage({
              id: 'profile.preferences.switcher.confirmActions',
              defaultMessage: 'Ask to confirm actions',
            })}
          </Typography>
        }
        onChange={onChange}
        checked={initialValues.get('modal')}
        control={<Switch />}
      />
    </GroupItem>
  );
};

export default ProfilePreferencesForm;
