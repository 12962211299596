// @flow
import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { compose, type Dispatch } from 'redux';
import type { List, Map, RecordOf } from 'immutable';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { nanoid } from 'nanoid';
import moment from 'moment';
import {
  documentUploadAction,
  queueAddAction,
  uploadQueueItem,
  paramsAdapter,
  queueRemoveAction,
  type QueueItemType,
  isPendingUploadSelector,
} from 'domain/documents';
import { type CategoriesList } from 'domain/categories';
import { isCategoryAll, isToApproveCategory } from 'domain/categories/helpers';
import * as ACL from 'domain/restriction';
import acceptTypes from 'lib/acceptTypes';
import { checkFiles, checkFileSize, SelectedCategoryFactory, selectedCategoryAdapter } from './helpers';

import Queue from './queue';
import Dropzone from './dropzone';
import Dialog from 'components/mui/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import InputAdornment from '@mui/material/InputAdornment';
import SelectBase from 'components/mui/Form/Select/SelectBase';
import { UploadText, FileInput } from './StyledComponents';

import UploadFileIcon from '@mui/icons-material/UploadFile';
import category from './category.svg';

import { lightBlue } from '@mui/material/colors';

import elements from 'components/elements';

type Props = {|
  confidential: boolean,
  queue: Map<string, RecordOf<QueueItemType>>,
  remove: Dispatch<queueRemoveAction>,
  closeModal: () => void,
  rootCategoriesList: CategoriesList,
  isGranted: (r: number | number[]) => boolean,
  format: ?string,
  open: boolean,
  items: List<*>,
|};

const mapStateToProps = (state) => ({
  isPendingUpload: isPendingUploadSelector(state),
});

const DialogUpload: React$StatelessFunctionalComponent<Props> = ({
  closeModal,
  queue,
  rootCategoriesList,
  isGranted,
  confidential,
  remove,
  format,
  open,
  items,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const fileInputElRef = useRef(null);

  const [selectedCategory, setSelectedCategory] = useState('');
  const [day, setDay] = useState(moment(new Date()));
  const [isDatePristine, setIsDatePristine] = useState(true);
  const [isFileDialogActive, setIsFileDialogActive] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const { isPendingUpload } = useSelector(mapStateToProps);

  const optionsList = useMemo(() => {
    const list = rootCategoriesList
      .filter((it) => !isCategoryAll(it) && !isToApproveCategory(it))
      .map((it) => {
        const { label, value } = selectedCategoryAdapter({
          label: {
            id: `category.name.${it.nameLangId}`,
            defaultMessage: it.name,
          },
          value: it.id,
        });
        return { label: formatMessage(label), value };
      });
    return list;
  }, [formatMessage, rootCategoriesList]);

  const check = (fileType: string) => checkFiles(acceptTypes, fileType);

  const isUser = isGranted(ACL.IS_USER);

  const params = useMemo(
    () => (isUser ? { creationDate: day } : { category: selectedCategory, creationDate: day }),
    [day, isUser, selectedCategory],
  );

  const createQueue = useCallback(
    (file: File, path) => {
      const add = (args) => dispatch(queueAddAction(args));
      const id: string = nanoid(10);
      const { name, type, size } = file;
      const fileNames = [];
      queue.forEach((e) => {
        fileNames.push(e.file.name);
      });
      const splittedName = name.split('.');
      const fileType = type.length > 0 ? type : `.${splittedName[splittedName.length - 1]}`;
      const isValidType = check(fileType);
      const isValidSize = checkFileSize(size);
      const isValidFile = isValidType && isValidSize && !fileNames.includes(name);

      if (isValidFile) {
        dispatch(
          queueAddAction(
            uploadQueueItem({
              file,
              id,
              path,
              isConfidential: confidential,
              params,
            }),
          ),
        );
      } else if (!isValidType) {
        compose(add, uploadQueueItem)({ path, status: 'unsupportedType', id });
      } else if (!isValidSize) {
        compose(add, uploadQueueItem)({ path, status: 'maxFileSizeError', id });
      }
    },
    [confidential, dispatch, params, queue],
  );

  const transferDndFilter = useCallback(
    (item, p?: string) => {
      const path = p || '';
      if (item) {
        createQueue(item, `${path}${item.name}`);
      }
    },
    [createQueue],
  );

  const transferFilter = useCallback(
    (item, p?: string) => {
      const path = p || '';
      if (item && item.isFile) {
        item.file((file) => createQueue(file, `${path}${file.name}`));
      } else if (item && item.isDirectory) {
        const dirReader = item.createReader();
        dirReader.readEntries((entries) => {
          for (let i = 0; i < entries.length; i++) {
            transferFilter(entries[i], `${path}${item.name}/`);
          }
        });
      }
    },
    [createQueue],
  );

  const onDrop = useCallback(
    (e: SyntheticDragEvent<any>) => {
      if (!isFileDialogActive && e.dataTransfer && 'items' in e.dataTransfer) {
        const { items } = e.dataTransfer;
        for (let i = 0; i < items.length; i++) {
          if (typeof items[i].webkitGetAsEntry === 'function') {
            transferFilter(items[i].webkitGetAsEntry());
          }
        }
      }
    },
    [isFileDialogActive, transferFilter],
  );

  const onDndDrop = useCallback(
    (files) => {
      if (!isFileDialogActive && files) {
        for (let i = 0; i < files.length; i++) {
          transferDndFilter(files[i]);
        }
      }
    },
    [transferDndFilter, isFileDialogActive],
  );

  const handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { files } = e.target;
    for (let i = 0; i < files.length; i++) {
      createQueue(files[i], files[i].name);
    }

    if (isFileDialogActive) {
      setIsFileDialogActive(false);
    }
    // allows onChange to fire when previously selected file is selected again
    e.target.value = '';
  };

  const handleSelectChange = ({ target: { value } }: SyntheticInputEvent<HTMLInputElement>) => {
    if (value) {
      const selectedCategory = optionsList.find(({ value: catValue }) => (catValue || '') === value);
      setSelectedCategory(selectedCategory.value);
    }
  };

  const handleDayChange = (day: Date) => {
    // we have to show the user if he changed the date
    if (isDatePristine) {
      setDay(day);
      setIsDatePristine(false);
    } else {
      setDay(day);
    }
  };

  const uploadFromQueue = () => {
    setIsUploading(true);
    queue.forEach((item) => {
      if (item.status !== 'pending') return;
      dispatch(documentUploadAction(item.set('params', paramsAdapter(params))));
    });
  };

  const clickHandler = () => {
    setIsFileDialogActive(true);
  };

  const reload = useCallback(
    (item) => {
      if (item.file instanceof File) {
        dispatch(documentUploadAction(item));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    setSelectedCategory(optionsList.first(SelectedCategoryFactory()).value);
  }, [optionsList]);

  useEffect(() => {
    if (isUploading && !queue.size) {
      closeModal();
    }
  }, [queue.size, closeModal, isUploading]);

  useEffect(() => {
    if (items) {
      items.valueSeq().forEach((item) => {
        if (typeof item.webkitGetAsEntry === 'function') {
          transferFilter(item.webkitGetAsEntry());
        } else {
          transferDndFilter(item);
        }
      });
    }
  }, [items, transferDndFilter, transferFilter]);

  const accept = acceptTypes.join(', ');

  const uploaderBody = (
    <>
      <Box borderRadius="50%" bgcolor={lightBlue[50]} p={1} dispaly="flex" lineHeight={0}>
        <UploadFileIcon color="primary" />
      </Box>
      <Typography variant="subtitle1">
        {formatMessage({ id: 'document.upload.dropFilesToUploadOr', defaultMessage: 'Drop files to upload ' })}
        <UploadText>
          {formatMessage({ id: 'document.upload.chooseFromComputer', defaultMessage: 'or choose from computer' })}
        </UploadText>
      </Typography>
      <Typography variant="caption" color="text.disabled" textAlign="center">
        {formatMessage({ id: 'document.upload.maxSize', defaultMessage: 'The maximum file size supported is 64 MB' })}{' '}
        <br />
        {formatMessage({ id: 'document.upload.weSupportFormats', defaultMessage: '.docx, .xlsm, .xlsx, .pdf, .jpg' })}
      </Typography>
      <FileInput
        type="file"
        hidden
        ref={fileInputElRef}
        accept={accept}
        id="dropzone"
        multiple
        onChange={handleChange}
        onClick={clickHandler}
      />
    </>
  );

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      title={formatMessage({ id: 'document.upload.uploadDocument', defaultMessage: 'Upload Documents' })}
      maxWidth="sm"
      withActions={false}
      withContent={false}
      PaperProps={{ sx: { overflow: 'inherit' } }}
      data-element={elements.popup.Upload.dialog}
    >
      <DialogContent>
        <Dropzone htmlFor="dropzone" onDndDrop={onDndDrop} onDrop={onDrop}>
          {uploaderBody}
        </Dropzone>
        {!queue.size ? <Divider sx={{ my: 4 }} /> : <Queue queue={queue} remove={remove} reload={reload} />}
        <Stack direction="row" spacing={2}>
          {!isUser && (
            <SelectBase
              id="uploadCategory"
              value={selectedCategory}
              options={optionsList}
              onChange={handleSelectChange}
              fullWidth
              FormControlProps={{ fullWidth: true }}
              label={formatMessage({ id: 'document.upload.uploadToDefaultCategory', defaultMessage: 'Upload to:' })}
              startAdornment={
                <InputAdornment position="start">
                  <Box sx={{ background: `url(${category}) 50% 50% no-repeat`, width: 18, height: 18 }} />
                </InputAdornment>
              }
            />
          )}
          <DesktopDatePicker
            label={formatMessage({ id: 'document.upload.uploadByDate', defaultMessage: 'Upload Date:' })}
            inputFormat={format}
            value={day}
            onChange={handleDayChange}
            disableFuture
            slotProps={{
              textField: { fullWidth: true, inputProps: { 'data-element': elements.popup.Upload.datePicker.input } },
              inputAdornment: { position: 'start', 'data-element': elements.popup.Upload.datePicker.iconContainer },
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} variant="text" data-element={elements.popup.Upload.cancelBtn}>
          {formatMessage({ id: 'button.cancel', defaultMessage: 'Cancel' })}
        </Button>
        <Button
          onClick={uploadFromQueue}
          disabled={!queue.size || !isPendingUpload}
          data-element={elements.popup.Upload.uploadBtn}
        >
          {formatMessage({ id: 'input.upload', defaultMessage: 'Upload' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogUpload;
