// @flow
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { List, type RecordOf } from 'immutable';
import { injectIntl, FormattedMessage } from 'react-intl';
import type { intlShape } from 'react-intl';
import { compose } from 'redux';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { rtlEnable, userFeaturesSelector } from 'domain/env';
import { RenderSuccessData, Page } from 'react-pdf';
import PdfViewerDocument from 'components/PdfViewer';
import { getRotateByDocID } from 'lib/pdfHelpers';
import { editableDocSelector, currentCompanySelector } from 'domain/documents';
import type { DocumentsType } from 'domain/documents/documentsModel';
import { documentSplit } from 'domain/documents/documentsActions';
import type { TUserFeatures } from 'domain/env/types.js.flow';

import sheet from './sheet';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import Button from '@mui/material/Button';
import MuiIconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import EditPreview from './EditPreview';
import DocPageList from './DocPageList';
import SplitOptions from './SplitOptions';
import Tooltip from 'components/mui/Tooltip';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import { storage } from 'lib/storage';
import { createMemoFn, withPropsCollector } from 'lib/propHelpers';
import { withApiToken } from 'lib/apiTokenKeeper';
import withConfirm from 'hoc/withConfirm';
import type { ThemesParamsType } from 'styles/dokkaTheme/types.js.flow';

import { withStyles } from '@mui/styles';
import { styled, darken } from '@mui/material/styles';

const MAX_BADGE_COUNT = 10000;

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  border: `1px solid ${theme.palette.common.white}`,
  width: 40,
  height: 40,
  padding: theme.spacing(1),
  borderRadius: 50,
  margin: 'auto',
  zIndex: 1,
  opacity: 0,

  '&:hover': {
    backgroundColor: darken(theme.palette.primary.main, 0.1),
    boxShadow: theme.shadows[5],
  },
  'li .MuiPaper-root:hover &': {
    opacity: 1,
  },
}));

const baseUrl = process.env.REACT_APP_API_URL || '';

type PageType = {
  number: number,
  idx: number,
  rotate: number,
};

type Props = {
  intl: intlShape,
  apiToken: string,
  companyId: string,
  classes: {
    [key: string]: string,
  },
  // eslint-disable-next-line react/no-unused-prop-types
  direction: 'rtl' | 'ltr',
  onClose: () => void,
  documentSplit: Dispatch<documentSplit>,
  document: DocumentsType,
  userFeatures: RecordOf<TUserFeatures>,
  theme: ThemesParamsType,
};

type Changeable = {
  removed: Array<PageType>,
  pages: Array<PageType>,
  separated: Array<number>,
};

type State = {
  selected: Array<number>,
  changeable: Changeable,
  changes: Array<Changeable>,
  reverted: Array<Changeable>,
  initialPages: Array<PageType>,
  previewPageNumber?: number,
  isLoading: boolean,
  embededRotate: {
    [key: string]: number,
  },
  splitOptionValue: 'add' | 'add_on_first' | 'remove',
  pagesBoundary: Array<{|
    height?: number,
    width?: number,
  |}>,
};

function getSelectedList(array: Array<number>, item: number) {
  if (array.indexOf(item) + 1) return array.filter((number) => number !== item);
  return [...array, item];
}

function setIdx(array: Array<*>) {
  return array.map((item, idx) => ({ ...item, idx }));
}

function getTooltipText(cutted: boolean = false) {
  return {
    id: cutted ? 'documentEdit.tooltip.cutted' : 'documentEdit.tooltip.cut',
    defaultMessage: cutted ? 'Press to unite documents' : 'Press to split document',
  };
}

class DocumentEdit extends Component<Props, State> {
  constructor(props) {
    super(props);
    // https://github.com/wojtekmaj/react-pdf/wiki/Frequently-Asked-Questions#react-pdf-reloads-itself-with-every-render-whats-going-on

    this.viewportRef = React.createRef();

    this.state = {
      selected: [],
      changeable: {
        removed: [],
        pages: [],
        separated: [],
      },
      initialPages: [],
      changes: [],
      reverted: [],
      isLoading: false,
      embededRotate: {},
      splitOptionValue: storage().splitOptions().get() || 'add',
      pagesBoundary: [],
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyUndo);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyUndo);
  }

  onSelect = (event: SyntheticMouseEvent<HTMLLIElement>, pageNumber: number) => {
    const { selected } = this.state;
    if (event.ctrlKey || event.metaKey) {
      this.setState({ selected: getSelectedList(selected, pageNumber) });
      return;
    }

    this.setState({ selected: selected.indexOf(pageNumber) + 1 ? [] : [pageNumber] });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const {
      changeable: { pages },
    } = this.state;
    this.onDndDrop(pages[oldIndex], newIndex);
  };

  onDndDrop = (draggablePage, droppedPosition: number) => {
    const { changeable } = this.state;

    if (draggablePage && typeof droppedPosition === 'number') {
      this.saveChanges(() => {
        const pages = [...changeable.pages];
        // eslint-disable-next-line prefer-destructuring
        let separated = changeable.separated;
        const idx = droppedPosition <= 0 ? 0 : droppedPosition || 1;
        pages.splice(draggablePage.idx, 1);
        pages.splice(idx, 0, { number: draggablePage.number });
        if (separated.indexOf(draggablePage.number) + 1) {
          separated = separated.map((item) => {
            if (item === draggablePage.number) return pages[draggablePage.idx].number;
            return item;
          });
        }
        this.setState({
          changeable: {
            ...changeable,
            pages: setIdx(pages),
            separated,
          },
        });
      });
    }
  };

  onRemove = () => {
    const { selected, changeable } = this.state;
    const { pages, removed } = changeable;

    this.saveChanges(() =>
      this.setState({
        selected: [],
        changeable: {
          ...changeable,
          pages: setIdx(pages.filter((item) => selected.indexOf(item.number) < 0)),
          removed: [...removed, ...selected],
        },
      }),
    );
  };

  onSave = () => {
    const {
      document: { documentID },
      onClose,
      documentSplit: docSplit,
    } = this.props;
    const {
      splitOptionValue,
      changeable: { pages, separated },
    } = this.state;

    const spages = [
      0,
      ...pages.filter((item) => separated.indexOf(item.number) + 1).map((item) => item.idx + 1),
      pages.length,
    ];
    const newdocs = spages
      .map((split, idx) => [...pages].splice(split, spages[idx + 1] - split).map((item) => item.number - 1))
      .filter((array) => array.length > 0)
      .map((array) => ({ pages: array }));

    this.setState({ isLoading: true });
    new Promise((resolve, reject) => {
      docSplit({
        documentID,
        newdocs,
        resolve,
        reject,
        splitOptions: splitOptionValue,
      });
    })
      .then(() => {
        onClose();
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  onSaveClick = () => {
    const {
      confirm,
      intl: { formatMessage },
    } = this.props;

    const action = () => {
      this.onSave();
    };

    this.isAllowAutoConfirm()
      ? action()
      : confirm({
          title: formatMessage({
            id: 'documentEdit.confirm.title',
            defaultMessage: 'Are you sure?',
          }),
          description: formatMessage({
            id: 'documentEdit.confirm.placeholder',
            defaultMessage: 'Are you sure you want to save current changes?',
          }),
          confirmationText: formatMessage({
            id: 'documentEdit.confirm.yes',
            defaultMessage: 'Yes',
          }),
          cancellationText: formatMessage({
            id: 'documentEdit.confirm.cancel',
            defaultMessage: 'No',
          }),
        }).then(() => {
          action();
        });
  };

  onOpenPreview = (previewPageNumber) => (e) => {
    e.stopPropagation();
    this.setState({ previewPageNumber });
  };

  onClosePreview = () => {
    this.setState({ previewPageNumber: null });
  };

  onRenderDocSuccess = (pageIndex: number) => (docRender: RenderSuccessData) => {
    this.setState(({ embededRotate }: State) => ({
      embededRotate: { ...embededRotate, [pageIndex]: docRender.rotate },
    }));
  };

  get disabled() {
    const {
      changeable: { separated, pages },
      initialPages,
      isLoading,
    } = this.state;
    const changes = separated.length < 1;
    const isSamePages = List(initialPages).equals(List(pages));
    const noChanges = [changes, isSamePages].filter((check) => !check).length < 1;
    return noChanges || isLoading;
  }

  get getPageHeight() {
    const viewport = this.viewportRef.current;
    const { height } = viewport.getBoundingClientRect();

    const thumbHeight = (height - 120) / 2;

    if (thumbHeight < 240) {
      return 240;
    }

    if (thumbHeight > 800) {
      return 800;
    }

    return thumbHeight;
  }

  getLink = createMemoFn(
    () => {
      const {
        apiToken,
        companyId,
        document: { documentID },
      } = this.props;
      return { apiToken, companyId, documentID };
    },
    ({ apiToken, companyId, documentID }) => {
      const url =
        apiToken && documentID && companyId
          ? `${baseUrl}/v2/3/getDocument?documentID=${documentID}&dokkaToken=${apiToken}&companyId=${companyId}&markAsOld=0`
          : '';
      return url ? { url } : null;
    },
  );

  handleKeyUndo = (event: SyntheticMouseEvent<HTMLLIElement>) => {
    // eslint-disable-line
    if (event.code === 'KeyZ' && (event.ctrlKey || event.metaKey)) {
      event.preventDefault();
      const execute = event.shiftKey ? this.redo : this.undo;
      execute();
    }
  };

  handleOnLoad = (pdf) => {
    const { numPages } = pdf;
    const { changeable } = this.state;

    const pages = Array.from({ length: numPages }).map((item, idx) => ({ number: idx + 1, idx }));
    const pagesBoundary = pages.map(() => ({ height: this.getPageHeight }));

    this.setState({
      changeable: {
        ...changeable,
        pages,
      },
      initialPages: pages,
      pagesBoundary,
    });
  };

  onSelectSplitOption = (e) => {
    this.setState({ splitOptionValue: e.target.value }, () => storage().splitOptions().set(e.target.value));
  };

  getRotate = (pageIndex) => {
    const {
      document: { viewinfo, documentID },
    } = this.props;
    const { embededRotate } = this.state;

    const embRotate = embededRotate[pageIndex] | 0;
    const rs = getRotateByDocID(documentID) || {};
    const lsRotate = rs[pageIndex];
    const rotate = typeof lsRotate === 'number' ? lsRotate : viewinfo.rotations[pageIndex] || 0;
    return (rotate + embRotate) % 360;
  };

  separatePages = (number: number, event: SyntheticMouseEvent<HTMLLIElement>) => {
    const {
      changeable,
      changeable: { separated },
    } = this.state;
    event.stopPropagation();

    this.saveChanges(() => {
      this.setState({
        changeable: { ...changeable, separated: getSelectedList(separated, number) },
      });
    });
  };

  separateAllPages = () => {
    const {
      changeable,
      changeable: { pages },
    } = this.state;
    const pageNumbers = pages.map((page) => page.number);

    this.saveChanges(() => {
      this.setState({ changeable: { ...changeable, separated: pageNumbers } });
    });
  };

  saveChanges = (cb) => {
    const { changeable } = this.state;
    this.setState({ changes: [changeable], reverted: [] }, cb);
  };

  undo = () => {
    const { changes, changeable } = this.state;
    if (changes.length > 0) {
      const [tail] = changes;
      this.setState({
        changeable: tail,
        changes: [],
        reverted: [changeable],
      });
    }
  };

  redo = () => {
    const { reverted, changeable } = this.state;
    if (reverted.length > 0) {
      const [tail] = reverted;
      this.setState({
        changeable: tail,
        reverted: [],
        changes: [changeable],
      });
    }
  };

  isAllowAutoConfirm = () => {
    const { userFeatures } = this.props;
    return !userFeatures.get('modal', true);
  };

  handlePageLoad = (page) => {
    const { pagesBoundary } = this.state;
    const { height, width, _pageIndex } = page;
    const ratio = width / height;
    const isHorizontal = ratio > 1;
    const rotate = this.getRotate(_pageIndex);

    if (isHorizontal) {
      const currentPagesBoundary = pagesBoundary.map((boundary, index) => {
        if (_pageIndex === index && (rotate === 0 || rotate === 180)) {
          return {
            ...boundary,
            width: 300,
          };
        }
        return boundary;
      });

      this.setState({ pagesBoundary: currentPagesBoundary });
    }
  };

  render() {
    const { classes, onClose, theme } = this.props;

    const {
      changes,
      selected,
      changeable: { pages, separated },
      reverted,
      previewPageNumber,
      splitOptionValue,
      pagesBoundary,
    } = this.state;

    const link = this.getLink();

    const disableSplitAll = pages.length === 0 || separated.length === pages.length;

    return (
      <Dialog open fullWidth PaperProps={{ sx: { width: '100%', maxWidth: '100%', m: 0, height: '90vh' } }}>
        <DialogContent ref={this.viewportRef} className={classes.contentWrapper}>
          {link && (
            <PdfViewerDocument file={link} onLoadSuccess={this.handleOnLoad}>
              <DocPageList
                items={pages}
                className={classes.pageList}
                axis="xy"
                distance={10}
                onSortEnd={this.onSortEnd}
                helperClass={classes.docDragPage}
              >
                {(page, idx) => (
                  <Badge
                    component="li"
                    color="primary"
                    max={MAX_BADGE_COUNT}
                    className={classes.itemWrapper}
                    badgeContent={idx + 1}
                    onClick={(event) => this.onSelect(event, page.number)}
                    style={{ height: this.getPageHeight }}
                  >
                    <Paper
                      variant="outlined"
                      sx={{
                        borderRadius: 2,
                        height: '100%',
                        overflow: 'hidden',
                        ...(selected.includes(page.number) && {
                          filter: 'brightness(0.8)',
                          boxShadow:
                            '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
                        }),
                      }}
                    >
                      <IconButton onClick={this.onOpenPreview(page.number)}>
                        <RemoveRedEyeOutlinedIcon />
                      </IconButton>
                      <Page
                        pageNumber={page.number}
                        renderTextLayer={false}
                        renderAnnotations={false}
                        onRenderSuccess={this.onRenderDocSuccess(idx)}
                        onLoadSuccess={this.handlePageLoad}
                        renderAnnotationLayer={false}
                        rotate={this.getRotate(page.number - 1)}
                        {...pagesBoundary[page.number - 1]}
                      />
                    </Paper>
                    {idx < pages.length - 1 && (
                      <Tooltip t={getTooltipText(separated.includes(page.number))}>
                        <Divider
                          orientation="vertical"
                          component="button"
                          sx={{
                            cursor: 'pointer',
                            position: 'absolute',
                            left: '100% /* @noflip */',
                            top: 0,
                            backgroundColor: 'transparent',
                            '&:before, &:after': {
                              borderLeftStyle: 'dashed',
                              borderLeftColor: (theme) =>
                                separated.indexOf(page.number) + 1
                                  ? theme.palette.primary.main
                                  : theme.palette.grey[500],
                            },
                          }}
                          onClick={(event) => this.separatePages(page.number, event)}
                        >
                          <ContentCutIcon
                            sx={{
                              transform: 'rotate(90deg)',
                              color: (theme) =>
                                separated.indexOf(page.number) + 1
                                  ? theme.palette.primary.main
                                  : theme.palette.grey[500],
                            }}
                          />
                        </Divider>
                      </Tooltip>
                    )}
                  </Badge>
                )}
              </DocPageList>
              {previewPageNumber && (
                <EditPreview pageNumber={previewPageNumber} getRotate={this.getRotate} onClose={this.onClosePreview} />
              )}
            </PdfViewerDocument>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: (theme) => theme.palette.grey[50],
            borderTop: 1,
            borderColor: (theme) => theme.palette.grey[200],
          }}
        >
          <Stack direction="row" flex="1" alignItems="center">
            <Stack flex="1" direction="row" justifyContent="start">
              <SplitOptions onSelect={this.onSelectSplitOption} value={splitOptionValue} theme={theme} />
            </Stack>
            <Stack flex="1" direction="row" justifyContent="center" spacing={1}>
              <Button
                variant="outlined"
                type="button"
                startIcon={<ContentCutIcon />}
                disabled={disableSplitAll}
                onClick={this.separateAllPages}
              >
                <FormattedMessage id="documentEdit.splitAll" defaultMessage="Split all" />
              </Button>
              <Stack direction="row" spacing={0.5}>
                <MuiIconButton disabled={changes.length < 1} onClick={this.undo}>
                  <UndoIcon />
                </MuiIconButton>
                <Divider orientation="vertical" variant="middle" flexItem />
                <MuiIconButton onClick={this.redo} disabled={reverted.length < 1}>
                  <RedoIcon />
                </MuiIconButton>
              </Stack>
              <Button
                variant="outlined"
                type="button"
                color="error"
                startIcon={<DeleteOutlineIcon />}
                disabled={selected.length < 1}
                onClick={this.onRemove}
              >
                <FormattedMessage id="documentEdit.remove" defaultMessage="Remove" />
              </Button>
            </Stack>
            <Stack flex="1" direction="row" justifyContent="end" spacing={2}>
              <Button variant="outlined" onClick={onClose}>
                <FormattedMessage id="documentEdit.cancel" defaultMessage="Cancel" />
              </Button>
              <Button disabled={this.disabled} onClick={this.onSaveClick}>
                <FormattedMessage id="documentEdit.save" defaultMessage="Save" />
              </Button>
            </Stack>
          </Stack>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  companyId: currentCompanySelector(state),
  direction: rtlEnable(state) ? 'rtl' : 'ltr',
  document: editableDocSelector(state),
  userFeatures: userFeaturesSelector(state),
});

const mapDispatchToProps = {
  documentSplit,
};

export default compose(
  withApiToken,
  withConfirm,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(sheet),
  withPropsCollector,
)(DocumentEdit);
