/* @flow */
import React from 'react';

import AuthLogo from 'pages/authPage/Layout/Logo';
import AuthLayoutHeader from 'pages/authPage/Layout/Header';
import AuthAsideDefault from 'pages/authPage/Layout/AuthAside/Default';
import AuthAsideMade from 'pages/authPage/Layout/AuthAside/Made';
import AuthAsideBdo from 'pages/authPage/Layout/AuthAside/Bdo';
import AuthAsideAutodok from 'pages/authPage/Layout/AuthAside/Autodok';
import AuthAsideProfitian from 'pages/authPage/Layout/AuthAside/Profitian';
import AuthAsideAlprime from 'pages/authPage/Layout/AuthAside/Alprime';
import FooterWithTerms from 'components/mui/Layouts/components/FooterWithTerms';
import { AuthLayoutGrid, AuthMain, AuthMainContainer, AuthMainContent } from 'pages/authPage/Layout/StyledComponents';

import { useTheme } from '@mui/material/styles';

// TODO: make a common layout for aside WL, they are very similar
const AuthAsideComponents = {
  Dokka: AuthAsideDefault,
  Made: AuthAsideMade,
  BDO: AuthAsideBdo,
  AutoDok: AuthAsideAutodok,
  Profitian: AuthAsideProfitian,
  AlPrime: AuthAsideAlprime,
};

type TAuthLayout = {
  children: React$Node,
};

function AuthLayout({ children }: TAuthLayout) {
  const {
    labelOptions: { name },
  } = useTheme();

  const mqColorFooterLink = { xs: 'common.white', md: 'primary.main' };
  const AuthAsideComponent = AuthAsideComponents[name] || AuthAsideDefault;

  return (
    <AuthLayoutGrid>
      <AuthAsideComponent />
      <AuthMain>
        <AuthLayoutHeader />
        <AuthMainContainer disableGutters maxWidth={false}>
          <AuthLogo />
          <AuthMainContent>{children}</AuthMainContent>
        </AuthMainContainer>
        <FooterWithTerms mqColor={mqColorFooterLink} />
      </AuthMain>
    </AuthLayoutGrid>
  );
}

export default AuthLayout;
