import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  organizationPasswordExpirationDaysSelector,
  organizationSecurityFeaturesSelector,
} from 'domain/organization/organizationSelector';
import { updatePasswordExpirationAction } from 'domain/organization/organizationActions';

import SecurityForm from './components/SecurityForm';
import FeatureItem from 'pages/configurations/components/FeaturesSettings/components/FeatureItem';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';

const Security = () => {
  const dispatch = useDispatch();

  const expirationDays = useSelector(organizationPasswordExpirationDaysSelector);
  const features = useSelector(organizationSecurityFeaturesSelector);

  const onSubmit = useCallback(
    (data) => {
      dispatch(updatePasswordExpirationAction(data.toJS()));
    },
    [dispatch],
  );

  return (
    <Stack component={Paper} variant="outlined" p={2}>
      {features.map(([key, values]) => (
        <FeatureItem key={key} featureKey={key} {...values} />
      ))}
      {expirationDays !== null && (
        <SecurityForm initialValues={{ expiration_days: expirationDays }} onSubmit={onSubmit} />
      )}
    </Stack>
  );
};
export default Security;
