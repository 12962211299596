// @flow
// $FlowFixMe outdated flow types for the package
import React, { useState, useCallback } from 'react';
// $FlowFixMe outdated flow types for the package
import { useDispatch } from 'react-redux';
// $FlowFixMe outdated flow types for the package
import { useIntl, FormattedMessage } from 'react-intl';
import { type OrderedSet } from 'immutable';

import Dialog from 'components/mui/Dialog';
import { bulkCreateApprovalsAction } from 'domain/approvals/actions';
import StandAloneApprovalManager from './components/ApprovalsManager';
import Switch from 'components/mui/Form/Switch/SwitchBase';
import { FormControlLabelSwitch } from 'components/mui/Form/FormControl/FormControlLabelSwitch';
import useToggle from 'hooks/useToggle';
import useApprovals from 'hooks/approval/useApprovals';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { ContentArea } from './StyledComponents';
import Button from '@mui/material/Button';

type TDialogProps = {
  onClose: () => void,
  open: boolean,
  selectedIDs: OrderedSet<string>,
};

const BulkApprovalFlow: React$StatelessFunctionalComponent<TDialogProps> = ({ onClose, open, selectedIDs }) => {
  const [value, setValue] = useState([]);
  const [groupId, setGroupId] = useState('');
  const [approvalFlowAutoStart, toggleApprovalFlowAutoStart] = useToggle();
  // @to-do rename to processedValue
  const { approvers, value: processedValue } = useApprovals(value);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const submitApprovals = useCallback(
    () =>
      new Promise((resolve, reject) => {
        return dispatch(
          bulkCreateApprovalsAction({ processedValue, selectedIDs, approvalFlowAutoStart, groupId, resolve, reject }),
        );
      })
        .then(() => onClose())
        .catch(() => onClose()),
    [onClose, dispatch, processedValue, selectedIDs, approvalFlowAutoStart, groupId],
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={formatMessage({ id: 'documents.show.modals.approvals.title', defaultMessage: 'Add approvers' })}
      maxWidth="sm"
      withActions={false}
      withContent={false}
      withCloseBtn
      draggable
    >
      <DialogContent>
        <ContentArea>
          <StandAloneApprovalManager
            setValue={setValue}
            approvers={approvers}
            processedValue={processedValue}
            currentValue={value}
            setGroupId={setGroupId}
            groupId={groupId}
          />
        </ContentArea>
      </DialogContent>
      <DialogActions sx={{ pl: 3 }}>
        <FormControlLabelSwitch
          color="primary"
          checked={approvalFlowAutoStart}
          control={<Switch onChange={toggleApprovalFlowAutoStart} />}
          labelPlacement={'end'}
          label={formatMessage({
            id: 'document.approval.form.bulkApprove.autoStart',
            defaultMessage: 'Start approval flow',
          })}
        />
        <div style={{ flex: '1 0 0' }} />
        <Button onClick={onClose}>
          {formatMessage({
            id: 'button.cancel',
            defaultMessage: 'Cencel',
          })}
        </Button>
        <Button onClick={submitApprovals} disabled={value.length === 0}>
          {formatMessage({
            id: 'button.create',
            defaultMessage: 'Create',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulkApprovalFlow;
