// @flow
import React, { useMemo } from 'react';
import { injectIntl, type IntlShape } from 'react-intl';

// redux
import { compose, type Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getCurrentCompanyData } from 'domain/companies/companiesSelector';

import { documentsGridHeadersListSelector, documentsGridFilterAppliedAction } from 'domain/documents';

import type { ColDef } from 'ag-grid-react';
// jss
import { withStyles } from '@mui/styles';
// classnames
import cn from 'classnames';
// styles
import sheet from './sheet';
import { GridFilterType, GridHeaderItemType } from 'domain/documents/types.js.flow';
import { DEFAULT_FORMAT } from 'components/Form/DayPicker/helper';
import moment from 'moment';
import { CompanyType } from 'domain/companies';
import { withOutsideTooltip, type OutsideTooltipData, getTooltipParams } from 'components/OutsideTooltip';

type Props = {|
  classes: { [key: string]: string },
  isHide: boolean,
  gridFilters: GridFilterType,
  gridHeaders: GridHeaderItemType,
  savedGridColumns: ColDef[],
  currentCompany: any,
  currentCompanyData: CompanyType,
  setFilters: Dispatch<documentsGridFilterAppliedAction>,
  intl: IntlShape,
  setOutsideTooltip: (d: OutsideTooltipData) => void,
|};

const dateFormater = (value, dateFormat) => moment(value).format(dateFormat || DEFAULT_FORMAT);

const GridFilters = ({
  classes,
  isHide,
  gridFilters,
  gridHeaders,
  currentCompanyData,
  setFilters,
  intl,
  setOutsideTooltip,
}: Props) => {
  const removeFilters = (colId) => () => {
    const { [colId]: _, ...filters } = gridFilters;
    setFilters({ filters, documentIDs: [] });
  };

  const filterEntries = useMemo(() => Object.entries(gridFilters), [gridFilters]);

  const titles = useMemo(
    () => gridHeaders.reduce((res, header) => ({ ...res, [header.field]: header.headerName }), {}),
    [gridHeaders],
  );

  const getTypeText = (type: string) =>
    intl.formatMessage({
      id: `company.workspace.grid.filters.operator.${type}`,
      defaultMessage: type,
    });

  const textFilterHandler = ({ type, filter }) => (filter ? `${getTypeText(type)}: ${filter}` : getTypeText(type));

  const numberFilterHandler = ({ type, filter, filterTo = null }) => {
    const value = filterTo === null ? filter : `${filter} - ${filterTo}`;
    return value !== null ? `${getTypeText(type)}: ${value}` : getTypeText(type);
  };

  const setFilterHandler = ({ values }) =>
    `${getTypeText('contains')}: ${values
      .map((value) => {
        const transKey = value || 'blanks';
        return intl.formatMessage({
          id: `company.workspace.grid.filters.values.${transKey}`,
          defaultMessage: transKey,
        });
      })
      .join(', ')}`;

  const dateFilterHandler = (data) => {
    const { type, dateFrom = null, dateTo = null } = data;

    const value =
      dateFrom && dateTo
        ? `${dateFrom} - ${dateTo}`
        : (dateFrom && dateFormater(dateFrom, currentCompanyData.dateFormat)) ||
          (dateTo && dateFormater(dateTo, currentCompanyData.dateFormat));

    return `${getTypeText(type)}: ${value}`;
  };

  const filterTypeHandlers = {
    set: setFilterHandler,
    text: textFilterHandler,
    number: numberFilterHandler,
    date: dateFilterHandler,
  };

  const getFilterItemText = (filter): string => {
    const handler =
      filterTypeHandlers[filter.filterType] || (() => console.error(`Undefined filter type ${filter.filterType}`));
    return handler(filter);
  };

  const getFilterText = (filters): string => {
    const { condition1 = null, condition2 = null, operator = null, ...currentFilter } = filters;
    return operator
      ? `${getFilterItemText(condition1)} ${getTypeText(operator.toLowerCase())} ${getFilterItemText(condition2)}`
      : getFilterItemText(currentFilter);
  };

  const onMouseEnter = (message: string) => (e: SyntheticEvent<HTMLElement>) => {
    setOutsideTooltip({
      text: message,
      pos: getTooltipParams(e.currentTarget),
      delay: 500,
      type: 'gridFilter',
    });
  };

  const onMouseLeave = () => {
    setOutsideTooltip(null);
  };

  return (
    <>
      {filterEntries.map(([key, filter]) => {
        const text = getFilterText(filter);
        return (
          <li
            key={key}
            className={cn(classes.item, { [classes.itemHide]: isHide })}
            onMouseEnter={onMouseEnter(text)}
            onMouseLeave={onMouseLeave}
          >
            <span className={classes.colName}>{titles[key]}</span>
            <span className={classes.filterText}>{text}</span>
            <button onClick={removeFilters(key)} />
          </li>
        );
      })}
    </>
  );
};

const mapStateToProps = (state) => ({
  gridHeaders: documentsGridHeadersListSelector(state),
  currentCompanyData: getCurrentCompanyData(state),
});

const mapDispatchToProps = {
  setFilters: documentsGridFilterAppliedAction,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(sheet),
  injectIntl,
  withOutsideTooltip,
)(GridFilters);
