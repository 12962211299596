// @flow
import { Map, RecordOf } from 'immutable';
import type { ChatUser } from 'domain/chat/types.js.flow';
import { TagFactory } from 'domain/approvals/helpers';
import type { TagRecord } from 'domain/approvals/helpers';

export const createTagRecord = (tag: string) => TagFactory({ tag });

export const separateTags = (tags: TagRecord[], usersById: Map<string, RecordOf<ChatUser>>) =>
  tags.reduce(
    ([userTags, generalTags], tag) => {
      const user = usersById.get(tag.tag);
      return user ? [[...userTags, tag], generalTags] : [userTags, [...generalTags, tag]];
    },
    [[], []],
  );

export const getGeneralTagProps = (tag: TagRecord) => ({ tag });

export const getUserTagProps = (user: RecordOf<ChatUser>) => ({ picture: user.picture, username: user.username });

export const filterCustomTags = (tags: string[]) => tags.filter((tag) => !tag.startsWith('_'));

export const replaceTagToUser = (tags: string[], usersById: Map<string, RecordOf<ChatUser>>) =>
  tags.map((tag) => {
    const user = usersById.get(tag);
    return user ? user.username : tag;
  });
