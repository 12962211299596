import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as ACL from 'domain/restriction';
import { rtlEnable } from 'domain/env';
import { getIndicateWarningByDocSelector } from 'domain/documents';
import { getRowErrorStatus } from 'pages/company/grid/helpers';
import { useIntl } from 'react-intl';
import CONST from 'domain/documents/constants';
import { createLocaleTextFunc, createTranslationsList } from 'pages/company/grid/helpers/translations';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';

import useStyles from 'pages/company/grid/sheet';

const mapPropsToState = (state) => ({
  isGranted: ACL.isGranted(state),
  isRtl: rtlEnable(state),
  getIndicateWarningByDoc: getIndicateWarningByDocSelector(state),
});

const useAgGridManualConfig = () => {
  const { isRtl, getIndicateWarningByDoc } = useSelector(mapPropsToState);
  const classes = useStyles();
  const intl = useIntl();

  const isScheduledAcceptanceRow = useCallback(
    (params) =>
      params.data.rowDocumentData.tags ? params.data.rowDocumentData.tags.has(CONST.tags.isScheduledAcceptance) : false,
    [],
  );

  const rowClassRules = useMemo(
    () => ({
      [classes.errorRow]: (params) => getRowErrorStatus(params) === 'error',
      [classes.warningRow]: (params) =>
        getRowErrorStatus(params) === 'warning' && getIndicateWarningByDoc(params.data.rowDocumentData),
      [classes.scheduledForPublishingRow]: (params) => isScheduledAcceptanceRow(params),
    }),
    [classes, getIndicateWarningByDoc, isScheduledAcceptanceRow],
  );

  const translations = useMemo(() => createTranslationsList(intl), [intl]);
  const getLocaleText = useMemo(() => createLocaleTextFunc(translations), [translations]);

  return {
    enableRtl: isRtl,
    rowClassRules,
    getLocaleText,
  };
};

export default useAgGridManualConfig;
