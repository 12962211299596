// @flow
import { useState, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { documentRemoveNotes, documentUpdateNotes, documentRemoveNotesInList } from 'domain/documents';
import { promisify } from 'lib/helpers';

import toast from 'components/Toast';

import { NOTE_PALETTE_DEFAULT_KEY } from 'components/LabeledThemeProvider/notesPalette';

type TUseStickyNotes = {
  onClose: () => void,
  closeAfterSave: boolean,
  value: string,
  noteColorKey: string,
  documentID?: string,
};

const useStickyNotes = ({
  onClose = () => {},
  closeAfterSave = false,
  content = '',
  noteColorKey = '',
  documentID,
}: TUseStickyNotes) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(content);
  const [colorKey, setColorKey] = useState(noteColorKey || NOTE_PALETTE_DEFAULT_KEY);

  const handleChange = useCallback((e) => {
    setValue(e.currentTarget.value);
  }, []);

  const handleChangeRadio = useCallback((event: SyntheticInputEvent<HTMLTextAreaElement>) => {
    setColorKey(event.target.value);
  }, []);

  const onNotesUpdate = useCallback(() => {
    setLoading(true);
    new Promise((resolve, reject) => {
      dispatch(documentUpdateNotes({ notes: value, notesColor: colorKey, documentID, cb: { resolve, reject } }));
    })
      .then(() => {
        toast.info(
          formatMessage({
            id: 'document.actions.notes.success',
            defaultMessage: 'Documents notes were updated',
          }),
        );
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
        if (closeAfterSave) onClose();
      });
  }, [formatMessage, value, colorKey, dispatch, onClose, closeAfterSave, documentID]);

  const onNotesRemove = useCallback(() => {
    setLoading(true);
    const fn = (arg) =>
      dispatch(documentID ? documentRemoveNotesInList({ documentID, ...arg }) : documentRemoveNotes({ ...arg }));
    promisify(fn, { notes: value })
      .then(() => {
        toast.info(
          formatMessage({
            id: 'document.actions.notesRemove.success',
            defaultMessage: 'Documents notes were removed',
          }),
        );
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
        onClose();
      });
  }, [formatMessage, dispatch, value, onClose, documentID]);

  const disableUpdate = useMemo(
    () => !value || value.length < 1 || (content === value && noteColorKey === colorKey) || loading,
    [value, loading, content, noteColorKey, colorKey],
  );

  return {
    value,
    setValue,
    colorKey,
    setColorKey,
    loading,
    handleChange,
    handleChangeRadio,
    onNotesUpdate,
    onNotesRemove,
    disableUpdate,
  };
};

export default useStickyNotes;
