// @flow
import { fork, takeEvery, cancel, call, select, put } from 'redux-saga/effects';
import { type Saga } from 'redux-saga';
import { type Dispatch } from 'redux';
import routes from 'domain/router/routes';
import { completeAction, noLocationChange } from 'domain/router/RouterActions';
import tokenKeeper from 'lib/apiTokenKeeper';

import { hasUserRequireDataSelector, getUserDataComplete } from 'domain/env';
import { locationIsChange, locationSelector } from 'domain/router/RouterSelector';
import { LOCATION_CHANGE } from 'domain/router/redux/reduxActions';
import { matchRoutes } from 'react-router-dom';
import { ToastEventHandler } from 'components/Tables/toast';

type Action<T, P, D> = {
  type?: T,
  payload: P,
  dispatch: D,
};

function RouteMatcher(): (params: Action<string, Location, Dispatch<*>>) => Generator<*, *, *> {
  this.task = false;

  this.item = {};

  this.params = {};

  this.saga = {};

  // TODO: !IMPORTANT matchPath fn in router v6 work incorrectly
  //  https://github.com/remix-run/react-router/discussions/9862?sort=old
  //  when in route exist optional params - matchPath fn cant correctly find route
  //  so used workaround via matchRoutes now
  //  in future(maybe when will this be fixed) - rewrite this approach
  // this.matcher = (payload) => (item) =>
  // const match = matchPath(payload.pathname, {
  //   exact: 'exact' in item ? item.exact : true,
  //   strict: false,
  //   path: item.path.pathname,
  // });
  //
  // if (match !== null) {
  //   this.item = item;
  //   this.match = match;
  //   return true;
  // }
  // false;

  this.complete = (key = '') =>
    function* cb() {
      yield put(completeAction(key));
    };

  function* cancelTask(task) {
    yield cancel(task);
    yield put(noLocationChange());
  }

  const nav = function* nav({ payload, dispatch }: Action<string, Location, Dispatch<*>>) {
    ToastEventHandler.closeAll();
    const hasUserRequireData = yield select(hasUserRequireDataSelector);
    const isLocationChange = yield select(locationIsChange);
    const location = 'location' in payload ? payload.location : payload;

    if (isLocationChange && hasUserRequireData) {
      const match = matchRoutes(routes, location);

      if (match !== null) {
        // match[0] - its App Route - wrapper for all routes
        this.item = match[1].route;
        this.match = match[1];
      }
      if (match) {
        const rootSagaFound = 'saga' in this.item && typeof this.item.saga === 'function';
        if (rootSagaFound) {
          const sagas = yield call(this.item.saga);
          // whole func can be run in parallel and this.task can be mutated
          // though it might be not set at function start yet, its set here
          // in case of consequtive push/resplase redirects
          if (this.task) {
            // eslint-disable-next-line no-console
            yield cancelTask(this.task);
          }
          tokenKeeper.injectCurrentParams(this.match.params);
          this.task = yield fork(
            sagas.default,
            location,
            { params: this.match.params },
            this.complete(location.key),
            dispatch,
            this.item.sagasProps,
          );
          this.saga = sagas.default;
        }
        // trying to match subroutes and if match exists we call matched route saga and let it
        // call 'complete'
        const submatch = match[2]; // deeper than 2 level - we dont have sagas, so its enough
        let subSagaFound = null;
        if (submatch) {
          const { route, params } = submatch;
          subSagaFound = 'saga' in route && typeof route.saga === 'function';
          if (subSagaFound) {
            const sagas = yield call(route.saga);
            if (this.subtask) {
              // eslint-disable-next-line no-console
              yield cancelTask(this.subtask);
              // @to-do detect if current subsaga should emit failure to reduce env.loading count
            }
            this.subtask = yield fork(
              sagas.default,
              location,
              { params },
              this.complete(location.key),
              dispatch,
              route.sagasProps,
            );
            this.subsaga = sagas.default;
          }
        }
        if (!rootSagaFound && !subSagaFound) {
          // if rootSaga not found, and no subroutes found, invoke complete explicitely,
          // otherwise it will must invoked in rootSaga or subRoute saga
          // eslint-disable-next-line no-console
          console.log('invoking complete as no rootSaga and subroutes found');
          yield call(this.complete(payload.key));
        }
      }
    } else {
      // location_change event has been dispatched by react-router but location has no changes
      yield put(noLocationChange());
    }
  };
  return nav.bind(this);
}

const MatcheRoute = new RouteMatcher();

export function* afterInit(dispatch) {
  const payload = yield select(locationSelector);
  yield call(MatcheRoute, { payload, dispatch });
}

export default function* navigator(dispatch: Dispatch<*>): Saga<void> {
  yield takeEvery(LOCATION_CHANGE, ({ payload }): Generator<*, *, *> => MatcheRoute({ payload, dispatch }));

  yield takeEvery(getUserDataComplete.type, (): Generator<*, *, *> => afterInit(dispatch));
}
