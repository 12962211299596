// @flow
// types
import { type IntlShape } from 'react-intl';

const messages = {
  noRowsToShow: {
    id: 'company.workspace.grid.noRowsToShow',
    defaultMessage: 'No Rows To Show',
  },
  copy: {
    id: 'company.workspace.grid.contextMenu.copy',
    defaultMesssage: 'Copy',
  },
  copyWithHeaders: {
    id: 'company.workspace.grid.contextMenu.copyWithHeaders',
    defaultMessage: 'Copy with Headers',
  },
  paste: {
    id: 'company.workspace.grid.contextMenu.paste',
    defaultMessage: 'Paste',
  },
  export: {
    id: 'company.workspace.grid.contextMenu.export',
    defaultMessage: 'Export',
  },
  csvExport: {
    id: 'company.workspace.grid.contextMenu.CSVExport',
    defaultMessage: 'CSV Export',
  },
  excelExport: {
    id: 'company.workspace.grid.contextMenu.excelExport',
    defaultMessage: 'Excel Export (.xlsx)',
  },
  pinColumn: {
    id: 'company.workspace.grid.filters.pinColumn',
    defaultMessage: 'Pin Column',
  },
  pinLeft: {
    id: 'company.workspace.grid.filters.pinStart',
    defaultMessage: 'Pin Left',
  },
  pinRight: {
    id: 'company.workspace.grid.filters.pinEnd',
    defaultMessage: 'Pin Right',
  },
  noPin: {
    id: 'company.workspace.grid.filters.noPin',
    defaultMessage: 'No Pin',
  },
  autosizeThiscolumn: {
    id: 'company.workspace.grid.filters.autosizeThisColumn',
    defaultMessage: 'Autosize This Column',
  },
  autosizeAllColumns: {
    id: 'company.workspace.grid.filters.autosizeAllColumn',
    defaultMessage: 'Autosize All Columns',
  },
  resetColumns: {
    id: 'company.workspace.grid.filters.resetColumns',
    defaultMessage: 'Reset Columns',
  },
  searchOoo: {
    id: 'company.workspace.grid.filters.input.placeholder.search',
    defaultMessage: 'Search...',
  },
  filterOoo: {
    id: 'company.workspace.grid.filters.input.placeholder.filter',
    defaultMessage: 'Filter...',
  },
  selectAll: {
    id: 'company.workspace.grid.filters.selectAllBtn',
    defaultMessage: '(Select All)',
  },
  blanks: {
    id: 'company.workspace.grid.filters.blanks',
    defaultMessage: '(Blanks)',
  },
  orCondition: {
    id: 'company.workspace.grid.filters.or',
    defaultMessage: 'OR',
  },
  andCondition: {
    id: 'company.workspace.grid.filters.and',
    defaultMessage: 'AND',
  },
  afterOrOn: {
    id: 'company.workspace.grid.filters.operator.afterOrOn',
    defaultMessage: 'After Or On',
  },
  beforeOrOn: {
    id: 'company.workspace.grid.filters.operator.beforeOrOn',
    defaultMessage: 'Before Or On',
  },
  inRange: {
    id: 'company.workspace.grid.filters.operator.inRange',
    defaultMessage: 'In Range',
  },
  startsWith: {
    id: 'company.workspace.grid.filters.operator.startsWith',
    defaultMessage: 'Starts With',
  },
  endsWith: {
    id: 'company.workspace.grid.filters.operator.endsWith',
    defaultMessage: 'Ends With',
  },
  contains: {
    id: 'company.workspace.grid.filters.operator.contains',
    defaultMessage: 'Contains',
  },
  notContains: {
    id: 'company.workspace.grid.filters.operator.notContains',
    defaultMessage: 'Not contains',
  },
  exist: {
    id: 'company.workspace.grid.filters.operator.exist',
    defeaultMessage: 'Exist',
  },
  notExist: {
    id: 'company.workspace.grid.filters.operator.notExist',
    defeaultMessage: 'Not Exist',
  },
  equals: {
    id: 'company.workspace.grid.filters.operator.equals',
    defaultMessage: 'Equals',
  },
  notEqual: {
    id: 'company.workspace.grid.filters.operator.notEqual',
    defaultMessage: 'Not Equal',
  },
  greaterThan: {
    id: 'company.workspace.grid.filters.operator.greaterThan',
    defaultMessage: 'Greater Than',
  },
  greaterThanOrEqual: {
    id: 'company.workspace.grid.filters.operator.greaterThanOrEquals',
    defaultMessage: 'Greater Than Or Equals',
  },
  lessThan: {
    id: 'company.workspace.grid.filters.operator.lessThan',
    defaultMessage: 'Less Than',
  },
  lessThanOrEqual: {
    id: 'company.workspace.grid.filters.operator.lessThanOrEquals',
    defaultMessage: 'Less Than Or Equals',
  },
  applyFilter: {
    id: 'company.workspace.grid.filters.applyBtn',
    defaultMessage: 'OK',
  },
  cancelFilter: {
    id: 'company.workspace.grid.filters.cancelBtn',
    defaultMessage: 'Cancel',
  },
  resetFilter: {
    id: 'company.workspace.grid.filters.resetBtn',
    defaultMessage: 'Reset',
  },
  inRangeStart: {
    id: 'company.workspace.grid.filters.operator.inrangeStart',
    defaultMessage: 'from',
  },
  inRangeEnd: {
    id: 'company.workspace.grid.filters.operator.inrangeEnd',
    defaultMessage: 'to',
  },

  // status bar
  sum: {
    id: 'company.workspace.grid.statusBar.sum',
    defaultMessage: 'Sum',
  },
  min: {
    id: 'company.workspace.grid.statusBar.min',
    defaultMessage: 'Min',
  },
  max: {
    id: 'company.workspace.grid.statusBar.max',
    defaultMessage: 'Max',
  },
  none: {
    id: 'company.workspace.grid.statusBar.none',
    defaultMessage: 'None',
  },
  count: {
    id: 'company.workspace.grid.statusBar.count',
    defaultMessage: 'Count',
  },
  avg: {
    id: 'company.workspace.grid.statusBar.average',
    defaultMessage: 'Average',
  },
  filteredRows: {
    id: 'company.workspace.grid.statusBar.filtered',
    defaultMessage: 'Filtered',
  },
  selectedRows: {
    id: 'company.workspace.grid.statusBar.selected',
    defaultMessage: 'Selected',
  },
  totalRows: {
    id: 'company.workspace.grid.statusBar.totalRows',
    defaultMessage: 'Total Rows',
  },
  totalAndFilteredRows: {
    id: 'company.workspace.grid.statusBar.rows',
    defaultMessage: 'Rows',
  },
  more: {
    id: 'company.workspace.grid.statusBar.more',
    defaultMessage: 'More',
  },
  to: {
    id: 'company.workspace.grid.statusBar.to',
    defaultMessage: 'to',
  },
  of: {
    id: 'company.workspace.grid.statusBar.of',
    defaultMessage: 'of',
  },
};

export const createTranslationsList = (intl: IntlShape) =>
  Object.entries(messages).reduce((a, [key, value]) => ({ ...a, [key]: intl.formatMessage(value) }), {});

export const createLocaleTextFunc =
  (translations) =>
  ({ key, defaultValue }: { key: string, defaultValue?: string }) =>
    translations[key] || defaultValue;
