// @flow
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Stack from '@mui/material/Stack';
import MuiSkeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { alpha } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import MuiOutlinedInput from '@mui/material/OutlinedInput';

const ContentWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingBottom: 0,
  backgroundColor: alpha(theme.palette.common.white, 0.5),
  backdropFilter: 'blur(2px)',
  position: 'sticky',
  top: '50%',
  transform: 'translateY(-50%)',
  textAlign: 'center',
}));

const OutlinedInput = styled(MuiOutlinedInput)(({ theme }) => ({
  paddingRight: 3,
  borderRadius: theme.shape.borderRadius * 6,
  '& .MuiInputBase-input': {
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
  },
}));

const NestedSkeletonWrapper = styled(Stack)(() => ({
  width: '100%',
  paddingTop: '10%',
  paddingBottom: '10%',

  '& .MuiSkeleton-root': { visibility: 'visible' },
}));

const Skeleton = ({ responsiveHeight = '5%', visibility = 'inherit', children, ...rest }) => (
  <Box position="relative" display="flex" visibility={visibility} width="100%">
    <div style={{ marginTop: responsiveHeight }} />
    <Stack direction="row" justifyContent="space-between" sx={{ position: 'absolute', inset: 0 }}>
      <MuiSkeleton {...rest} />
      {children}
    </Stack>
  </Box>
);

type Props = {
  doctype: 'general' | 'financial',
  error?: boolean,
  onPasswordEnter: (string) => void,
};
type State = {
  password: string,
};

class Protect extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
    };
  }

  onSubmit = (event) => {
    const { password } = this.state;
    event.preventDefault();
    if (password.length > 0) {
      this.props.onPasswordEnter(password);
    }
  };

  onChange = (event) => {
    this.setState({
      password: event.target.value,
    });
  };

  render() {
    const { error } = this.props;
    const { password } = this.state;

    const validationError = error && !password;

    return (
      <Stack width="100%" alignSelf="center" alignItems="center" position="relative" overflow="auto" sx={{ p: 3 }}>
        <Stack variant="outlined" component={Paper} width="100%" maxWidth="35vw" position="relative" sx={{ p: 3 }}>
          <Box position="absolute" sx={{ inset: 0, zIndex: 1 }}>
            <ContentWrapper flex={0}>
              <Stack sx={{ mb: 3 }}>
                <Typography variant="h6">
                  <FormattedMessage id="document.protect.title" defaultMessage="Document is password protected" />
                </Typography>
                <Typography variant="body1" maxWidth={400} margin="auto">
                  <FormattedMessage
                    id="document.protect.placeholder"
                    defaultMessage="Enter password to edit this document and make Journal Entry"
                  />
                  :
                </Typography>
              </Stack>
              <Box component="form" onSubmit={this.onSubmit}>
                <FormControl variant="outlined" eror={validationError}>
                  <OutlinedInput
                    type="password"
                    error={validationError}
                    value={password}
                    onChange={this.onChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <Button type="submit" disabled={password.length < 1}>
                          <FormattedMessage id="document.protect.submit" defaultMessage="OK" />
                        </Button>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText error={validationError} sx={{ opacity: validationError ? 1 : 0 }}>
                    <FormattedMessage id="document.protect.error" defaultMessage="Wrong password" />
                  </FormHelperText>
                </FormControl>
              </Box>
            </ContentWrapper>
          </Box>
          <Stack width="100%">
            <Skeleton width="20%" />
            <Skeleton width="25%" />
            <Skeleton width="100%" />
          </Stack>
          <Stack width="50%" alignSelf="end" sx={{ py: '10%' }}>
            <Stack width="100%" direction="row">
              <Skeleton width="20%" responsiveHeight="10%">
                <MuiSkeleton width="70%" />
              </Skeleton>
            </Stack>
            <Stack width="100%" direction="row">
              <Skeleton width="80%" responsiveHeight="10%">
                <MuiSkeleton width="10%" />
              </Skeleton>
            </Stack>
            <Stack width="100%" direction="row">
              <Skeleton width="20%" responsiveHeight="10%">
                <MuiSkeleton width="70%" />
              </Skeleton>
            </Stack>
          </Stack>
          <Stack sx={{ mt: 'auto', pt: '25%' }}>
            <NestedSkeletonWrapper>
              <MuiSkeleton width="100%" sx={{ transform: 'none', p: 2 }}>
                <Skeleton width="100%" visibility="visible" />
                <Stack width="50%" sx={{ ml: 'auto' }}>
                  {Array.from(Array(3).keys()).map((i) => (
                    <Skeleton key={i} width="100%" responsiveHeight="10%" />
                  ))}
                </Stack>
              </MuiSkeleton>
            </NestedSkeletonWrapper>
            <Stack width="100%">
              <Skeleton width="15%" />
              <Skeleton width="100%" />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  }
}

export default Protect;
