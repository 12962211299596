// @flow
import React, { useState, useEffect, useRef, useCallback } from 'react';
import Switcher from 'pages/common/input/switcher';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import useSheet from './sheet';
import { MatchingColorsMap } from '../TextractCanvas/utils';
import boundIcon from './assets/bound.svg';
import unboundIcon from './assets/unbound.svg';

import {
  useFloating,
  offset,
  flip,
  arrow,
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useInteractions,
  useListNavigation,
  useClick,
  useRole,
  useDismiss,
} from '@floating-ui/react-dom-interactions';

import { size } from '@floating-ui/dom';
import { useMemo } from 'react';

type Props = {|
  fields: Array<{}>,
  isMandatory: boolean,
  width: number,
  height: number,
  name: string,
  bound: boolean,
  onClick: (name: string) => void,
  onSelect: (index: number, mandatory: boolean) => void,
  selectedFieldIndex: ?number,
  mandatoryField: boolean | null,
  disabledFields: number[],
  bindingCell: {
    [key: number]: {|
      X: number,
      Y: number,
    |},
  },
  index: number,
|};

export const TextractFieldBinding = ({
  bindingCell,
  fields,
  isMandatory,
  width,
  height,
  onClick,
  name,
  bound,
  selectedFieldIndex,
  mandatoryField,
  disabledFields,
  onSelect,
  index,
}: Props) => {
  const arrowRef = useRef(null);
  const listRef = useRef([]);
  const [open, setOpen] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [mandatory, setMandatory] = useState<boolean>(isMandatory);
  const toggleMandatory = useCallback(() => setMandatory((prevState) => !prevState), [setMandatory]);
  const placement = 'bottom-end';

  useEffect(() => {
    setMandatory(mandatoryField);
  }, [mandatoryField]);

  // floating-ui
  const {
    x,
    y,
    reference: buttonReference,
    floating,
    strategy,
    context,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
    placement: flippedPlacement,
  } = useFloating({
    open,
    onOpenChange: setOpen,
    placement,
    middleware: [
      flip(),
      size({
        apply({ availableWidth, availableHeight, elements }) {
          // this allows to add extra paddings for dropdown
          Object.assign(elements.floating.style, {
            maxWidth: `${availableWidth}px`,
            maxHeight: `${availableHeight - 70}px`,
          });
        },
      }),
      offset(10),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  const classes = useSheet({ bindingCell, flippedPlacement });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    useClick(context),
    useRole(context, { role: 'menu' }),
    useDismiss(context),
    useListNavigation(context, {
      listRef,
      activeIndex,
      onNavigate: setActiveIndex,
      disabledIndices: disabledFields,
    }),
  ]);

  const staticSide = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[flippedPlacement.split('-')[0]];

  const onMandatoryChecked = (checked: boolean) => {
    // if dropdown option selected, invoke parent component callback, otherwise change local checkbox state
    toggleMandatory();
    if (selectedFieldIndex) {
      onSelect(selectedFieldIndex, checked);
    }
  };

  const disabledFieldsExceptSelected = useMemo(
    () => disabledFields.filter((field) => field !== selectedFieldIndex),
    [disabledFields, selectedFieldIndex],
  );

  const buttonStyle = () => {
    const size = 16;
    // @to-do make use of enum
    const [binding, icon] = bound ? ['match', boundIcon] : ['partial_match', unboundIcon];
    const color = MatchingColorsMap[binding].border;

    return bindingCell && bindingCell[0]
      ? {
          position: 'absolute',
          top: height * bindingCell[0].Y - size,
          left: width * bindingCell[0].X - 1, // - border size
          width: `${size}px`,
          height: `${size}px`,
          border: 0,
          backgroundImage: `url("${icon}")`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundColor: color,
          backgroundSize: size / 2 - 1,
        }
      : {};
  };

  return bindingCell && bindingCell[0] ? (
    <>
      <div style={buttonStyle()} />
      <button
        {...getReferenceProps({
          ref: buttonReference,
          style: buttonStyle(),
          className: classes.binding,
          onClick: () => onClick(name),
        })}
      />
      {open && (
        <FloatingPortal>
          <FloatingOverlay style={{ zIndex: 99 }} lockScroll>
            <FloatingFocusManager context={context} preventTabbing>
              <div
                {...getFloatingProps({
                  id: 'menu',
                  className: classes.floatingContainer,
                  ref: floating,
                  style: {
                    position: strategy,
                    top: y ?? '',
                    left: x ?? '',
                  },
                })}
              >
                <div className={classes.header}>
                  <h2>
                    <FormattedMessage id="3WayMatching.bindings.matchTo" defaultMessage="Matching to" />
                  </h2>
                  <input />
                  <Switcher
                    labelClass={classes.mandatoryLabel}
                    withSmallSizeLabel
                    label={{
                      id: '3WayMatching.bindings.mandatory',
                      defaultMessage: 'Mandaroty',
                    }}
                    value={mandatory}
                    onChange={onMandatoryChecked}
                  />
                </div>
                <div className={classes.scrollableContent}>
                  {fields.map((option, index) => (
                    // renderer function
                    <button
                      {...getItemProps({
                        disabled: disabledFieldsExceptSelected.includes(index),
                        role: 'menuitem',
                        key: option.name,
                        className: cx(classes.option, {
                          [classes.active]: index === selectedFieldIndex,
                        }),
                        ref: (node) => {
                          listRef.current[index] = node;
                        },
                        onPointerEnter() {
                          setActiveIndex(index);
                        },
                        onClick: () => {
                          onSelect(index, mandatory);
                        },
                      })}
                    >
                      {option.display_name}
                    </button>
                  ))}
                </div>

                <div
                  className={classes.arrow}
                  ref={arrowRef}
                  style={{
                    left: arrowX != null ? `${arrowX}px` : '',
                    top: arrowY != null ? `${arrowY}px` : '',
                    right: '',
                    bottom: '',
                    [staticSide]: '-4px',
                  }}
                />
              </div>
            </FloatingFocusManager>
          </FloatingOverlay>
        </FloatingPortal>
      )}
    </>
  ) : null;
};
