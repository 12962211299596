// flow
import React, { memo } from 'react';

import AuthPaperBox from 'pages/authPage/Layout/PaperBox';
import TFAForm from 'pages/authPage/TFAuth/Form';
import FormVersionBuild from 'pages/authPage/Layout/BuildVersion';

const TFAuth = () => (
  <>
    <AuthPaperBox>
      <TFAForm />
    </AuthPaperBox>
    <FormVersionBuild />
  </>
);

export default memo(TFAuth);
