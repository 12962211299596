/* @flow */
import * as React from 'react';
import { throttle } from 'throttle-debounce';
import { get as _get, isEqual as _isEqual, size as _size } from 'lodash';

import { isChangeProps } from 'lib/propHelpers';
import { limit, setTableColsWidth, getTableColsWidth, type TColumnsWidth } from './helpers';
import { swapJEGridLineColumnsData } from 'domain/journal/helper';

import type { TableProps } from '../table';
import type { TViewArrangement } from 'pages/document/types.js.flow';
import type { TJEGridLineColumnsDictionary } from 'domain/journal/helper';

import { ResizeHandler } from 'components/Tables/grid/withResize/StyledComponents';

const MOVE_LEFT = 'left';
const MOVE_RIGHT = 'right';
const DEFAULT_WIDTH = 100;
const MIN_WIDTH = 50;
const MAX_WIDTH = 500;
const CELL_PADDING = 20; // left + right
const CELL_BORDER = 2; // left + right

type MoveDirection = 'left' | 'right';

type ResizeProps = {|
  storeKey: string,
  headerLineNumber: number, // for detecting header cells
  minWidth: number,
  maxWidth: number,
  isRtl: boolean,
  isAutoresize: boolean,
  disabledCols: Array<string>,
  tableColsWidth: TColumnsWidth,
  onChangeColsWidth: (w: TColumnsWidth) => void,
  viewArrangement: TViewArrangement,
  wrapperWidth: number | null,
  gridLineColumnsDictionary: TJEGridLineColumnsDictionary,
|};

type Props = { ...TableProps, ...ResizeProps };

type State = {|
  resizingColumnName: string,
  startPosition: number,
  currentPosition: number,
  direction: MoveDirection,
  isResizeActive: boolean,
|};

export const withResize = (WrappedComponent: React.Component<TableProps>) => {
  class WithResize extends React.Component<Props, State> {
    isMountedState: boolean;

    columnsWidthByHeaderLength: TColumnsWidth;

    headerLineCellRefs: {
      [key: string]: HTMLElement,
    };

    constructor(props) {
      super(props);

      this.state = {
        resizingColumnName: '',
        startPosition: 0,
        currentPosition: 0,
        direction: MOVE_LEFT,
        isResizeActive: false,
      };

      this.columnsWidthByHeaderLength = {};
      this.headerLineCellRefs = [];
    }

    componentDidMount() {
      this.isMountedState = true;
      this.loadColumnsConfig();
    }

    componentDidUpdate(prevProps: Props) {
      const { meta, tableColsWidth } = this.props;
      const { meta: prevMeta, tableColsWidth: prevTableColsWidth } = prevProps;
      // recalculate if column was pinned
      const isEqualMeta = _isEqual(meta[0], prevMeta[0]);
      // recalculate if we click "autosize columns" - its mean remove stored config and fit/shrink cell by title length
      const isConfigWasResetToDefault = _size(prevTableColsWidth) !== 0 && _size(tableColsWidth) === 0;
      const isChanged =
        isConfigWasResetToDefault ||
        !isEqualMeta ||
        isChangeProps(prevProps, this.props, ['storeKey', 'viewArrangement', 'wrapperWidth']);

      if (isChanged) {
        this.loadColumnsConfig();
      }
    }

    componentWillUnmount() {
      this.clearResizeListeners();
      this.isMountedState = false;
    }

    onStart = (e: MouseEvent, resizingColumnName: string, direction: MoveDirection) => {
      const startPosition = e.clientX;

      this.setState({
        startPosition,
        currentPosition: startPosition,
        resizingColumnName,
        direction,
        isResizeActive: true,
      });
      this.initResizeListeners();
    };

    setStateColumnsWidth = (columnsWidth: TColumnsWidth) => {
      const { onChangeColsWidth } = this.props;
      // also we need update state twice to have an up-to-date state for comparison and adjustment
      // define column width from config or by header length
      onChangeColsWidth(this.defineColumnsWidth(columnsWidth));
      // after we can compare real column width with defined
      // and adjust but now compare real width by ref and width from state
      onChangeColsWidth(this.adjustColumnsWidth());
    };

    setCurrentPosition = throttle(50, (currentPosition) => {
      this.setState({ currentPosition });
    });

    getResizeDelta = () => {
      const { isRtl } = this.props;
      const { startPosition, currentPosition, direction } = this.state;
      const delta =
        direction === MOVE_LEFT || isRtl ? startPosition - currentPosition : currentPosition - startPosition;
      return delta;
    };

    getResizingWidth = (columnName: string) => {
      const resizeWidth = this.getStoredColumnWidth(columnName) + this.getResizeDelta();
      return this.limitWidth(resizeWidth);
    };

    getColumnWidth = (columnName: string): number => {
      const { isResizeActive, resizingColumnName } = this.state;
      const width =
        isResizeActive && resizingColumnName === columnName
          ? this.getResizingWidth(columnName)
          : this.getStoredColumnWidth(columnName);

      return this.limitWidth(width);
    };

    getStoredColumnWidth = (columnName: string): number => {
      const { tableColsWidth } = this.props;
      const entireDefaultWidth = _get(this.columnsWidthByHeaderLength, columnName, DEFAULT_WIDTH);

      return _get(tableColsWidth, columnName, entireDefaultWidth);
    };

    /**
     * prepare columns
     * null - indicate that we dont have config and set entire cell width
     * @param columns
     * @returns {columnName: columnWidth || null}
     */
    getCurrentTableColsWidth(columns: TColumnsWidth) {
      const { meta, disabledCols } = this.props;
      return meta[0].reduce(
        (res, colName) => (disabledCols.includes(colName) ? res : { ...res, [colName]: _get(columns, colName, null) }),
        {},
      );
    }

    tableRef = (el: HTMLElement) => {
      if (el) {
        this.tableRefEl = el;
      }
    };

    prevElRef = (el: HTMLElement) => {
      if (el) {
        this.tableRefEl = el;
      }
    };

    /**
     * calculate and collect all cells with entire header length
     * @param el - HTMLElement
     * @param col - string
     * @param row - number
     */
    setColumnsWidthByHeaderLength = (el: HTMLElement, col: string, row: number) => {
      if (el) {
        const { headerLineNumber } = this.props;

        if (row === headerLineNumber) {
          const cellTextEl = el.querySelector('.cell-text');
          const cellTextParentEl = el.querySelector('.cell-text-parent');
          this.headerLineCellRefs[col] = el;

          if (cellTextEl && cellTextParentEl) {
            //   textWidth: number, // width calculated for .cell-text - inline text width
            //   parentWidth: number, // width calculated of parent - parent of .cell-text(div.description) - .cell-text-parent
            //   wrapperWidth: number, // width for wrapper, this wrapper added in render and getting by ref
            const { width: textWidth } = cellTextEl.getBoundingClientRect();
            const { width: parentWidth } = cellTextParentEl.getBoundingClientRect();
            const { width: wrapperWidth } = el.getBoundingClientRect();

            const pinBoxWidth = Math.ceil(wrapperWidth) - Math.ceil(parentWidth); //  I dont know how better calculate pinBox
            // calculate width of all elements and push
            this.columnsWidthByHeaderLength[col] = Math.ceil(textWidth) + CELL_PADDING + CELL_BORDER + pinBoxWidth;
          }
        }
      }
    };

    generateColgroup = () => {
      const { meta, prevItem } = this.props;
      return (
        <colgroup>
          {prevItem && <col width={30} />}
          {meta[0].map((columnName) => (
            <col key={columnName} width={this.getColumnWidth(columnName)} />
          ))}
        </colgroup>
      );
    };

    limitWidth = (width: number) => {
      const { minWidth, maxWidth } = this.props;
      return limit(width, { min: minWidth, max: maxWidth });
    };

    clearResizeListeners = () => {
      window.removeEventListener('mousemove', this.handleMouseMove);
      window.removeEventListener('mouseup', this.handleMouseUp);
    };

    handleMouseUp = () => {
      this.clearResizeListeners();

      const { storeKey, onChangeColsWidth, tableColsWidth, gridLineColumnsDictionary } = this.props;
      const { resizingColumnName } = this.state;

      const adjustedColumns = this.defineColumnsWidth({
        ...tableColsWidth,
        [resizingColumnName]: this.getColumnWidth(resizingColumnName),
      });

      onChangeColsWidth(adjustedColumns);
      this.setState({ isResizeActive: false, resizingColumnName: '' });
      setTableColsWidth(storeKey, swapJEGridLineColumnsData(adjustedColumns, gridLineColumnsDictionary));
    };

    handleMouseMove = (e: MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      this.setCurrentPosition(e.clientX);
    };

    initResizeListeners = () => {
      window.addEventListener('mousemove', this.handleMouseMove);
      window.addEventListener('mouseup', this.handleMouseUp);
    };

    /**
     * define column width from config or by header length
     * columnWidth can be null or number
     * if we are resizing now - set columnWidth from config
     * @param columns
     * @returns {string: number} - {columnName: columnWidth}
     */
    defineColumnsWidth = (columns: TColumnsWidth) => {
      const { resizingColumnName } = this.state;
      const currentColumnsWidth = this.getCurrentTableColsWidth(columns);

      return Object.entries(currentColumnsWidth).reduce((acc, [columnName, columnWidth]) => {
        const columnWidthByHeaderLength = this.columnsWidthByHeaderLength[columnName];

        acc[columnName] =
          resizingColumnName === columnName || columnWidth !== null ? columnWidth : columnWidthByHeaderLength;

        return acc;
      }, {});
    };

    /**
     * first we set column width from config or by header length using function defineColumnsWidth
     * after we check if defined column width is less than real column width(case when table width is bigger than all columns width)
     * we get real width by header cell ref and set
     * @returns {string: number} - {columnName: columnWidth}
     */
    adjustColumnsWidth = () => {
      const { tableColsWidth } = this.props;

      return Object.entries(tableColsWidth).reduce((acc, [columnName, columnWidth]) => {
        const { width: columnWidthByRefRaw } = this.headerLineCellRefs[columnName].getBoundingClientRect();
        const columnWidthByRef = Math.ceil(columnWidthByRefRaw) + CELL_BORDER;

        acc[columnName] = columnWidthByRef > columnWidth ? columnWidthByRef : columnWidth;

        return acc;
      }, {});
    };

    withGetItems = (row: number, col: string) => {
      const { getItem, headerLineNumber } = this.props;
      const { isResizeActive, resizingColumnName } = this.state;

      const isHeaderCell = headerLineNumber === row;
      const item = getItem(row, col);

      return (
        <>
          {isHeaderCell ? (
            <div className="cell-wrapper-ref" ref={(el) => this.setColumnsWidthByHeaderLength(el, col, row)}>
              {item}
            </div>
          ) : (
            item
          )}
          <ResizeHandler
            onMouseDown={(e: MouseEvent) => {
              this.onStart(e, col, MOVE_RIGHT);
            }}
            active={isResizeActive && resizingColumnName === col}
          />
        </>
      );
    };

    loadColumnsConfig() {
      const { storeKey, gridLineColumnsDictionary } = this.props;
      if (this.isMountedState) {
        getTableColsWidth(storeKey).then((storedColsWidth) => {
          this.setStateColumnsWidth(
            swapJEGridLineColumnsData(_get(storedColsWidth, 'width', {}), gridLineColumnsDictionary, true),
          );
        });
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          getItem={this.withGetItems}
          colgroup={this.generateColgroup}
          tableRef={this.tableRef}
          prevElRef={this.prevElRef}
        />
      );
    }
  }

  WithResize.defaultProps = {
    getItem: () => null,
    storeKey: 'table',
    minWidth: MIN_WIDTH,
    maxWidth: MAX_WIDTH,
    isRtl: false,
  };

  return WithResize;
};

export default withResize;
