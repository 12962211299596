// @flow
import React from 'react';
import { compose } from 'redux';

import type { ApprovalRecordList, ApprovalRecord, Approval } from 'domain/approvals/types.js.flow';

import { documentSelector, type DocumentsType } from 'domain/documents';

import { List, Map } from 'immutable';
import { connect } from 'react-redux';
import type { RecordOf } from 'immutable';
import type { ChatUser } from 'domain/chat/types.js.flow';
import Stack from '@mui/material/Stack';
import Approver from 'pages/components/Approver';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';

const CompactApprover = styled(Approver)(() => ({
  borderRadius: '50%',
  width: 34,
  borderWidth: 2,
  '& .MuiAvatar-root': { margin: 0 },
  '& .MuiChip-label': { display: 'none' },
}));

type Props = {
  approvals: ApprovalRecordList,
  approvalsCurrentSignerGuid: string,
  document: DocumentsType,
  usersById: Map<string, RecordOf<ChatUser>>,
};

type State = {
  currentFullViewUserGuid: string | null,
};

const getApprovalByUserGuid = (approvals: ApprovalRecordList, userGuid: string | null): ApprovalRecord =>
  approvals.reduce(
    (res: ApprovalRecord | null, approval) => (!res && approval.get('userGuid') === userGuid ? approval : res),
    null,
  );

const getApprovalByStatus = (approvals: ApprovalRecordList): ApprovalRecord =>
  approvals.reduce((res: ApprovalRecord | null, approval, i, list) => {
    const isPenultimateOrLast = i + 2 >= list.size;
    if (!res && isPenultimateOrLast) {
      return approval;
    }
    if (!res && ['approved', 'rejected'].includes(approval.get('status'))) {
      return res;
    }
    return approval;
  }, null);

export const getPrevApproval = (current: Approval, approvals: List<Approval>): Approval =>
  approvals.reduce(
    (res, approval, index) => (!res && approval.tag === current.tag && !!index ? approvals.get(index - 1) : res),
    null,
  );

export const getNextApproval = (current: Approval, approvals: List<Approval>): Approval =>
  getPrevApproval(current, approvals.reverse());

class SlimFlow extends React.Component<Props, State> {
  state = {
    currentFullViewUserGuid: null,
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    const { approvalsCurrentSignerGuid } = props;
    if (!state.currentFullViewUserGuid) {
      return { currentFullViewUserGuid: approvalsCurrentSignerGuid };
    }
    return null;
  }

  componentDidUpdate(prevProps: Props): * {
    const { document, approvalsCurrentSignerGuid } = this.props;
    if (prevProps.document.documentID !== document.documentID) {
      this.setCurrentFullViewUserGuid(approvalsCurrentSignerGuid)();
    }
  }

  setCurrentFullViewUserGuid = (currentFullViewUserGuid: string) => () => {
    this.setState({ currentFullViewUserGuid });
  };

  render() {
    const { approvals = new Map(), usersById } = this.props;
    const { currentFullViewUserGuid } = this.state;
    const currentApproval = getApprovalByUserGuid(approvals, currentFullViewUserGuid) || getApprovalByStatus(approvals);
    if (currentApproval) {
      const prevApproval = getPrevApproval(currentApproval, approvals);
      const nextApproval = getNextApproval(currentApproval, approvals);

      return (
        <Stack direction="row" flexWrap="nowrap" spacing={1}>
          {prevApproval && (
            <CompactApprover
              hasArrow
              status={prevApproval.get('status')}
              avatar={<Avatar alt="Alt" src={usersById.getIn([prevApproval.get('tag'), 'picture'])} />}
              onClick={this.setCurrentFullViewUserGuid(prevApproval.get('userGuid'))}
            />
          )}
          <Approver
            hasArrow={nextApproval}
            label={currentApproval.fullName}
            status={currentApproval.status}
            avatar={<Avatar alt="Alt" src={usersById.getIn([currentApproval.get('tag'), 'picture'])} />}
          />
          {nextApproval && (
            <CompactApprover
              hasArrow
              status={nextApproval.get('status')}
              avatar={<Avatar alt="Alt" src={usersById.getIn([nextApproval.get('tag'), 'picture'])} />}
              onClick={this.setCurrentFullViewUserGuid(nextApproval.get('userGuid'))}
            />
          )}
        </Stack>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => ({ document: documentSelector(state) });

export default compose(connect(mapStateToProps))(SlimFlow);
