// @flow
import { List } from 'immutable';
import { UserFactory, ClientFactory, EnhancedUserFactory } from 'domain/organizationUser/helpers';
import { userGUIDSelector } from 'domain/env/envSelector';
import { currentCompanySelector, isDocumentConfidentialSelector } from 'domain/documents/documentSelector';
import { selector } from 'lib/selectors';
import type { UserList, EnhancedUserList, User } from './types.js.flow';
import type { S as DokkaStore } from '../types.js.flow';
import type { ChatUserRecords } from 'domain/chat/types.js.flow';

type SelectorType<T> = (s: DokkaStore) => T;

export const organizationUsersSelector: SelectorType<UserList> = (state) => state.organizationUser;

export const organizationUsersForConfidentialDocSelector: SelectorType<ChatUserRecords> = selector(
  (state) => state.chat.users,
  isDocumentConfidentialSelector,
  // eslint-disable-next-line max-len
  (users: ChatUserRecords, isDocumentConfidential: boolean) =>
    !isDocumentConfidential
      ? users.filter(({ role }) => role !== 'supplier')
      : users.filter(
          (user) =>
            [
              'confidential-user',
              'confidential-restricted-user',
              'accountant',
              'limited-accountant',
              'restricted-accountant',
            ].includes(user.role), // user role have access to confidentialdocs
        ),
);

export const getOrganizationUserByIdSelector =
  (id: ?string): SelectorType<User> =>
  (state) =>
    state.organizationUser.find((_) => _.id === id);

// finds bookkeeper by id or returns empty bookkeeper
export const organizationUserByIdSelector =
  (id: ?string): SelectorType<User> =>
  (state) =>
    state.organizationUser.find((_) => _.id === id) || UserFactory();

// finds client by id or returns empty client
export const clientByIdSelector =
  (id: ?string): SelectorType<User> =>
  (state) =>
    state.organizationUser.find((_) => _.id === id) || ClientFactory();

export const currentUserSelector: SelectorType<User> = selector(
  userGUIDSelector,
  organizationUsersSelector,
  (userId: string, users: UserList) => users.find((_) => _.id === userId) || UserFactory(),
);

export const organizationEnhancedUsersSelector: SelectorType<EnhancedUserList> = selector(
  organizationUsersSelector,
  currentCompanySelector,
  (users: UserList, companyId: string): EnhancedUserList =>
    List(
      users
        .filter((user) => ['accountant', 'limited-accountant', 'restricted-accountant'].includes(user.role))
        .map((user) => {
          // pure 'Record -> another Record' transform, find a way to do it better
          const enhancedUser = { ...user.toJS(), assigned: user.companies_id.has(companyId) };
          return EnhancedUserFactory(enhancedUser);
        }),
    ),
);

export const clientUserSelector: SelectorType<UserList> = selector(organizationUsersSelector, (users: UserList) =>
  users.filter((user) =>
    ['user', 'confidential-user', 'restricted-user', 'confidential-restricted-user', 'supplier'].includes(user.role),
  ),
);

export const bookkeeperUserSelector: SelectorType<UserList> = selector(organizationUsersSelector, (users: UserList) =>
  users.filter((user) => ['accountant', 'limited-accountant', 'restricted-accountant'].includes(user.role)),
);
