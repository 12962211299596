// @flow
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { omit, set } from 'lodash/fp';
import {
  textractTableFieldsMappingSelector,
  textractFieldsMappingUniqueSelector,
  textractLineItemsBoundTableHeadersSelector,
  textractFirstTablePageSelector,
  learnDocumentExtractedTableFieldsAction,
  textractCoordsTableByPageSelector,
  textractLineItemsPolygonsSelector,
} from 'domain/textract';
import { jeMatchedLinesSelector } from 'domain/journal';
import { TextractCanvas } from './index';
import { TextractFieldBindings } from 'components/TextractBindings/container';
import { TextractArrows } from '../TextractArrows';
import { Map, List } from 'immutable';
import { type TextractBlock } from 'domain/textract/types.js.flow';
import DragItem from './components/DragItem';

export type TTextractPolygonPartial = {|
  id: $PropertyType<TextractBlock, 'id'>,
  BlockType: $PropertyType<TextractBlock, 'BlockType'>,
  Geometry: $PropertyType<TextractBlock, 'Geometry'>,
  row: $PropertyType<TextractBlock, 'row'>,
|};

type Props = {|
  width: number,
  height: number,
  scale: number,
  page: number,
  wrapper: ?HTMLElement,
|};

export const TextractTableContainer: React$StatelessFunctionalComponent<Props> = (props) => {
  const { width, height, scale, page, wrapper } = props;
  const dispatch = useDispatch();
  const [activeBinding, setActiveBinding] = useState(null);
  const [boundFields, setBoundFields] = useState({});
  const [isDirty, setDirty] = useState(false);
  const polygons = useSelector(textractLineItemsPolygonsSelector(page)); // converted
  const mappings = useSelector(textractTableFieldsMappingSelector); // converted
  const mappingFields = useSelector(textractFieldsMappingUniqueSelector); // is ok
  const mappingHeaders = useSelector(textractLineItemsBoundTableHeadersSelector); // is ok
  const matchedLines: Map<number, List<Map<String, string>>> = useSelector(jeMatchedLinesSelector);
  const tablePage: ?number = useSelector(textractFirstTablePageSelector); // table_coords selector. Not changed.
                                                                          // identifies 1 page to show field bindings on
  const coords = useSelector(textractCoordsTableByPageSelector(page));

  const matchedLinesFlat = matchedLines
    .reduce((acc, v) => {
      const flatValue = v.flatten();
      // 1 - line, 0 - status
      return acc.set(flatValue.get(1), flatValue.get(0));
    }, new Map())
    .filter((v) => v);

  const learnedFields = useMemo(
    () =>
      mappingHeaders?.reduce((acc, { key, header }) => {
        if (Array.isArray(mappingFields)) {
          // if mappingHeaders is not among current fields, we add empty object as binding
          // which results in further learn request payload corruption
          const field = mappingFields?.filter((field) => field.name === header)[0];
          const mapping = mappings?.filter((mapping) => mapping.name === key)[0];
          if (field) {
            field.mandatory = mapping?.metadata.mandatory;
            acc[key] = field;
          }
        }
        return acc;
      }, {}),
    [mappingHeaders, mappingFields, mappings],
  );

  useEffect(() => {
    setBoundFields(learnedFields);
  }, [learnedFields]);

  useEffect(() => {
    // change binding should trigger learn api invocation
    if (isDirty) {
      dispatch(learnDocumentExtractedTableFieldsAction(boundFields));
    }
  }, [boundFields, isDirty, dispatch]);

  const bindField = (index: number, mandatory: boolean) => {
    setBoundFields((prevState) => {
      switch (true) {
        // selected the same field to bind to
        case prevState[activeBinding]?.name === mappingFields[index].name:
          switch (true) {
            // deselect currently selected option as option clicked is selected
            case prevState[activeBinding]?.mandatory === mandatory:
            // same as previous but active binding mandatory is initially undefined and can not be
            // compared to false
            case typeof prevState[activeBinding]?.mandatory === 'undefined' && typeof mandatory === 'undefined': {
              return omit([activeBinding])(prevState);
            }

            // mandatory field change only
            default:
              return set([activeBinding], { ...mappingFields[index], mandatory })(prevState);
          }

        // selected another field to bind to
        case prevState[activeBinding]?.name !== mappingFields[index].name:
          return set([activeBinding], { ...mappingFields[index], mandatory })(prevState);
      }
    });
    setDirty(true);
  };

  const boundCells = mappings
    .filter((field) => {
      return Object.keys(boundFields).includes(field.name);
    })
    .map((cell) => cell.id);

  // form state for active dropdown
  const [selectedIndex, mandatory, boundFieldsIndecis] = useMemo(() => {
    const index = Array.isArray(mappingFields)
      ? mappingFields?.findIndex((field) => field.name === boundFields[activeBinding]?.name)
      : null;
    const boundFieldsIndecis = Array.isArray(mappingFields)
      ? Object.values(boundFields).reduce((acc, { name }) => {
          const i = mappingFields?.findIndex((field) => field.name === name);
          if (Number.isFinite(i)) acc.push(i);
          return acc;
        }, [])
      : [];

    return Number.isFinite(index) && index >= 0
      ? [index, boundFields[activeBinding]?.mandatory || false, boundFieldsIndecis]
      : [null, boundFields[activeBinding]?.mandatory || false, boundFieldsIndecis];
  }, [activeBinding, boundFields, mappingFields]);

  return (
    <>
      <TextractCanvas
        matchedLines={matchedLinesFlat}
        boundCells={boundCells}
        width={width * scale}
        height={height}
        polygons={polygons}
        page={page}
      />
      <TextractArrows
        width={width * scale}
        scale={scale}
        height={height}
        page={page}
        wrapper={wrapper}
      />
      {page === tablePage ? (
        <TextractFieldBindings
          onClick={setActiveBinding}
          width={width * scale}
          height={height}
          fields={mappingFields}
          mappings={mappings}
          onSelect={bindField}
          selectedFieldIndex={selectedIndex}
          mandatory={mandatory}
          disabledFields={boundFieldsIndecis}
          boundCells={boundCells}
        />
      ) : null}
      {coords && <DragItem width={width * scale} height={height} page={page} coords={coords} mappings={mappings} />}
    </>
  );
};
