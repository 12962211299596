import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import { alpha } from '@mui/material';

export const SelectContainer = styled(Box)(({ theme }) => ({
  minWidth: 5,
  minHeight: 5,
  height: 50,
  backgroundColor: alpha(theme.palette.primary.light, 0.3),
  border: `1px solid ${theme.palette.primary.main}`,
  zIndex: 1,
  position: 'absolute',
  resize: 'both',
  overflow: 'hidden',
  display: 'flex',
}));
