// @flow
import type { List } from 'immutable';
import type { EnhancedUser as EnhancedUserType } from 'domain/organizationUser/types.js.flow';
import type { ERPsType } from 'domain/settings/types';

export function checkErpConnected(erps: ERPsType): boolean {
  return !erps.some((v) => v.get('status') === 'connected');
}

export function checkUsers(users: List<EnhancedUserType>, companyId: ?string): boolean {
  if (users.size < 1) return true;
  const accountants = users.filter(
    (user) =>
      ['accountant', 'limited-accountant', 'restricted-accountant'].includes(user.role) &&
      user.companies_id.indexOf(companyId) + 1 > 0,
  );
  const clients = users.filter((user) => user.role === 'user');
  return !(accountants.size + clients.size);
}

export function checkErps(erps: ERPsType): boolean {
  return !erps.some((v) => ['connected', 'connecting'].includes(v.get('status')));
}
