/* @flow */
import toast, { type TToastType } from 'components/Toast';

const messages = {
  remove: {
    id: 'documents.bulkActions.remove',
    defaultMessage: '{success} out of {total} documents were removed',
  },
  signSuccess: {
    id: 'documents.bulkActions.addSignatures',
    defaultMessage: '{success} out of {total} documents were submitted for signing',
  },
  signFailed: {
    id: 'documents.bulkActions.documentSigningFailed',
    defaultMessage: 'Document signing failed',
  },
  changeTypeSuccess: {
    id: 'documents.bulkActions.addTags',
    defaultMessage: '{success} out of {total} documents were modified',
  },
  bulkAcceptSuccess: {
    id: 'documents.bulkActions.published',
    defaultMessage: '{success} of {total} documents were scheduled for Publishing',
  },
  printFetchError: {
    id: 'selectedPanel.toast.print.fetch.error',
    defaultMessage: 'We encountered a printing issue, please try again',
  },
};

export type ToastActionType = $Keys<typeof messages>;

export default messages;

export function makeTost(translate: Function) {
  return (action: ToastActionType, args: Object = {}, type: TToastType = 'info') => {
    toast[type](translate(messages[action], args));
  };
}
