// @flow
import { type OrganizationType } from 'domain/organization';
import { ROLES } from 'domain/env/helpers';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const getNumberWithDash = (number: string | number) => `${number}`.replace(/^.{3}/, (m) => `${m}-`);

const removeDashFromString = (number: string) => number.replace(/-/, '');

// ** info-main form for company and organization */

export const prepareToForm = (data: OrganizationType) =>
  data.update('whatsapp_phone', (w) => w && getNumberWithDash(w));

export const prepareToSubmit = (data: OrganizationType) =>
  data.update('whatsapp_phone', (w) => w && removeDashFromString(w));

export const roleTranslation = (entry) => {
  const { role, limited, restricted, admin } = entry;
  const userRole = admin ? 'administrator' : role;
  switch (userRole) {
    case 'administrator':
      return 'configurations.clients.list.role.administrator';
    case ROLES.accountant: {
      switch (true) {
        case limited && restricted:
          return 'forms.organization.users.position.LimitedRestrictedBookkeeper';
        case limited:
          return 'forms.organization.users.position.limitedBookkeeper';
        case restricted:
          return 'forms.organization.users.position.restrictedBookkeeper';
        default:
          return 'forms.organization.users.position.bookkeeper';
      }
    }
    case ROLES.user: {
      switch (true) {
        case restricted:
          return 'form.organization.users.edit.restricted';
        default:
          return 'form.organization.users.edit.regular';
      }
    }
    case ROLES['confidential-user']: {
      switch (true) {
        case restricted:
          return 'form.organization.users.edit.confidential-restricted';
        default:
          return 'form.organization.users.edit.confidential';
      }
    }
    case ROLES.supplier:
      return 'form.organization.users.edit.supplier';
  }
};

export const getUserDisplayName = (user) => {
  const { role, first_name, last_name, display_name, admin } = user;
  const name = `${typeof first_name === 'string' ? `${first_name} ` : ''}${last_name || ''}`;
  const userRole = admin ? 'administrator' : role;
  const createdName = (
    <>
      {name} (
      <FormattedMessage id={roleTranslation(user)} defaultMessage={userRole} />)
    </>
  );

  return display_name || createdName;
};
