// @flow
import { Record } from 'immutable';

export const TEXTRACT_MATCH_STATUSES = {
  MATCH: 'match',
  PARTIAL_MATCH: 'partial_match',
  HEADER: 'header',
  DEFAULT: 'default',
};

export const MatchingColorsMap = {
  [TEXTRACT_MATCH_STATUSES.MATCH]: {
    border: 'rgba(19, 157, 41, 1)',
    fill: 'rgba(19, 157, 41, 0.12)',
  },
  [TEXTRACT_MATCH_STATUSES.PARTIAL_MATCH]: {
    border: 'rgba(211, 47, 47, 1)',
    fill: 'rgba(211, 47, 47, 0.12)',
  },
  [TEXTRACT_MATCH_STATUSES.DEFAULT]: {
    border: 'rgba(211, 47, 47, 1)',
    fill: 'rgba(211, 47, 47, 0.12)',
  },
  [TEXTRACT_MATCH_STATUSES.HEADER]: {
    border: 'rgba(204, 204, 204, 1)',
    fill: 'rgba(204, 204, 204, 0.12)',
  },
};

export const MatchinArrowsAnchors = {
  documentElementIdFactory: (id: string) => `element-${id}`,
  textractVisibleAnchorElementIdFactory: (id: string) => `element-${id}-child`,
  journalEntryElementIdFactory: (line: string) => `matcher-${line}`,
};

export const ArrowTargetFactory = (targetId: string, color: string, rtl: boolean) => {
  const [targetAnchor, sourceAnchor] = rtl ? ['left', 'right'] : ['right', 'left'];

  const ArrowStyleRecord = new Record({
    strokeColor: 'transparent',
    strokeWidth: 1,
  });

  const ArrowTargetRecord = new Record({
    targetId: null,
    targetAnchor: 'right',
    sourceAnchor: 'left',
    style: ArrowStyleRecord(),
  });

  return ArrowTargetRecord({
    targetId,
    targetAnchor,
    sourceAnchor,
    style: ArrowStyleRecord({ strokeColor: color }),
  });
};

export type TTextractMatchStatuses = $Values<typeof TEXTRACT_MATCH_STATUSES>;

export type TMatchingColorsMap = {|
  [key: TTextractMatchStatuses]: {|
    border: string,
    fill: string,
  |},
|};
