// @flow
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { companiesByIdSelector, goBackToListAction } from 'domain/companies';

import { DrawerExpanded, DrawerHeader, DrawerContent, DrawerFooter } from 'components/mui/Layouts/components/Drawer';

import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import Tooltip from 'components/mui/Tooltip';
import SettingsLink from 'components/mui/Layouts/components/Drawer/SettingsLink';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

type TLayoutCompanies = {
  children: React$Node,
  open: boolean,
};

const LayoutCompanyDrawer: React$StatelessFunctionalComponent<TLayoutCompanies> = ({ children, open }) => {
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const company = useSelector(companiesByIdSelector).get(companyId);

  const onClickHome = () => {
    dispatch(goBackToListAction(company.id));
  };

  return (
    <DrawerExpanded variant="permanent" open={open}>
      <DrawerHeader open={open}>
        <Tooltip t={{ id: 'company.companyList', defaultMessage: 'Company List' }} placement="right">
          <IconButton sx={{ ml: open ? 1 : 0 }} onClick={onClickHome} color="inherit">
            <HomeOutlinedIcon />
          </IconButton>
        </Tooltip>
      </DrawerHeader>
      <DrawerContent>{children}</DrawerContent>
      <DrawerFooter>
        <List sx={{ boxShadow: '0px 10px 5px rgb(0 0 0 / 20%), 0px -2px 5px rgb(0 0 0 / 20%)' }}>
          <SettingsLink expanded={open} />
        </List>
      </DrawerFooter>
    </DrawerExpanded>
  );
};

export default LayoutCompanyDrawer;
