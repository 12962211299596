// @flow
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { generatePath } from 'react-router-dom';

// in case of IDp initiated login app starts at /idp route
// redirectSignIn config value must be set to domain/idp in this only case
const IDpRoute = generatePath(ROUTES_PATH.AUTH_IDP_LOGIN.absolute);
const regularLoginRoute = generatePath(ROUTES_PATH.AUTH_LOGIN.absolute);
const signInRedirectUrl =
  window.location.pathname === IDpRoute
    ? window.location.origin + IDpRoute
    : window.location.origin + regularLoginRoute;
export default {
  domain: process.env.REACT_APP_COGNITO_HOSTED_UI_DOMAIN,
  scope: [
    'phone',
    'email',
    'openid',
    // "profile",
    // "aws.cognito.signin.user.admin"
  ],
  redirectSignIn: signInRedirectUrl,
  redirectSignOut: window.location.origin + regularLoginRoute,
  responseType: process.env.REACT_APP_COGNITO_RESPONSE_TYPE,
};
