/* @flow */

import type { BodyProps } from './index';

export default {
  contentPanel: {
    position: 'relative',
    width: 34,
    minWidth: 34,
    flexGrow: 1,
    zIndex: 20,
  },

  contentPanelHide: {
    display: 'none',
  },

  bodyBox: {
    display: 'flex',
    width: '100%',
    flexGrow: 1,
    overflow: 'auto',
  },

  colDirection: {
    flexDirection: 'column',
  },

  rowDirection: {
    flexDirection: ({ rtl }: BodyProps) => (rtl ? 'row-reverse' : 'row'),
  },

  docPreviewPortalBase: {
    height: '100%',
    width: 'fit-content',
    zIndex: 1,
    position: 'absolute',
    boxShadow: '0px 4px 15px rgba(79, 116, 209, 0.4)',
  },

  docPreviewPortal: {
    extend: 'docPreviewPortalBase',
    left: 0,
  },

  docPreviewPortalRtl: {
    extend: 'docPreviewPortalBase',
    right: 0,
  },
};
