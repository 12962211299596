/* @flow */
import React, { memo } from 'react';
import { type NamedCol } from 'components/Tables/layout/grid/VisibilityColsDropdown/VisibilityColsDropdown';

import MenuItem from '@mui/material/MenuItem';
import { FormControlLabelSwitch } from 'components/mui/Form/FormControl/FormControlLabelSwitch';
import Stack from '@mui/material/Stack';
import SwitchBase from 'components/mui/Form/Switch/SwitchBase';
import { SortableHandle } from 'react-sortable-hoc';

import PushPinIcon from '@mui/icons-material/PushPin';
import ReorderIcon from '@mui/icons-material/Reorder';

import { styled } from '@mui/material/styles';

type Props = {
  column: NamedCol,
  isPinned: boolean,
  checked: boolean,
  isDisabled?: boolean,
  onChange: (c: string) => void,
};

type ColOptionType = React.StatelessFunctionalComponent<Props>;

const HandleIcon = styled(ReorderIcon)(() => ({
  cursor: 'ns-resize',
}));

const HandleComponent = SortableHandle(HandleIcon);

const ColumnOption: ColOptionType = ({ column, isPinned, checked, isDisabled, onChange }: Props) => (
  <MenuItem component="div" onClick={onChange} disabled={isDisabled} sx={{ backgroundColor: 'common.white' }}>
    <FormControlLabelSwitch
      checked={checked}
      control={<SwitchBase />}
      disabled={isDisabled}
      label={column.title}
      sx={{ flexGrow: 1 }}
    />
    <Stack direction="row" alignItems="center" spacing={0.5} ml="auto">
      {isPinned && <PushPinIcon sx={{ fontSize: 16 }} color="primary" />}
      <HandleComponent />
    </Stack>
  </MenuItem>
);

export default memo(ColumnOption);
