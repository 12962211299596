/* @flow */
import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { companyNameSelector, companyPictureSelector } from 'domain/requestTransaction';
import elements from 'components/elements';
// white-labeling
import type { TLabelOptions } from 'labels/type.js.flow';

import file from './file.svg';

type Props = {
  classes: {|
    [key: string]: string,
  |},
  name: string,
  logo: string,
  isPreview: boolean,
  onChange: (s: boolean) => void,
  theme: { labelOptions: TLabelOptions },
};

const sheet = (theme) => ({
  header: {
    gridColumnStart: 1,
    gridColumnEnd: 3,
    background: theme.colors.primaryDark,
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
  },
  isPreview: {
    '& $logoWrapper': {
      '@media (min-width: 750px)': {
        flex: [0, 0, '33%'],
      },
    },
  },
  logoWrapper: {
    padding: [0, 0, 0, 30],
    '@media (max-width: 749px)': {
      position: 'absolute',
    },
  },
  logo: {
    display: 'block',
  },
  companyLogo: {
    height: 32,
    width: 'auto',
  },
  wrapper: {
    display: 'flex',
    flex: [1, 1, 'auto'],
    alignItems: 'center',
    padding: [0, 12],
    position: 'relative',
    maxWidth: '100%',
  },
  company: {
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    padding: [0, 65, 0, 50],
    overflow: 'hidden',
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    marginLeft: 16,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  switchView: {
    position: 'absolute',
    right: 12,
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 749px)': {
      '&:before': {
        content: '""',
        display: 'block',
        position: 'relative',
        width: 24,
        height: 24,
        background: `url(${file}) scroll no-repeat`,
      },
    },
    '& > span': {
      '@media (max-width: 749px)': {
        display: 'none',
      },
    },
  },
  switchLabel: {
    width: 32,
    height: 16,
    borderRadius: 8,
    background: '#8C9BA5',
    display: 'inline-block',
    marginLeft: 8,
    position: 'relative',
    transition: '0.3s background',
    '&:after': {
      content: '""',
      display: 'block',
      width: 12,
      height: 12,
      borderRadius: '50%',
      position: 'absolute',
      top: 2,
      right: 2,
      bottom: 2,
      transition: '0.3s',
      transform: 'translateX(-16px)',
      background: '#FFF',
    },
  },
  switch: {
    position: 'absolute',
    opacity: '0.01',
    '&:checked + $switchLabel': {
      background: '#fff',
      '&:after': {
        transform: 'translateX(0)',
        background: theme.colors.primaryLight,
      },
    },
  },
});

function Header({ classes, name, logo, theme: { labelOptions }, ...rest }: Props) {
  return (
    <header className={cx(classes.header, { [classes.isPreview]: rest.isPreview })}>
      <div className={classes.logoWrapper}>
        <img className={classes.logo} alt="logo" {...labelOptions.whiteLogo} />
      </div>
      <div className={classes.wrapper}>
        <div className={classes.company}>
          <img src={logo} className={classes.companyLogo} alt="logo company" />
          <h2 data-element={elements.je.request.companyName} className={classes.title}>
            {name}
          </h2>
        </div>
        <div className={classes.switchView}>
          <FormattedMessage id="requestTransactions.showDocument" defaultMessage="Show document" />
          <input
            type="checkbox"
            id="is-preview"
            className={classes.switch}
            checked={rest.isPreview}
            onChange={(e) => rest.onChange(e.currentTarget.checked)}
            data-element={elements.je.request.docPreview}
          />
          <label htmlFor="is-preview" className={classes.switchLabel} />
        </div>
      </div>
    </header>
  );
}

const mapStateToProps = (state) => ({
  name: companyNameSelector(state),
  logo: companyPictureSelector(state),
});

export default compose(connect(mapStateToProps), withStyles(sheet))(Header);
