// @flow
import c from '../../constatnt';

export default (theme) => ({
  container: {
    position: 'relative',
  },
  row: {
    '&:first-child': {
      '& $col': {
        backgroundColor: theme.colors.greyLighter,
      },
    },
    '&:not(:first-child) $col:hover': {
      '&:hover': {
        backgroundColor: '#F8F8F8',
      },
    },
  },
  col: {
    borderBottom: '1px solid #DEE2E6',
    minWidth: 170,
    width: '50%',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: -1,
      left: -1,
      bottom: 2,
      borderRight: '1px solid #DEE2E6',
      zIndex: 0,

      '.rtl &': {
        left: 'auto',
        right: -1,
      },

      '.ltr &': {
        left: -1,
      },
    },
  },
  activeRow: {},
  hovered: {},
  lineItems: {},
  removeBtn: {
    padding: 4,
    opacity: 0,
    transition: 'opacity 0.3s',
  },
});
