import { red, orange } from '@mui/material/colors';

export default () => ({
  notSigned: {
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    height: 31,
    background: red[100],
    width: 'fit-content',
    minWidth: 150,
    border: `1px solid ${red[400]}`,
    '&[disabled]': {
      cursor: 'not-allowed',
    },
    '#doc-preview &': {
      left: 0,
      transformOrigin: 'top left',
      transform: 'rotate(-90deg) translate(-100%, 0%)',
      borderRadius: [0, 0, 3, 3],
      boxShadow: '-3px 2px 3px -1px #00000040',
    },

    '#doc-preview-rtl &': {
      right: 0,
      transformOrigin: 'bottom right',
      transform: 'rotate(-90deg) translate(30px, 0%)',
      borderRadius: [3, 3, 0, 0],
      boxShadow: '-3px -2px 3px -1px #00000040',
    },
  },
  pending: {
    background: orange[100],
    border: `1px solid ${orange[500]}`,
  },

  widgetsContainer: {
    pointerEvents: 'none',
    '#doc-preview &': { alignItems: 'flex-start', left: 0 },
    '#doc-preview-rtl &': { alignItems: 'flex-end', right: 0 },
    '.rtl #doc-preview &': { alignItems: 'flex-start', left: 0 },
    '.rtl #doc-preview-rtl &': { alignItems: 'flex-end', right: 0 },
  },
});
