// @flow
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { rtlEnable, localeSelector, hasUserRequireDataSelector } from 'domain/env';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';

import SmartLook from 'components/SmartLook';
import OneSignalInit from 'components/OneSignalInit';
import { AppStatusBar, ConnectionStatus, DiskSpaceStatus } from 'components/AppStatusBar';
import CssBaseline from '@mui/material/CssBaseline';
import MetadataManager from 'components/MetadataManager';

import 'react-toastify/dist/ReactToastify.css';
import 'lib/toast.css';

const mapStateToProps = (state) => ({
  direction: rtlEnable(state) ? 'rtl' : 'ltr',
  locale: localeSelector(state),
  hasUserRequireData: hasUserRequireDataSelector(state),
});

const App = () => {
  const { direction, locale, hasUserRequireData } = useSelector(mapStateToProps);

  useEffect(() => {
    if (document.body) {
      document.body.setAttribute('class', direction);
      document.body.setAttribute('dir', direction);
    }

    moment.locale(locale);
  }, [locale, direction]);

  return (
    hasUserRequireData && (
      <>
        <CssBaseline />
        <Outlet />
        <ToastContainer
          autoClose={3000}
          rtl={direction === 'rtl'}
          newestOnTop
          pauseOnHover
          hideProgressBar
          position={direction === 'rtl' ? 'top-left' : 'top-right'}
        />
        <AppStatusBar />
        <ConnectionStatus />
        <DiskSpaceStatus />
        <SmartLook />
        <OneSignalInit />
        <MetadataManager />
      </>
    )
  );
};

export default App;
