import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';

const CheckboxSetBase = ({ items, checked, onChange, ListProps = {}, ListItemProps = {}, CheckboxProps = {} }) => {
  return (
    <List {...ListProps}>
      {items.map(({ label, value }) => {
        const labelId = `list-item-${value}-${label}`;

        return (
          <ListItem
            {...ListItemProps}
            key={value}
            role="listitem"
            button
            onClick={(e) => {
              onChange(e, value);
            }}
          >
            <ListItemIcon>
              <Checkbox
                {...CheckboxProps}
                checked={checked.indexOf(value) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{
                  'aria-labelledby': labelId,
                }}
              />
            </ListItemIcon>
            <ListItemText id={labelId} {...(label.primary ? label : { primary: label })} />
          </ListItem>
        );
      })}
      <ListItem />
    </List>
  );
};

export default CheckboxSetBase;
