// @flow
export const handleImport = (importPromise) =>
  new Promise((resolve, reject) => {
    importPromise
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        window.location.reload(true);
        reject(error);
      });
  });
