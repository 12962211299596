// @flow
import React, { useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';

import Box from '@mui/material/Box';

import { type TextractPolygon } from 'domain/textract/types.js.flow';

type Props = {
  width: number,
  height: number,
  page: number,
  coords: Array<TextractPolygon>,
  mappings: Array<{|
    name: string,
    bindingCell: {
      [key: number]: {|
        X: number,
        Y: number,
      |},
    },
    id: string,
    metadata: {
      mandatory: boolean,
    },
  |}>,
};

const cropAndMerge = (canvases, width, height, callback) => {
  const buffer = document.createElement('canvas');
  const bufferCanvasContext = buffer.getContext('2d');

  buffer.width = width * 0.25;
  buffer.height = height * 0.25;

  canvases.forEach(({ canvas, offsetX, offsetY, width: imgWidth, height: imgHeight }) => {
    bufferCanvasContext.drawImage(canvas, offsetX, offsetY, imgWidth, imgHeight, 0, 0, buffer.width, buffer.height);
  });

  callback(buffer.toDataURL());
};

const isCanvasReady = (canvas) => canvas !== null && !!canvas?.offsetWidth && !!canvas?.offsetHeight;

const DragItem: React$StatelessFunctionalComponent<Props> = ({ width, height, page, coords, mappings }) => {
  const [previewImg, setPreviewImg] = useState('');
  const textractCanvasId = `textract-${page}`;
  const pdfPageContainerId = `pdfPage-${page}`;

  const [, drag, preview] = useDrag({
    item: {
      type: 'extractLines',
      name: 'textract',
    },
  });

  const textractCanvas = document.getElementById(textractCanvasId);
  const pdfCanvas = document.getElementById(pdfPageContainerId)?.getElementsByTagName('canvas')[0];

  useEffect(() => {
    if (previewImg.length) {
      const img = new Image();
      img.src = previewImg;
      preview(img);
    }
  }, [previewImg, preview]);

  useEffect(() => {
    if (isCanvasReady(pdfCanvas) && isCanvasReady(textractCanvas) && !previewImg.length) {
      const pdfCanvasWidth = pdfCanvas.getAttribute('width') || width;
      const pdfCanvasHeight = pdfCanvas.getAttribute('height') || height;

      cropAndMerge(
        [
          {
            canvas: pdfCanvas,
            offsetX: pdfCanvasWidth * coords[0]?.X,
            offsetY: pdfCanvasHeight * coords[0]?.Y,
            width: pdfCanvasWidth * (coords[1]?.X - coords[0]?.X),
            height: pdfCanvasHeight * (coords[2]?.Y - coords[0]?.Y),
          },
          {
            canvas: textractCanvas,
            offsetX: width * coords[0]?.X,
            offsetY: height * coords[0]?.Y,
            width: width * (coords[1]?.X - coords[0]?.X),
            height: height * (coords[2]?.Y - coords[0]?.Y),
          },
        ],
        width * (coords[1]?.X - coords[0]?.X),
        height * (coords[2]?.Y - coords[0]?.Y),
        setPreviewImg,
      );
    }
  }, [pdfCanvas, textractCanvas, width, height, coords, previewImg.length]);

  return (
    <Box ref={drag}>
      {mappings.map(({ bindingCell, id }) => (
        <Box
          key={id}
          width={width * (bindingCell[1].X - bindingCell[0].X)}
          height={height * (bindingCell[3].Y - bindingCell[0].Y)}
          position="absolute"
          top={height * bindingCell[0].Y}
          left={width * bindingCell[0].X}
          zIndex={3}
          sx={{
            cursor: 'move',
          }}
        />
      ))}
    </Box>
  );
};

export default DragItem;
