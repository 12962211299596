// @flow
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import Menu from '@mui/material/Menu';
import MenuItem, { type TMenuItem } from 'pages/company/DocumentContextMenu/components/MenuItem';

type TMuiContextMenu = {
  anchor: {
    anchorEl?: HTMLElement,
    anchorReference?: 'anchorPosition',
    anchorPosition?: {
      top: number,
      left: number,
    },
  },
  onClose: () => void,
  options: Array<TMenuItem>,
  open: Boolean,
  getDocumentLinkedCountByContextMenu?: () => void,
};

const MuiContextMenu: React$StatelessFunctionalComponent<TMuiContextMenu> = ({
  open,
  anchor,
  onClose,
  options,
  getDocumentLinkedCountByContextMenu,
}) => {
  const handleClick = useCallback(
    (event, { action }) => {
      event.stopPropagation();
      event.preventDefault();

      action();
      onClose();
    },
    [onClose],
  );

  const items = useMemo(() => options.filter((o) => !o.hidden), [options]);

  useLayoutEffect(() => {
    if (typeof getDocumentLinkedCountByContextMenu === 'function') {
      getDocumentLinkedCountByContextMenu();
    }
  }, [getDocumentLinkedCountByContextMenu]);

  return (
    <Menu
      open={open}
      onClose={onClose}
      disableRestoreFocus
      MenuListProps={{ disablePadding: true, autoFocus: true }}
      transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      {...(anchor || {})}
    >
      {items.map((entry) => (
        <MenuItem key={entry.id} entry={entry} onClick={handleClick} />
      ))}
    </Menu>
  );
};

export default MuiContextMenu;
