// @flow
import * as React from 'react';
import Item from '../item__legacy';
import type { ChatUser } from 'domain/chat/types.js.flow';
import { type DocumentsType } from 'domain/documents';
import { DOCUMENT } from 'components/DnDItem__legacy';
import type { Map, RecordOf } from 'immutable';

type Props = {
  to: string,
  onClick: (e: MouseEvent, d: DocumentsType) => void,
  onPreview: ({ [key: string]: string }) => void,
  onContextMenu: (event: SyntheticMouseEvent<HTMLDivElement>, document: DocumentsType) => void,
  previewSrc: string,
  openNotes: (e: SyntheticMouseEvent<HTMLElement>, d: DocumentsType) => void,
  readOnly: boolean,
  hasUnread: boolean,
  selected: boolean,
  item: DocumentsType,
  className: string,
  locale: 'en' | 'hb',
  isAllowSignForCompany?: boolean,
  readOnlyDrag?: boolean,
  onShowLinked?: () => void,
  selectedDocuments: ?Set<string>,
  srcType: string,
  DragSource: React$Node,
  onBeginDrag?: (string) => void,
  usersById: Map<string, RecordOf<ChatUser>>,
  getIndicateWarningByDoc: (doc: Document) => boolean,
  setIdDraggedDocument?: (string | null) => void,
  isAllowNotes?: boolean,
};

class DragItem extends React.PureComponent<Props, {}> {
  previewImgRef: ?HTMLDivElement;

  onClick = (e: MouseEvent) => {
    const { item, onClick } = this.props;
    onClick(e, item);
  };

  onContextMenu = (e: MouseEvent) => {
    const { item, onContextMenu } = this.props;
    onContextMenu(e, item);
  };

  onOpenNotes = (e: MouseEvent) => {
    const { item, openNotes } = this.props;
    openNotes(e, item);
  };

  onDragOut = (event: SyntheticDragEvent<HTMLElement>, doc) => {
    const { setIdDraggedDocument } = this.props;
    if (setIdDraggedDocument) setIdDraggedDocument(null);
    if (event) event.dataTransfer.setData('docFromLinked', JSON.stringify(doc));
  };

  onBeginDrag = () => {
    const { item, setIdDraggedDocument, onBeginDrag } = this.props;
    if (setIdDraggedDocument) setIdDraggedDocument(item.documentID);
    const { linkedID } = item.linkid ? this.getLinkedParams(item.linkid) : {};

    if (linkedID && onBeginDrag) {
      onBeginDrag(item.linkid);
    }
  };

  onEndDrag = () => {};

  getLinkedParams = (linkid) => {
    const { srcType } = this.props;
    return srcType === DOCUMENT ? { linkedID: [linkid] } : { linkid };
  };

  getDndSourceParams = ({ documentID, linkid }: DocumentsType) => {
    const linkedData = linkid ? this.getLinkedParams(linkid) : {};
    const docIds = !linkedData.linkedID ? { documentID: [documentID] } : {};
    return { ...docIds, ...linkedData };
  };

  render() {
    const {
      to,
      onPreview,
      previewSrc,
      readOnly,
      hasUnread,
      selected,
      item,
      className,
      locale,
      isAllowSignForCompany,
      readOnlyDrag,
      onShowLinked,
      DragSource,
      selectedDocuments,
      usersById,
      getIndicateWarningByDoc,
      isAllowNotes = true,
    } = this.props;

    return (
      <DragSource
        id={item.documentID}
        params={this.getDndSourceParams(item)}
        isDragging
        className={className.dragContainer}
        onBeginDrag={this.onBeginDrag}
        onEndDrag={this.onEndDrag}
        readOnly={readOnlyDrag}
        withMinimizedPreview
      >
        <Item
          onDragStart={this.onDragOut}
          to={to}
          onClick={this.onClick}
          onPreview={onPreview}
          onContextMenu={this.onContextMenu}
          document={item}
          className={className.docInList}
          previewSrc={previewSrc}
          openNotes={this.onOpenNotes}
          locale={locale}
          readOnly={readOnly}
          hasUnread={hasUnread}
          linkedAsSinglePage
          selected={selected}
          isAllowSignForCompany={isAllowSignForCompany}
          onShowLinked={onShowLinked}
          selectedDocumentsSize={selectedDocuments && selectedDocuments.size}
          usersById={usersById}
          getIndicateWarningByDoc={getIndicateWarningByDoc}
          isAllowNotes={isAllowNotes}
        />
      </DragSource>
    );
  }
}

export default DragItem;
