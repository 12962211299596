// @flow
import React from 'react';
import useLocaleSelect, { type TLocaleValue } from 'components/mui/LocaleSelect/useLocaleSelect';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/material';

type TLocaleSelect = {
  flagOnly?: boolean,
};

const Toggle = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isOpen', // exclude prop from spread to component
})(({ theme, isOpen }) => ({
  borderRadius: 4,
  backgroundColor: grey[100],
  borderColor: isOpen ? theme.palette.primary.main : grey[300],
  '&:hover': {
    backgroundColor: grey[200],
  },
}));

const LocaleSelect: React$StatelessFunctionalComponent<TLocaleSelect> = ({ flagOnly = false }) => {
  const { labelOptions } = useTheme();
  const popupState = usePopupState({ variant: 'popover', popupId: 'LocaleSelector' });
  const { options, currentLocale, onChangeLocale } = useLocaleSelect();

  const handleItemClick = (value: TLocaleValue) => {
    onChangeLocale(value);
    popupState.close();
  };

  if (!labelOptions.loginLangSelect) {
    return null;
  }

  return (
    <Box>
      {flagOnly ? (
        <IconButton {...bindTrigger(popupState)} sx={{ borderRadius: 2 }}>
          <img src={currentLocale.icon} alt={currentLocale.label} />
        </IconButton>
      ) : (
        <Toggle
          color="inherit"
          variant="outlined"
          size="small"
          {...bindTrigger(popupState)}
          isOpen={popupState.isOpen}
          endIcon={popupState.isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        >
          <img src={currentLocale.icon} alt={currentLocale.label} />
          <Typography variant="body2" ml={1}>
            {currentLocale.label}
          </Typography>
        </Toggle>
      )}
      <Menu
        {...bindMenu(popupState)}
        MenuListProps={{
          sx: { minWidth: 92 },
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            onClick={() => handleItemClick(option.value)}
            selected={option.value === currentLocale.value}
          >
            <img src={option.icon} alt={option.label} />
            <Typography variant="body2" ml={1}>
              {option.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default LocaleSelect;
