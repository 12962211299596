/* @flow */
import appStoreIcon from './icons/appStoreIcon.svg';
import googlePlayIcon from './icons/googlePlayIcon.svg';
import { makeStyles } from '@mui/styles';

const textStyle = {
  padding: 20,
  textAlign: 'center',
};

export default makeStyles((theme) => ({
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  logo: {
    flex: '0 0 55px',
    marginTop: 20,
    background: `url("${theme.labelOptions.extLogo.src}") scroll no-repeat`,
    width: '30%',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  },
  message: {
    flex: 0.8,
    fontSize: 50,
    ...textStyle,
    display: 'flex',
    alignItems: 'flex-end',
    fontWeight: 'bold',
    color: '#5A6872',
  },
  smallText: {
    fontSize: 32,
  },
  textContainer: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: 14,
    ...textStyle,
  },
  text: {
    flex: 1,
    padding: 20,
  },
  proposal: {
    textTransform: 'uppercase',
    color: theme.colors.primaryLight,
    flex: 0,
  },
  downloads: {
    flex: '0 0 40px',
    width: 274,
    display: 'flex',
    justifyContent: 'space-between',
  },
  appStore: {
    display: 'block',
    height: 42,
    width: 128,
    background: `url(${appStoreIcon}) center no-repeat`,
  },
  googlePlay: {
    display: 'block',
    height: 42,
    width: 128,
    background: `url(${googlePlayIcon}) center no-repeat`,
  },
  bottomText: {
    flex: 1,
    padding: [0, 20],
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    color: theme.colors.primaryDark,
  },
}));
