/* @flow */
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Set } from 'immutable';
import { useConfirm } from 'material-ui-confirm';

import toast from 'components/Toast';
import TagBox from 'pages/common/Dialog/DialogTagsManage/components/TagBox';
import Dialog from 'components/mui/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TagSuggestions from 'components/mui/Layouts/components/TagSuggestions';
import TagsSearch from '../components/TagsSearch';

import { documentMultipleUpdateTags, type DocumentsType } from 'domain/documents';
import { chatAllUsersByIdSelector } from 'domain/chat/chatSelector';
import { ROLES_FOR_CONFIRM } from 'pages/common/Dialog/DialogTagsManage';

type Props = {
  documents: Set<DocumentsType>,
  onCancel: () => void,
  open: boolean,
};

const getInitialTags = (documents) => {
  const documentsTags = documents.map((doc) => doc.tags.filter((tag) => tag[0] !== '_'));
  const allTags: Set<string> = Set.union(documentsTags);
  const commonTags = allTags.filter((tag) => documentsTags.every((docTags: Set<string>) => docTags.includes(tag)));
  const uniqueTags = allTags.filter((tag) => !commonTags.includes(tag));
  return { commonTags, uniqueTags, allTags };
};

const TagMultiple: React$StatelessFunctionalComponent<Props> = ({ documents, onCancel, open }) => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const { formatMessage } = useIntl();

  const [value, setValue] = useState('');
  const [commonTags, setCommonTags] = useState(Set());
  const [uniqueTags, setUniqueTags] = useState(Set());

  const users = useSelector(chatAllUsersByIdSelector);

  useEffect(() => {
    const { commonTags: common, uniqueTags: unique } = getInitialTags(documents);
    setCommonTags(common);
    setUniqueTags(unique);
  }, [documents]);

  const getUserForConfirm = useCallback(
    (tag: string) => {
      const user = users.get(tag);

      return user && ROLES_FOR_CONFIRM.includes(user.role) && user.restricted ? user : null;
    },
    [users],
  );

  const tagsToAdd = useMemo(() => {
    const { commonTags: initialCommonTags } = getInitialTags(documents);
    return commonTags.filter((tag) => !initialCommonTags.includes(tag));
  }, [commonTags, documents]);

  const tagsToRemove = useMemo(() => {
    const { allTags } = getInitialTags(documents);
    return allTags.filter((tag) => !commonTags.includes(tag) && !uniqueTags.includes(tag));
  }, [commonTags, uniqueTags, documents]);

  const isAddPossibility = useMemo(() => !commonTags.includes(value), [commonTags, value]);

  const sortedTags = useMemo(() => {
    const isUser = (tag: string) => +!!users.find((user) => user.userId === tag);
    const common = commonTags.sort((a, b) => isUser(b) - isUser(a));
    const unique = uniqueTags.sort((a, b) => isUser(b) - isUser(a));
    return { commonTags: common, uniqueTags: unique };
  }, [commonTags, uniqueTags, users]);

  const usersToAllowAccess = useMemo(
    () =>
      tagsToAdd.reduce((acc: Set<string>, tag: string) => {
        const user = getUserForConfirm(tag);

        return user ? acc.add(user.username) : acc;
      }, new Set()),
    [tagsToAdd, getUserForConfirm],
  );

  const usersToDenyAccess = useMemo(
    () =>
      tagsToRemove.reduce((acc: Set<string>, tag: string) => {
        const user = getUserForConfirm(tag);

        return user ? acc.add(user.username) : acc;
      }, new Set()),
    [tagsToRemove, getUserForConfirm],
  );

  const isAutoConfirm = usersToAllowAccess.size === 0 && usersToDenyAccess.size === 0;

  const onDeleteCommonTags = useCallback(
    (deleteTag: string) => {
      const tags = commonTags.filter((tag) => deleteTag !== tag);

      setCommonTags(tags);
    },
    [commonTags],
  );

  const onDeleteUniqueTags = useCallback(
    (deleteTag: string) => {
      const tags = uniqueTags.filter((tag) => deleteTag !== tag);

      setUniqueTags(tags);
    },
    [uniqueTags],
  );

  const handleInputChange = useCallback((value: string) => {
    setValue(value);
  }, []);

  const handleTagSelect = useCallback(
    (tag?: string) => {
      if (!tag || (typeof tag === 'string' && tag[0] === '_')) return;
      if (tag && !commonTags.includes(tag)) {
        setCommonTags(commonTags.add(tag));
        setUniqueTags(uniqueTags.remove(tag));
        setValue('');
      }
    },
    [commonTags, uniqueTags],
  );

  const onSubmit = useCallback(
    () =>
      new Promise((resolve, reject) => {
        dispatch(
          documentMultipleUpdateTags({
            resolve,
            reject,
            documentID: documents.map((doc) => doc.documentID),
            tagsToAdd,
            tagsToRemove,
          }),
        );
      })
        .then(({ success, total }) => {
          toast.success(
            formatMessage(
              {
                id: 'documents.bulkActions.addTags',
                defaultMessage: '{success} out of {total} documents were modified',
              },
              { success, total },
            ),
          );
          onCancel();
        })
        .catch(() => {
          toast.error(formatMessage({ id: 'documents.bulkActions.addTagsFailure', defaultMessage: 'Failed' }));
        }),
    [formatMessage, dispatch, tagsToAdd, tagsToRemove, onCancel, documents],
  );

  const handleSubmit = useCallback(() => {
    if (isAutoConfirm) {
      onSubmit();
    } else {
      confirm({
        title: formatMessage({
          id: 'confirm.userGainingDocumentAccess.title',
          defaultMessage: 'Access to document',
        }),
        description: (
          <>
            {usersToAllowAccess.size > 0 && (
              <Box mb={2}>
                {formatMessage(
                  {
                    id: 'confirm.userAllowDocumentAccess.placeholder',
                    defaultMessage: `This action will enable ${usersToAllowAccess.join(', ')} to view the document`,
                  },
                  {
                    usernames: <strong>{usersToAllowAccess.join(', ')}</strong>,
                  },
                )}
              </Box>
            )}
            {usersToDenyAccess.size > 0 && (
              <Box>
                {formatMessage(
                  {
                    id: 'confirm.userDenyDocumentAccess.placeholder',
                    defaultMessage: `This action will disable ${usersToDenyAccess.join(', ')} to view the document`,
                  },
                  {
                    usernames: <strong>{usersToDenyAccess.join(', ')}</strong>,
                  },
                )}
              </Box>
            )}
          </>
        ),
        confirmationButtonProps: {
          color: usersToDenyAccess.size > 0 ? 'error' : 'primary',
        },
      }).then(() => {
        onSubmit();
      });
    }
  }, [onSubmit, usersToAllowAccess, confirm, formatMessage, isAutoConfirm, usersToDenyAccess]);

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      title={formatMessage({ id: 'modal.document.tags.title', defaultMessage: 'Document Tags' })}
      maxWidth="sm"
      withActions={false}
      withContent={false}
      PaperProps={{ sx: { overflow: 'inherit' } }}
    >
      <DialogContent>
        <TagSuggestions
          InputComponent={TagsSearch}
          inputValue={value}
          onSelect={handleTagSelect}
          onInputChange={handleInputChange}
          prefixOutputWithHashtag={false}
          inputComponentProps={{ disabled: !isAddPossibility, onClick: handleTagSelect }}
        />
        <Stack direction="row" flexWrap="wrap" gap={0.5} mt={2} mb={1}>
          {sortedTags.commonTags.map((tag) => (
            <TagBox key={tag} tag={tag} onDelete={onDeleteCommonTags} />
          ))}
        </Stack>
        {sortedTags.uniqueTags.size > 0 && (
          <Stack direction="row" flexWrap="wrap" gap={0.5}>
            {sortedTags.uniqueTags.map((tag) => (
              <TagBox key={tag} tag={tag} onDelete={onDeleteUniqueTags} isUnique />
            ))}
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="text">
          {formatMessage({ id: 'button.cancel', defaultMessage: 'Cancel' })}
        </Button>
        <Button onClick={handleSubmit} disabled={!(tagsToAdd.size || tagsToRemove.size)}>
          {formatMessage({ id: 'button.save', defaultMessage: 'Save' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TagMultiple;
