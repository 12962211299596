// @flow
import React from 'react';
import { useSelector } from 'react-redux';

import { shouldHighlightAmountSelector } from 'domain/companies';
import { SYMBOL_FOR_EMPTY_CELL } from 'pages/company/grid/helpers';
import { useTheme } from '@mui/material';

const getDisplayValue = (shouldHighlight, valueFormatted) => {
  if (!valueFormatted) return SYMBOL_FOR_EMPTY_CELL;
  return shouldHighlight ? `(${valueFormatted})` : valueFormatted;
};
const Amount = ({ value, valueFormatted }) => {
  const { palette } = useTheme();

  const shouldHighlightAmount = useSelector(shouldHighlightAmountSelector);
  const shouldHighlight = shouldHighlightAmount && value < 0;

  return (
    <div
      style={{
        color: shouldHighlight ? palette.error.main : 'inherit',
        width: '100%',
        flip: 'false',
        direction: 'ltr',
      }}
    >
      {getDisplayValue(shouldHighlight, valueFormatted)}
    </div>
  );
};

export default Amount;
