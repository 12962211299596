// @flow
import React, { memo, useCallback, useContext, useRef } from 'react';
import ReactDOM from 'react-dom';
import useDocumentHook from 'pages/document/DocumentComponent/useDocumentHook';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { generatePath } from 'react-router-dom';
import usePreloadDocuments from './usePreloadDocuments';
import type { TDocumentComponent } from 'pages/document/DocumentComponent/types.js.flow';

import Page from 'pages/document/page';
import Protected from 'pages/document/protect';
import PdfViewerDocument from 'components/PdfViewer';
import ProgressBar from 'pages/document/components/ProgressBar';
import DocumentNavBar from 'pages/document/DocumentPagesNavigationBar';
import DocumentDuplicateAlert from 'pages/document/components/DocumentDuplicateAlert';
import ArcherContainerContext from 'pages/document/Body/ArcherContainerContext';
import SelectTableHeader from 'pages/document//components/SelectTableHeader';

import cx from 'classnames';
import useStyles from './sheet';

const PreviewPortalLeft = ({ children }) => {
  const el = useRef(null);
  const current = el.current || window.document.getElementById('doc-preview');
  if (!el.current) {
    el.current = current;
  }
  return el.current ? ReactDOM.createPortal(children, el.current) : null;
};

const PreviewPortalRight = ({ children }) => {
  const el = useRef(null);
  const current = el.current || window.document.getElementById('doc-preview-rtl');
  if (!el.current) {
    el.current = current;
  }
  return el.current ? ReactDOM.createPortal(children, el.current) : null;
};

const portals = {
  left: PreviewPortalLeft,
  right: PreviewPortalRight,
};

// TODO: need more refactoring, its just rewrite class component to hooks
const DocumentComponent: React$StatelessFunctionalComponent<TDocumentComponent> = ({
  children,
  data,
  width,
  startResizeWidth,
  documentsWidth,
  isFinancial,
  onDocRef,
  signatureStatus,
  dokkaToken,
  companyId,
  scale,
  onScale,
  showGridBetaLabel,
  isStatement,
  getIsDisabledZoomButton,
  onZoom,
  onReset,
  viewArrangement,
  currentSidebar,
  isSupplier,
  textractEnabledForDocument,
  isSelectTableMode,
  setSelectTableHeaderRef,
}: TDocumentComponent) => {
  const classes = useStyles({ isFinancial });
  const documentContainerRef = useRef(null);
  const { refreshScreen } = useContext(ArcherContainerContext);
  const { doctype, sourceID, documentID, versionID } = data;
  const {
    pageParams,
    passwordError,
    onDocumentLoadError,
    onPasswordEnter,
    isProtected,
    isPaid,
    isPageShow,
    isVisibleStamp,
    viewportWidth,
    stampMode,
    handleFinancialRef,
    handleViewportRef,
    handlePageRef,
    handleRotate,
    scrollViewRef,
    renderCancelable,
    loadCancelable,
    frameResizeScale,
    preparePolygonsForPage,
    setPasswordError,
    selectPage,
    onScroll,
    focusedPage,
    isPreviewOpen,
    setPreviewOpen,
    password,
  } = useDocumentHook({
    data,
    width,
    startResizeWidth,
    dokkaToken,
    companyId,
    signatureStatus,
    onDocRef,
    scale,
    isFinancial,
  });

  const cachedDocument = usePreloadDocuments({
    currentDocumentId: documentID,
    signatureStatus,
    isSupplier,
  });

  const onClosePreview = useCallback(() => setPreviewOpen(false), [setPreviewOpen]);
  const Portal = portals[currentSidebar];

  return (
    <>
      {isProtected || passwordError ? (
        <>
          <Portal>{children}</Portal>
          <Protected
            doctype={doctype}
            error={passwordError}
            documentsWidth={documentsWidth}
            onPasswordEnter={onPasswordEnter}
          />
        </>
      ) : (
        <div className={classes.pdfContainer} ref={documentContainerRef}>
          {pageParams.get(0)?.status !== 'complete' && <ProgressBar value={cachedDocument.downloadProgress} />}
          {pageParams.size > 1 && viewArrangement !== 'jeOnly' ? (
            <Portal>
              <DocumentNavBar
                file={cachedDocument.blob}
                pageParams={pageParams}
                onSelect={selectPage}
                focusedPage={focusedPage}
                isOpen={isPreviewOpen}
                setOpen={setPreviewOpen}
                password={password}
              >
                {children}
              </DocumentNavBar>
            </Portal>
          ) : (
            <Portal>{children}</Portal>
          )}
          {cachedDocument.blob && (
            <PdfViewerDocument
              className={cx(classes.pdfWrapper, {
                [classes.general]: !isFinancial,
                [classes.financial]: isFinancial,
                [classes.splitView]: isFinancial,
              })}
              file={cachedDocument.blob}
              inputRef={handleViewportRef}
              onLoadError={onDocumentLoadError}
              onLoadSuccess={loadCancelable.execute}
              loading={<div />}
              onScroll={refreshScreen}
              width={300}
              onPassword={(cp, reason) => {
                // NEED_PASSWORD: 1,
                // INCORRECT_PASSWORD: 2
                cp(password);
                if (reason === 2) {
                  setPasswordError(true);
                }
              }}
            >
              {isSelectTableMode && (
                <SelectTableHeader
                  setSelectTableHeaderRef={setSelectTableHeaderRef}
                  documentContainerRef={documentContainerRef}
                />
              )}
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events */}
              <div
                className={cx(classes.pageList, { [classes.generalPageList]: !isFinancial })}
                onScroll={onScroll}
                ref={handleFinancialRef}
                onClick={onClosePreview}
              >
                {sourceID && (
                  <DocumentDuplicateAlert
                    versionID={versionID || 1}
                    href={generatePath(ROUTES_PATH.DOCUMENT.absolute, {
                      companyId,
                      documentId: sourceID,
                    })}
                  />
                )}

                {pageParams.map((params, idx) => (
                  <div
                    key={`${documentID}-${idx.toString()}`}
                    className={cx(classes.item, classes.paid)}
                    ref={handlePageRef(idx)}
                    data-attr={`${documentID}-${idx}`}
                    style={{ zoom: frameResizeScale }}
                  >
                    {isPageShow(params) ? (
                      <Page
                        idx={params.idx}
                        total={pageParams.size}
                        document={documentID}
                        width={viewportWidth}
                        isFinancial={isFinancial}
                        isPaid={isPaid}
                        isVisibleStamp={isVisibleStamp}
                        params={params}
                        scale={scale}
                        stampMode={stampMode}
                        onScale={onScale}
                        onRenderSuccess={renderCancelable.execute}
                        onRotate={handleRotate}
                        isStatement={isStatement}
                        showGridBetaLabel={showGridBetaLabel}
                        container={scrollViewRef}
                        polygons={preparePolygonsForPage(params.idx)}
                        getIsDisabledZoomButton={getIsDisabledZoomButton}
                        onZoom={onZoom}
                        onReset={onReset}
                        textractEnabledForDocument={textractEnabledForDocument}
                      />
                    ) : null}
                  </div>
                ))}
              </div>
            </PdfViewerDocument>
          )}
        </div>
      )}
    </>
  );
};

export default memo(DocumentComponent);
