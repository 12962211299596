// @flow

// this hook prevents system columns from being selected
export const useRangeSelectionChanged = (gridApi) => {
  /**
   * by default, if a cell is selected
   * and the grid loses focus,
   * the cell gets highlighted - undesired effect (see DA-4597)
   *
   * @summary deselects cell ranges on grid blur
   */
  const onSelectionBlur = (event) => {
    const { relatedTarget } = event;
    if (!relatedTarget || relatedTarget.type === 'checkbox') {
      gridApi.current.clearRangeSelection();
    }
  };
  const onRangeSelectionChanged = (event) => {
    const target = document.activeElement;
    if (target) {
      target.addEventListener('blur', onSelectionBlur, { once: true });
    }

    // '__SERVICE' columns ignore logic
    const cellRanges = event.api.getCellRanges();

    if (!cellRanges || cellRanges.length === 0) return;
    const excludedColumn = cellRanges
      .map((r) => r.columns.find((el) => el.getColId().startsWith('__SERVICE')))
      .filter((r) => r !== undefined).length;

    if (!excludedColumn) return;

    const rangesParams = cellRanges.map(({ startRow, endRow, columns }) => ({
      rowStartIndex: startRow.rowIndex,
      rowStartPinned: startRow.rowPinned,
      rowEndIndex: endRow.rowIndex,
      rowEndPinned: endRow.rowPinned,
      columns: columns.map((el) => el.getColId()).filter((el) => !el.startsWith('__SERVICE')),
    }));

    event.api.clearRangeSelection();
    rangesParams.forEach((params) => {
      if (params.columns.length) {
        event.api.addCellRange(params);
      }
    });
  };
  return { onRangeSelectionChanged };
};
